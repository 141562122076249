import React, { useEffect, useState, useContext } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,SelectViewWithHeading
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import config from "../../../config";
import { validateNumber } from "../../../Utils";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { getDeviceInfo, getGroupAuthId } from "../../Kalyanlite/utils";

const ProfessionalComponent = (props) => {
  const [isShowEdit, setEditView] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [eduactionDropDown, seteduactionDropDown] = useState([]);
  const [employmentDropDown, setemploymentDropDown] = useState([]);
  const [occupationDropDown, setoccupationDropDown] = useState([]);
  const [currencyDropDown, setcurrencyDropDown] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false)


  const formik = useFormik({
    initialValues: {
      eduaction: props?.data?.education ? props?.data?.education[0].id : "",
      employment: props?.data?.employedIn ? props?.data?.employedIn[0].id : "",
      occupation: props?.data?.occupation ? props?.data?.occupation[0].id : "",
      income: props?.data?.monthlyIncome ? props?.data?.monthlyIncome : "",
      currency: props?.data?.currency ? props?.data?.currency : "",
      descriptionEdit:props?.data?.descriptionEdit
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      eduaction: Yup.string().required("Please Select Education"),
      employment: Yup.string().required("Please Select Employment Type"),
      occupation: Yup.string().required("Please Select Occupation"),
      income: Yup.string(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true)
      apiCallProfessional(values);
    },
  });
  // //console.log(formik.values.income,"sub");
  // //console.log(props?.data?.monthlyIncome ,"sub2");
  const SubmitCall = (value) => {
    //console.log(value);
  };
  useEffect(() => {
    seteduactionDropDown(props?.dropDown?.educationData);
    setemploymentDropDown(props?.dropDown?.employedInData);
    setoccupationDropDown(props?.dropDown?.occupationData);
    setcurrencyDropDown(props?.dropDown?.currency);
  }, [props?.dropDown]);


  
  const apiCallProfessional = async (value) => {
    
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      education: value.eduaction,
      occupationId: value.occupation ,
      employedInId: value.employment,
      currencyName: value.currency,
      monthly: value.income ,
      descriptionEdit:value?.descriptionEdit,
      registerfrom:"Desktop",
      ...(value.income != "" && {monthlyincomerequesttypeid:20}),
      requestfulfilledfrom:getDeviceInfo(),
      requestfulfilledvia:"viewprofile",
      recieverprofileid:getGroupAuthId()    
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editeducationinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false)
        props.callBackReload();
        setEditView(false);
        onClickTrack(EventName.profileProfessionalSaveButton , {[EventKeys.profileProfessional]:value})
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const { onClickTrack } = useAnalytics()

  return (
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="md:mb-8 p-5 md:p-0">
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>

          <BasicTextView
            title={"Education"}
            value={
              props?.data?.education
                ? props?.data?.education[0].name
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Employment in"}
            value={
              props?.data?.employedIn
                ? props?.data?.employedIn[0].name
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Occupation"}
            value={
              props?.data?.occupation
                ? props?.data?.occupation[0].name
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Monthly Income"}
            value={
              props?.data?.monthlyIncome && props?.data?.currency
                ? (props?.data?.currency)+" "+(props?.data?.monthlyIncome)
                : "Not Specified"
            }
          />
        </div>
      )}

      {/* <!-- edit part  --> */}
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className="p-5 md:p-0">
            <SelectViewWithHeading
              title={"Highest Education"}
              inputID={"eduaction"}
              inputName={"eduaction"}
              value={formik.values.eduaction}
              onChange={formik.handleChange}
              error={formik.errors.eduaction}
              options={eduactionDropDown}
              required={true}
            />
            {/* <SelectInput
              name="employment"
              title="Employment Type"
              value={selectedCountry}
              onChange={(selected, ev) => {
                formik.setFieldValue("employment", selected.id)
              }}
              error={formik.errors.employment}
              defaultValue={formik.values.employment}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...countryDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            /> */}
            <BasiSelectView
              title={"Employment Type"}
              inputID={"employment"}
              inputName={"employment"}
              value={formik.values.employment}
              onChange={formik.handleChange}
              error={formik.errors.employment}
              options={employmentDropDown}
              required={true}
            />
            <SelectViewWithHeading
              title={"Occupation"}
              inputID={"occupation"}
              inputName={"occupation"}
              value={formik.values.occupation}
              onChange={formik.handleChange}
              error={formik.errors.occupation}
              options={occupationDropDown}
              required={true}
            />
            {/* <BasiSelectView
              title={"Monthly Income"}
              inputID={"height"}
              inputName={"height"}
              value={formik.values.income}
              onChange={formik.handleChange}
              error={formik.errors.income}
              options={[
                "4ft 6in - 137 cms",
                "4ft 7in - 137 cms",
                "4ft 8in - 137 cms",
                "5ft 6in - 137 cms",
              ]}
            /> */}
            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="col-span-4 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                  Monthly Income
                  {/* <span className="text-[#D10A11]">*</span> */}
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">                
                  <div className="flex space-x-3 ">
                    <label className="select font-bold text-[#575556] w-[8rem] ">
                      <select
                        name="currency"
                        id="currency"
                        value={formik.values.currency}
                        onChange={formik.handleChange}
                        className=" md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] font-semibold text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[8px]   "
                      >
                        {currencyDropDown?.map((e, i) => (
                          <option key={i} value={e.currency}>
                            {e.currency}
                          </option>
                        ))}
                      </select>
                    </label>
                    <p className=" flex items-center pt-[5px] col-span-4 RegPhone">
                      <input
                        type="tel"
                        id="income"
                        name="income"
                        className="RegPhone  border border-[#8F8F8F]   text-sm  rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5 font-semibold text-[#575556]"
                        value={formik.values.income}
                        onChange={(e)=>{
                          if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                          }
                        }}
                      />
                    </p>
                  </div>
                  {formik.errors.income && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.income}
                    </p>
                  )}               
              </div>
            </div>
            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                setEditView(false);
                props.refresh()
                onClickTrack(EventName.profileProfessionalCancelButton)
              }}
              submitStatus={submitStatus}
            />
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default ProfessionalComponent;
