import { call, put, takeEvery } from "redux-saga/effects";

//constant file
import Constant, { EditProfileMenu } from '../../Constants/constants'
import RouteSegment, { RouteHelper, RoutePaths } from '../../Routes/RouteSegment'


// Login Redux States
import { CHANGE_MOBILE_NO, COMMUNITY_SITE_DETAIL, GET_BASIC_DATA, GET_BASIC_DATA_SUCCESS, LOGIN_FOR_AKHAYATRITIYA, LOGIN_FROM_ADMIN_PARTIAL_USER, LOGIN_FROM_ADMIN_USER, LOGIN_FROM_EMAIL_USER, LOGIN_FROM_OFFLINE, LOGIN_FROM_SMS_USER, LOGIN_USER, MOBILE_VERIFICATION, MOBILE_VERIFICATION_FRESH, MORE_LINK_CONTENT, OTP_VALIDATION, REQUEST_AGAIN, RESET_PASSWORD, SEND_OTP, SITE_MAP} from "./ActionTypes";
import { apiError, loginSuccess, getBasicDataSuccess, resetPasswordApiError, resetPasswordSuccess, sendOTPSuccess, sendOTPApiError, OTPValidationSuccess, OTPValidationApiError, mobileVerificationSuccess, mobileVerificationApiError, requestAgainSuccess, requestAgainApiError, mobileVerificationFreshApiError, mobileVerificationFreshSuccess, changeMobileNoSuccess, changeMobileNoapiError, moreLinkContentSuccess, moreLinkContentApiError, loginFromAdminSuccess, loginFromAdminApiError, communitySiteDetailSuccess, communitySiteDetailApiError, loginFromEmailSuccess, loginFromEmailApiError, loginForAkhayatritiyaSuccess, loginForAkhayatritiyaApiError, loginFromAdminPartialUser, loginFromAdminPartialSuccess, loginFromAdminPartialApiError, siteMapDetailSuccess, siteMapDetailApiError, loginFromOfflineSuccess, loginFromOfflineError, loginForViewProfileSuccess, loginForViewProfileError } from "./Action";
import { Login, ResetPassword, BasicData, SendOTP, OTPValidation, MobileVerification, RequestAgain, MobileVerificationFresh, ChangeMobileNo, MoreLinkContent, AdminLogin, SMSLogin, EmailLogin, CommunitySiteData, AkhayatritiyaLogin, PartialAdminLogin, SiteMapData, Update_Notification_Status, CMS_Login, CMS_Login_via, offlineProfileLogin, viewProfileLogin } from '../../Helpers/api_helpers'
import { SMS_LOGIN } from "../../Helpers/EndPoints";
import Constants from "../../Constants/constants";
import RouteSegments from "../../Routes/RouteSegment";
import { addPoint } from "../KalyanRewards/Action";


function* loginUser({ payload: { user, history } }) {
  //console.log(history,"history");
  const isMobileSite = localStorage.getItem(Constant.loginLocalStorageKeys.loginDevice) === "Mobile Site";

  const isCheckboxTrue = localStorage.getItem(Constants.loginLocalStorageKeys.isCheckbox) === "true";
  const isCheckedTrue = localStorage.getItem(Constants.loginLocalStorageKeys.isChecked) === "true";
  const isFromNotLoginOrNotPresent = localStorage.getItem(Constants.loginLocalStorageKeys.From) == "sign in" ;

  const rememberMe =  
    isMobileSite && localStorage.getItem(Constants.loginLocalStorageKeys.isFrom) == "login" && isCheckboxTrue ? { remember_me: true } :
    isMobileSite && localStorage.getItem(Constants.loginLocalStorageKeys.isFrom) == "sign in" && isCheckedTrue ? { remember_me: true } :
    { remember_me: false };

  const device = 
    isMobileSite && localStorage.getItem(Constants.loginLocalStorageKeys.isFrom) == "login" && isCheckboxTrue ? { device: "yes"  } :
    isMobileSite && localStorage.getItem(Constants.loginLocalStorageKeys.isFrom) == "sign in" && isCheckedTrue ? { device: "yes"  } :
    { device: "no" };
  try {
    ////console.log(user,"123");
    const response = yield call(Login, {
      email: user.name ?? null,
      password: user.password,
      loginFrom: localStorage.getItem(Constant.loginLocalStorageKeys.loginDevice),
      ioskey: "",
      androidkey: "",
      webKey: localStorage.getItem(Constant.loginLocalStorageKeys.fcmToken),
      offlinepack:user.offlinepack ?? undefined,
      ...device
    });
    ////console.log(response.data.data,"login response");
    if(response.data.status === "Success"){
    if (response.data.data.status === "SUCCESS") {
      localStorage.setItem(Constant.loginLocalStorageKeys.loginFrom,new URL(window.location.href).pathname+new URL(window.location.href).search)
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)

      // if(localStorage.getItem("checkbox") == "true"){
        localStorage.setItem("loggedIn",true)
        localStorage.setItem("pw",user.password) //pw
      // }

      const reg = new RegExp('^[0-9]+$');
      if (response.data.data?.selectedversion == "V3") {
        localStorage.setItem("isV3User", true)
        const cms_response = yield call(CMS_Login, {
          [reg.test(user.name) ? "mobile_number" : "email"]: user.name ?? null,
          // email: response.data.data.userName,
          password: user.password,
          ...(isMobileSite && { device: "Mobile Site" }),
          ...rememberMe
        });

        if (cms_response.data) {
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLogin,
            String(cms_response?.data?.access_token) ?? "OLD"
          );
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLoginId,
            String(cms_response?.data?.id) ?? ""
          );
        }
      }
      yield put(loginSuccess(response));
      localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
      localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
      localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
      localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
      yield put(addPoint({activityId:1}))
      if (response.data.data?.paymentToken) {
        window.location.href = response.data.data?.paymentToken
      } else if (response.data.data.nextPage == "Redirect") {
        // history(`${response.data.data.redirect}`)
        window.location.href = "https://" + response.data.data.redirect
      } else if (response.data.data.nextPage === "phoneNumberEdit") {
        localStorage.setItem("phoneNumberEdit",true)
        history(`${RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath)}`)
      }else if (response.data.data.nextPage === "phoneNumberEdit") {
        localStorage.setItem("phoneNumberEdit",true)
        history(`${RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath)}`)
      } else if(user.profileid && user.action == "view"){
        if(response.data.data?.selectedversion == "V3"){
          
            history(RouteSegments.MATCH_CONVERSATIONS_LITE, {
              state: {
                isFrom: "login",
                id: user.profileid,
                isFromChat: false,
                from: "search"
              }
            })
        }else{
          history(RouteHelper.getProfileUrl, { state: { id: user.profileid.replace( /KM/g, "" ) } })
        }
      }
      
      else {
        localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`/${response.data.data.nextPage}`)
        history(`/${response.data.data.nextPage}`)
      }
    }
    else {
      // localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginSuccess(response));
    }
  }
  else {
    localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
    yield put(apiError(response));
  }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* changeMobileNo({ payload: { user } }) {
  //console.log(user,"user");
  try {
    //console.log(user,"123");
    const response = yield call(ChangeMobileNo, {
      userId: localStorage.getItem(Constant.loginLocalStorageKeys.loginId),
      email:localStorage.getItem(Constant.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constant.loginLocalStorageKeys.loginName),
      changeNumberFlag: "mobile",
      isdcode: user.ISDCode,
      mobileNumber: user.MobileNumber
    });
    //console.log(response.data,"login response");
    if (response.data.status === "Success") {
      yield put(changeMobileNoSuccess(response));
    } else {
      //console.log(user,"123");
      yield put(changeMobileNoapiError(response));
    }
  } catch (error) {
    //console.log(user,"123");
    yield put(sendOTPApiError(error));
  }
}

function* sendOTP({ payload: { user } }) {
  //console.log(user,"user");
  try {
    //console.log(user,"123");
    const response = yield call(SendOTP, {
      userId: localStorage.getItem(Constant.loginLocalStorageKeys.loginId),
      email:localStorage.getItem(Constant.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constant.loginLocalStorageKeys.loginName),
      loginFrom: localStorage.getItem(Constant.loginLocalStorageKeys.loginDevice),
      isdcode: user[0].isdcode,
      mobileNumber: user[0].mobileNo
    });
    //console.log(response.data,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(sendOTPSuccess(response));
      if(response.data.data.nextPage == "basicdata"){
        ////console.log("123",response.data.data.nextPage);
      }
      ////console.log("123");
    } else {
      //console.log(user,"123");
      yield put(sendOTPApiError(response));
    }
  } catch (error) {
    //console.log(user,"123");
    yield put(sendOTPApiError(error));
  }
}

function* OTPValidationCall({ payload: { user, requestData, phone, history, formik } }) {
  // function* resetPassword({ payload: {id, user, history } }) {
    //console.log(user,requestData,phone,"pinnn");
    try {
      const response = yield call(OTPValidation, {
        userId:user,
        otp:requestData.pin,
        mobileNumber:phone
      });
      //console.log(response.data.status,"reset password response");
      if (response?.data?.status === "Success") {
        if (response?.data?.data?.status === "FAILURE") {
          formik.setFieldError("pin", response?.data?.data?.statusMessage)
          yield put(OTPValidationSuccess(response));
        }
        else{
          yield put(OTPValidationSuccess(response));
          history(RouteSegment?.FORGOT_PASSWORD)
        }
        ////console.log("123");
    } else {
      yield put(OTPValidationApiError(response));
    }
  } catch (error) {
    yield put(OTPValidationApiError(error));
  }
}

function* mobileVerificationCall({ payload: { user,requestData, history,setIsSubmitting } }) {
  try {
    //console.log(user,requestData,history,"123");
    const response = yield call(MobileVerification, {
      email: localStorage.getItem(Constant.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constant.loginLocalStorageKeys.loginName),
      userId: localStorage.getItem(Constant.loginLocalStorageKeys.loginId),
      loginFrom: localStorage.getItem(Constant.loginLocalStorageKeys.loginDevice),
      otp: user.PINNumber,
    });
    console.log(response.data.status,"response.data.status");
    if (response.data.status === "Success") {
      yield put(mobileVerificationSuccess(response));
      console.log(response.data.data,"response.data.data");
      if (response.data.data.status === "SUCCESS") {



        // localStorage.setItem(Constant.loginLocalStorageKeys.loginFrom,new URL(window.location.href.hostname))
        localStorage.setItem(Constant.loginLocalStorageKeys.loginFrom,new URL(window.location.href).pathname+new URL(window.location.href).search)
        localStorage.setItem(Constant.loginLocalStorageKeys.isFirstLogin,"true")
        localStorage.setItem(Constant.loginLocalStorageKeys.verificationLoginFrom,response.data.data.loginFrom)

        if (response.data.data?.selectedversion == "V3") {
          localStorage.setItem("isV3User", true)
          const cms_response = yield call(CMS_Login_via, {
            userId: localStorage.getItem(Constant.loginLocalStorageKeys.loginId),
          });

          if (cms_response.data) {
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLogin,
              String(cms_response?.data?.access_token) ?? "OLD"
            );
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLoginId,
              String(cms_response?.data?.id) ?? ""
            );
          }
          history(RouteSegment.MATCH_LITE, { replace: true });
        } else {
          history(RouteSegment.DASHBOARD, { replace: true });
        }


        //to allow navigation into after reg page
        localStorage.setItem(Constants.loginLocalStorageKeys.allowAfterRegPage,"true")
        localStorage.removeItem("preferenceFlowState")
        localStorage.removeItem("parentsNumberFlowState")
        localStorage.removeItem("isOriginalImageUploaded")
        localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage,RouteSegment.AFTER_REGISTRATION)
        history(RouteSegment.AFTER_REGISTRATION);
        // history(RouteSegment.MEMBERSHIP_REGISTER);
      }
      if(setIsSubmitting){
        setIsSubmitting(false)
      }
    } else {
      if(setIsSubmitting){
        setIsSubmitting(false)
      }
      yield put(mobileVerificationApiError(response));
    }
  } catch (error) {
    console.log("error-mobile-verification",error);
    yield put(mobileVerificationApiError(error));
  }
}

function* mobileVerificationFreshCall({ payload: { user,requestData, history } }) {
  try {
    //console.log(user,requestData,history,"123");
    const response = yield call(MobileVerificationFresh, {
      email: localStorage.getItem(Constant.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constant.loginLocalStorageKeys.loginName),
      userId: localStorage.getItem(Constant.loginLocalStorageKeys.loginId)
    });
    //console.log(response.data,"login response");
    if (response.data.status === "Success") {
      yield put(mobileVerificationFreshSuccess(response));
      history(RouteSegment.DASHBOARD);
    } else {
      yield put(mobileVerificationFreshApiError(response));
    }
  } catch (error) {
    yield put(mobileVerificationFreshApiError(error));
  }
}

function* requestAgainCall({ payload: { user } }) {
  try {
    //console.log(user,"123");
    const response = yield call(RequestAgain, {
      email: localStorage.getItem(Constant.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constant.loginLocalStorageKeys.loginName),
      userId: localStorage.getItem(Constant.loginLocalStorageKeys.loginId)
    });
    //console.log(response.data.data,"login response");
    if (response.data.status === "Success") {
      yield put(requestAgainSuccess(response));
    } else {
      yield put(requestAgainApiError(response));
    }
  } catch (error) {
    yield put(requestAgainApiError(error));
  }
}

function * getBasicData ({payload: {userId}}){
  ////console.log(userId,"123");
  try {
    ////console.log(userId,"123");
    const responseData = yield call(BasicData, {
      "userId": userId,
      "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "registerFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),

  });
    ////console.log(responseData.data.data,"basic data response");
    if (responseData.data.data.status === "SUCCESS") {
      yield put(getBasicDataSuccess(responseData));
    }
  }
  catch (error) {
    yield put(apiError(error));
  }
}

//reset password
function* resetPassword({ payload: { user, id, history } }) {
  ////console.log(user,"123");
  try {
    //console.log(history,"123");
    const response = yield call(ResetPassword, {
      userId:id,
      password:user.confirmPassword
    });
    //console.log(response.data.status,"reset password response");
    if (response.data.status === "Success") {
      if(response.data.data.status === "SUCCESS"){
        yield put(resetPasswordSuccess(response));
        history(RouteSegment?.HOME)
      }else{
        yield put(resetPasswordApiError(response));
      }
    } else {
      yield put(resetPasswordApiError(response));
    }
  } catch (error) {
    yield put(resetPasswordApiError(error));
  }
}

//more link content 
function* moreLinkContent({ payload: { user } }) {
  //console.log(user,"123");
  try {
    ////console.log(user,"123");
    const response = yield call(MoreLinkContent);
    ////console.log(response.data.data.status,"reset password response");
    if (response.data.status === "Success") {
      yield put(moreLinkContentSuccess(response));
      ////console.log("123");
    } else {
      yield put(moreLinkContentApiError(response));
    }
  } catch (error) {
    yield put(moreLinkContentApiError(error));
  }
}

//login from admin
function* adminLoginUser({ payload: { user, history, from } }) {
  //console.log(history,"history");
  try {
    const response = yield call(from == "loginfromadmin"?AdminLogin:from == "loginfromsms"?SMSLogin:EmailLogin, user);
    if (response.data.status === "Success") {
      if (response.data.data.userId) {
        localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)


        if (response.data.data?.selectedversion == "V3") {
          localStorage.setItem("isV3User", true)
          const cms_response = yield call(CMS_Login_via, {
            userId: response.data.data.userId,
          });

          if (cms_response.data) {
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLogin,
              String(cms_response?.data?.access_token) ?? "OLD"
            );
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLoginId,
              String(cms_response?.data?.id) ?? ""
            );
          }
        }

        yield put(loginFromAdminSuccess(response));

        if (response.data?.data?.KPcustomer != "Y") {
          localStorage.setItem(Constant.loginLocalStorageKeys.loginFromAdmin, true) 
        }
        localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
        localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
        localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
        localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
        localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`${response.data.data.nextPage}`)

        //to allow navigation into after reg page
        if (response.data.data.nextPage == RouteSegment.AFTER_REGISTRATION) {
          localStorage.setItem(Constants.loginLocalStorageKeys.allowAfterRegPage, "true")
          localStorage.removeItem("preferenceFlowState")
          localStorage.removeItem("parentsNumberFlowState")
          localStorage.removeItem("isOriginalImageUploaded")
          localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegment.AFTER_REGISTRATION)
        }
        history(`${response.data.data.nextPage}`);
      }
      else{
        yield put(loginFromAdminSuccess(response));
      }
    } else {
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginFromAdminApiError(response));
    }
  } catch (error) {
    yield put(loginFromAdminApiError(error));
  }
}

//login from partial admin
function* adminLoginPartialUser({ payload: { user, history, from } }) {
  try {
    const response = yield call(PartialAdminLogin, user);
    if (response.data.status === "Success") {
      if (response.data.data.userId) {
        localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
        yield put(loginFromAdminPartialSuccess(response));

        if (response.data.data?.selectedversion == "V3") {
          localStorage.setItem("isV3User", true)
          const cms_response = yield call(CMS_Login_via, {
            userId: response.data.data.userId,
          });

          if (cms_response.data) {
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLogin,
              String(cms_response?.data?.access_token) ?? "OLD"
            );
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLoginId,
              String(cms_response?.data?.id) ?? ""
            );
          }
        }
        
        localStorage.setItem(Constant.loginLocalStorageKeys.loginFromAdmin,true) 
        localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
        localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
        localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
        localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
        localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`${response.data.data.nextPage}`)
        history(`${response.data.data.nextPage}`);
      }
      else{
        yield put(loginFromAdminPartialSuccess(response));
      }
    } else {
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginFromAdminPartialApiError(response));
    }
  } catch (error) {
    yield put(loginFromAdminPartialApiError(error));
  }
}

//login from admin
function* adminEmailUser({ payload: { user, history, from } }) {
  try {
    const response = yield call(EmailLogin, {
      jwt:user.jwt?user.jwt:undefined,
      photo:user.photo?user.photo:undefined,
      otherMailFlag:user.otherMailFlag?user.otherMailFlag:undefined,
      toProfileId:user.toProfileId?user.toProfileId:undefined,
      matching:user.matching?user.matching:undefined,
      editpreferences:user.editpreferences?user.editpreferences:undefined,
      upgrade:user.upgrade?user.upgrade:undefined,
      horoscope:user.horoscope?user.horoscope:undefined,
      trust:user.trust?user.trust:undefined,
      manage:user.manage?user.manage:undefined,
      bridexmail:user.bridexmail?user.bridexmail:undefined,
      profileId:user.profileId?user.profileId:undefined,
      settings:user.settings?user.settings:undefined,
      inboxunread:user.inboxunread?user.inboxunread:undefined,
      upgradeFlag:user.upgradeFlag?user.upgradeFlag:undefined,
      appoinment:user.appoinment?user.appoinment:undefined,
      fairPolicyFlag:user.fairPolicyFlag?user.fairPolicyFlag:undefined,
      muhurat:user.muhurat?user.muhurat:undefined,
      parentsnumber:user.parentsnumber?user.parentsnumber:undefined,
      email:user.email?user.email:undefined,
      password:user.password?user.password:undefined,
      profileRegisterFrom:user.profileRegisterFrom?user.profileRegisterFrom:undefined,
      admuserid:user.admuserid?user.admuserid:undefined,
    });
    if (response.data.status === "Success") {
      if (response.data.data.userId) {
        localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)


        const isV3 = response.data.data?.selectedversion == "V3";

        if (isV3) {
          localStorage.setItem("isV3User", true)
          const cms_response = yield call(CMS_Login_via, {
            userId: response.data.data.userId,
          });

          if (cms_response.data) {
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLogin,
              String(cms_response?.data?.access_token) ?? "OLD"
            );
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLoginId,
              String(cms_response?.data?.id) ?? ""
            );
          }
        }

        yield put(loginFromAdminSuccess(response));

        localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
        localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
        localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
        localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
        if(response.data.data?.nextPage === RouteHelper.getProfileUrl){
          history(`${response.data.data.nextPage}`,{replace:true,state:{id: user.toProfileId}});
        } else if (response.data.data?.nextPage === RouteSegments.MATCH_LITE && user.toProfileId) {
          history(RouteSegments.MATCH_CONVERSATIONS_LITE, {
            state: {
              isFrom: "login",
              id: "KM" + user.toProfileId,
              isFromChat: false,
              from: "search"
            }
          })
        }else{
          localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`${response.data.data.nextPage}`)
          history(`${response.data.data.nextPage}`,{replace:true});
        }
      }
      else{
        yield put(loginFromAdminSuccess(response));
      }
    } else {
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginFromEmailApiError(response));
    }
  } catch (error) {
    yield put(loginFromEmailApiError(error));
  }
}

//login from sms
function* adminSMSUser({ payload: { user, history, from } }) {
  console.log(user.profiletoken,"profiletokenprofiletoken");
  try {
    const response = yield call(SMSLogin, {
      jwt: user.jwt?user.jwt:undefined,
      profiletoken: user.profiletoken?user.profiletoken:undefined,
      photo:user.photo?user.photo:undefined,
      otherMailFlag:user.otherMailFlag?user.otherMailFlag:undefined,
      toProfileId:user.toProfileId?user.toProfileId:undefined,
      matching:user.matching?user.matching:undefined,
      editpreferences:user.editpreferences?user.editpreferences:undefined,
      upgrade:user.upgrade?user.upgrade:undefined,
      upgradeFlag:user.upgradeFlag?user.upgradeFlag:undefined,
      horoscope:user.horoscope?user.horoscope:undefined,
      trust:user.trust?user.trust:undefined,
      manage:user.manage?user.manage:undefined,
      bridexmail:user.bridexmail?user.bridexmail:undefined,
      profileId:user.profileId?user.profileId:undefined,
      settings:user.settings?user.settings:undefined,
      email:user.email?user.email:undefined,
      password:user.password?user.password:undefined,
      inboxunread:user.inboxunread?user.inboxunread:undefined,
      appoinment:user.appoinment?user.appoinment:undefined,
      fairPolicyFlag:user.fairPolicyFlag?user.fairPolicyFlag:undefined,
      muhurat:user.muhurat?user.muhurat:undefined,
      parentsnumber: user.parentsnumber ? user.parentsnumber : undefined,
      mobileverification: user.mobileverification ? user.mobileverification : undefined,
      pp_matches: user.pp_matches ? user.pp_matches : undefined,
      replypending: user.replypending ? user.replypending : undefined,
      recommendation: user.recommendation ? user.recommendation : undefined,
      parentsMobileNumber: user.parentsMobileNumber ? user?.parentsMobileNumber : undefined,
      whatsappverified: user.whatsappverified ? user?.whatsappverified : undefined,
      offlinepack: user.offlinepack ? user?.offlinepack : undefined,
      mobilenumber: user.mobilenumber ? user?.mobilenumber : undefined,
      loginfrom: user.loginfrom ? user?.loginfrom : undefined,
      startmessage: user.startmessage ? user?.startmessage : undefined,
      iswhatsappClick: user.iswhatsappClick ? user?.iswhatsappClick : undefined,
      messageId: user.messageId ? user?.messageId : undefined,
      trialpackpopup:user?.trialpackpopup ? user?.trialpackpopup : undefined,
    });
    if (response.data.status === "Success") {
      if (response.data.data.userId) {
        if (user.notificationId) {
          const res = yield call(Update_Notification_Status, {
            "email": user.email,
            "userId": response.data.data.userId,
            "isClicked": true,
            "notificationId": user.notificationId,
          })
        }
        localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)


        if (response.data.data?.selectedversion == "V3") {
          localStorage.setItem("isV3User", true)
          const cms_response = yield call(CMS_Login_via, {
            userId: response.data.data.userId,
          });

          if (cms_response.data) {
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLogin,
              String(cms_response?.data?.access_token) ?? "OLD"
            );
            localStorage.setItem(
              Constant.loginLocalStorageKeys.chatLoginId,
              String(cms_response?.data?.id) ?? ""
            );
          }
        }

        yield put(loginFromAdminSuccess(response));

        sessionStorage.setItem(Constants.loginLocalStorageKeys.loginFromUrl, window.location.href)
        sessionStorage.setItem(Constants.loginLocalStorageKeys.isLoginFromSms, "true")

        localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
        localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
        localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
        localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
        if (user.notificationId) {
          sessionStorage.setItem(Constant.loginLocalStorageKeys.notificationId, user.notificationId)
          sessionStorage.setItem(Constants.loginLocalStorageKeys.loginUrl, window.location.href)
        }
        if(response.data.data?.nextPage === RouteHelper.getProfileUrl){
          history(`${response.data.data.nextPage}`,{replace:true,state:{id: user.toProfileId}});
        } else if (response.data.data?.nextPage === RouteSegments.MATCH_LITE && user.toProfileId) {
          history(RouteSegments.MATCH_CONVERSATIONS_LITE, {
            state: {
              isFrom: "login",
              id: "KM" + user.toProfileId,
              isFromChat: false,
              from: "search"
            }
          })
        }else if (response.data.data?.nextPage === RouteSegments.OFFLINE_PROFLIE && user.toProfileId) {
          history(`${response.data.data.nextPage}`, {
            replace:true,
            state: {
              isFrom: "login",
              profile_id:user.toProfileId
            }
          })
        }
        else if (response.data.data?.nextPage === RouteSegments.TRIALPACK && user.toProfileId) {
          history(`${response.data.data.nextPage}`, {
            replace:true,
            state: {
              isFrom: "login",
              profile_id:user.toProfileId
            }
          })
        }
        else if (user.mobilenumber == "Y" && user.toProfileId) {
          if (response.data.data?.selectedversion == "V2") {
            
            const cms_response = yield call(CMS_Login_via, {
              userId: response.data.data.userId,
            });
  
            if (cms_response.data) {
              localStorage.setItem(
                Constant.loginLocalStorageKeys.chatLogin,
                String(cms_response?.data?.access_token) ?? "OLD"
              );
              localStorage.setItem(
                Constant.loginLocalStorageKeys.chatLoginId,
                String(cms_response?.data?.id) ?? ""
              );
            }
          }

          history(RouteSegments.OFFLINE_PROFLIE,{replace:true,state:{isFrom: "login",profile_id: user.toProfileId,isPhonePopup:user.mobilenumber}});
        }
        else{
          localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`${response.data.data.nextPage}`)

          //to allow navigation into after reg page
          if (response.data.data.nextPage == RouteSegment.AFTER_REGISTRATION) {
            localStorage.setItem(Constants.loginLocalStorageKeys.allowAfterRegPage, "true")
            localStorage.removeItem("preferenceFlowState")
            localStorage.removeItem("parentsNumberFlowState")
            localStorage.removeItem("isOriginalImageUploaded")
          }
          let state = {};
          const redirectUrl = `${response.data.data.nextPage}`;

          if (redirectUrl.includes(RouteSegment.PROFILELOOKINGFOR)) {
            state.isfrom = "lookingfor"
          } else if (redirectUrl.includes(RouteSegment.RECOMMENDED)) {
            state.isfrom = "recommended"
          }

          history(`${response.data.data.nextPage}`, { replace: true, state });
        }
      }
      else{
        yield put(loginFromAdminSuccess(response));
      }
    } else {
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginFromAdminApiError(response));
    }
  } catch (error) {
    yield put(loginFromAdminApiError(error));
  }
}

//akhayatritiya
function* akhayatritiyaUser({ payload: { user, history, from } }) {
  try {
    const response = yield call(AkhayatritiyaLogin, {
      profileId:user.profileId?user.profileId:undefined,
      mobileNumber:user.mobileNumber?user.mobileNumber:undefined,
      sentFrom:user.sentFrom?user.sentFrom:undefined
    });
    if (response.data.status === "Success") {
        localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
        yield put(loginForAkhayatritiyaSuccess(response));

      if (response.data.data?.selectedversion == "V3") {
        localStorage.setItem("isV3User", true)
        const cms_response = yield call(CMS_Login_via, {
          userId: response.data.data.userId,
        });

        if (cms_response.data) {
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLogin,
            String(cms_response?.data?.access_token) ?? "OLD"
          );
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLoginId,
            String(cms_response?.data?.id) ?? ""
          );
        }
      }

        localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
        localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
        localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
        localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
        // history(`/${response.data.data.nextpage}`);
        localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`${response.data.data.nextPage}`)
        window.location.href = response.data.data.nextpage
    } else {
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginForAkhayatritiyaApiError(response));
    }
  } catch (error) {
    yield put(loginForAkhayatritiyaApiError(error));
  }
}

// get community detail
function* communitySiteDetailCall({ payload: { value, id, history } }) {
  try {
    const response = yield call(CommunitySiteData, {
      community:value,
    });
    console.log(response,"responseresponse");
    if (response.data.status === "Success") {
      yield put(communitySiteDetailSuccess(response));
    } else {
      yield put(communitySiteDetailApiError(response));
    }
  } catch (error) {
    yield put(communitySiteDetailApiError(error));
  }
}

// site map detail
function* SiteMapDetailCall({ payload: { value } }) {
  try {
    const response = yield call(SiteMapData, value);
    console.log(response,"responseresponse")
    if (response.data.status === "Success") {
      yield put(siteMapDetailSuccess(response));
    } 
    else {
      yield put(siteMapDetailApiError(response));
    }
  } catch (error) {
    window.location.href="/"
  }
}

// offline
function* offlineProfile({ payload: { user, history, from } }) {
  try {
    const response = yield call(offlineProfileLogin, {

      matching:user.matching?user.matching:undefined,
      profiletoken: user.profiletoken?user.profiletoken:undefined,      
      jwt: user.jwt?user.jwt:undefined,
      toProfileId:user.toProfileId?user.toProfileId:undefined,
      offlinepack: user.offlinepack ? user?.offlinepack : undefined,
      mobilenumber: user.mobilenumber ? user?.mobilenumber : undefined,

    });
    if (response.data.status === "Success") {

        localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
        yield put(loginFromOfflineSuccess(response));

      if (response.data.data?.selectedversion == "V3") {
        localStorage.setItem("isV3User", true)
        const cms_response = yield call(CMS_Login_via, {
          userId: response.data.data.userId,
        });

        if (cms_response.data) {
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLogin,
            String(cms_response?.data?.access_token) ?? "OLD"
          );
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLoginId,
            String(cms_response?.data?.id) ?? ""
          );
        }
      }

      if (response.data.data?.nextPage === RouteSegments.OFFLINE_PROFLIE && user.toProfileId) {
        history(`${response.data.data.nextPage}`, {
          replace:true,
          state: {
            isFrom: "login",
            profile_id:user.toProfileId
          }
        })
      }else if (user.mobilenumber == "Y" && user.toProfileId) {
        history(`${response.data.data.nextPage}`,{replace:true,state:{isFrom: "login",profile_id: user.toProfileId,isPhonePopup:user.mobilenumber}});
      }

        localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
        localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
        localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
        localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
        localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`${response.data.data.nextPage}`)
    } else {
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginFromOfflineError(response));
    }
  } catch (error) {
    yield put(loginFromOfflineError(error));
  }
}

// view Profile
function* viewForProfileLogin({ payload: { user, history, from } }) {
  try {
    const response = yield call(viewProfileLogin, {

      profileid: user.profileid ? user?.profileid : undefined,
      action: user.action ? user?.action : undefined,

    });
    if (response.data.status === "Success") {

        localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
        yield put(loginForViewProfileSuccess(response));

      if (response.data.data?.selectedversion == "V3") {
        localStorage.setItem("isV3User", true)
        const cms_response = yield call(CMS_Login_via, {
          userId: response.data.data.userId,
        });

        if (cms_response.data) {
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLogin,
            String(cms_response?.data?.access_token) ?? "OLD"
          );
          localStorage.setItem(
            Constant.loginLocalStorageKeys.chatLoginId,
            String(cms_response?.data?.id) ?? ""
          );
        }

        history(RouteSegments.MATCH_CONVERSATIONS_LITE, {
          state: {
            isFrom: "login",
            id: "KM" + user.toProfileId,
            isFromChat: false,
            from: "search"
          }
        })
      }else{
          history(RoutePaths.PROFILE, { state: { id: user.toProfileId } })
        }

        localStorage.setItem(Constant.loginLocalStorageKeys.isLoggedIn,true) //is loggedin
        localStorage.setItem(Constant.loginLocalStorageKeys.loginName,response.data.data.userName ?? "") //username
        localStorage.setItem(Constant.loginLocalStorageKeys.loginEmail,response.data.data.userName ?? "")
        localStorage.setItem(Constant.loginLocalStorageKeys.loginId,response.data.data.userId) //userid
        localStorage.setItem(Constant.loginLocalStorageKeys.loginCurrentPage,`${response.data.data.nextPage}`)
    } else {
      localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
      yield put(loginForViewProfileError(response));
    }
  } catch (error) {
    yield put(loginForViewProfileError(error));
  }
}

function* loginSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(CHANGE_MOBILE_NO, changeMobileNo)
  yield takeEvery(SEND_OTP, sendOTP);
  yield takeEvery(OTP_VALIDATION, OTPValidationCall);
  yield takeEvery(MOBILE_VERIFICATION, mobileVerificationCall);
  yield takeEvery(MOBILE_VERIFICATION_FRESH, mobileVerificationFreshCall);
  yield takeEvery(REQUEST_AGAIN,requestAgainCall)
  yield takeEvery(GET_BASIC_DATA, getBasicData)
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(MORE_LINK_CONTENT, moreLinkContent);

  yield takeEvery(LOGIN_FROM_ADMIN_USER, adminLoginUser);
  yield takeEvery(LOGIN_FROM_ADMIN_PARTIAL_USER, adminLoginPartialUser);
  yield takeEvery(LOGIN_FROM_EMAIL_USER, adminEmailUser);
  yield takeEvery(LOGIN_FROM_SMS_USER, adminSMSUser);
  yield takeEvery(LOGIN_FOR_AKHAYATRITIYA, akhayatritiyaUser);
  yield takeEvery(LOGIN_FROM_OFFLINE, offlineProfile);
  yield takeEvery(LOGIN_FROM_OFFLINE, viewForProfileLogin);
  yield takeEvery(COMMUNITY_SITE_DETAIL, communitySiteDetailCall);
  yield takeEvery(SITE_MAP, SiteMapDetailCall);
}

export default loginSaga;
