import React, { useState } from 'react'
import RouteSegments from '../../../Routes/RouteSegment'
import newgropuicon from '../../../Components/Kalyanlite/assets/Images/newgroupicon.svg'
import familygroupicon from '../../../Components/Kalyanlite/assets/Images/familyGroup1.svg'
import searchloveicon from '../../../Components/Kalyanlite/assets/Images/search-love.svg'
import sendarrowicon from '../../../Components/Kalyanlite/assets/Images/sendArrow.svg'
import callChaticon from '../../../Components/Kalyanlite/assets/Images/call-chat.svg'
import WelcomePage from '../WelcomePage'
import PlusIcon from '../../../Components/Kalyanlite/assets/Images/Group 224.svg'
import { useContext } from 'react'
import { NavBarContext } from '../../../Contexts/NavBarContext'
import { useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import Constants from '../../../Constants/constants'
import { CommonQualityPopup, CommonRewardPopup } from '../../CommonPopup'
import Skeleton from 'react-loading-skeleton'

const StartNewGroupPage = ({ familydata,handleAddMembersClick,showParentsNumberPopup,isLoading }) => {
    
    const { setMenuSelect } = useContext(NavBarContext);
    useEffect(() => {
        setMenuSelect("9");
    }, []);

    const isFamilyPage = useMatch(`${RouteSegments.MATCH_GROUP}`);

    const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  
    return (
        <>
       
        
        
            <div className='flex h-full'>
                    
                <div className='md:w-[36%] lg:w-[30%] lg:bg-[#F7F8FA] md:bg-[#F7F8FA] bg-[#ffffff] w-[100%] h-full'>
                    <div class="flex flex-col justify-center items-center h-full">
                        <div class="flex-1 overflow-y-auto lite-scrollbar max-h-[calc(100vh - 5px)] text-left mt-[1rem] xl:mt-[1rem] lg:mt-3 xl:px-8 lg:px-4 md:px-4 px-4">
                            <div class="l flex justify-center items-center flex-col">
                                <img src={newgropuicon} alt='' className='xl:w-[40%] lg:w-[6rem] w-[8rem] h-auto'/>
                                <div className='lg:block md:block hidden'>
                                    <p className='text-[#1A1A1A] xl:text-[18px] lg:text-[12px] text-[16px] font-Poppins-SemiBold text-center pt-5 lg:pt-2 mb-[1rem]'>
                                        Add your father, mother, brother, 
                                        <br /> sister and more to search,
                                        <br /> select and call matches!
                                    </p>
                                </div>
                                <div className='lg:hidden md:hidden'>
                                    <p className='text-[#1A1A1A] xl:text-[18px] lg:text-[12px] text-[16px] font-Poppins-SemiBold text-center pt-5 lg:pt-2 mb-[1rem]'>
                                        Add your father, mother, 
                                        <br />  brother,sister and more to search,
                                        <br /> select and call matches!
                                    </p>
                                </div>
                            </div>
                            {/* <div className='flex gap-3'>

                            <div onClick={onRewardsClick}>
                                <button className =" text-white bg-[#D10A11] md:px-2 whitespace-nowrap 
                                px-4 lg:px-3 py-1 text-[13px] lg:text-[12px] rounded xl:px-5 ">Rewards</button>
                            </div>
                            <div onClick={onQualityClick}>
                                <button className =" text-white bg-[#D10A11] md:px-2 whitespace-nowrap 
                                px-4 lg:px-3 py-1 text-[13px] lg:text-[12px] rounded xl:px-5 ">Quality</button>
                            </div>
                            </div> */}
                           
                            <div className='lg:mt-5 xl:mt-5 md:mt-1 mt-4'>
                                <p className='font-Poppins-SemiBold text-[#1A1A1A] md:text-[13px] xl:text-[16px] lg:text-[12px] pb-4'>Each family member can :</p>
                            </div>
                            <div className="w-full h-20 lg:h-[4rem] xl:h-20 flex mb-[2rem] mt-[2rem] py-5 lg:py-1">
                                <div className='w-[21%] h-full flex items-center xl:w-[19%] 2xl:w-[15%] 3xl:w-[13%] lg:w-[20%] md:w-[17%]'>
                                    <div className='flex flex-col space-y-2 ml-auto mr-auto'>
                                        {isCommunitySite
                                            ? <img className='w-[4rem]  ' src="/Assets/Images/googleplay.png" alt="" />
                                            : <a href={RouteSegments.staticLinkForAppDownload.android} target="_blank">
                                        <img className='w-[4rem]  ' src="/Assets/Images/googleplay.png" alt="" />
                                            </a>}
                                        {isCommunitySite
                                            ? <img className='w-[4rem]' src="/Assets/Images/appstore.png" alt="" />
                                            : <a href={RouteSegments.staticLinkForAppDownload.ios} target="_blank">
                                            <img className='w-[4rem]' src="/Assets/Images/appstore.png" alt="" />
                                            </a>}
                                    </div>
                                </div>
                                <div 
                                    className="w-[60%] pl-4 md:pl-4 lg:pl-4 h-full grow flex flex-col justify-center mb-[2rem] lg:mb-[4rem]" >
                                    <h3 className="font-Poppins-SemiBold text-[#1A1A1A] md:text-[13px] xl:text-[16px] lg:text-[12px] flex gap-2 items-center"> 
                                        Download App on Their Own Phones: {isCommunitySite && "(Coming Soon!)"}
                                    </h3>
                                    <p className="pt-1 md:pt-0 font-Poppins-Regular xl:w-[14rem] 2xl:w-[18rem] lg:w-[10.5rem] md:w-[11rem] w-[15rem] text-[#1A1A1A] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px]">
                                        Each family member can download the {isCommunitySite && <span>{isCommunitySite}</span>} Kalyan Matrimony app on their respective devices to access the profile. 
                                        {isCommunitySite 
                                            ? ""
                                            : <>
                                            <span className='text-[#007DD1] underline cursor-pointer'>
                                                <a href={RouteSegments.staticLinkForAppDownload.android} target="_blank"> Click here to share the app download link</a>
                                            </span> 
                                            <span>to your family members.</span>
                                            </>
                                        } 
                                    </p>
                                </div>
                            </div>
                            

                            <div className="w-full h-20 lg:h-[4rem] xl:h-20 flex mb-[2rem] mt-[4rem]">
                                <div className='w-[21%] h-full flex items-center xl:w-[19%] 2xl:w-[15%] 3xl:w-[13%] lg:w-[20%] md:w-[17%]'>
                                    <div className="flex items-center rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] w-[60px] h-[58px] md:w-[43px] bg-[#D71920]">
                                        <img className='w-[50%] block ml-auto mr-auto' src={searchloveicon} alt=''/>
                                    </div>
                                </div>
                                <div 
                                    className="w-[60%] pl-4 md:pl-4 lg:pl-4 h-full grow flex flex-col justify-center" >
                                    <h3 className="font-Poppins-SemiBold text-[#1A1A1A] md:text-[13px] xl:text-[16px] lg:text-[12px] flex gap-2 items-center"> 
                                        Search and select profiles:
                                    </h3>
                                    <p className="pt-1 md:pt-0 font-Poppins-Regular xl:w-[14rem] 2xl:w-[18rem] lg:w-[10.5rem] md:w-[11rem] w-[15rem] text-[#1A1A1A] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] ">
                                
                                        <span>Each Family member can independently search select profiles.</span>
                                    
                                    </p>
                                </div>
                            </div>
                            
                            <div className="w-full h-20 lg:h-[4rem] xl:h-20 flex mb-[2rem]">
                                <div className='w-[21%] h-full flex items-center xl:w-[19%] 2xl:w-[15%] 3xl:w-[13%] lg:w-[20%] md:w-[17%]'>
                                    <div className="flex items-center rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] w-[60px] h-[58px] md:w-[43px] bg-[#D71920]">
                                        <img className='w-[50%] block ml-auto mr-auto' src={sendarrowicon} alt=''/>
                                        {/* <img className='w-[50%] block mt-[2rem] mr-3' src={PlusIcon}/> */}
                                    </div>
                                </div>
                                <div 
                                    className="w-[60%] pl-4 md:pl-4 lg:pl-4 h-full grow flex flex-col justify-center" >
                                    <h3 className="font-Poppins-SemiBold text-[#1A1A1A] md:text-[13px] xl:text-[14px] lg:text-[12px] flex gap-2 items-center"> 
                                        Share Profiles in the family group and chat:
                                    </h3>
                                    <p className="pt-1 md:pt-0 font-Poppins-Regular xl:w-[14rem] 2xl:w-[18rem] lg:w-[10.5rem] md:w-[11rem] w-[15rem] text-[#1A1A1A] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] ">
                                
                                        <span>Family members can share profile in family group and chat confidentially</span>
                                    
                                    </p>
                                </div>
                            </div>

                            <div className="w-full h-20 lg:h-[4rem] xl:h-20 flex mb-[2rem]">
                                <div className='w-[21%] h-full flex items-center xl:w-[19%] 2xl:w-[15%] 3xl:w-[13%] lg:w-[20%] md:w-[17%]'>
                                    <div className="flex items-center rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] w-[60px] h-[58px] md:w-[43px] bg-[#D71920]">
                                        <img className='w-[50%] block ml-auto mr-auto' src={callChaticon} alt=''/>
                                    </div>
                                </div>
                                <div 
                                    className="w-[60%] pl-4 md:pl-4 lg:pl-4 h-full grow flex flex-col justify-center" >
                                    <h3 className="font-Poppins-SemiBold text-[#1A1A1A] md:text-[13px] xl:text-[16px] lg:text-[12px] flex gap-2 items-center"> 
                                        Send Messages and call Matches on your behalf
                                    </h3>
                                    <p className="pt-1 md:pt-0 font-Poppins-Regular xl:w-[14rem] 2xl:w-[18rem] lg:w-[10.5rem] md:w-[11rem] w-[15rem] text-[#1A1A1A] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] ">
                                
                                        <span>Any Family member can send message and call matches.</span>
                                    
                                    </p>
                                </div>
                            </div>
                            
                            <div className="w-full h-20 lg:h-[4rem] xl:h-20 flex mb-[1rem]">
                                <div className='w-[21%] h-full flex items-center xl:w-[19%] 2xl:w-[15%] 3xl:w-[13%] lg:w-[20%] md:w-[17%]'>
                                    <div className="flex items-center rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] w-[60px] h-[58px] md:w-[43px] bg-[#D71920]">
                                        <img className='w-[50%] block ml-auto mr-auto' src={familygroupicon} alt=''/>
                                    </div>
                                </div>
                                <div 
                                    className="w-[60%] pl-4 md:pl-4 lg:pl-4 h-full grow flex flex-col justify-center" >
                                    <h3 className="font-Poppins-SemiBold text-[#1A1A1A] md:text-[13px] xl:text-[16px] lg:text-[12px] flex gap-2 items-center"> 
                                        You can Add up-to 7 family members
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="py-6 lg:py-4 w-[100%]" style={{ boxShadow: '0 -5px 12px -3px #00000029'}}>
                            {isLoading ? 
                                <div className='flex justify-around'>
                                    <p className='w-[10rem]'><Skeleton/></p>
                                    <p className='w-[10rem]'><Skeleton/></p>
                                </div>
                            : 
                                <div className='flex justify-center items-center'>
                                    {familydata?.length > 0 && !showParentsNumberPopup &&
                                        <>
                                            <button type='button'
                                                className=' bg-[#E2E8F0] text-[#1A1A1A] whitespace-nowrap px-4 lg:px-3 py-1 text-[13px] lg:text-[12px] rounded xl:px-5 md:px-2 lg:mr-3 xl:mr-3 md:mr-1 mr-3'
                                                onClick={(e) => handleAddMembersClick("view")}
                                            >
                                                View Members
                                            </button>
                                        </>   
                                    }
                                    {/* <button type='button'
                                        onClick={(e) => handleAddMembersClick("add")}
                                        className=' text-white bg-[#D10A11] md:px-2 whitespace-nowrap px-4 lg:px-3 py-1 text-[13px] lg:text-[12px] rounded xl:px-5 '
                                    >
                                        Add Members Now!
                                    </button> */}
                                    
                                    <button type='button'
                                        onClick={(e) => handleAddMembersClick("add")}
                                        className=' text-white bg-[#D10A11] md:px-2 whitespace-nowrap px-4 lg:px-3 py-1 text-[13px] lg:text-[12px] rounded xl:px-5 '
                                        >
                                            Add Members Now!
                                    </button>
                                        
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className={`w-[70%] overflow-y-hidden  overflow-x-hidden duration-1000 ease-linear bg-[#F0F2F5] hidden md:block relative  justify-center ${isFamilyPage ? "lg:flex": "lg:block"}`}>
                    <WelcomePage />
                </div>
          
            </div>
        
        </>

    )
}

export default StartNewGroupPage