import React, { useRef, useState ,useEffect} from 'react'
import { Select, AutoComplete } from "antd";
import { CaretDownOutlined } from '@ant-design/icons'


const SelectDropdownUtil = (props) => {
  
  const selectRef = useRef()

  return (
    props?.value != undefined &&
    <>
    <Select
    // suffixIcon={<CaretDownOutlined />}
    {...props}
    showSearch
    style={{
      width: "100%"
    }}
    className={props?.menu=="aferRegister" ?"new disabled":""}
    mode="multiple"
    autoComplete="none"
    id={props?.id}
    name={props?.name}
    defaultValue={props.defalutValue?props?.defalutValue:props?.placeholder} 
    optionFilterProp="children"
    showArrow={props?.menu=="aferRegister"} 
    ref={selectRef} 
    
    onChange={event => {
      props?.onChange(event);
    
      setTimeout(() => {
        selectRef.current.blur();
      }, 0);
    }}
      filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    }
    options={props?.option?.map((option,i)=>(
      option.heading == "Y" ?
      {
        label: option.name,
        options: 
        [
          {
            label: option.name,
            value: option.id,
          }
        ],
      }
      :
      {
                value: 
                      props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                       option?.id.toString()
                       :props?.name == "maritalStatus"?
                       option.name
                       :props?.name == "maritalStatusId" ?
                       option.id
                       :option.id,
                label: props?.name == "countryNameId" ? 
                        option?.country:
                        props?.name == "currencyId"  ?
                        option.currency+","+option.country:
                        props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                       option?.isdcode+","+option.country:option.name,
              }
    ))
      
    }
  />
  
  {props?.errors && (
    <p className="text-[red] text-[12px]">
      {props?.errors}
    </p>
  )}{" "}
  </>
  )
}

const SelectDropdownUtilHeading = (props) => {
 console.log(props,"fvhng-ddd");
return (
  props?.value != undefined &&
  <>
  <Select
  // suffixIcon={<CaretDownOutlined />}
  {...props}
  showSearch
  style={{
    width: "100%"
  }}
  className={props?.menu=="aferRegister" ?"new ":""}
  mode="multiple"
  autoComplete="none"
  id={props?.id}
  name={props?.name}
  defaultValue={props.defalutValue?props?.defalutValue:props?.placeholder} 
  optionFilterProp="children"
  showArrow={props?.menu=="aferRegister"}
  onChange={event => {
      //console.log(event,"event")
      props?.onChange(event)}}
  filterOption={(input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }
  options={props?.option?.map((option,i)=>(
    option.heading == "Y" ?
  {
    label: option.name,
    options: 
    [
      {
        label: option.name,
        value: option.id,
      }
    ],
  }
  :
          {
              value: props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.id.toString()
                     :option.id,
              label: props?.name == "countryNameId" ? 
                      option?.country:
                      props?.name == "currencyId"  ?
                      option.currency+","+option.country:
                      props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.isdcode+","+option.country:option.name,
            }
  ))
    
  }
/>

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}
</>
)
}



const SingleSelectDropdown = (props) => {
  ////console.log(props,"fvhng");
return (
  props?.value != undefined &&
  <>
  <Select
  // suffixIcon={<CaretDownOutlined />}
  {...props}
  showSearch
  style={{
    width: "100%"
  }}
  placeholder={props?.placeholder}
  className= {props?.menu=="afterReigister" ?"new text-[#7A7A7A] font-bold text-[14px]" :'text-[#7A7A7A] font-bold text-[14px]'}
  // onSearch={(e)=>{if(e.length>2){ props.callBackSearch(e)}}}
  onSearch={(e) => {
    if (e.length > 2) {
      props.callBackSearch(e); // Ensure this correctly updates the dropdown options
    }
  }}
  id={props?.id}
  name={props?.name}
  value={props?.value}
  defalutValue={props.defalutValue?props?.defalutValue:props?.placeholder}
  optionFilterProp="children"
  onChange={event => {
      //console.log(event,"event")
      props?.onChange(event)}}

  filterOption={(input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }
  options={props?.option?.map((option,i)=>(
          {
              value: props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.id.toString()
                     :props.name==="placeOfBirth"?option.cityId :option.id,
              label: props?.name == "countryNameId" ? 
                      option?.country:
                      props?.name == "currencyId"  ?
                      option.currency+","+option.country:
                      props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.isdcode+","+option.country: props.name==="placeOfBirth"?
                     `${option?.placeName}-${option?.regionName}-${option?.countryName}`
                     :option.name,
            }
  ))
  }
/>

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}
</>
)
}



const PincodeSingleSelectDropdown = (props) => {
  ////console.log(props,"fvhng");
return (
  props?.value != undefined &&
  <>
   <Select
    filterOption={false}
    showSearch={true}
    onChange={props?.onSelect}
    onSearch={props?.onSearch}
    id={props?.id.toString()}
    name={props?.name}
    // onChange={event =>{
    //   if(props?.onSelect){
    //     props?.onSelect(event)
    //   }
    //   return props?.onChange(props?.name)(event.toString())}
    // } 
    placeholder={props?.placeholder}
    defaultValue={props.value}
    value={props.value}
    
    options={props?.options}
      notFoundContent="No matches"
      style={{
        width: "100%"
      }}
  />

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}
</>
)
}

export {SelectDropdownUtil,SelectDropdownUtilHeading, SingleSelectDropdown ,PincodeSingleSelectDropdown}