import React, { useState, useEffect, useContext, useMemo ,useRef} from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
  SelectInput,
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import { registerStarList } from "../../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { getDeviceInfo, getGroupAuthId } from "../../Kalyanlite/utils";

const ReligiousComponent = (props) => {
  const [isShowEdit, setEditView] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [relogionDropDown, setReligionDropDown] = useState([]);
  const [motherTongueDropDown, setmotherTongueDropDown] = useState([]);
  const [castDropDown, setCastDropDown] = useState([]);
  const [subCastDropDown, setSubCastDropDown] = useState([]);
  const [gothraDropDown, setGothraDropDown] = useState([]);
  const [isShowGorthram, setisShowGorthram] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [isOtherSubcaste, setIsOtherSubcaste] = useState(false)
  const [isOtherCaste,setIsOtherCaste] = useState();
  const [inputValue, setInputValue] = useState('');
  const [matchedCastes, setMatchedCastes] = useState([]);
  const [subCasteValue, setSubCasteValue] = useState('');
  const [matchedSubCastes, setMatchedSubCastes] = useState([]);

  const { denominationList } = useSelector((state) => ({
    denominationList: state?.Home?.denominationList?.data?.domain,
  }));

  console.log(subCastDropDown,"isOtherSubcaste");

  const formik = useFormik({
    initialValues: {
      religion: props?.data?.religion ? props?.data?.religion[0].id : "",
      denomination: props?.data?.domain ? props?.data?.domain[0].id : "",
      mothertonge: props?.data?.motherTongue
        ? props?.data?.motherTongue[0].id
        : "",
      
      cast: props?.data?.caste ? props?.data?.caste[0].id : "",

      otherCaste: props?.data?.otherCaste ? props?.data?.otherCaste : "",

      subcast: props?.data?.subCaste ? props?.data?.subCaste[0].id : "",

      subCasteOther : props?.data?.othersubcaste ? props?.data?.othersubcaste :props?.data?.otherSubCaste ,
       
      otherSubCast: props?.data?.otherSubCaste ? props?.data?.otherSubCaste :  props?.data?.othersubcaste,
       
      gothra: props?.data?.gothra ? props?.data?.gothra[0].id : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      religion: Yup.string().required("Please Enter Religion"),
      denomination: Yup.string().when("religion",
      (religion, schema)=>{
        console.log('value business : ',religion);
        if(religion.includes(Constants.christialId)){
          return schema.required('Please Select Denomination');
        }else{
          return schema;
        }
      }
      ),
      mothertonge: Yup.string().required("Please select mother tongue"),
      cast: Yup.string().required("Please select caste"),
      otherCaste: Yup.string().when("cast",
      (cast, schema)=>{
        if(cast == 433){
          return schema
          .required('Please enter your Caste/Division')
          .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
          .test('singleSpaceBetweenWords', 'Only one space between words allowed', value => !/\s{2,}/.test(value))        
        }else{
          return schema;
        }
      }
      ),
      subCasteOther: Yup.string().when("subcast",
      (subcast, schema)=>{
        if(selectedSubCaste?.name.trim() == "Others"){
          return schema
          .required('Please enter your Subcaste')
          .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
          .test('singleSpaceBetweenWords', 'Only one space between words allowed', value => !/\s{2,}/.test(value))                }else{
          return schema;
        }
      }
      ),
      
      otherSubCast: Yup.string()
      .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
      .test('singleSpaceBetweenWords', 'Only one space between words allowed', value => !/\s{2,}/.test(value))                
      ,
      // subcast:isOtherSubcaste?Yup.string(): Yup.string().required("Please select subcast"),
      // otherSubCast:isOtherSubcaste?Yup.string().required("Please enter other caste"):Yup.string(),
      gothra: isShowGorthram
        ? Yup.string().required("Please Select Gothram")
        : Yup.string(),
    }),


    onSubmit: (values) => {
      // if (formik.values.otherSubCast !== "") {
      //   formik.setFieldValue("subcast", "");
      // }
      // if (formik.values.subcast !== "") {
      //   formik.setFieldValue("otherSubCast", "");
      // }
      console.log(values,"sub4");
      setSubmitStatus(true)
      apiCallReligion(values);
    },
  });

  console.log(formik.values.subCasteOther,"formik.values.subCasteOther");

  const isChristian = formik.values?.religion == Constants.christialId

  useEffect(() => {

  },[formik.values])

  const setGothram = (value) => {
    //console.log(value, value.includes("Brahmin"), "subcast id");
    if (value?.includes("Brahmin")) {
      setisShowGorthram(true);
    } else {
      setisShowGorthram(false);
      formik.setFieldValue("gothra", "");
      // formik.setFieldValue('otherSubCast',"")
    }
  };


  const apiCallCast = async (motherTounge,religionId) => {
    // getCastUrl

    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCastUrl(motherTounge,religionId)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setCastDropDown(data.data.castes);
        if(data.data.castes?.length === 0){
          setIsOtherCaste(true)

        }
        else{
          setIsOtherCaste(false)
        }
        
      }
    } else if (statusCode === 401) {
      logout();
    }
  };




  //called whenever caste and religion, mother tonge dropdown values updated
  const apiCallSubCast = async (id) => { 
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getProfileSubCastUrl(id??formik.values.cast)}`
    );
    //console.log("apiCallSubCast religion");
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setSubCastDropDown(data.data.subcastes);
        if(data.data.subcastes?.length === 0){
          setIsOtherSubcaste(true)
        }else {
          setIsOtherSubcaste(false)
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };



//Submit edited religion details
  const apiCallReligion = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      religionId: value.religion,
      domainId:value.religion == Constants.christialId?value.denomination:undefined,
      motherTongueId: value.mothertonge,
      casteId: isOtherCaste?"":value.cast,
      subcasteId:isOtherSubcaste?"": value.subcast,
      gothraId:isShowGorthram? value.gothra:"",
      othersubcaste: isOtherSubcaste?value.otherSubCast:value.subCasteOther,
      otherCaste : isOtherCaste?value.otherCaste :value.otherCaste,
      otherSubcaste :  isOtherSubcaste ?value.subCasteOther : value.otherSubCast,
      ...(value.subcast != "" && {subcasterequesttypeid:19}),
      requestfulfilledfrom:getDeviceInfo(),
      requestfulfilledvia:"viewprofile",
      recieverprofileid:getGroupAuthId()   
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editreligioninfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        // //console.log(value.otherSubCast,"sub5");
        setSubmitStatus(false)
        props.callBackReload();
        setEditView(false);
        onClickTrack(EventName.profileReligiousSaveButton , {[EventKeys.profileReligious]:value});
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  useEffect(() => {
    if (props.data) {
      apiCallSubCast(props.data?.caste?.at(0)?.id)
      apiCallCast(props?.data?.motherTongue?.at(0)?.id, props?.data?.religion?.at(0)?.id)
      if(props?.data?.caste?.at(0)?.name){
        setGothram(props?.data?.caste?.at(0)?.name??"")
      }
    }
    console.log(props.data,'props.dataq');
  }, [props.data])

  useEffect(() => {
    setReligionDropDown(props?.dropDown?.religion ?? []);
    setmotherTongueDropDown(props?.dropDown?.motherTongue ?? []);
    setGothraDropDown(props?.dropDown?.gothra ?? []);
  }, [props?.dropDown])
  


  const clearCaste = () => {
    formik.setFieldValue('cast',"")
    formik.setFieldValue('subcast',"")
    formik.setFieldValue('otherSubCast',"")
    formik.setFieldValue('subCasteOther',"")
    formik.setFieldValue('gothra',"")
    formik.setFieldValue('otherCaste',"")
  }
  
console.log('relogionDropDown',props?.dropDown);


const sortedCastDropDown = useMemo(() => {
  const others = castDropDown.filter(option => option.name === "Others");
  const withoutOthers = castDropDown.filter(option => option.name !== "Others");
  return [...withoutOthers, ...others];
}, [castDropDown]);

const sortedSubCastDropDown = useMemo(() => {
  const others = subCastDropDown.filter(option => option.name === "Others");
  const withoutOthers = subCastDropDown.filter(option => option.name !== "Others");
  return [...withoutOthers, ...others];
}, [subCastDropDown]);


const selectedReligion = useMemo(()=>{
  return relogionDropDown.filter(option=>option.id === formik.values.religion)?.at(0)
},[formik.values.religion, relogionDropDown])

const selectedDenomination = useMemo(()=>{
  return denominationList?.filter(option=>option.id === formik.values.denomination)?.at(0)
},[formik.values.denomination, denominationList])

const selectedMotherTongue = useMemo(()=>{
  return motherTongueDropDown.filter(option=>option.id === formik.values.mothertonge)?.at(0)
},[formik.values.mothertonge, motherTongueDropDown])

const selectedCaste = useMemo(()=>{
  return castDropDown.filter(option=>option.id === formik.values.cast)?.at(0)
},[castDropDown, formik.values.cast])

const selectedSubCaste = useMemo(()=>{
  return subCastDropDown.filter(option=>option.id === formik.values.subcast)?.at(0)
},[formik.values.subcast, subCastDropDown])


console.log(selectedSubCaste,"selectedSubCaste");
const selectedGothra = useMemo(()=>{
  return gothraDropDown.filter(option=>option.id === formik.values.gothra)?.at(0)
},[formik.values.gothra, gothraDropDown])

const handleOtherCaste = (e) => {
  const newValue = e.target.value;

  const othercaste = newValue.replace(/[^\w\s]/gi, "").replace(/  +/g, " ").replace(/[^a-zA-Z0-9 .]/g, "")
  setInputValue(othercaste);

  if (newValue.trim() === '') {
    setMatchedCastes([]); // Clear matched castes
    formik.setFieldValue("otherCaste","");
  } else {
    // Filter caste names starting with the input value (case-insensitive)
    const filteredCastes = castDropDown.filter(
      (caste) =>
        caste.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setMatchedCastes(filteredCastes);
    formik.setFieldValue("otherCaste",newValue);
  }
};

const handleOtherSubCaste = (e) =>{
  const newValue = e.target.value;
  const othersubcate =newValue.replace(/[^\w\s]/gi, "").replace(/  +/g, " ").replace(/[^a-zA-Z0-9 .]/g, "")
  setSubCasteValue(othersubcate);
  
  if (newValue.trim() === '') {
    // Clear matched castes when input value is empty
    setMatchedSubCastes([]);
    formik.setFieldValue("subCasteOther","")
  } else {
    // Filter caste names starting with the input value (case-insensitive)
    const filteredCastes = subCastDropDown.filter(
      (subcast) => subcast.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setMatchedSubCastes(filteredCastes);
    formik.setFieldValue("subCasteOther",newValue)
  }
}

const handleOtherCasteClick = (selectedValue)=>{
  formik.setFieldValue("cast", selectedValue)
  formik.setFieldValue("subcast","")
  setMatchedCastes([])
  setInputValue("")

  if(selectedValue === ""){ 
    setGothram(castDropDown.filter((ele) => ele.id === selectedValue)[0]?.name );

  }else{
    
    console.log(selectedValue,"selectedValue");
    setGothram(castDropDown.filter((ele) => ele.id === selectedValue)[0]?.name );
  }
  apiCallSubCast(selectedValue)
  formik.setFieldValue("otherCaste","");

}


const handleOtherSubCasteClick = (selectedValue)=>{
  formik.setFieldValue("subcast", selectedValue)
  setMatchedSubCastes([])
  setSubCasteValue("")
  formik.setFieldValue("subCasteOther","")
}


const { onClickTrack } = useAnalytics()

const [showDropdown, setShowDropdown] = useState(false);
const dropdownRef = useRef(null);
useEffect(() => {
  // Function to handle click outside of the component
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  // Add event listener for clicks
  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    // Remove event listener on cleanup
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [dropdownRef]);

useEffect(() => {
  // Additional side effects based on formik values can be handled here
}, [formik.values])


console.log(formik.values.subCasteOther,"formik.values.subCasteOther");
  return (
    <div key={isShowEdit}>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="md:mb-8 p-5 md:p-0">
          <ActionRestrict>
        <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>
          <BasicTextView
            title={"Religion"}
            value={
              props?.data?.religion
                ? props?.data?.religion[0].name
                : "Not Specified"
            }
          />
          {isChristian && <BasicTextView
            title={"Denomination"}
            value={
              props?.data?.domain
                ? props?.data?.domain[0].name
                : "Not Specified"
            }
          />}
          <BasicTextView
            title={"Mother Tongue"}
            apiCallRaasi={props?.apiCallRaasi}
            value={
              props?.data?.motherTongue
                ? props?.data?.motherTongue[0].name
                : "Not Specified"
            }
          />
          
          <BasicTextView
            title={"Caste"}
            value={
              props?.data?.caste ? props?.data?.caste[0].name : "Not Specified"
            }
          />
         {Array.isArray(props?.data?.caste) && props.data.caste[0]?.name === "Others" ? 
         <BasicTextView
              title={"Other Caste"}
              value={props?.data?.otherCaste
                ?
                     props?.data?.otherCaste
                     : "Not Specified"
                    }
            /> :""} 
            {!isOtherSubcaste
              ? <BasicTextView
                title={"Sub-Caste"}
                value={
                  props?.data?.subCaste
                    ? props?.data?.subCaste[0]?.name
                    : "Not Specified"
                }
              />
              : <BasicTextView
                title={"Other Sub-Caste"}
                value={props?.data?.otherSubCaste
                  ?
                       props?.data?.otherSubCaste
                       : "Not Specified"
                      }
              />}
              {Array.isArray(props?.data?.subCaste) && props.data.subCaste[0]?.name === "Others"  ?
               <BasicTextView
               title={"Other Sub-Caste"}
               value={props?.data?.otherSubCaste
                 ?
                      props?.data?.otherSubCaste
                      : "Not Specified"
                     }
             />:""}
          {props?.data?.gothra &&
          <BasicTextView
            title={"Gothram"}
            value={
              props?.data?.gothra
                ? props?.data?.gothra[0]?.name
                : "Not Specified"
            }
          />
        }
        </div>
      )}
      {/* <!-- edit part  --> */}
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className="p-5 md:p-0">
            {props?.isPaid
              ? <BasicTextView
                title={"Religious"}
                value={
                  props?.data?.religion
                    ? props?.data?.religion[0].name
                    : "Not Specified"
                }
              /> 
              : <SelectInput
                name="religion"
                title="Religion"
                value={selectedReligion}
                onChange={(selected,ev) => {
                  //console.log('selected,ev',selected,ev);
                  formik.setFieldValue("religion", selected.id)
                  apiCallCast(formik.values.mothertonge, selected.id);
                  clearCaste()
                }}
                error={formik.errors.religion}
                defaultValue={formik.values.religion}
                options={[{id:null,name:"Select", isDisabled:"true"},...relogionDropDown]}
                required={true}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              /> 
              }
            {isChristian ?
              props?.isPaid
                ? <BasicTextView
                  title={"Denomination"}
                  value={
                    props?.data?.denomination
                      ? props?.data?.denomination[0].name
                      : "Not Specified"
                  }
                />
                : <SelectInput
                  name="denomination"
                  title="Denomination"
                  value={selectedDenomination}
                  onChange={(selected, ev) => {
                    //console.log('selected,ev',selected,ev);
                    formik.setFieldValue("denomination", selected.id)
                  }}
                  error={formik.errors.denomination}
                  defaultValue={formik.values.denomination}
                  options={[{ id: null, name: "Select", isDisabled: "true" }, ...denominationList]}
                  required={true}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              : <></>
            }
            {props?.isPaid
              ? <BasicTextView
                title={"Mother Tongue"}
                value={
                  props?.data?.motherTongue
                    ? props?.data?.motherTongue[0].name
                    : "Not Specified"
                }
              /> 
              :
              <SelectInput
              name="mothertonge"
              title="Mother Tongue"
              value={selectedMotherTongue}
              onChange={(selected,ev) => {
                formik.setFieldValue("mothertonge", selected.id)
                apiCallCast(selected.id, formik.values.religion);
                props?.apiCallStar(selected.id)
                clearCaste()
              }}
              error={formik.errors.mothertonge}
              options={[{id:null,name:"Select", isDisabled:"true"},...motherTongueDropDown]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            }
            {
            props?.isPaid
              ? <BasicTextView
                title={"Caste"}
                value={
                  props?.data?.caste ? props?.data?.caste[0].name : "Not Specified"
                }
              /> 
              :
              <SelectInput
              name="cast"
              title="Caste"
              value={selectedCaste}
              onChange={(selected,ev) => {
                formik.setFieldValue("cast", selected.id)
                formik.setFieldValue("subcast","")
                formik.setFieldValue("otherSubCast","")
                formik.setFieldValue("subCasteOther","")
                formik.setFieldValue("otherCaste","")
                if(selected.name === "Others"){
                  formik.setFieldValue("otherCaste","")
                }
                if(selected.id == null){
                  setGothram("");
                }else{
                  setGothram(
                    castDropDown.filter((ele) => ele.id === selected.id)[0]
                      .name
                  );
                }
               
                apiCallSubCast(selected.id)
              }}
              getOptionClassName={(option) => {
                console.log('optionname', option.id);
                return option.id === '433'
                  ? 'dropdown-option-others'
                  : '';
              }}
              error={formik.errors.cast}
              options={[{id:null,name:"Select", isDisabled: true },...sortedCastDropDown]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isFrom={"otherCaste"}
            />
            }

            <div className="relative" ref={dropdownRef}>
              {selectedCaste?.name === "Others" && (
                <BasicEditTextView
                  title={"Other Caste"}
                  inputType={"text"}
                  inputID={"otherCast"}
                  inputName={"otherCast"}
                  value={formik.values.otherCaste}
                  
                  onChange={(e) => {
                    handleOtherCaste(e);
                  }}
                  
                  error={formik.errors.otherCaste}
                  // disabled={props?.isPaid}
                />
              )}
            {inputValue.trim() !== '' && matchedCastes.length > 0  &&(
              <div className="grid grid-cols-9 items-center absolute z-50 w-full">
              <div className="col-span-9 md:col-span-4 md:pl-[1rem] lg:pl-[2rem]"></div>
              <div className="popup col-span-9 md:col-span-5 md:mr-[1rem] lg:mr-[4rem] md:ml-[1rem] mt-2 md:mt-0 z-50 max-h-[15rem] overflow-y-auto lite-scrollbar" style={{ backgroundColor: '#FFFFFF', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                <p className='text-[#000000] bg-white text-[15px] border-b border-[#E9E9E9] px-5 py-3 sticky top-0'>Do you Mean</p>
                <ul>
                  {matchedCastes.map(caste => (
                    <li key={caste.id} className='px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer' 
                    onClick={(selected)=>
                      {
                        formik.setFieldValue("cast", selected.id)
                        formik.setFieldValue("subcast","")
                        formik.setFieldValue("otherSubCast","")
                        
                        if(selected.id == null){
                          setGothram("");
                        }else{
                          setGothram(
                            castDropDown.filter((ele) => ele.id === selected.id)[0].name
                          );
                        }
                       
                        apiCallSubCast(selected.id)
                      handleOtherCasteClick(caste.id)
                      }
                    }
                    >{caste.name}</li>
                  ))}
                </ul>
              </div>
              </div>
            )}
            </div>

            {isOtherSubcaste? (
           
            <BasicEditTextView
                title={"Other Sub-Caste"}
                inputType={"text"}
                inputID={"otherSubCast"}
                inputName={"otherSubCast"}
                value={formik.values.otherSubCast}
                onChange={formik.handleChange}
                // onChange={(e) => {
                //   const sanitizedValue = e.target.value.
                //   replace(/[^\w\s]/gi, "").replace(/  +/g, " ").replace(/[^a-zA-Z0-9 .]/g, "")
                //   formik.setFieldValue("otherSubCast", sanitizedValue);
                // }}
                error={formik.errors.otherSubCast}
                // disabled={props?.isPaid}

              />
            ) : (
             
              <SelectInput
              name="subcast"
              title="Sub-Caste"
              value={selectedSubCaste}
              onChange={(selected,e) => {
                
                formik.setFieldValue("subcast", selected.id)
                console.log(selected,'kilo');
                // if(selected.name.trim() != "Others"){
                //   formik.setFieldValue("subCasteOther","")
                // }
              }}
              error={formik.errors.subcast}
              options={[{id:null,name:"Select", isDisabled:"true"},...sortedSubCastDropDown]}
              // required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isFrom={"otherCaste"}
             
            />
            )}

              <div className="relative">
              {selectedSubCaste?.name.trim() == "Others" && (
                <BasicEditTextView
                title={"Other Sub-Caste"}
                inputType={"text"}
                inputID={"subCasteOther"}
                inputName={"subCasteOther"}
                value={formik.values.subCasteOther}
                // value={subCasteValue}
                onChange={(e)=>{
                  formik.setFieldValue("subCasteOther",e.id)

                  handleOtherSubCaste(e)
                  // formik.handleChange();
                }}
                error={formik.errors.subCasteOther}
                // disabled={props?.isPaid}
  
                />
              )}
              {subCasteValue.trim() !== '' && matchedSubCastes.length > 0 && (
                <div className="grid grid-cols-9 items-center absolute z-50 w-full">
                <div className="col-span-9 md:col-span-4 md:pl-[1rem] lg:pl-[2rem]"></div>
                <div className="popup col-span-9 md:col-span-5 md:mr-[1rem] lg:mr-[4rem] md:ml-[1rem] mt-2 md:mt-0 z-50 max-h-[15rem] overflow-y-auto lite-scrollbar" style={{ backgroundColor: '#FFFFFF', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                  <p className='text-[#000000] bg-white text-[15px] border-b border-[#E9E9E9] px-5 py-3 sticky top-0'>Do you Mean</p>
                  <ul>
                    {matchedSubCastes.map(subcaste => (
                      <li key={subcaste.id} className='px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer' 
                      
                      // onClick={()=>handleOtherSubCasteClick(caste.id)}

                      onClick={(selected)=>
                        {
                         
                          formik.setFieldValue("subCasteOther",selected.id)
                          formik.setFieldValue("otherSubCast",selected.id)
                         
                         
                          handleOtherSubCasteClick(subcaste.id)
                        }
                      }
                      >{subcaste.name}</li>
                    ))}
                  </ul>
                </div>
                </div>
              )}
              </div>

            {isShowGorthram ? (
              props?.isPaid
              ?  <BasicTextView
              title={"Gothram"}
              value={
                props?.data?.gothra
                  ? props?.data?.gothra[0]?.name
                  : "Not Specified"
              }
            />:<SelectInput
              name="gothra"
              title="Gothram"
              value={selectedGothra}
              onChange={(selected,ev) => {
                formik.setFieldValue("gothra", selected.id)
              }}
              error={formik.errors.gothra}
              options={[{id:null,name:"Select"},...gothraDropDown]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            ) : (
              <></>
            )}
            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                setEditView(false);
                props.refresh()
                onClickTrack(EventName.profileReligiousCancelButton)
              }}
              submitStatus={submitStatus}
            />
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default ReligiousComponent;
