import React, { useEffect, useMemo, useState } from "react";
import { EditButton, TextIconButton } from "../EditProfileUtills";
import { useNavigate } from "react-router";
import { Formik } from "formik";
import { useAnalytics } from "../../../Hooks/usePageChange";
import { EventName } from "../../../Constants/constants";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { useSelector } from "react-redux";

const ProfileSettingsTextField = (props) => {
  const [isShowEdit, setEditView] = useState(false);
  const { onClickTrack } = useAnalytics()

  const disableSave = useMemo(() => {
    if (props.error) {
      return true;
    } else if (props.value === props.inputvalue) {
      return true;
    } else if (props.isLoading) {
      return true;
    }else if (props.inputvalue=== ""){
      return true
    }

    return false
  }, [props.error, props.inputvalue, props.isLoading, props.value])

  const onEditClick = () => {
    setEditView(!isShowEdit);
    onClickTrack( props.inputID == "eMail" ? EventName.profileEmailIdEditButton : EventName.profilePasswordEditButton );
  }

  return (
    <>
      {/* Mobile anf tab view */}
      <div className="flex lg:hidden flex-col pl-3 py-2 pr-2">
        <p className="text-[14px] flex text-[#7A7A7A] font-semibold py-1">
          {props.title}
        </p>
        {
          isShowEdit
            ? null
            : <div className="flex items-center justify-between">
              <p className="text-[14px] text-[#7A7A7A] font-semibold">{props.value}</p>
              <ActionRestrict>
              <TextIconButton
                onClick={onEditClick}
                className="ml-2"
                text="Edit"
              />
              </ActionRestrict>
            </div>
        }
        {
          isShowEdit
            ? <div className="flex flex-col">
              <div className="flex">
                <input
                  type={props.inputType}
                  id={props.inputID}
                  name={props.inputName}
                  value={props.inputvalue}
                  className="border border-[#8F8F8F] text-[12px] rounded-[3px] w-full font-bold p-2"
                  placeholder=""
                  onChange={props.onChange}
                />
              </div>
              {props.error && (
                <p className="text-[red] text-[12px] py-1">{props.error}</p>
              )}
              <div className="flex justify-end space-x-2">
                <button
                  disabled={disableSave}
                  onClick={() => {
                    props.onSave();
                  }}
                  className={` flex items-center mx-2 mt-[5px] justify-center  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px] ${disableSave ? "opacity-50" : ""}`}>
                  Save
                </button>
                <button
                  onClick={() => {
                    props.setDefault()
                    setEditView(false);
                  }}
                  className=" flex items-center mt-[5px] justify-center  font-bold text-[#D10A11] border border-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px]">
                  Cancel
                </button>
              </div>
            </div>
            : null
        }
      </div>
      {/* desktop view */}
      <div className="hidden lg:grid grid-cols-9 mt-[2rem] pl-[1rem]flex items-center justify-center">
        <div className="col-span-3   md:pl-[1rem] lg:pl-[2rem]">
          <p className="text-[14px] flex text-[#7A7A7A] font-semibold  ">
            {props.title}
          </p>
        </div>
        {!isShowEdit ? (
          <div className=" md:col-span-5 lg:col-span-3 lg:pr-[4rem] md:pl-[1rem] ">
            <p className=" text-[14px] text-[#7A7A7A] font-semibold">{props.value}</p>
          </div>
        ) : (
          <div className="space-y-1 md:mt-[1.3rem] col-span-3">
            <lable className=" w-full  ">
              <input
                type={props.inputType}
                id={props.inputID}
                name={props.inputName}
                value={props.inputvalue}
                className="  border border-[#8F8F8F]   text-sm  rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold    "
                placeholder=""
                onChange={props.onChange}
              />
              </lable>
              {/* {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )} */}
                <div className="text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                {props.error}

                {/* {//console.log(props.error,"props?.data?.email")} */}
                </div>
            
          </div>
        )}

        {isShowEdit
          ? <></>
          : <div className="md:col-span-1 lg:col-span-3">
            <ActionRestrict>
            <EditButton editCallBack={onEditClick} />
            </ActionRestrict>
          </div>}
        {isShowEdit ? (
          <>
            <button
              disabled={disableSave}
              onClick={() => {
                props.onSave();
              }}
              className={` flex items-center mx-2 mt-[5px] justify-center  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px] ${disableSave ? "opacity-50" : ""}`}
            >
              {props.isLoading ? "Saving..." : "Save"}
            </button>
            <button
              onClick={() => {
                props.setDefault()
                setEditView(false);
                onClickTrack(props.inputID == "password" ? EventName.profilePasswordCancelButton : EventName.profileEmailIdCancelButton)
              }}
              className=" flex items-center mt-[5px] justify-center  font-bold text-[#D10A11] border border-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px]"
            >
              Cancel
            </button>
          </>

        ) : (
          <></>
        )}
      </div>
    </>
  );
};
const ProfileSettingsPhoneField = (props) => {
  const [isShowEdit, setEditView] = useState(() => {
    const phoneNumberEdit = localStorage.getItem("phoneNumberEdit")
    let initial = false;

    if(phoneNumberEdit){
      if(phoneNumberEdit === "true"){
        initial = true
        localStorage.removeItem("phoneNumberEdit")
      }
    }

    return initial;

  });
  const navigate = useNavigate();
  const deletePageRedirection = () => {
    navigate("/deleteprofile")
  }
  // onClick={() => {
  //                 {e.name === "Delete"? 
  //                  navigate("/profiledelete")
  //                  : "" }
  //               }}
  // onClick={() => {
  //                 {e.name === "Delete"? 
  //                  navigate("/profiledelete")
  //                  : "" }
  //               }}
  const { onClickTrack } = useAnalytics()
  const disableSave = useMemo(() => {
    const selectedCountry = props?.options?.filter((e) => e.selected === "Y")[0]?.id
    if (props.error) {
      return true;
    } else if (parseInt(props.value) === parseInt(props.inputvalue) && props.selcetValue === selectedCountry) {
      return true;
    }

    return false
  }, [props.error, props.inputvalue, props?.options, props.selcetValue, props.value])

 
  const onEditClick = () => {
    setEditView(!isShowEdit);
    onClickTrack(EventName.profilePhoneNumberEditButton);
  }
  
  
  return (
    <>
      {/* Mobile view */}
      <div className="flex lg:hidden flex-col pl-3 py-2 pr-2">
        <p className="text-[14px] flex text-[#7A7A7A] font-semibold py-1">
          {props.title}
        </p>
        {
          isShowEdit
            ? null
            : <div className="flex items-center justify-between">
              <p className="text-[14px] text-[#7A7A7A] font-semibold">{props.selcetValue}{" "}{props.value}</p>
              <ActionRestrict>
              <TextIconButton
                onClick={onEditClick}
                className="ml-2"
                text="Edit"
              />
              </ActionRestrict>
            </div>
        }
        {
          isShowEdit
            ? <div className="flex flex-col">
              <div className="flex">
                <select
                  type={props.selectType}
                  id={props.selectID}
                  name={props.selcectName}
                  value={props.selcetValue}
                  className="border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] bg-white rounded-[3px] mr-2 w-[72px]"
                  placeholder=""
                  onChange={props.onChangeSelect}
                >
                  {props?.options?.map((e, i) => (
                    <option value={e.id}
                      onClick={deletePageRedirection}
                    >
                      {e.name}
                    </option>
                  ))}
                </select>
                <input
                  type={props.inputType}
                  id={props.inputID}
                  name={props.inputName}
                  value={props.inputvalue}
                  className=" border border-[#8F8F8F] text-[12px] rounded-[3px] w-full font-bold p-2"
                  placeholder=""
                  onChange={props.onChange}
                />
              </div>
              {props.error && (
                <p className="text-[red] text-[12px] ml-20 py-1">{props.error}</p>
              )}
              <div className="flex justify-end space-x-2">
                <button
                  disabled={disableSave}
                  onClick={() => {
                    props.onSave();
                  }}
                  className={` flex items-center mx-2 mt-[5px] justify-center  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px] ${disableSave ? "opacity-50" : ""}`}>
                  Save
                </button>
                <button
                
                  onClick={() => {
                    props.setDefault()
                    setEditView(false);
                    onClickTrack(EventName.profilePhoneNumberCancelButton)
                  }}
                  className=" flex items-center mt-[5px] justify-center  font-bold text-[#D10A11] border border-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px]">
                  Cancel
                </button>
              </div>
            </div>
            : null
        }
      </div>
      {/* tab and desktop view */}
      <div className="hidden lg:grid grid-cols-9 mt-[2rem] pl-[1rem]flex items-center justify-center">
        <div className="col-span-3   md:pl-[1rem] lg:pl-[2rem]">
          <p className="text-[14px] flex text-[#7A7A7A] font-semibold  ">
            {props.title}
          </p>
        </div>
        {!isShowEdit ? (
          <div className=" col-span-5 lg:col-span-3 lg:pr-[4rem] md:pl-[1rem] ">
            <p className=" text-[14px] text-[#7A7A7A] whitespace-nowrap font-semibold">{props.selcetValue}{" "}{props.value}</p>
          </div>
        ) : (
          <div className="flex items-center col-span-3 justify-center space-x-3 ">
            {/* <p className=" "> */}
            <label className="select font-bold text-[#575556] w-[8rem] ">
              <select
                type={props.selectType}
                id={props.selectID}
                name={props.selcectName}
                value={props.selcetValue}
                className="  md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                placeholder=""
                onChange={props.onChangeSelect}
              >
                {props?.options?.map((e, i) => (
                  <option value={e.id}
                    onClick={deletePageRedirection}
                  >
                    {e.name}
                  </option>
                ))}
              </select>
            </label>
            <div className="space-y-1 md:mt-[1.3rem] col-span-2">
             <lable className="relative">
               <input
                type={props.inputType}
                id={props.inputID}
                name={props.inputName}
                value={props.inputvalue}
                className=" border border-[#8F8F8F]   text-sm  rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold    "
                placeholder=""
                onChange={props.onChange}
              />
              </lable>
              <div className="text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                
                  {props.error}
                
              </div>
         </div>
            {/* </p> */}
          </div>
        )}

        {isShowEdit
          ? <></>
          : <div className="md:col-span-1 lg:col-span-3">
            <ActionRestrict>
            <EditButton editCallBack={onEditClick} />
            </ActionRestrict>
          </div>}
        {isShowEdit ? (
          <>
            <button
              disabled={disableSave}
              onClick={() => {
                props.onSave();
              }}
              className={` flex items-center mx-2 mt-[5px] justify-center  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px] ${disableSave ? "opacity-50" : ""}`}
            >
              Save
            </button>
            <button
              onClick={() => {
                props.setDefault()
                setEditView(false);
                onClickTrack(EventName.profilePhoneNumberCancelButton)
              }}
              className=" flex items-center mt-[5px] justify-center  font-bold text-[#D10A11] border border-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px]"
            >
              Cancel
            </button>
          </>

        ) : (
          <></>
        )}
      </div>
    </>
  );
};
const ProfileSettingsSelectField = (props) => {
  const [isShowEdit, setEditView] = useState(false);
  const { onClickTrack } = useAnalytics()
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
);
  const selected = props.options?.filter(option => option.selected === "Y")?.at(0)
  const disableSave = useMemo(() => {
    if (props.error) {
      return true;
    } else if (props.value === selected?.name) {
      return true;
    } else if (props.isLoading) {
      return true;
    }

    return false
  }, [props.error, props.isLoading, props.value, selected?.name])

  const onEditClick = () => {
    setEditView(!isShowEdit);
    onClickTrack(EventName.profileProfileStatusEditButton);
  }

  return (
    <>
      {/* Mobile view and tab view */}
      <div className="flex lg:hidden flex-col pl-3 py-2 pr-2">
        <p className="text-[14px] flex text-[#7A7A7A] font-semibold py-1">
          {props.title}
        </p>
        {
          isShowEdit
            ? null
            : <div className="flex items-center justify-between">
              <p className="text-[14px] text-[#7A7A7A] font-semibold">{props.value}</p>
              <ActionRestrict>
              <TextIconButton
                onClick={onEditClick}
                className="ml-2"
                text="Edit"
              />
              </ActionRestrict>
            </div>
        }
        {
          isShowEdit
            ? <div className="flex flex-col">
              <select
                type={props.inputType}
                id={props.inputID}
                name={props.inputName}
                value={props.inputvalue}
                className="border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[12px] bg-white rounded-[3px] p-2"
                placeholder=""
                onChange={props.onChange}
              >
                {props?.options?.map((e, i) => (
                  <option value={e.value}>{e.name}</option>
                ))}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px] ml-20 py-1">{props.error}</p>
              )}
              <div className="flex justify-end space-x-2">
                <button
                  disabled={disableSave}
                  onClick={() => {
                    props.onSave();
                  }}
                  className={` flex items-center mx-2 mt-[5px] justify-center  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px] ${disableSave ? "opacity-50" : ""}`}>
                  {props.isLoading ? "Saving..." : "Save"}
                </button>
                <button
                  onClick={() => {
                    props.setDefault()
                    setEditView(false);
                    onClickTrack(EventName.profileProfileStatusCancelButton)
                  }}
                  className=" flex items-center mt-[5px] justify-center  font-bold text-[#D10A11] border border-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px]">
                  Cancel
                </button>
              </div>
            </div>
            : null
        }
      </div>
      {/* desktop view */}
      <div className="hidden lg:grid grid-cols-9 mt-[2rem] pl-[1rem]flex items-center justify-center">
        <div className="col-span-3   md:pl-[1rem] lg:pl-[2rem]">
          <p className="text-[14px] flex text-[#7A7A7A] font-semibold  ">
            {props.title}
          </p>
        </div>
        {!isShowEdit ? (
          <div className="col-span-5 lg:col-span-3 lg:pr-[4rem] md:pl-[1rem] ">
            <p className=" text-[14px] text-[#7A7A7A] font-semibold">{props.value}</p>
          </div>
        ) : (
          <div className="col-span-3  ">
            <p className=" w-full  ">
              <select
                type={props.inputType}
                id={props.inputID}
                name={props.inputName}
                value={props.inputvalue}
                className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
                placeholder=""
                onChange={props.onChange}
              >
                {props?.options?.map((e, i) => (
                  <option value={e.value}>{e.name}</option>
                ))}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </p>
          </div>
        )}

        {isShowEdit
          ? <></>
          : <div className="md:col-span-1 lg:col-span-3">
            <ActionRestrict>
            <EditButton editCallBack={onEditClick} />
            </ActionRestrict>
          </div>}
        {isShowEdit ? (
          <>
            <button
              disabled={disableSave}
              onClick={() => {
                props.onSave();
              }}
              className={` flex items-center mx-2 mt-[5px] justify-center  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px] ${disableSave ? "opacity-50" : ""}`}
            >
              {props.isLoading ? "Saving..." : "Save"}
            </button>
            <button
              onClick={() => {
                props.setDefault()
                setEditView(false);
                onClickTrack(EventName.profileProfileStatusCancelButton)
              }}
              className=" flex items-center mt-[5px] justify-center  font-bold text-[#D10A11] border border-[#D10A11] px-[1rem] rounded-[4px] py-2 text-[11px] lg:text-[13px] h-[35px]"
            >
              Cancel
            </button>
          </>

        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const SettingsRadioButton = (props) => {
  return (
    <div className="flex py-2 items-center">
      <div className="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative cursor-pointer">
        <input
          // aria-labelledby="label1"
          id={props?.isFrom+props.id}
          checked={props.selected == "Y" ? true : false}
          type="radio" 
          value={props.name}
          onChange={(e) => props.onChange(e)}
          name={props?.isFrom+props.name}

          className=" accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
        // className="checkbox appearance-none focus:opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-[#D10A11] focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none"
        />
        <div className="check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"></div>
      </div>
      <label
        // id="label1"
        // htmlFor={props.id}
        className=" font-semibold text-[11px] md:text-[10px] lg:text-[13px] text-[#7A7A7A]  pl-1 cursor-pointer"
      >
        {props.radiotext}
      </label>
    </div>
  );
};
export {
  ProfileSettingsTextField,
  ProfileSettingsPhoneField,
  ProfileSettingsSelectField,
  SettingsRadioButton,
};
