import React, { useEffect, useState, useContext } from "react";
import { loginFrom } from "../../Constants/constants";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { GET, POST } from "../../Services/api_services";
import { ProfileAccordianComponent } from "../ViewMessageComponents/AccordianComponents";
import { IoAdd } from "react-icons/io5"
import horoscopeImage from '../../Images/Group 879.svg'
import HoroscopeProfileImage from '../../Images/Group 279.svg'
import AccrodianSelectImage from '../../Images/Group 930@2x.png'
import addgroupicon from '../../Components/Kalyanlite/assets/Images/addgroupicon.svg'
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
  StaticCircularProgressBar,
  StaticlineProgressBar
} from "./EditProfileUtills";
import BasicForm from "./MyProfileComponents/BasicForm";
import DescriptionForm from "./MyProfileComponents/DescriptionForm";
import { LoginContext } from "../../Contexts/LoginContext"; 
import ReligiousComponent from "./MyProfileComponents/ReligiousComponent";
import HoroscopeComponent from "./MyProfileComponents/HoroscopeComponent";
import ProfessionalComponent from "./MyProfileComponents/ProfessionalComponent";
import LocationComponent from "./MyProfileComponents/LocationComponent";
import FamilyComponent from "./MyProfileComponents/FamilyComponent";
import HabitsComponents from "./MyProfileComponents/HabitsComponents";
import PartnerPreferenceComponent from "./MyProfileComponents/PartnerPreferenceComponent";
import Loader from "../Loader";
import { registerStarList } from "../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import PlusIcon from "../../Images/Path 3979.svg"
import Constants, {
  EventName,
} from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";
import HobbyListComponent from "./MyProfileComponents/HobbyListComponent";

const ProfileBasicComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [editbasicinfofreshData, seteditbasicinfofresh] = useState();
  const [editreligioninfofreshData, seteditreligioninfofresh] = useState();
  const [edithoroinfofreshData, setedithoroinfofresh] = useState();
  const [educationinfofreshData, seteducationinfofresh] = useState();
  const [editlocationinfofreshData, seteditlocationinfofresh] = useState();
  const [familyinfofreshData, setfamilyinfofresh] = useState();
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [profileCount, setProfileCount] = useState([]);
  const [isReligiosLoading, setIsReligiosLoading] = useState(true)
  const [isBasicInfoLoading, setIsBasicInfoLoading] = useState(true)
  const [isHoroscopeLoading, setIsHoroscopeLoading] = useState(true)
  const [isProfessionLoading, setIsProfessionLoading] = useState(true)
  const [isLocationLoading, setIsLocationLoading] = useState(true)
  const [isFamilyLoading, setIsFamilyLoading] = useState(true)
  const [isPrefLoading, setIsPrefLoading] = useState(false)
  const isV3User = localStorage.getItem("isV3User")

  useEffect(() => {
    // apicallDashboard()
    setDashboard1Data(props.dashboard1Data);
    setEditMyProfielData(props.data);
  }, [props.dashboard1Data, props.data]);

  useEffect(() => {
    setProfileCount(props.proileCount)
  },[props.proileCount])
  useEffect(() => {
    console.log("editMyProfileData", editMyProfileData);
  }, [
    editMyProfileData,
    editbasicinfofreshData,
    editreligioninfofreshData,
    edithoroinfofreshData,
    educationinfofreshData,
    editlocationinfofreshData,
    familyinfofreshData,
    dashboard1Data,
  ]);

  const dispatch = useDispatch();
  //called whenever religion, mother tonge dropdown values updated
  const apiCallStar = async (motherTounge) => {
    // getCastUrl

    dispatch(registerStarList(motherTounge))
  };

  const { starList } = useSelector(state => ({
    starList: state.Home?.starList?.data?.data
  }));

  //console.log(starList, "starList");

  function horoscopeCallBack() {
    setIsHoroscopeLoading(true);
    apiCallDropDown("HOROSCOPE");
  }
  function familyCallBack() {
    setIsFamilyLoading(true);
    apiCallDropDown("FAMILY");
  }
  function basicCallBack() {
    setIsBasicInfoLoading(true);
    apiCallDropDown("BASIC");
  }
  function religigosCallBack() {
    setIsReligiosLoading(true);
    apiCallDropDown("RELIGION");
  }
  function professionalCallBack() {
    setIsProfessionLoading(true);
    apiCallDropDown("PROFESSIONAL");
  }
  function locationCallBack() {
    setIsLocationLoading(true);
    apiCallDropDown("LOCATION");
  }
  // const accordianSelect =  props.accordianSelect ?document.getElementsByClassName(props.accordianSelect)[0].scrollIntoView({ behavior: 'smooth',Block:'start' }): ''

  useEffect(() => {
    //console.log("props.accordianSelect", props.accordianSelect);
    if (props.accordianSelect) {
      document.getElementsByClassName(props.accordianSelect)[0].scrollIntoView({ behavior: 'smooth', Block: 'start' })
    }
    if (props.accordianSelect === 'HOROSCOPE') {
      horoscopeCallBack();
    }
    else if (props.accordianSelect === 'FAMILY') {
      //console.log("props.accordianSelectfam", props.accordianSelect);
      familyCallBack();
    }
    else if (props.accordianSelect === 'BASIC') {
      basicCallBack();
    }
    else if (props.accordianSelect === 'RELIGION') {
      religigosCallBack();
    }
    else if (props.accordianSelect === 'PROFESSIONAL') {
      professionalCallBack();
    }
    else if (props.accordianSelect === 'LOCATION') {
      locationCallBack();
    }
  }, [props.accordianSelect]);
  const apiCallDropDown = async (type) => {
    let url = "";

    if (type === "BASIC") {
      url = `${config.api.API_URL}${EndPoints.editbasicinfofreshUrl()}`;
    } else if (type === "RELIGION") {
      url = `${config.api.API_URL}${EndPoints.editreligioninfofreshUrl()}`;
    } else if (type === "HOROSCOPE") {
      url = `${config.api.API_URL}${EndPoints.edithoroinfofreshUrl({ motherToungeId: editMyProfileData?.motherTongue?.at(0)?.id })}`;
    } else if (type === "PROFESSIONAL") {
      url = `${config.api.API_URL}${EndPoints.educationinfofreshUrl()}`;
    } else if (type === "LOCATION") {
      url = `${config.api.API_URL}${EndPoints.editlocationinfofreshUrl()}`;
    } else if (type === "FAMILY") {
      url = `${config.api.API_URL}${EndPoints.familyinfofreshUrl()}`;
    }
    let { statusCode, data } = await GET(url);
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      setDropDownData(data.data, type);
    } else if (statusCode === 401) {
      logout();
    }
    setIsReligiosLoading(false)
    setIsBasicInfoLoading(false)
    setIsHoroscopeLoading(false)
    setIsProfessionLoading(false)
    setIsLocationLoading(false)
    setIsFamilyLoading(false)
  };
  const setDropDownData = (data, type) => {
    if (type === "BASIC") {
      seteditbasicinfofresh(data);
    } else if (type === "RELIGION") {
      seteditreligioninfofresh(data);
    } else if (type === "HOROSCOPE") {
      setedithoroinfofresh(data);
    } else if (type === "PROFESSIONAL") {
      seteducationinfofresh(data);
    } else if (type === "LOCATION") {
      seteditlocationinfofresh(data);
    } else if (type === "FAMILY") {
      setfamilyinfofresh(data);
    }
  };
  //console.log(dashboard1Data?.data?.isPaid, dashboard1Data, 'dashboard1Data');

  function preferenceRefresh() {
    
      setIsPrefLoading(true);
      
    
  }

  const { onClickTrack } = useAnalytics()

  return (
    <div className="p-3">
      <div className="  mx-auto mb-5">
        {(dashboard1Data?.username && dashboard1Data?.smProfileId) ? dashboard1Data?.username + ' (' + dashboard1Data?.smProfileId + ')' : ''}
      </div>
      {/* {tab and wesite view} */}
      <div className="hidden md:block">
        <div className="flex justify-between items-center  ">
          <div className="flex flex-col md:flex-row lg:flex-row justify-between items-center lg:gap-6 gap-4">
            <span className=" relative inline-block">
              {/* {dashboard1Data?.avatar == "N" ?
                <img
                  alt=""
                  src={dashboard1Data?.profile_imageurl}
                  onClick={() => { props.onOpenCallback(dashboard1Data?.profile_imageurl) }}
                  className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem]  lg:w-28 lg:h-28"
                />
                :
                <img
                  alt=""
                  src={dashboard1Data?.profile_imageurl}
                  className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] lg:w-28 lg:h-28"
                />
              } */}
              {editMyProfileData?.profilepic.includes("avatar") ?
                <img
                  alt=""
                  src={editMyProfileData?.profilepic}
                  className={`w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] ${isV3User ? "lg:w-40 lg:h-24 xl:w-28 xl:h-28" : "lg:w-28 lg:h-28"}`}
                />
                :
                <img
                  alt=""
                  src={editMyProfileData?.profilepic}
                  onClick={() => { props.onOpenCallback(editMyProfileData?.profilepic) }}
                  className={`w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] ${isV3User ? "lg:w-40 lg:h-24 xl:w-28 xl:h-28" : "lg:w-28 lg:h-28"}`}
                />
              }
              {/* <span onClick={() => { props.callbackPhotoNav(); onClickTrack(EventName.profilePhotoPlusButton) }} 
              // className="p-1 absolute -top-4 -right-4 bg-[#FDC300] rounded-full cursor-pointer "
              className={`absolute top-[-1rem] lg:top-[-0.7rem] ${isV3User ? "w-[1.5rem] h-[1.5rem] lg:text-[22px]" : "w-[2rem] h-[2rem] lg:text-[32px]"}  text-[#ffffff] right-[-1rem] lg:right-[-1rem] inline-flex items-center justify-center lg:px-[6px] md:px-1  md:text-[30px] pb-1 font-bold leading-none bg-[#FDC300] rounded-full cursor-pointer`}

              > */}
                {/* <IoAdd size={22} color="white" /> */}
                {/* +

              </span> */}
              <img src={addgroupicon} onClick={() => { props.callbackPhotoNav(); onClickTrack(EventName.profilePhotoPlusButton) }} className="p-1 absolute -top-4 -right-4 cursor-pointer" alt=""/>
            </span>
            
            <div className="lg:space-y-6 space-y-2">
              <div
                onClick={() => {
                  props.callBackHoroscopePopup();
                  (editMyProfileData?.incomeproof || editMyProfileData?.eduproof || editMyProfileData?.idproof
                    ? onClickTrack(EventName.profileViewHoroscope)
                    : onClickTrack(EventName.profileAddHoroscope))
                }}
                className={`${isV3User ? "border border-[#D10A11] cursor-pointer flex md:p-[0.2rem] lg:px-[0.4rem] lg:py-[0.4rem] rounded-[6px] lg:rounded-[10px] w-[14rem] md:w-[9rem] lg:w-[10rem] xl:w-[14rem] bg-white" : "border border-[#D10A11] cursor-pointer flex md:p-[0.2rem] lg:p-2 rounded-[6px] lg:rounded-[10px]"}`}
              >
                <div className={`flex items-center ${isV3User ? "pl-[0.4rem] md:pl-[0.4rem]" : "pl-1 md:pl-0"}`}>
                  <img
                    className="w-[1.2rem] lg:w-[1.5rem]"
                    src={HoroscopeProfileImage}
                    alt=""
                  />
                </div>
                <p className={`text-[#D10A11] p-1 pl-3 md:pl-2 lg:pl-2 flex items-center font-semibold ${isV3User ?"text-[9px] md:text-[10px] xl:text-[14px] lg:text-[11px]":"text-[9px] md:text-[10px] lg:text-[14px]"}  whitespace-nowrap}`}>
                  {editMyProfileData?.horoscope
                    ? "View Horoscope"
                    : "Add Horoscope"}
                </p>
              </div>
              <div
                onClick={() => {
                  props.callBackTrustPopup();
                  (editMyProfileData?.incomeproof || editMyProfileData?.eduproof || editMyProfileData?.idproof
                    ? onClickTrack(EventName.profileViewTrustdocument)
                    : onClickTrack(EventName.profileAddTrustdocument))
                }}
                className={`${isV3User ? "border border-[#D10A11] cursor-pointer flex md:p-[0.2rem] lg:px-[0.4rem] lg:py-[0.4rem] rounded-[6px] lg:rounded-[10px] w-[14rem] md:w-[9rem] lg:w-[10rem] xl:w-[14rem] bg-white" : "border border-[#D10A11] cursor-pointer flex md:p-[0.2rem] lg:p-2 rounded-[6px] lg:rounded-[10px]"}`}
              >
                <div className={`flex items-center ${isV3User ? "pl-[0.4rem] md:pl-[0.4rem]" : "pl-1 md:pl-0"}`}>
                  <img
                    className="w-[1.2rem] lg:w-[1.3rem]"
                    src={horoscopeImage}
                    alt=""
                  />
                </div>
                <p className={`text-[#D10A11] p-1 pl-3 md:pl-2 lg:pl-2 flex items-center font-semibold ${isV3User ?"text-[9px] md:text-[10px] xl:text-[14px] lg:text-[11px]":"text-[9px] md:text-[10px] lg:text-[14px]"}  whitespace-nowrap`}>
                  {editMyProfileData?.incomeproof ||
                    editMyProfileData?.eduproof ||
                    editMyProfileData?.idproof
                    ? "View Trust Document"
                    : "Add Trust Document"}
                </p>
              </div>
            </div>
          </div>
          <div className=" md:pl-[0.3rem] pl-[1rem]">
            <div className="flex md:pl-[0.3rem]">
              <div className="flex " id="progress">
                <StaticCircularProgressBar
                  value={profileCount?.profilecompletationcount}
                  profileStrengthRemaining={dashboard1Data?.profileStrengthRemaining?.split(
                    ","
                  )}
                  remainingProfileNav={props.remainingProfileNav}
                />
                {/* <div  data-progress="65" style="--progress: 65deg;">65%</div> */}
              </div>
              <div className=" flex items-center ">
                <div className="  pl-2  ">
                  <p className={`  ${isV3User ?"md:text-[10px] xl:text-[16px] lg:text-[13px]":"md:text-[10px] lg:text-[16px]"} font-semibold text-[#7A7A7A] whitespace-nowrap`}>
                    {" "}
                    Profile Completion{" "}
                  </p>
                  <p className={`  ${isV3User ?"md:text-[10px] xl:text-[16px] lg:text-[13px]":"md:text-[10px] lg:text-[16px]"} font-semibold text-[#7A7A7A] whitespace-nowrap`}>
                    Score{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className=" flex items-center ">
            <div className="  pl-2  ">
              <p className=" md:text-[10px] lg:text-[16px] font-semibold text-[#7A7A7A] ">
                {" "}
                Profile Completion{" "}
              </p>
              <p className=" md:text-[10px] lg:text-[16px] font-semibold text-[#7A7A7A] ">
                Score{" "}
              </p>
            </div>
          </div> */}
          </div>
        </div>
      </div>

      {/* {mobile view} */}


      <div className="md:hidden ">


        <div className="md:hidden flex mx-[1.5rem] ">
          <div className="flex md:flex-row justify-center items-center gap-6 ">
            <span className=" relative inline-block">
              {/* {dashboard1Data?.avatar == "N" ?
                <img
                  alt=""
                  src={dashboard1Data?.profile_imageurl}
                  onClick={() => { props.onOpenCallback(dashboard1Data?.profile_imageurl) }}
                  className="w-[7rem] h-[7rem]  rounded-[10px] lg:w-28 lg:h-28"
                />
                :
                <img
                  alt=""
                  src={dashboard1Data?.profile_imageurl}
                  className="w-[8rem] h-[8rem] rounded-[10px]  lg:w-28 lg:h-28"
                />
              } */}
              {editMyProfileData?.profilepic.includes("avatar") ?
                <img
                  alt=""
                  src={editMyProfileData?.profilepic}
                  className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] lg:w-28 lg:h-28"
                />
                :
                <img
                  alt=""
                  src={editMyProfileData?.profilepic}
                  onClick={() => { props.onOpenCallback(editMyProfileData?.profilepic) }}
                  className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem]  lg:w-28 lg:h-28"
                />
              }
              <span onClick={() => { props.callbackPhotoNav(); onClickTrack(EventName.profilePhotoPlusButton) }} 
              // className="p-1 absolute top-[-9px] right-[-6px] bg-[#FDC300] rounded-full cursor-pointer "
              className="absolute w-[2rem] h-[2rem]  text-[#ffffff] text-[31px] top-[-1rem] right-[-1rem] inline-flex items-center justify-center pb-[0.3rem] font-bold leading-none bg-[#FDC300] rounded-full"

              > +
                {/* <IoAdd size={15} className="stroke-2" color="white" /> */}

                
              </span>
            </span>
            <div className="lg:space-y-6 space-y-2">
              <div
                onClick={() => {
                  props.callBackHoroscopePopup();
                  (editMyProfileData?.incomeproof || editMyProfileData?.eduproof || editMyProfileData?.idproof
                    ? onClickTrack(EventName.profileAddHoroscope)
                    : onClickTrack(EventName.profileViewHoroscope))
                  
                }}
                className="border border-[#D10A11] cursor-pointer h-[2rem] w-[11rem] flex md:p-[0.2rem] lg:p-2 rounded-[6px] lg:rounded-[10px]"
              >
                <div className="flex items-center pl-1 md:pl-0">
                  <img
                    className="w-[1.4rem]"
                    src={HoroscopeProfileImage}
                    alt=""
                  />
                </div>
                <p className="text-[#D10A11] p-1 pl-3  lg:pl-2 flex  h-[2rem]  items-center font-semibold text-[12px] md:text-[10px] lg:text-[14px] whitespace-nowrap">
                  {editMyProfileData?.horoscope
                    ? "View Horoscope"
                    : "Add Horoscope"}
                </p>
              </div>
              <div
                onClick={() => {
                  props.callBackTrustPopup();
                  (editMyProfileData?.incomeproof || editMyProfileData?.eduproof || editMyProfileData?.idproof
                    ? onClickTrack(EventName.profileAddTrustdocument)
                    : onClickTrack(EventName.profileViewTrustdocument))
                  
                }}
                className="border border-[#D10A11] cursor-pointer w-[11rem] flex md:p-[0.2rem] lg:p-2 rounded-[6px] lg:rounded-[10px] "
              >
                <div className="pl-1 md:pl-0 flex items-center">
                  <img
                    className="w-[1.2rem]"
                    src={horoscopeImage}
                    alt=""
                  />
                </div>
                <p className="text-[#D10A11] p-1 pl-3  pt-[0.4rem] lg:pl-2 flex h-[2rem] items-center font-semibold text-[12px] md:text-[10px] lg:text-[14px] whitespace-nowrap">
                  {editMyProfileData?.incomeproof ||
                    editMyProfileData?.eduproof ||
                    editMyProfileData?.idproof
                    ? "View Trust Document"
                    : "Add Trust Document"}
                </p>
              </div>
            </div>
          </div>

        </div>
        <div className="mt-1">
          <div className="">
            {/* <div className="flex " id="progress">
              <StaticCircularProgressBar
                value={dashboard1Data?.profilecompletationcount}
                profileStrengthRemaining={dashboard1Data?.profileStrengthRemaining?.split(
                  ","
                )}
                remainingProfileNav={props.remainingProfileNav}
              />
            </div> */}
            {/* <div className=" flex items-center ">
              <div className="  pl-2  ">
                <p className=" md:text-[10px] lg:text-[16px] font-semibold text-[#7A7A7A]">
                  {" "}
                  Profile Completion{" "}
                </p>
                <p className=" md:text-[10px] lg:text-[16px] font-semibold text-[#7A7A7A]">
                  Score{" "}
                </p>
              </div>
            </div> */}

            <div className=" pt-[0.6rem] ">
              <p className={`${isV3User ? "hidden md:block text-[#575556] text-[20px] md:text-[22px] font-semibold px-3 py-2" : "text-[#575556] text-[20px] md:text-[22px] font-semibold px-3 py-2"}`}>
              Profile Completion Score {profileCount?.profilecompletationcount}%
              </p>
              {isV3User ? 
              <p className="md:hidden text-center text-[#575556] text-[20px] md:text-[22px] font-semibold px-3 py-2">
              Profile Completion Score  {profileCount?.profilecompletationcount}%
              </p>: null}
              <div className={`${isV3User ? "md:pt-4 mx-[2rem]" : "bg-white pt-4 mx-[2rem]"}`}>
                <div className="md:w-[20rem] w-[91%] bg-[#707070] rounded-lg h-[8px]">
                  <div
                    className="bg-[#73FE81] h-[8px] rounded"
                    //  style="width: 76%"
                    style={{ width: `${profileCount?.profilecompletationcount}%` }}
                  >
                    
                    <StaticlineProgressBar 
                    value={profileCount?.profilecompletationcount}
                      profileStrengthRemaining={dashboard1Data?.profileStrengthRemaining?.split(
                        ","
                      )} 
                      remainingProfileNav={props.remainingProfileNav}
                      />

                      
                  </div>
                </div>
            
                
              </div>
            </div>
          </div>
          {/* <div className=" flex items-center ">
            <div className="  pl-2  ">
              <p className=" md:text-[10px] lg:text-[16px] font-semibold text-[#7A7A7A] ">
                {" "}
                Profile Completion{" "}
              </p>
              <p className=" md:text-[10px] lg:text-[16px] font-semibold text-[#7A7A7A] ">
                Score{" "}
              </p>
            </div>
          </div> */}
        </div>
      </div>


      <div className={`${isV3User ? "custom-font-family mx-auto mt-6 md:mt-8" : "mx-auto mt-8"}`}>
        <div
        //  activeClass: ' focus:outline-none',
        //  bordeClass: ' border-radiums:10px; border-width:1px; border-color:#707070 '
        //  }"
        // className="p-6 mx-auto bg-white"
        >
          <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={"DESCRIPTION"}
              titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
              defaultView={props.accordianSelect === "" ? true : false}
              titleIcon1={
                <img
                  //   :className="faqOne === true ? 'block' : 'hidden'"
                  src={AccrodianSelectImage}
                  className=""
                  alt=""
                />
              }
              titleIcon2={
                <img
                  //   :className="faqOne === false ? 'block' : 'hidden'"
                  src={PlusIcon}
                  className=""
                  alt=""
                />
              }
              Callback={() => { onClickTrack(EventName.profileDescriptionButton) } }
            >
              {props.isEditProfileLoading
                ? <Loader />
                : <DescriptionForm
                  data={editMyProfileData}
                  callBackReload={props.callBackReload}
                />}
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>
      <div className={`${isV3User ? "custom-font-family mx-auto mt-6 md:mt-8" : "mx-auto mt-8"}`}>
        <div

        // className="p-6 mx-auto bg-white"
        >
          <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={"BASIC"}
              titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
              defaultView={props.accordianSelect === "BASIC" ? true : false}
              titleIcon1={
                <img src={AccrodianSelectImage} className="" alt="" />
              }
              titleIcon2={
                <img src={PlusIcon} className="" alt="" />
              }
              Callback={() =>{
                basicCallBack();
                onClickTrack(EventName.profileBasicButton)
              }}
            >
              {
                props.isEditProfileLoading || isBasicInfoLoading
                  ? <Loader />
                  : <BasicForm
                    data={editMyProfileData}
                    dropDown={editbasicinfofreshData}
                    refresh={basicCallBack}
                    callBackReload={props.callBackReload}
                    isPaid={props?.dashboard1Data?.isPaid === 'Y'}
                  />
              }

            </ProfileAccordianComponent>
          </div>
        </div>
      </div>

      <div className={`${isV3User ? "custom-font-family mx-auto mt-6 md:mt-8" : "mx-auto mt-8"}`}>
        <div
        //   className="p-6 mx-auto bg-white"
        >
          <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={"RELIGIOUS"}
              titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
              defaultView={props.accordianSelect === "RELIGIOUS" ? true : false}
              titleIcon1={
                <img
                  //   :className="faqOne === true ? 'block' : 'hidden'"
                  src={AccrodianSelectImage}
                  className=""
                  alt=""
                />
              }
              titleIcon2={
                <img
                  //   :className="faqOne === false ? 'block' : 'hidden'"
                  src={PlusIcon}
                  className=""
                  alt=""
                />
              }
              Callback={() => {
                religigosCallBack();
                onClickTrack(EventName.profileReligiousButton)
              }}
            >
              {props.isEditProfileLoading || isReligiosLoading
                ? <Loader />
                : <ReligiousComponent
                  apiCallStar={apiCallStar}
                  data={editMyProfileData}
                  dropDown={editreligioninfofreshData}
                  callBackReload={props.callBackReload}
                  refresh={religigosCallBack}
                  isPaid={props?.dashboard1Data?.isPaid === 'Y'}
                />}
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>

      <div className={`${isV3User ? "custom-font-family mx-auto mt-6 md:mt-8" : "mx-auto mt-8"}`}>
        <div
        //    className="p-6 mx-auto bg-white"
        >
          <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={"HOROSCOPE"}
              titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
              defaultView={props.accordianSelect === "HOROSCOPE" ? true : false}
              titleIcon1={
                <img
                  //   :className="faqOne === true ? 'block' : 'hidden'"
                  src={AccrodianSelectImage}
                  className=""
                  alt=""
                />
              }
              titleIcon2={
                <img
                  //   :className="faqOne === false ? 'block' : 'hidden'"
                  src={PlusIcon}
                  className=""
                  alt=""
                />
              }
              Callback={() => {
                horoscopeCallBack();
                onClickTrack(EventName.profileHoroscopeButton);
              }}
            >
              {props.isEditProfileLoading || isHoroscopeLoading
                ? <Loader />
                : <HoroscopeComponent
                  starList={starList}
                  refresh={horoscopeCallBack}
                  data={editMyProfileData}
                  callBackReload={props.callBackReload}
                  dropDown={edithoroinfofreshData}
                />}
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>
      <div className={`${isV3User ? "custom-font-family mx-auto mt-6 md:mt-8" : "mx-auto mt-8"}`}>
        <div
        //   className="p-6 mx-auto bg-white"
        >
          <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={"PROFESSIONAL"}
              titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
              defaultView={props.accordianSelect === "PROFESSIONAL" ? true : false}
              titleIcon1={
                <img
                  //   :className="faqOne === true ? 'block' : 'hidden'"
                  src={AccrodianSelectImage}
                  className=""
                  alt=""
                />
              }
              titleIcon2={
                <img
                  //   :className="faqOne === false ? 'block' : 'hidden'"
                  src={PlusIcon}
                  className=""
                  alt=""
                />
              }
              Callback={() => {
                professionalCallBack();
                onClickTrack(EventName.profileProfessionalButton);
              }}
            >
              {props.isEditProfileLoading || isProfessionLoading
                ? <Loader />
                : <ProfessionalComponent
                  data={editMyProfileData}
                  refresh={professionalCallBack}
                  callBackReload={props.callBackReload}
                  dropDown={educationinfofreshData}
                />}
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>
      <div className={`${isV3User ? "custom-font-family mx-auto mt-6 md:mt-8" : "mx-auto mt-8"}`}>
        <div
        // className="p-6 mx-auto bg-white"
        >
          <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={"LOCATION"}
              titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
              defaultView={props.accordianSelect === "LOCATION" ? true : false}
              titleIcon1={
                <img
                  //   :className="faqOne === true ? 'block' : 'hidden'"
                  src={AccrodianSelectImage}
                  className=""
                  alt=""
                />
              }
              titleIcon2={
                <img
                  //   :className="faqOne === false ? 'block' : 'hidden'"
                  src={PlusIcon}
                  className=""
                  alt=""
                />
              }
              Callback={() => {
                locationCallBack();
                onClickTrack(EventName.profileLocationButton);
              }}
            >
              {props.isEditProfileLoading || isLocationLoading
                ? <Loader />
                : <LocationComponent
                  data={editMyProfileData}
                  refresh={locationCallBack}
                  callBackReload={props.callBackReload}
                  dropDown={editlocationinfofreshData}
                />}
            </ProfileAccordianComponent>
          </div>
        </div>
      </div>
      <div className={`${isV3User ? "custom-font-family mx-auto mt-6 md:mt-8" : "mx-auto mt-8"}`}>
        <div
        //   className="p-6 mx-auto bg-white"
        >
          <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
            <ProfileAccordianComponent
              title={"FAMILY"}
              titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
              defaultView={props.accordianSelect === "FAMILY" ? true : false}
              titleIcon1={
                <img
                  //   :className="faqOne === true ? 'block' : 'hidden'"
                  src={AccrodianSelectImage}
                  className=""
                  alt=""
                />
              }
              titleIcon2={
                <img
                  //   :className="faqOne === false ? 'block' : 'hidden'"
                  src={PlusIcon}
                  className=""
                  alt=""
                />
              }
              Callback={() => {
                familyCallBack();
                onClickTrack(EventName.profileFamilyButton);
              }}
            >
              {props.isEditProfileLoading || isFamilyLoading
                ? <Loader />
                : <FamilyComponent
                  data={editMyProfileData}
                  callBackReload={props.callBackReload}
                  refresh={familyCallBack}
                  dropDown={familyinfofreshData}
                />}
            </ProfileAccordianComponent>
          </div>
        </div>

        <div className="  mx-auto mt-8">
          <div
          // className="p-6 mx-auto bg-white"
          >
            <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
              <ProfileAccordianComponent
                title={"HABITS"}
                titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
                defaultView={props.accordianSelect === "HABITS" ? true : false}
                titleIcon1={
                  <img
                    //   :className="faqOne === true ? 'block' : 'hidden'"
                    src={AccrodianSelectImage}
                    className=""
                    alt=""
                  />
                }
                titleIcon2={
                  <img
                    //   :className="faqOne === false ? 'block' : 'hidden'"
                    src={PlusIcon}
                    className=""
                    alt=""
                  />
                }
                Callback={() => { onClickTrack(EventName.profileHabitsButton) }}
              >
                {
                  props.isEditProfileLoading
                    ? <Loader />
                    : <HabitsComponents
                      data={editMyProfileData}
                      callBackReload={props.callBackReload}
                    />
                }
              </ProfileAccordianComponent>
            </div>
          </div>

          <div className="  mx-auto mt-8">
            <div
            //   className="p-6 mx-auto bg-white"
            >
              <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
                <ProfileAccordianComponent
                  title={"EXPECTATIONS"}
                  titleClassName={`${isV3User ? "bg-[#DADADAE0] lg:py-[0.7rem]" : "bg-[#E9E9E9] lg:py-[1rem]"} flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider`}
                  defaultView={props.accordianSelect === "PREFERENCES" ? true : false}
                  titleIcon1={
                    <img
                      //   :className="faqOne === true ? 'block' : 'hidden'"
                      src={AccrodianSelectImage}
                      className=""
                      alt=""
                    />
                  }
                  titleIcon2={
                    <img
                      //   :className="faqOne === false ? 'block' : 'hidden'"
                      src={PlusIcon}
                      className=""
                      alt=""
                    />
                  }
                  Callback={() => { onClickTrack(EventName.profilePartnerPrefernceButton) }}
                >
                  {props.isEditProfileLoading || props.isPartnerPrefLoading
                    ? <Loader />
                    : <PartnerPreferenceComponent
                    dashboard1Data={props?.dashboard1Data}
                      data={editMyProfileData}
                      callBackReload={props.callBackReload}
                      refresh={preferenceRefresh}
                      managePreferenceData={props?.managePreferenceData}
                    />}
                </ProfileAccordianComponent>
              </div>
            </div>
          </div>
          {isV3User &&
            <div className="mx-auto mt-8">
              <div>
                <div className="accordions border-[#EAE6DF] bg-white">
                  <ProfileAccordianComponent
                    title={"HOBBIES & INTERESTS"}
                    titleClassName={"bg-[#DADADAE0] lg:py-[0.7rem] flex items-center justify-between  w-full pl-3 pr-2 font-bold text-[#575556] py-[0.5rem] cursor-pointer md:text-[12px] lg:text-[16px] tracking-wider"}
                    defaultView={false}
                    titleIcon1={
                      <img
                        src={AccrodianSelectImage}
                        className=""
                        alt=""
                      />
                    }
                    titleIcon2={
                      <img
                        src={PlusIcon}
                        className=""
                        alt=""
                      />
                    }
                    Callback={() => { onClickTrack(EventName.profilePartnerPrefernceButton) }}
                  >
                       <HobbyListComponent
                        data={props?.data?.hobbiesInterest}
                        callHobbiesAndInterest={props?.callHobbiesAndInterest}
                        editMyProfileData={editMyProfileData}
                      />
                  </ProfileAccordianComponent>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ProfileBasicComponent;
