import React, { createContext, useState } from "react";
export const NavBarContext = createContext();
export default function NavBarProvider({children}) {
  const [menuSelect, setMenuSelect] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const openFilter = () => setIsOpenFilter(true)
  const closeFilter = () => setIsOpenFilter(false)

  const [searchFilter, setSearchFilter] = useState(true)
  const [showSearch, setShowSearch] = useState(false)
  const [showMedia, setShowMedia] = useState(false)
  const [showMutePopup, setShowMutePopup] = useState(false)
  const [showBlockPopup, setShowBlockPopup] = useState(false)
  const [showUnBlockPopup, setShowUnBlockPopup] = useState(false)
  const [showReportPopup, setShowReportPopup] = useState(false)
  const [showPaynowPopup, setShowPaynowPopup] = useState(false)
  const [showPayBackPopup, setShowPayBackPopup] = useState(false)
  const [showDownloadPopup, setShowDownloadPopup] = useState(false)
  const [showMessagePopup, setShowMessagePopup] = useState(false)
  // const [isFilterResult, setIsFilterResult] = useState(false)
  const [showNoMatchesPopup, setShowNoMatchesPopup] = useState(false)
  const [showSuccessUpload, setShowSuccessUpload] = useState(false)
  const [showLogoutPopup, setShowLogoutPopup] = useState(false)
  const [showDangerPopup, setShowDangerPopup] = useState(false)
  const [showWarningPopup, setShowWarningPopup] = useState(false)
  const [showParentPopup, setShowParentPopup] = useState(false)
  const [showValidPopup, setShowValidPopup] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [showClearFilterPopup, setShowClearFilterPopup] = useState(false)
  const [showMyProfileAddPhotoPopup, setShowMyProfileAddPhotoPopup] = useState(false)
  const [searchFocus, setSearchFocus] = useState(false)
  const [clearButton, setClearButton] = useState(false)
  const [groupChatClicked, setGroupChatClicked] = useState(false)
  const [customerChatClicked, setCustomerChatClicked] = useState(false)
  const [completeNow,setCompleteNow] = useState(true)
  const [profileSharedFrom, setProfileSharedFrom] = useState()
  const[showHoroPopup,setShowHoroPopup]=useState(true)
  const[openfamilymodel,setopenfamilymodel]=useState(true)
  const[showModel,setShowModel]=useState(true)
  const [clickAddHoro, setClickAddHoro] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [clickAddHabits, setClickAddHabits] = useState(false);

  const [clickAddAstro, setClickAddAstro] = useState(false);
  const [clickAddFamily, setClickAddFamily] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [customerTemplate, setCustomerTemplate] = useState(true);
  const [isCustomer, setIsCustomer] = useState('No')
  const [showRankingPopup, setShowRankingPopup] = useState(false)
  const [isRequestMsg, setIsRequestMsg] = useState('');
  const [isRequestMsgClick, setIsRequestMsgClick] = useState(false);
  const [isPusherReceived, setIsPusherReceived] = useState(false)
  const [showValidationMessage,setShowValidationMessage]= useState(false)

  const selectedMenu = {
    menuSelect,
    setMenuSelect,
    isDrawerOpen, setIsDrawerOpen,
    openDrawer,
    closeDrawer,
    isOpenFilter, setIsOpenFilter,
    openFilter ,
    closeFilter,
    searchFilter, setSearchFilter,
    showSearch, setShowSearch,
    showMedia, setShowMedia,
    showMutePopup, setShowMutePopup,
    showBlockPopup, setShowBlockPopup,
    showReportPopup, setShowReportPopup,
    showPaynowPopup, setShowPaynowPopup,
    showPayBackPopup, setShowPayBackPopup,
    showDownloadPopup, setShowDownloadPopup,
    showUnBlockPopup, setShowUnBlockPopup,
    showMessagePopup, setShowMessagePopup,
    showNoMatchesPopup, setShowNoMatchesPopup,
    showSuccessUpload, setShowSuccessUpload,
    showLogoutPopup, setShowLogoutPopup,
    showDangerPopup, setShowDangerPopup,
    showWarningPopup, setShowWarningPopup,
    showParentPopup, setShowParentPopup,
    showValidPopup, setShowValidPopup,
    showSuccessPopup, setShowSuccessPopup,
    showClearFilterPopup, setShowClearFilterPopup,
    showMyProfileAddPhotoPopup, setShowMyProfileAddPhotoPopup,
    searchFocus, setSearchFocus,
    clearButton, setClearButton,
    groupChatClicked, setGroupChatClicked,
    customerChatClicked, setCustomerChatClicked,
    completeNow,setCompleteNow,
    profileSharedFrom,setProfileSharedFrom,
    showHoroPopup,setShowHoroPopup,
    openfamilymodel,setopenfamilymodel,
    openModel, setOpenModel,
    showModel,setShowModel,
    clickAddHoro, setClickAddHoro,
    clickAddHabits, setClickAddHabits,
    clickAddAstro, setClickAddAstro,
    clickAddFamily, setClickAddFamily,
    submitStatus, setSubmitStatus,
    isGenerating, setIsGenerating,
    customerTemplate, setCustomerTemplate,
    isCustomer, setIsCustomer,
    isRequestMsg, setIsRequestMsg,
    isRequestMsgClick, setIsRequestMsgClick,
    isPusherReceived, setIsPusherReceived,
    showValidationMessage,setShowValidationMessage,
  };
  return (
    <NavBarContext.Provider value={selectedMenu}>
      {children}
    </NavBarContext.Provider>
  )
}
