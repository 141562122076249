import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useSearchParams } from 'react-router-dom';

import UserProfileHeader from "../Components/UserProfileHeader";
import UserProfileFooter from "../Components/UserProfileFooter";
import { useLocation, useNavigate } from "react-router";
import "../Styles/app-styles.css";
import NavigationBar from "../Components/NavBar";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar";
import { POST } from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import endPoints from "../Constants/EndPoints";
import Slider from "react-slick";
import {
  DashboardSection,
  DashboardSection2,
  DashboardSection4,
} from "../Components/DashboardComponents/DashboardSection";
import ProfileSection from "../Components/DashboardComponents/ProfileSection";
import {
  MatchesProfileSection,
  MatchesProfileSection2,
} from "../Components/MatchesProfile/MatchesProfileSection";

//context
import { LoginContext } from "../Contexts/LoginContext";
import PhonePopup from "../Components/DashboardComponents/PhonePopup";
import SendMessagePopup from "../Components/DashboardComponents/SendMessagePopup";
import {
  BulkRequestPopup,
  CommonPopup,
  CommonProfilePopup,
  CommonSuccessPopup,
  MessageSuccessPopup,
  UploadPhotoPopup,
  ViewPhotoPasswordPopup,
} from "../Components/CommonPopup";
import Constants, { EditProfileMenu, InboxFilters, loginFrom } from "../Constants/constants";
import ImageHoroscopePopup from "../Components/Edit Profile/ImageHoroscopePopup";
import { TrustDocumentViewPopup } from "../Components/Edit Profile/TrustDocumentPopup";
import RequestPhotoHoroTrustPopup from "../Components/DashboardComponents/RequestPhotoHoroTrustPopup";
import RouteSegments, { RouteHelper } from "../Routes/RouteSegment";
import ViewMessageNav from "../Components/ViewMessageComponents/ViewMessageNav";
import { NavListContext } from "../Contexts/navContext";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { blockProfile, getAstroBalance, getHoroscopeMatch } from "../Store/Conversation/Action";
import { useDispatch, useSelector } from "react-redux";
import KjBannerPopup from "../Components/DashboardComponents/KjBannerPopup";
import { NavBarContext } from "../Contexts/NavBarContext";
import config from "../config";
import LoaderGif from "../Gif/loader.gif"
import Loader from "../Components/Loader";
import ParentsPhonePopup from "../Components/DashboardComponents/ParentsPhonePopup";
import DownloadAppBar from "../Components/DownloadAppBar";
import MessageSentIcon from "../Images/message-sent.svg"
import { dashboardLeftPanelData, dashboardMatcheslist, dashboardProfileData } from '../Store/DashboardAPI/Action';
import { dateFormat, getIsLoginFromAdmin } from "../Utils";
import incomicon from '../Images/icomeicon.svg'
import familyvaluesicon from '../Images/familyvaluesicon.svg'
import familystatusicon from '../Images/familystatusicon.svg'
import familyicon from '../Images/familyicon.svg'
import complexionicon from '../Images/complexionicon.svg'
import { getDeviceInfo } from "../Components/Kalyanlite/utils";
export const MatchesProfile = (props) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation();
  const navigate = useNavigate();
  const { navList, setNavList } = useContext(NavListContext);
  const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const [addKjBanner, setAddKjBanner] = useState();
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showImageHoroScopePopup, setShowImageHoroScopepopup] = useState(false);
  const [showMatchHoroscopePopup, setShowMatchHoroscopePopup] = useState(false)
  const [showTrustPopup, setTrustPopup] = useState(false);
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [phoneNumData, setPhoneNumData] = useState();
  const [showSendMessagePopup, setSendMessagePopup] = useState(false);
  const [showMessageSuccess, setshowMessageSuccess] = useState(false);
  const [messageProfileDetails, setMessageProfileDetails] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [showBlockPopup, setShowBlockPopup] = useState(false);
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showPasswordPopup, setShowPasswordpopup] = useState(false);
  const [showRequestPopup, setShowRequestpopup] = useState(false);
  const [showRequestSidePanelPopup, setShowRequestSidePanelpopup] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [pswErrorMsg, setPswErrorMsg] = useState("")
  const [parentNumber, setParentNumber] = useState()
  const [showParentRequestPopup, setShowParentRequestPopup] = useState(false)
  const [messageFor, setMessageFor] = useState();
  const { loginDetail, logout } = useContext(LoginContext);
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  const [photoview, setPhotoview] = useState()
  const [showLoader, setShowLoader] = useState(false)
  const [showParentNumPopup, setShowParentNumPopup] = useState(false)
  const [showAdminPopup, setShowAdminPopup] = useState(false)

  const [requestsType, setRequestsType] = useState([]);

  const [showPhotoRequestPopup, setShowPhotoRequestPopup] = useState(false)
  const [showParentsRequestPopup, setShowParentsRequestPopup] = useState(false)
  const [showMessagePopup, setShowMessagePopup] = useState(false)

  const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({ show: false, title: "", content: "", message: "" })


  const onPhotoRequestClick = ()=>{
    if (getIsLoginFromAdmin()) {
      setShowAdminPopup(true)
      return false;
    }
    setShowPhotoRequestPopup(true)
  }

  const onParentRequestClick = ()=>{
    if (getIsLoginFromAdmin()) {
      setShowAdminPopup(true)
      return false;
    }
    setShowParentsRequestPopup(true)
  }

  const profileId = location.state.id

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipe: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [buttonAction, setButtonAction] = useState({
    select: false,
    reject: false,
    message: false,
    phoneNo: false,
    parentsPhoneNo: false,
  });

  const matchesRef = useRef(null);
  const [cardPageNum, setCardPageNum] = useState({
    recentlyViewedPage: 1,
    similarMatchPage: 1,
  });

  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [addBanner, setAddBanner] = useState();
  const [matchViewProfile1, setMatchViewProfile1] = useState();
  const [matchViewProfile2, setMatchViewProfile2] = useState();
  const [navitem, setNavItem] = useState(location?.state?.isfrom ?? "");
  console.log("matchViewProfile1-matchViewProfile1", matchViewProfile1);
  const [matchPreference, setMatchPreference] = useState();
  const [isPasswordProtected, setPasswordProtected] = useState(false);
  const [isPhotoBlur, setPhotoBlur] = useState(false);
  const [similarMatchesData, setSimilarMatchesData] = useState([]);
  const [recentlyViewedData, setrecentlyViewedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showProfileStatusPopup, setShowProfileStatusPopup] = useState(false)
  const [ProfileStatusMessage, setProfileStatusMessage] = useState("")
  const [messaged, setMessaged] = useState(false)
  const [phoneNumberViewed, setPhoneNumberViewed] = useState(false)
  const [photoRequest, setPhotoRequest] = useState()
  const [photoRequestCheck, setPhotoRequestCheck] = useState()
  const [requestPhoto, setRequestPhoto] = useState()
  const [requestHoroscope, setRequestHoroscope] = useState()
  const [showHoroScopeLoader, setShowHoroScopeLoader] = useState(true)
  const [horoChecked, setHoroChecked] = useState(false)
  const [trustChecked, setTrustChecked] = useState(false)
  const [photoChecked, setPhotoChecked] = useState(false)
  const [parentNumberChecked, setParentNumberChecked] = useState(false)
  const [showMultiRequestPopup, setShowMultiRequestPopup] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [recentlyViewedDataCount, setrecentlyViewedDataCount] = useState([]);
  const [similarMatchesDataCount, setSimilarMatchesDataCount] = useState([]);


  console.log(photoChecked, "photoChecked");

  const [error, setError] = useState();

  const dispatch = useDispatch();
  const isCommunitySite = localStorage?.getItem(Constants.loginLocalStorageKeys.isCommunitySite);




  useEffect(() => {
    let pathnameURL = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
    navList.indexOf(RouteSegments.breadCrumbUrl[pathnameURL]) < 0 && setNavList([...navList, RouteSegments.breadCrumbUrl[pathnameURL]])
  }, [])

  useEffect(() => {

    // apicallDashboard();
    apicallMatches(1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location?.state?.id]);
  useEffect(() => {
    console.log(matchViewProfile1, 'testing2');
  }, [
    matchViewProfile1,
    matchViewProfile2,
    matchPreference,
    buttonAction,
    dashboard1Data,
    addBanner,
    showSuccessPopup,
    successMessage,
    isPasswordProtected,
    isPhotoBlur,
    showPasswordPopup,
    error,
    pswErrorMsg,
    photoview,
    profileData
  ]);


  // const apicallDashboard = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //     pageno: "1",
  //     status: "new",
  //   };
  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${endPoints.dashboardUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     setDashboard1Data(data?.data);
  //     localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
  //     setAddBanner(data?.data?.addbanner[0]?.showphotobanner);
  //     setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
  //     if(data?.data?.popup[0].showpopup == "RejectedProfile"){
  //       navigate(RouteHelper.getEditProfileUrl())
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };


  useEffect(() => {
    dispatch(dashboardLeftPanelData())
    dispatch(dashboardProfileData())

  }, [])


  //Left Panel API Call and Response  
  const { leftPanelData } = useSelector(state => ({
    leftPanelData: state.DashBoardAPI?.leftPanelData?.data
  }));

  //Profile Data API Call and Response  
  const { ProfileData } = useSelector(state => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data
  }));
  console.log("ProfileData-red", ProfileData);


  useEffect(() => {
    if (ProfileData) {
      setDashboard1Data(ProfileData);
      localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData.status)
    }
    if (ProfileData?.popup[0]?.showpopup == "RejectedProfile") {
      navigate(RouteHelper.getEditProfileUrl())
    }

    setAddBanner(ProfileData?.addbanner[0]?.showphotobanner)
    setAddKjBanner(ProfileData?.addbanner[0]?.kjbanner);

  }, [ProfileData])

  console.log(leftPanelData, "leftPanelDataleftPanelData");
  // console.log(matchesListData,"matchesListDatamatchesListData");
  console.log(ProfileData, "ProfileDataProfileData")


  useEffect(() => {
    setProfileData(ProfileData)

  }, [ProfileData])


  const apicallMatches = async (pagenum, rstatus) => {
    if (!rstatus) {
      setIsLoading(true)
    }
    let url =
      location?.state?.isfrom === "search"
        ? endPoints.searchById()
        : endPoints.matchViewProfileUrl()

    let request =
      location?.state?.isfrom === "search"
        ? {
          pageno: `${pagenum}`,
          email: loginDetail()[0],
          userId: loginDetail()[1],
          toProfileId: location?.state?.KMid?.kalyanid,
          loginFrom: getDeviceInfo()
        }
        : {

          email: loginDetail()[0],
          userId: loginDetail()[1],
          viewprofileid: profileId, //"1210478",
          // viewprofileid: location?.state?.id, //"1210478",
          status: "new",
          notificationId: location.state?.notificationId ?? undefined,
          pageno: `${pagenum}`,
        };

    let { statusCode, data } = await POST(`${config.api.API_URL}${url}`, request);

    if (statusCode === 200) {
      if (pagenum === 1) {
        let requestArray = [];
        console.log(data?.data?.profiledetails?.parentnumberrequestcheck, "matchViewProfile1?.profiledetails?.parentnumberrequestcheck");
        if (data?.data?.profiledetails?.profileparentsnumber == "") {
          if (!data?.data?.profiledetails?.parentnumberrequestcheck) {
            setParentNumberChecked(true)
            requestArray.push("Parents Number")
          }
        }

        if (data?.data?.horoscopeRequest == "N") {
          // document.getElementById("horoscope")?.defaultChecked;
          setHoroChecked(true)
          requestArray.push("Horoscope")
        }
        if (data?.data?.profiledetails?.isAvatarPic) {
          if (!data?.data?.profiledetails?.profilerequestcheck) {
            setPhotoChecked(true)
            requestArray.push("Photo")
          }
        }
        if (data?.data?.trustRequest == "N") {
          setTrustChecked(true)
          requestArray.push("Trust")
        }
        setRequestsType(requestArray)

        setMatchViewProfile1(data.data);
        // setAddBanner(data.data.addbanner[0]?.showphotobanner);
        setButtonAction({
          select: data?.data?.profiledetails?.selectedprofile ?? false,
          reject: data?.data?.profiledetails?.selectedrejected ?? false,
          message:
            data?.data?.profiledetails?.profilealreadyContected === "Y"
              ? true
              : false,
          phoneNo: data?.data?.profiledetails?.selectedphoneno ?? false,
          parentsPhoneNo: data?.data?.profiledetails?.selectedparentno ?? false,
        });
        setPhotoBlur(data?.data?.profiledetails?.profileblurPhoto === "Y");
        setPasswordProtected(
          data?.data?.profiledetails?.profilefromPasswordProtected === "Y"
        );
        apicallMatches(2, "status");
      } else if (pagenum === 2) {
        setrecentlyViewedDataCount(data.data)
        setSimilarMatchesDataCount(data.data)
        setMatchViewProfile2(data.data);
        setMatchPreference(data.data.profile_preference);
        data.data.recently_viewed.map((e, i) => {
          setrecentlyViewedData((oldValue) => [...oldValue, e]);
        });
        //console.log(data?.data?.similarmatches,"similar43");
        // data.data.similarmatches.map((e, i) => {
        // setSimilarMatchesData(() => [e]);
        setSimilarMatchesData(data?.data?.similarmatches)
        // });
        setIsLoading(false)
      }
    }
    else if (statusCode === 401) {
      logout();
    }
  };
  //console.log(matchViewProfile2?.similarmatches?.map((element) => element.profileid),"profileid" );
  const callbackLoadMoreRecentlyView = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      recentpageno: `${cardPageNum.recentlyViewedPage}`,
      recentsize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.recentlyViewUrl()}`;
    var loadMoreFor = "RECENTLYVIEW";
    // apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMoreSimilarMatches = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      similarpageno: `${cardPageNum.similarMatchPage}`,
      similarsize: "5",
      status: "new",
      viewprofileid: location.state.id,
    };
    var url = `${config.api.API_URL}${endPoints.similarMatchesUrl()}`;
    var loadMoreFor = "SIMILARMATCHES";
    // apiCallLoadMore(url, request, loadMoreFor);
  };

  // const apiCallLoadMore = async (url, request, loadMoreFor) => {
  //   let { statusCode, data } = await POST(`${url}`, request);
  //   if (statusCode == 200) {
  //     if (loadMoreFor === "RECENTLYVIEW") {
  //       if (data.data.recently_viewed.length > 0) {
  //         cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage + 1;

  //         data.data.recently_viewed.map((e, i) => {
  //           setrecentlyViewedData((oldValue) => [...oldValue, e]);
  //         });
  //       }
  //     } else if (loadMoreFor === "SIMILARMATCHES") {
  //       if (data.data.similarmatches.length > 0) {
  //         cardPageNum.similarMatchPage = cardPageNum.similarMatchPage + 1;

  //         data.data.similarmatches.map((e, i) => {
  //           setSimilarMatchesData((oldValue) => [...oldValue, e]);
  //         });
  //       }
  //     }
  //   } else {
  //     alert("Something went wrong! Try again later");
  //   }
  // };

  const callbackSelectReject = (
    btntype,
    profileID,
    typeRequest,
    profileDetails,
    index,
    type
  ) => {
    if (btntype === "SELECT") {
      apicallSelectReject(typeRequest, profileID);
    } else if (btntype === "REJECT") {
      apicallSelectReject(typeRequest, profileID).then((res) => {
        //console.log("REJECT", res, type);
        if (res.status === "Success") {
          if (typeRequest === "Reject") {
            // handleReject(profileID, index, type);
          }
        }
      });
    } else if (btntype === "MESSAGE") {
      if (getIsLoginFromAdmin()) {
        setShowAdminPopup(true)
        return false;
      }
       if(profileDetails?.profileblockedByMe == "Y"){
        setShowBlockPopup(true)
      }
      else {
        // if (buttonAction.message === false) {
        //   setButtonAction({
        //     ...buttonAction,
        //     message: true,
        //   });
        // }
        // apicallMessage(profileID);
        setMessageProfileDetails(profileDetails);

        if (type === "similar" || type == "recent") {
          setShowMessagePopup(true)
        } else {
          setSendMessagePopup(true);
        }
      }
    } else {
    }
  };
  const handleReject = (id, index, type) => {
    if (type === "recent") {
      var newValue = recentlyViewedData.filter((e) => e.profileid !== id);
      setrecentlyViewedData(newValue);
    } else if (type === "similar") {
      var newValue = similarMatchesData.filter((e) => e.profileid !== id);
      setSimilarMatchesData(newValue);
    }
  };
  const apicallSelectReject = async (type, profileID) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      type: `${type}`,
      toProfileId: `${profileID}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.selectRejectUrl()}`,
      request
    );

    if (statusCode === 200) {
      return data;
    } else if (statusCode === 401) {
      logout();
    }
    return data;
  };

  const callBackViewPhone = async (profileId) => {
    //console.log("profile id", profileId);

    // api/viewphonenumber
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      phoneViewFrom: loginFrom,
      toProfileId: `${profileId}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.viewPhoneUrl()}`,
      request
    );

    if (statusCode === 200) {
      //console.log("view phone", data);
      if (data.status === "Success") {
        setPhoneNumData(data.data);
        setShowPhonePopup(true);
        if (data?.data?.status === "SUCCESS") {
          setPhoneNumberViewed(true)
        } else {

        }
        //  apicallMatches(1)

      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apicallMessage = async (profileID, values, fromCard) => {
    console.log(profileID, values, "valuesvaluesvalues");
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      message: values.message,
      status: "SendMessage",
      sendsms: "Y",
      messageFrom: "Desktop",
      requestphotocheck: values?.requestphotocheck ? values?.requestphotocheck == "Y" ? true : false : undefined,
      requestparentcheck: values?.requestparentcheck ? values?.requestparentcheck == "Y" ? true : false : undefined,
      triggerType: "singletrigger",
    };
    if (fromCard === true) {
      request.bulktrigger = true;
    }
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.sendMessageUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "Success") {

        if (data?.data?.status === "SUCCESS") {

          setShowBulkRequestPopupData({
            show: true,
            title: "Message Sent",
            content: "Message sent successfully!",
            message: data?.data?.bulkrequestcontent,
            imageSrc: MessageSentIcon
          })

          if (messageFor === "recent") {
            setrecentlyViewedData(prev => prev.map((e) => updateData(e, false, request.requestparentcheck === true, profileID)));
          } else if (messageFor === "similar") {
            setSimilarMatchesData(prev => prev.map((e) => updateData(e, false, request.requestparentcheck === true, profileID)))
          }
          setMessaged(true)
        }
        apicallMatches(1, "RequestStatus")
      }
      return data;
    } else if (statusCode === 401) {
      logout();
    }
    return data;
  };

  console.log(matchViewProfile1, "testing1");

  const apicallHoroTrustRequest = async (profileID, value, type) => {
    console.log(requestsType, "requestsTyperequestsType");
    // console.log(type,"typetypetype");
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      type: type,
      sendsms: "Y",
      triggerType: "singletrigger"
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.requestphotoUrl()}`,
      request
    );

    if (statusCode === 200) {
      // setRequestPhoto(data?.data?.trustRequest=="Y"?true:false)
      // setPhotoRequest(data?.data?.photoRequest)
      // setRequestHoroscope(data?.data?.horoscopeRequest=="Y"?true:false,"yesh")

      if (type === "photo") {

        setPhotoRequest(data?.data?.photoRequest == "Y" ? true : false)
        // setPhotoRequestCheck()
        apicallMatches(1, "");
        setsuccessMessage("Photo Request sent!");
        if (messageFor === "similar") {

          var newValue = similarMatchesData.map((e) => {
            if (e.profileid === profileID) {
              return { ...e, profilerequestcheck: true }
            } else {
              return e
            }
          }
          )
          setSimilarMatchesData(newValue);


        }
        else if (messageFor === "recent") {

          var newValue = recentlyViewedData.map((e) => {
            if (e.profileid === profileID) {
              return { ...e, profilerequestcheck: true }
            } else {
              return e
            }
          }
          )
          setrecentlyViewedData(newValue);

        }


      } else if (type === "trust") {

        setRequestPhoto(data?.data?.trustRequest == "Y" ? true : false)
        //console.log(requestPhoto,"yes");
        setsuccessMessage("Trust Document request sent!");
      } else if (type === "horoscope") {

        setsuccessMessage("Horoscope request sent!");
        setRequestHoroscope(data?.data?.horoscopeRequest == "Y" ? true : false, "yesh")
        //console.log(data?.data?.horoscopeRequest=="Y"?true:false,"yesh");
      }
      else if (parentNumber === "recent") {

        var newvalue = recentlyViewedData.map((e) => {
          if (e.profileid === profileID) {

            return { ...e, parentnumberrequestcheck: true };
          }
          return e;
        });
        setrecentlyViewedData(newvalue);
        setsuccessMessage("Parents Number Request sent!");

      }

      else if (parentNumber === "similar") {
        var newvalue = similarMatchesData.map((e) => {
          if (e.profileid === profileID) {
            return { ...e, parentnumberrequestcheck: true };
          }
          return e;
        });
        setSimilarMatchesData(newvalue);
        setsuccessMessage("Parents Number Request sent!");
      } else if (parentNumber === "profile") {
        Object.keys(matchViewProfile1).map((e) => {
          // matchViewProfile1?.profiledetails?.parentnumberrequestcheck
          // const matches = matchViewProfile1 
          const matches = matchViewProfile1?.profiledetails
          const matches1 = true
          Object.keys(matches).forEach((key) => {
            if (key === "parentnumberrequestcheck") {
              matches[key] = matches1
            }
          })
          setMatchViewProfile1(...matchViewProfile1, matches)
          // apicallMatches(1,"");
        })

      }
      else if (type === "Parents Number") {
        apicallMatches(1, "RequestStatus");
        setsuccessMessage("Parents Number request sent!");

      }


      // setsuccessMessage("Request sent successfully!");
      setshowSuccessPopup(true);
    } else if (statusCode === 401) {
      logout();
    }
    // apicallMatches(1,"RequestStatus");
  }

  const apicallHoroTrustPhotoParentRequest = async (profileID, value, type) => {
    if (getIsLoginFromAdmin()) {
      setShowAdminPopup(true)
      return false;
    }
    if(matchViewProfile1?.profiledetails?.profileblockedByMe == "Y"){
      setShowBlockPopup(true)
    }
    else if (ProfileData?.status === "pending") {
      setShowMultiRequestPopup(true);
    }
    else {
      console.log(requestsType, "requestsTyperequestsType");
      let request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        toProfileId: `${profileID}`,
        typeList: requestsType,
        sendsms: "Y",
        triggerType: "singletrigger"
      };

      let { statusCode, data } = await POST(
        `${config.api.API_URL}${endPoints.requestPhotoHoroTrustParent()}`,
        request
      );

      if (statusCode === 200) {
        setRequestPhoto(data?.data?.trustRequest == "Y" ? true : false)
        setPhotoRequest(data?.data?.photoRequest == "Y" ? true : false)
        setRequestHoroscope(data?.data?.horoscopeRequest == "Y" ? true : false, "yesh")
        setsuccessMessage("Request sent successfully!");
        setshowSuccessPopup(true);
      } else if (statusCode === 401) {
        logout();
      }
      apicallMatches(1, "RequestStatus");
    }

  };

  const updateData = (data, isPhoto, isParentNumber, profileId) => {
    let temp = { ...data }

    if (isPhoto && temp.profileid == profileId) {
      temp = { ...temp, profilerequestcheck: true }
    }

    if (isParentNumber && temp.profileid == profileId) {
      temp = { ...temp, parentnumberrequestcheck: true }
    }

    return temp;
  }
  console.log("setrecentlyViewedData", recentlyViewedData, similarMatchesData);
  const apicallRequest = async (profileID, value, type, fromCard) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      // type: type,
      sendsms: value.sendSms,
      "typeList": value.typeList,
      triggerType: "singletrigger"
    };

    if (fromCard === true) {
      request.bulktrigger = true;
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.requestPhotoHoroTrustParent()}`,
      request
    );

    const isPhotoRequested = value.typeList?.includes('Photo')
    const isParentsNumberRequested = value.typeList?.includes('Parents Number')

    if (statusCode === 200) {

      if (type === "photo") {
        if (data?.data?.recomm_count > 0 && fromCard === true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Photo Request sent!",
            content: "Photo Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo Request sent!");
          setshowSuccessPopup(true);
        }
      } else {
        if (data?.data?.recomm_count > 0 && fromCard === true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Parent No. Request sent",
            content: "Parents No. Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Parents Number Request sent!");
          setshowSuccessPopup(true);
        }
      }

      if (messageFor === "recent") {
        setrecentlyViewedData(prev => prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
      } else if (messageFor === "similar") {
        setSimilarMatchesData(prev => prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)))
      }

      setPhotoRequest(data?.data)
      apicallMatches(1, "status");

    } else if (statusCode === 401) {
      logout();
    }
  };




  const handleRequestPhoto = (content) => {
    if (getIsLoginFromAdmin()) {
      setShowAdminPopup(true)
      return false;
    }
    if (ProfileData.status === "pending") {
      // setShowUploadImagePopup(true);
      if (content) {
        setShowRequestSidePanelpopup(true);
      }
      else {
        setShowRequestpopup(true);
      }
    } else {
      if (content) {
        setShowRequestSidePanelpopup(true);
      }
      else {
        setShowRequestpopup(true);
      }
      // setRequestType("photo");
      // apicallHoroTrustRequest(
      //   matchViewProfile1?.profiledetails?.profileid,
      //   "photo"
      // );
    }
    setRequestType("photo");
  };

    const handleRequestParent = () => {
      if (getIsLoginFromAdmin()) {
        setShowAdminPopup(true)
        return false;
      }
    //console.log(dashboard1Data,"dashboard1Datadashboard1Data");
    if (ProfileData?.status === "pending") {

      // setShowUploadImagePopup(true);

      setShowParentRequestPopup(true);


    }
    else {
      setShowParentRequestPopup(true);

    }
  };
  const apicallPhotoPassword = async (value) => {
    {
      let request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        toProfileId: profileId,
        // toProfileId: `${matchViewProfile1?.profiledetails?.profileid}`,
        // password: data?.data?.isPasswordMatches ==="Y"?value:"invalid psw"
        password: value
      };

      let { statusCode, data } = await POST(
        `${config.api.API_URL}${endPoints.checkPhotoPassword()}`,
        request
      );

      // api/checkpasswordmatch

      if (statusCode === 200) {
        setPhotoview(data?.data?.isPasswordMatches)

        if (data?.data?.isPasswordMatches === "Y") {
          setShowPasswordpopup(false);
          setPasswordProtected(false);
          setPhotoBlur(false);
          setShowImagePopup(true)
        } else if (data?.data?.isPasswordMatches === "N") {


          setPswErrorMsg(data?.data?.statusmessage)


          //console.log(pswErrorMsg,"pswErrorMsg");

        }
      } else if (statusCode === 401) {
        logout();
      }

    }
  };

  const handleSetMessage = (id, type, sentMessageResponse) => {
    //console.log("type", type);
    if (type === "similar") {

      setSimilarMatchesData(prev => prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y", messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "recent") {

      setrecentlyViewedData(prev => prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y", messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    }
    // else if (type === "profile") {
    //     if(matchViewProfile1?.profiledetails?.profileid === id) {
    //     setMatchViewProfile1({...matchViewProfile1, profilealreadyContected: "Y"})
    //   }     
    // }
  };
  console.log(matchViewProfile1, "testing");

  const blockProfileApiCall = (status, receiverId) => {
    dispatch(blockProfile(status, receiverId))
  }

  const { blockProfileData } = useSelector(state => ({
    blockProfileData: state.Conversation?.blockProfileData?.data
  }));

  //console.log(blockProfileData,"blockProfileData");
  const scrollHandle = () =>
    matchesRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  const closePopup = () => {
    if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
      blockProfileApiCall(0, matchViewProfile1?.profiledetails?.profileid)
      window.location.href = window.location.href
      // localStorage.setItem("BLOCKED_STATUS",0)
    }
    else window.location.href = RouteSegments.MATCHES
  }

  const closePopupForMsgSent = () => {
    setShowImageHoroScopepopup(false)
    setShowRequestpopup(false)
    setShowMatchHoroscopePopup(false)
    setTrustPopup(false)
    setShowBlockPopup(false)
  }

  const showMatchHoroPopup = () => {
    setShowLoader(true)
    // setShowMatchHoroscopePopup()
    setShowHoroScopeLoader(true)
    setShowImageHoroScopepopup(false)
    if (ProfileData?.isPaid == "Y" && matchViewProfile1?.matchingprofilehoro == "Y" && matchViewProfile1?.profilehoro == "Y") {
      dispatch(getAstroBalance())
    }
    else if ((matchViewProfile1?.matchingprofilehoro == "N" && matchViewProfile1?.profilehoro == "N") || (matchViewProfile1?.matchingprofilehoro == "Y" && matchViewProfile1?.profilehoro == "N") || (matchViewProfile1?.matchingprofilehoro == "" && matchViewProfile1?.profilehoro == "Y")) {
      setShowLoader(false)
      setShowMatchHoroscopePopup(true)
    }

  }

  let { astroBalanceData } = useSelector(state => ({
    astroBalanceData: state.Conversation?.astroBalanceData?.data
  }));

  useEffect(() => {
    astroBalanceData = ""
  }, [])

  //console.log(astroBalanceData,"astroBalanceData");

  useEffect(() => {
    if (astroBalanceData?.astroBal > 0) {
      setShowLoader(false)
      dispatch(getHoroscopeMatch(matchViewProfile1?.profiledetails?.profileid))
      setShowMatchHoroscopePopup(true)
      // return false
    }
    else if (astroBalanceData?.astroBal < 1) {
      setShowLoader(false)
      setShowMatchHoroscopePopup(true)
      // return false
    }
  }, [astroBalanceData])

  //  const openHoro =()=>{
  //     if(astroBalanceData?.astroBal>0){
  //           dispatch(getHoroscopeMatch(matchViewProfile1?.profiledetails?.profileid)) 
  //           setShowMatchHoroscopePopup(true)
  //         }
  //         else if(astroBalanceData?.astroBal<1){
  //           setShowMatchHoroscopePopup(true)
  //         }

  //   }

  const { horoMatchData } = useSelector(state => ({
    horoMatchData: state.Conversation?.horoMatchData?.data
  }));

  console.log(horoMatchData, "horoMatchData");

  const closeModel = () => {
    setShowKjBannerPopup(false)
    setShowKjThankPopup(false)
  }

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { type } = requestsType;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      if (e.target.value == "Horoscope") {
        setHoroChecked(true)
      }
      else if (e.target.value == "Photo") {
        setPhotoChecked(true)
      }
      else if (e.target.value == "Trust") {
        setTrustChecked(true)
      }
      else if (e.target.value == "Parents Number") {
        setParentNumberChecked(true)
      }
      setRequestsType([...requestsType, value]);
    }

    // Case 2  : The user unchecks the box
    else {
      if (e.target.value == "Horoscope") {
        setHoroChecked(false)
      }
      else if (e.target.value == "Photo") {
        setPhotoChecked(false)
      }
      else if (e.target.value == "Trust") {
        setTrustChecked(false)
      }
      else if (e.target.value == "Parents Number") {
        setParentNumberChecked(false)
      }

      setRequestsType(requestsType.filter((e) => e !== value));
    }



  };

  console.log(requestsType, "requestsTyperequestsType");

  const onlineNowClick = () => {
    if (matchViewProfile1?.profiledetails?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(matchViewProfile1?.profiledetails?.profileStatus) && ProfileData?.status !== "pending" && ProfileData?.status !== "hidden") {
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: matchViewProfile1?.profiledetails?.profileid, threadId: matchViewProfile1?.profiledetails?.messageSent[0].subjectId, type: "Sent" } })
    } else {
      setMessageFor("profile");
      callbackSelectReject(
        "MESSAGE",
        matchViewProfile1?.profiledetails?.profileid,
        "",
        matchViewProfile1?.profiledetails
      );

    }
  }

  const closeBulkRequestPopup = () => {
    setShowBulkRequestPopupData({ show: false, content: "", message: "", title: "" })
  }

  const apiCallBulkRequest = async () => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "typeList": [
        "Photo",
        "Parents Number"
      ],
      "triggerType": "bulk"
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequest()}`,
      request
    );

    if (statusCode === 200) {
      closeBulkRequestPopup()
      setsuccessMessage("Request Sent Successfully");
      setshowSuccessPopup(true);
    }

  }

  const onBulkRequestSend = (status) => {
    if (status) {
      apiCallBulkRequest()
    } else {
      closeBulkRequestPopup()
    }
  }

  console.log("messageFor", messageFor, parentNumber);




  return (
    <div>
      {showLoader?<Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" /> :""}
      {showAdminPopup && <CommonProfilePopup
        close={() => setShowAdminPopup(false)}
        title={"Alert!"}
        content={"You cannot send message/request from Admin"} />
      }
      {showKjBannerPopup ? (
        <KjBannerPopup
          closeModel={closeModel}
          setShowKjThankPopup={setShowKjThankPopup}
          setShowKjBannerPopup={setShowKjBannerPopup}
          title={"Send Message"}
          dashboard1Data={ProfileData}
        />
      ) : (
        <></>
      )}
      {
        showBulkRequestPopupData.show && (
          <BulkRequestPopup
            close={closeBulkRequestPopup}
            onSend={onBulkRequestSend}
            content={showBulkRequestPopupData.content}
            message={showBulkRequestPopupData?.message}
            imageSrc={showBulkRequestPopupData.imageSrc}
            title={showBulkRequestPopupData.title} />
        )
      }
      {/* <UserProfileHeader /> */}
      {/* <NavigationBar /> */}
      <NavBar data={leftPanelData} ProfileData={ProfileData} />

      <main>
        {!isLoading && matchViewProfile1 ?
          <>
            <div className="overflow-x-hidden">
              {showProfileStatusPopup && (
                <CommonPopup title="Profile Status" close={() => setShowProfileStatusPopup(false)} menu="profileStatus">
                  <p>{ProfileStatusMessage}</p>
                </CommonPopup>
              )
              }
              {showMatchHoroscopePopup ?
                (
                  <CommonPopup title="View Horoscope Match" close={closePopupForMsgSent} menu="View Horoscope Match">
                    {matchViewProfile1?.profilehoro == "N" ?
                      <p className="text-[#575556] text-[15px]">
                        {/* {(astroBalanceData?.matchinghorostatus?.split("."))} */}
                        You should generate your horoscope Online to Match this horoscope.
                        <br />
                        <Link to={RouteSegments?.EDITPROFILE + "?tab=5"} className=" text-[15px] text-[#0278D4]">
                          {/* {(astroBalanceData?.matchinghorostatus?.split("."))} */}
                          Click here to generate a horoscope immediately
                        </Link>
                      </p>
                      :
                      matchViewProfile1?.matchingprofilehoro == "Y" ?
                        astroBalanceData?.astroBal < 1 ?
                          <p className="text-[#575556] text-[15px]">

                            {/* {(astroBalanceData?.matchinghorostatus?.split("."))} */}
                            Please buy an Astro Match pack to match the horoscope.
                            <br />
                            <a href={RouteSegments?.MEMBERSHIP_REGISTER()} className="text-[15px] text-[#0278D4]"> Click Here to buy Astro Match
                            </a>
                            <br />
                          </p>

                          :
                          <>
                            {showHoroScopeLoader === true ? <div className="absolute maxabsolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div> : null}
                            <iframe
                              console={console.log(showHoroScopeLoader, "showHoroScopeLoader")}
                              onLoad={() => setShowHoroScopeLoader(false)}
                              src={horoMatchData?.url} title="Horoscope" frameborder="0" className="w-[100%] h-[70vh]"></iframe>
                          </>
                        :
                        astroBalanceData?.matchinghorostatus

                    }
                  </CommonPopup>
                )
                : ""
              }

              {showPhonePopup ? (
                <PhonePopup
                  phoneNumData={phoneNumData}
                  close={() => setShowPhonePopup(false)}
                  title={"View Phone number"}
                />
              ) : (
                <></>
              )}
              {showUploadImagePopup ? (
                <UploadPhotoPopup
                  close={() => setShowUploadImagePopup(false)}
                  title={"Upload Photo"}
                  callBack={() => {
                    setShowRequestpopup(true);
                    setRequestType("photo");
                  }}
                />
              ) : (
                <></>
              )}
              {showSendMessagePopup ? (
                <SendMessagePopup
                  close={() => setSendMessagePopup(false)}
                  onButtonCallback={(profileID, value) => {
                    apicallMessage(profileID, value).then((res) => {
                      //console.log("callback message", res);
                      if (res?.status === "Success") {
                        handleSetMessage(profileID, messageFor, res?.data);
                      }
                    });
                  }}
                  title={"Send Message"}
                  dashboard1Data={ProfileData}
                  profileData={messageProfileDetails}
                />
              ) : (
                <></>
              )}
              {showMessageSuccess ? (
                <MessageSuccessPopup
                  close={() => {
                    setshowMessageSuccess(false)
                    setButtonAction({
                      ...buttonAction,
                      message: !buttonAction.message,
                    });
                  }
                  }

                  title={"Message Sent"}
                  dashboard1Data={ProfileData}
                  callback={() => {
                    //redirection to gallery
                  }}
                />
              ) : (
                <></>
              )}
              {showSuccessPopup && (
                <CommonSuccessPopup
                  close={() => setshowSuccessPopup(false)}
                  title={"Success!"}
                  content={successMessage}
                />
              )}
              {showBlockPopup ? (
                <CommonPopup title="Profile Status" close={() => setShowBlockPopup(false)} menu="profileStatus">
                  <p>{matchViewProfile1?.profiledetails?.profilename + " Profile Blocked by you. Do you want to unblock now?"}</p>
                  <div className='flex justify-between'>
                    <button className='text-[#D10A11] ml-[10rem]' onClick={closePopup}>Yes</button>
                    <button className='text-[#D10A11] mr-[1rem]' onClick={closePopupForMsgSent}>No</button>
                  </div>
                </CommonPopup>
              ) : (
                <></>
              )}
              {showImageHoroScopePopup ? (
                ProfileData?.status === "pending" ?
                  <CommonPopup title="Profile Status" close={closePopupForMsgSent} menu="profileStatus">
                    {ProfileData?.apipendinghoroscope}
                  </CommonPopup> :
                  matchViewProfile1?.profiledetails?.profileblockedByMe == "Y" ?
                    <CommonPopup title="Profile Status" close={closePopupForMsgSent} menu="profileStatus">
                      <p>{matchViewProfile1?.profiledetails?.profilename + " Profile Blocked by you. Do you want to unblock now?"}</p>
                      <div className='flex justify-between'>
                        <button className='text-[#D10A11] ml-[10rem]' onClick={closePopup}>Yes</button>
                        <button className='text-[#D10A11] mr-[1rem]' onClick={closePopupForMsgSent}>No</button>
                      </div>
                    </CommonPopup>
                    :

                    <ImageHoroscopePopup
                      close={() => setShowImageHoroScopepopup(false)}
                      openHoroMatch={() => showMatchHoroPopup()}
                      title={"View Horoscope"}
                      isMatch={(ProfileData?.status !== "pending" && !matchViewProfile1?.statusMessageHoroscope) ? "Match this Horoscope" : ""}
                      onButtonCallback={() => { }}
                      isShowEditBtn={false}
                    >
                      <>
                        {ProfileData?.status === "pending" ?
                          ProfileData?.apipendinghoroscope :
                          matchViewProfile1?.profiledetails?.profileblockedByMe == "Y" ? ""
                            :
                            matchViewProfile1?.horoscopeType === "GenerateHoroscope" ? (
                              <>
                                {showHoroScopeLoader === true ? <div className="absolute maxabsolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div> : null}
                                <iframe
                                  title="Horoscope"
                                  onLoad={() => setShowHoroScopeLoader(false)}
                                  src={matchViewProfile1?.horoscopeurl}
                                  className="w-[70vw] lg:w-[60vw] h-[70vh]"
                                // style={{ width: "60vw", height: "500px" }}
                                />
                              </>
                            ) : (
                              <iframe
                                onLoad={() => setShowHoroScopeLoader(false)}
                                alt=""
                                src={matchViewProfile1?.horoscopeurl}
                                className="w-[70vw] lg:w-[60vw] h-[70vh]"

                              // style={{ width: "50vw", height: "400px" }}
                              />
                            )}
                        {ProfileData?.status == "pending" ?
                          ""
                          : matchViewProfile1?.statusMessageHoroscope && matchViewProfile1?.profiledetails?.profileblockedByMe == "N" ?


                            <p className="popup-warning-msg-paid">
                              <center>

                                {matchViewProfile1?.statusMessageHoroscope == "Only Paid Members can view the Horoscope Upgrade your Profile Now" ?
                                  <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>{matchViewProfile1?.statusMessageHoroscope}</Link>
                                  : matchViewProfile1?.statusMessageHoroscope}
                              </center>
                            </p>
                            : ""


                        }
                      </>
                      {/* {//console.log(matchViewProfile1?.profiledetails?.profileblockedByMe ,"matchViewProfile1?.profileblockedByMe ")} */}
                    </ImageHoroscopePopup>
              ) : (
                <></>
              )}
              {showImagePopup ? (
                <ImageHoroscopePopup
                  close={() => setShowImagePopup(false)}
                  title={"View Photo"}
                  onButtonCallback={() => { }}
                  isShowEditBtn={false}
                >
                  <div className="w-[80vw] md:w-[63vw] lg:w-[30rem] max-w-[100%] ">

                    {matchViewProfile1?.profileimages && (
                      <Slider {...settings} className="matchspopup-slider">
                        {matchViewProfile1?.profileimages?.map((element, i) => (
                          <>
                            <img
                              key={i}
                              className={`${ProfileData?.myphoto == "N" && i != 0 ?
                                "blur-[2px]"

                                : photoview === "N"
                                  ? "blur-[2px]"
                                  : matchViewProfile1?.blurPhoto == "Y" ? "blur-[2px]" : ""
                                } h-[100%] w-[100%]`}
                              src={element}
                              alt="Person"
                            />
                            {photoview === "N" ?
                              <>
                                <p className="popup-warning-msg w-[80vw] md:w-[63vw] lg:w-[41vw]">
                                  <div>{matchViewProfile1?.statusMessagePhoto}</div>
                                </p>
                              </>
                              :
                              ProfileData?.myphoto == "N" && i != 0 ?
                                <p className="popup-warning-msg-photo lg:w-[30rem] md:w-[32.4rem] w-[19.5rem]">
                                  <center> <Link to={RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath)} className=" text-[18px] ">
                                    Upload your photo to view more photos
                                  </Link></center>
                                </p>
                                :
                                ""}
                          </>

                        ))}
                      </Slider>
                    )}
                  </div>
                </ImageHoroscopePopup>
              ) : (
                <></>
              )}
              {showTrustPopup ? (
                ProfileData?.status === "pending" ?
                  <CommonPopup title="Profile Status" close={closePopupForMsgSent} menu="profileStatus">
                    {ProfileData?.apipendingtrustdoc}
                  </CommonPopup> :
                  matchViewProfile1?.profiledetails?.profileblockedByMe == "Y" ?
                    <CommonPopup title="Profile Status" close={closePopupForMsgSent} menu="profileStatus">
                      <p>{matchViewProfile1?.profiledetails?.profilename + " Profile Blocked by you. Do you want to unblock now?"}</p>
                      <div className='flex justify-between'>
                        <button className='text-[#D10A11] ml-[10rem]' onClick={closePopup}>Yes</button>
                        <button className='text-[#D10A11] mr-[1rem]' onClick={closePopupForMsgSent}>No</button>
                      </div>
                    </CommonPopup>
                    :
                    <>
                      <TrustDocumentViewPopup
                        close={() => setTrustPopup(false)}
                        idproof={matchViewProfile1?.idProof}
                        eduproof={matchViewProfile1?.eduProof}
                        incomeproof={matchViewProfile1?.incomeProof}
                        dashboardProfileData={ProfileData}
                        matchViewProfile1={matchViewProfile1}
                        onButtonCallback={() => {
                        }}
                      >
                      </TrustDocumentViewPopup>
                    </>
              ) : (
                <></>
              )}
              {showPasswordPopup && (
                <ViewPhotoPasswordPopup
                  callBackApply={(val) => {
                    // setPhotoPassword(val);
                    apicallPhotoPassword(val);



                  }}
                  errorMsg={pswErrorMsg}
                  close={() => setShowPasswordpopup(false)}
                />
              )}

              {showRequestPopup && (
                <RequestPhotoHoroTrustPopup
                  close={() => setShowRequestpopup(false)}
                  ok={() => closePopup()}
                  onButtonCallback={(profileID, value) => {
                    apicallRequest(profileID, value, requestType);
                  }}
                  title={
                    requestType === "photo"
                      ? "Request Photo"
                      : requestType === "trust"
                        ? "Request Trust Document"
                        : requestType === "horoscope"
                          ? "Request Horoscope"
                          : ""
                  }
                  profileData={matchViewProfile1?.profiledetails}
                  data={ProfileData}

                />
              )}
              {showParentRequestPopup && (
                <RequestPhotoHoroTrustPopup
                  console={console.log(messageProfileDetails, "messageProfileDetails")}
                  close={() => setShowParentRequestPopup(false)}
                  onButtonCallback={(profileID, value) => {
                    // apicallHoroTrustRequest(profileID, value, "Parents Number");
                    apicallHoroTrustRequest(profileID, value, "Parents Number")
                  }}
                  title={"Parents Number Request"}
                  profileData={matchViewProfile1?.profiledetails}
                  data={ProfileData}

                />
              )}
              {showMultiRequestPopup && (
                <RequestPhotoHoroTrustPopup
                  console={console.log(messageProfileDetails, "messageProfileDetails")}
                  close={() => setShowMultiRequestPopup(false)}
                  onButtonCallback={(profileID, value) => {
                    // apicallHoroTrustRequest(profileID, value, "Parents Number");
                    apicallHoroTrustRequest(profileID, value, "Parents Number")
                  }}
                  title={"Send Request"}
                  profileData={matchViewProfile1?.profiledetails}
                  data={ProfileData}

                />
              )}
              {showRequestSidePanelPopup && (
                <RequestPhotoHoroTrustPopup
                  close={() => setShowRequestSidePanelpopup(false)}
                  ok={() => closePopup()}
                  onButtonCallback={(profileID, value) => {
                    apicallMessage(profileID, value);
                    // apicallHoroTrustPhotoParentRequestPopup(profileId, value)
                    // apicallHoroTrustRequest(profileID, value, requestType);
                  }}
                  title={
                    requestType === "photo"
                      ? "Request Photo"
                      : requestType === "trust"
                        ? "Request Trust Document"
                        : requestType === "horoscope"
                          ? "Request Horoscope"
                          : ""
                  }
                  profileData={messageProfileDetails}
                  data={ProfileData}
                />
              )}

              {showParentNumPopup &&
                <RequestPhotoHoroTrustPopup
                  close={() => setShowParentRequestPopup(false)}
                  onButtonCallback={(profileID, value) => {
                    // apicallHoroTrustRequest(profileID, value, "Parents Number");
                    apicallHoroTrustRequest(profileID, value, "Parents Number")

                  }}
                  title={"Parents Number Request"}
                  profileData={messageProfileDetails}
                />
              }

              {/* These popups for recently view and similar matches cards */}
              {showPhotoRequestPopup && (
                <RequestPhotoHoroTrustPopup
                  close={() => setShowPhotoRequestPopup(false)}
                  onButtonCallback={(profileID, value) => {
                    apicallRequest(profileID, value, "photo", true);
                  }}
                  title={"Request Photo"}
                  profileData={messageProfileDetails}
                />
              )}
              {showParentsRequestPopup && (
                <RequestPhotoHoroTrustPopup
                  close={() => setShowParentsRequestPopup(false)}
                  onButtonCallback={(profileID, value) => {
                    apicallRequest(profileID, value, "Parents Number", true);
                  }}
                  title={"Parents Number Request"}
                  profileData={messageProfileDetails}
                />
              )}
              {showMessagePopup ? (
                <SendMessagePopup
                  close={() => setShowMessagePopup(false)}
                  onButtonCallback={(profileID, value) => {
                    apicallMessage(profileID, value, true).then((res) => {
                      //console.log("callback message", res);
                      if (res?.status === "Success") {
                        handleSetMessage(profileID, messageFor, res?.data);
                      }
                    });
                  }}
                  title={"Send Message"}
                  dashboard1Data={ProfileData}
                  profileData={messageProfileDetails}
                />
              ) : (
                <></>
              )}
              {/* These popups for recently view and similar matches cards */}
              <ViewMessageNav menu={"profiledetails"} page={navitem} />
              <div className="grid grid-cols-1 md:grid-cols-10 lg:grid-cols-10 ">
                <div className=" col-span-2 md:col-span-6 py-2 px-2 lg:col-span-7 ">
                  <div className="hidden md:block pt-4  px-12 md:pl-[1.2rem] md:pr-[0rem] lg:pr-4 lg:pl-[3rem]  ">
                    <div className=" grid-cols-2 w-full ">
                      <div className="flex">
                        <div className="h-[15rem]  md:h-[16rem] lg:h-[18.45rem]">
                          <div className="relative h-full lg:w-[16rem] md:w-[12rem]">
                            {matchViewProfile1?.profileimages && (
                              <Slider {...settings} className="matchespopup-slider">
                                {matchViewProfile1?.profileimages?.map(
                                  (element, i) => (
                                    <>
                                      <img
                                        key={i}
                                        onClick={() => {
                                          if (
                                            matchViewProfile1?.profiledetails
                                              ?.isAvatarPic === false
                                          ) {
                                            setShowImagePopup(true);
                                          }
                                        }}
                                        className={`${matchViewProfile1?.profiledetails?.isAvatarPic === false ?
                                          matchViewProfile1?.blurPhoto == "Y" ?
                                            matchViewProfile1?.alreadyContected == "Y"
                                              ? "blur-[2px]" : matchViewProfile1?.alreadyContected == "N" ? "blur-[2px]" : "blur-[2px]"
                                            : matchViewProfile1?.alreadyContected == "N" && ProfileData?.myphoto == "N"

                                              ? ""

                                              : matchViewProfile1?.alreadyContected == "N" && ProfileData?.myphoto == "Y"

                                                ? ""
                                                : ProfileData?.myphoto == "Y" && ProfileData?.status == "open" ? // view all photo
                                                  ""
                                                  : ProfileData?.myphoto == "N" && i != 0 ? " "
                                                    : ""
                                          : ""

                                          } h-[15rem]  md:h-[16rem] lg:h-[18.47rem] w-full`}
                                        src={element}
                                        alt="Person"
                                      />

                                      {matchViewProfile1?.fromPasswordProtected == "Y" && matchViewProfile1?.blurPhoto == "Y" && matchViewProfile1?.profiledetails?.isAvatarPic === false ?
                                        "" :

                                        ProfileData?.myphoto == "N" && i != 0 ?
                                          // <p className="py-5 top-[45%] absolute  text-[14px] w-[16rem] bg-[#e6e6e6]">
                                          //   Upload your photo to view more photos</p>

                                          <p className="py-5 top-[33%] absolute  text-[14px] w-[16rem] bg-[#E6E6E6]">
                                            <center> <Link to={RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath)} className=" text-[18px] ">
                                              Upload your photo to view more photos
                                            </Link></center>
                                          </p>
                                          : ""

                                      }
                                    </>
                                  ))
                                }
                              </Slider>
                            )}
                            {matchViewProfile1?.fromPasswordProtected == "Y" && matchViewProfile1?.blurPhoto == "Y" && matchViewProfile1?.profiledetails?.isAvatarPic === false && (
                              <div
                                onClick={() => setShowPasswordpopup(true)}
                                className="blur-[2px] absolute top-0 flex justify-center items-center h-[15rem] md:h-[16rem] lg:h-[17.45rem] md:max-w-[100%] lg:max-w-none lg:w-[16rem] md:w-[12rem] cursor-pointer"
                              >
                                {/* profilerequestcheck */}
                                {/* profilefromPasswordProtected */}
                                <img
                                  className="h-[5rem] w-[5rem]"
                                  src="/Assets/Images/lockicon.svg"
                                  alt="Person"
                                />
                              </div>
                            )}
                            {matchViewProfile1?.profiledetails?.isAvatarPic && (
                              <div
                                onClick={() => {
                                  if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                    setShowBlockPopup(true)
                                  }
                                  else {
                                    if (!photoRequest &&
                                      matchViewProfile1?.profiledetails
                                        ?.profilerequestcheck === false
                                    ) {
                                      setRequestType("photo")
                                      handleRequestPhoto();
                                    }
                                  }
                                }}
                                className="absolute flex justify-center items-center text-[#575556] mb-6 bottom-2 h-[3.6rem] lg:w-[16rem] md:w-[12rem] hover:backdrop-blur-lg  backdrop-blur-sm cursor-pointer lg:h-[58px]"
                              >
                                <span className=" text-center font-bold text-[18px]">
                                  {matchViewProfile1?.profiledetails
                                    ?.profilerequestcheck
                                    ? "PHOTO REQUESTED"
                                    : "REQUEST PHOTO"}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="flex">
                            <div className="absolute top-[200px] md:top-[194px] lg:top-[209px] md:left-[34px] lg:left-[77px] flex py-[0.1rem] bg-[#9F9F9F] rounded-[6px] px-1">
                              {matchViewProfile1?.profiledetails
                                ?.profilelastOnline === "Online Now" ? (
                                <div onClick={onlineNowClick} className="flex items-center cursor-pointer">
                                  <img
                                    className="w-3 h-3"
                                    src="/Assets/Images/Group 619.svg"
                                    alt=""
                                  />
                                  <p className="text-[#000000] font-bold text-[8px] lg:px-1">
                                    online
                                  </p>
                                </div>
                              ) : (
                                <p className="text-[#000000] font-bold text-[8px] lg:px-1">
                                  {
                                    dateFormat(matchViewProfile1?.profiledetails
                                      ?.profilelastOnline)
                                  }
                                </p>
                              )}
                            </div>
                            {matchViewProfile1?.profileimagecount === "0" ? "" :
                              <div className="absolute top-[200px] md:top-[194px] lg:top-[209px] md:left-[173px] lg:left-[271px] flex py-[0.1rem] bg-[#FEBABA] rounded-[6px] px-1">
                                <img
                                  className="w-3 h-3"
                                  src="/Assets/Images/Group 263.svg"
                                  alt=""
                                />
                                <p className="text-[#000000] font-bold text-[9px] px-1">
                                  {matchViewProfile1?.profileimagecount}
                                </p>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="flex flex-col justify-between h-[15rem]  md:h-[16rem] lg:h-[18.45rem] md:w-full lg:w-full w-full sm:col-span-2">
                          <div className="lg:grid lg:grid-cols-4 justify-around lg:px-6">
                            <div className="col-span-1 py-2 md:py-0 md:pt-[0rem] lg:py-2 lg:pt-4  md:space-y-1 lg:space-y-2 col-start-1 col-end-3  px-3 md:pl-[1.5rem] lg:pl-0 md:px-3 lg:px-3  xl:px-5">
                              <p className="  text-[#575556] font-segeo text-[18px] md:text-[12px] lg:text-[20px] leading-[20px] md:leading-0 font-bold break-words lg:whitespace-nowrap">
                                {matchViewProfile1?.profiledetails?.profilename} |{" "}
                                {matchViewProfile1?.profiledetails?.smProfileId}
                              </p>
                              <p className=" text-[14px] md:text-[12px] lg:text-[17px] text-[#575556] font-normal font-segeo whitespace-nowrap md:pt-[0.1rem] ">
                                Profile ID -{" "}
                                {matchViewProfile1?.profiledetails?.smProfileId}
                              </p>
                              <p className=" text-[14px] md:text-[12px] md:pt-[0.1rem] lg:text-[17px] text-[#575556] font-normal break-words md:whitespace-nowrap lg:whitespace-normal font-segeo">
                                {/* {matchViewProfile1?.profiledetails?.profileage},{" "} */}
                                {matchViewProfile1?.basic_detailsprofile
                                  ?.basicprofileage.split("years")[0]}{"yrs"},{" "}
                                {matchViewProfile1?.profiledetails?.profileheight},{" "}
                                {
                                  matchViewProfile1?.profiledetails
                                    ?.profilenativelanguage
                                }
                                ,
                              </p>
                              <p className=" text-[14px] md:text-[12px] md:pt-[0.1rem] lg:text-[17px] text-[#575556] font-normal break-words md:whitespace-nowrap lg:whitespace-nowrap font-segeo">
                                {matchViewProfile1?.profiledetails?.profilecaste},
                              </p>
                              <p className=" text-[15px] md:pt-[0.1rem]  whitespace-nowrap md:text-[12px] lg:text-[17px] text-[#575556] font-normal font-segeo break-wordss">
                                {matchViewProfile1?.profiledetails?.profilecity},{" "}
                                {matchViewProfile1?.profiledetails?.profilestate}
                              </p>
                              <p className=" text-[15px]  whitespace-nowrap md:text-[12px] lg:text-[17px] text-[#575556] font-normal font-segeo break-wordss">

                              </p>
                            </div>

                            <div className=" hidden lg:block flex col-span-2   flex-col col-end-7 col-span-2 cursor-pointer">
                              <div className=" space-y-4 py-4 md:py-0 md:pt-[1rem] lg:py-0  lg:pt-4 lg:pb-[0.1rem] flex-col col-end-7 col-span-2 cursor-pointer">
                                {matchViewProfile1?.profiledetails?.isAvatarPic === false
                                  && matchViewProfile1?.profiledetails?.profileparentsnumber != "" && matchViewProfile1?.trustRequest === "" &&
                                  matchViewProfile1?.horoscopeRequest === ""
                                  ?
                                  <div
                                    className="flex flex-col justfy-center cursor-pointer"
                                    onClick={() => {
                                      if (matchViewProfile1?.horoscopeRequest === "Y") {
                                      } else if (requestHoroscope) {

                                      }
                                      else if (
                                        matchViewProfile1?.horoscopeRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "horoscope"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setShowHoroScopeLoader(true)
                                        setShowImageHoroScopepopup(true);
                                      }
                                    }}
                                  >
                                    <div className="flex flex-col cursor-pointer  items-center ">
                                      {matchViewProfile1?.horoscopeRequest === "" &&
                                        <img
                                          className="w-10 h-10 md:w-[3rem]  md:h-[3rem]  "
                                          src={
                                            requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                              ? "/Assets/Images/Group 279 green.svg"
                                              : "/Assets/Images/Group 279.svg"
                                          }
                                          alt=" "
                                        />
                                      }
                                      <span
                                        className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                                      >
                                        {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                          // || matchViewProfile1?.horoscopeRequest === ""
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <div>
                                              <p className="text-[#7A7A7A] text-[15px] pl-4">Horoscope Requested</p>
                                            </div>
                                          </label>
                                          : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                            // ? "Request Horoscope"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Horoscope"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <div>
                                                <p className="text-[#7A7A7A] text-[15px] pl-4">Request Horoscope</p>
                                              </div>
                                            </label>
                                            : "View Horoscope "}
                                      </span>
                                      <div className="text-[#7A7A7A] text-center font-segeo text-[14px] md:text-[10px] lg:text-[16px] font-semibold lg:hidden">
                                        <span
                                          className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                            ? "text-[#039511]"
                                            : "text-[#7A7A7A]"
                                            } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold break-wordss`}
                                        >
                                          {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                            // || matchViewProfile1?.horoscopeRequest === ""
                                            ? "Horoscope Requested"
                                            : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                              // ? "Request Horoscope"
                                              ? <label className="flex items-center">
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="true"
                                                  value="Horoscope"
                                                  onChange={(event) => handleChange(event)}
                                                />
                                                <div>
                                                  <p className="text-[#7A7A7A] text-[15px] pl-4">Request Horoscope</p>
                                                </div>
                                              </label>
                                              : "View Horoscope "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div
                                    className="flex flex-col justfy-center cursor-pointer"
                                    onClick={() => {
                                      if (matchViewProfile1?.horoscopeRequest === "Y") {
                                      } else if (requestHoroscope) {

                                      }
                                      else if (
                                        matchViewProfile1?.horoscopeRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "horoscope"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setShowHoroScopeLoader(true)
                                        setShowImageHoroScopepopup(true);
                                      }
                                    }}
                                  >
                                    <div className="flex">
                                      {matchViewProfile1?.horoscopeRequest === "" &&
                                        <img
                                          className="w-[1.8rem] h-[1.5rem] relative right-[7px] "
                                          src={
                                            requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                              ? "/Assets/Images/Group 279 green.svg"
                                              : "/Assets/Images/Group 279.svg"
                                          }
                                          alt=" "
                                        />
                                      }
                                      <span
                                        className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                                      >
                                        {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                          // || matchViewProfile1?.horoscopeRequest === ""
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <div>
                                              <p className="text-[#7A7A7A] text-[15px] pl-4">Horoscope Requested</p>
                                            </div>
                                          </label>
                                          : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                            // ? "Request Horoscope"
                                            ? <label className="flex items-center">
                                              <input
                                                id="horoscope"
                                                type="checkbox"
                                                checked={horoChecked}
                                                value="Horoscope"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <div>
                                                <p className="text-[#7A7A7A] text-[15px] pl-4">Request Horoscope</p>
                                              </div>
                                            </label>
                                            : "View Horoscope "}
                                      </span>
                                      <div className="text-[#7A7A7A] text-center font-segeo text-[14px] md:text-[10px] lg:text-[16px] font-semibold lg:hidden">
                                        <span
                                          className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                            ? "text-[#039511]"
                                            : "text-[#7A7A7A]"
                                            } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold break-wordss`}
                                        >
                                          {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                            // || matchViewProfile1?.horoscopeRequest === ""
                                            ? "Horoscope Requested"
                                            : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                              // ? "Request Horoscope"
                                              ? <label className="flex items-center">
                                                <input
                                                  id="horoscope"
                                                  type="checkbox"
                                                  checked={horoChecked}
                                                  value="Horoscope"
                                                  onChange={(event) => handleChange(event)}
                                                />
                                                <div>
                                                  <p className="text-[#7A7A7A] text-[15px] pl-4">Request Horoscope</p>
                                                </div>
                                              </label>
                                              : "View Horoscope "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {matchViewProfile1?.profiledetails?.isAvatarPic === false
                                  && matchViewProfile1?.profiledetails?.profileparentsnumber != "" && matchViewProfile1?.trustRequest === "" &&
                                  matchViewProfile1?.horoscopeRequest === ""
                                  ?
                                  <div
                                    onClick={() => {
                                      if (matchViewProfile1?.trustRequest === "Y") {
                                      } else if (requestPhoto) {

                                      }
                                      else if (
                                        matchViewProfile1?.trustRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "trust"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setTrustPopup(true);
                                      }
                                    }}
                                    className="flex flex-col justfy-center cursor-pointer"
                                  >
                                    <div className="flex flex-col cursor-pointer  items-center ">
                                      {matchViewProfile1?.trustRequest == "" &&
                                        <img
                                          className="w-10 h-10 md:w-[3rem]  md:h-[3rem]  "
                                          src={
                                            requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                              ? "/Assets/Images/Group 280 green.svg"
                                              : "/Assets/Images/Group 280.svg"
                                          }
                                        />
                                      }
                                      <span
                                        className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                                      >
                                        {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <p className="text-[#7A7A7A] text-[15px] pl-4">Trust Document Requested</p>
                                          </label>
                                          : matchViewProfile1?.trustRequest === "N"
                                            // ? "Request Trust Document"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Trust"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <p className="text-[#7A7A7A] text-[15px] pl-4">Request Trust Document</p>
                                            </label>
                                            : "View Trust Document"}
                                      </span>
                                      <div className="text-[#7A7A7A] font-segeo text-[14px] md:text-[10px] flex flex-col items-center justfy-center lg:text-[16px] font-semibold lg:hidden">
                                        <span
                                          className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                            ? "text-[#039511]"
                                            : "text-[#7A7A7A]"
                                            } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold break-wordss`}
                                        >
                                          {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                            ? "Trust Document Requested"
                                            : matchViewProfile1?.trustRequest === "N"
                                              // ? "Request Trust Document"
                                              ? <label className="flex items-center">
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="true"
                                                  value="Trust"
                                                  onChange={(event) => handleChange(event)}
                                                />
                                                <p className="text-[#7A7A7A] text-[15px] pl-4">Request Trust Document</p>
                                              </label>
                                              : "View Trust Document"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div
                                    onClick={() => {
                                      if (matchViewProfile1?.trustRequest === "Y") {
                                      } else if (requestPhoto) {

                                      }
                                      else if (
                                        matchViewProfile1?.trustRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "trust"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setTrustPopup(true);
                                      }
                                    }}
                                    className="flex flex-col justfy-center cursor-pointer"
                                  >
                                    <div className="flex">
                                      {matchViewProfile1?.trustRequest == "" &&
                                        <img
                                          className="w-[1.8rem] h-[1.5rem] relative right-[7px] "
                                          src={
                                            requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                              ? "/Assets/Images/Group 280 green.svg"
                                              : "/Assets/Images/Group 280.svg"
                                          }
                                        />
                                      }
                                      <span
                                        className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                                      >
                                        {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <p className="text-[#7A7A7A] text-[15px] pl-4">Trust Document Requested</p>
                                          </label>
                                          : matchViewProfile1?.trustRequest === "N"
                                            // ? "Request Trust Document"
                                            ? <label className="flex items-center">
                                              <input
                                                id="trust"
                                                type="checkbox"
                                                checked={trustChecked}
                                                value="Trust"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <p className="text-[#7A7A7A] text-[15px] pl-4">Request Trust Document</p>
                                            </label>
                                            : "View Trust Document"}
                                      </span>
                                      <div className="text-[#7A7A7A] font-segeo text-[14px] md:text-[10px] flex flex-col items-center justfy-center lg:text-[16px] font-semibold lg:hidden">
                                        <span
                                          className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                            ? "text-[#039511]"
                                            : "text-[#7A7A7A]"
                                            } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold break-wordss`}
                                        >
                                          {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                            ? "Trust Document Requested"
                                            : matchViewProfile1?.trustRequest === "N"
                                              // ? "Request Trust Document"
                                              ? <label className="flex items-center">
                                                <input
                                                  id="trust"
                                                  type="checkbox"
                                                  checked={trustChecked}
                                                  value="Trust"
                                                  onChange={(event) => handleChange(event)}
                                                />
                                                <p className="text-[#7A7A7A] text-[15px] pl-4">Request Trust Document</p>
                                              </label>
                                              : "View Trust Document"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                }




                                {matchViewProfile1?.profiledetails?.isAvatarPic ?
                                  matchViewProfile1?.profiledetails?.profilerequestcheck == false ?
                                    <span className="lg:block text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold md:hidden break-wordss">
                                      <label className="flex items-center">
                                        <input
                                          id="photo"
                                          type="checkbox"
                                          checked={photoChecked}
                                          value="Photo"
                                          onChange={(event) => handleChange(event)}
                                        />
                                        <p className="text-[#7A7A7A] text-[15px] pl-4">Request Photo</p>
                                      </label>
                                    </span>
                                    :
                                    <span className="lg:block text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold md:hidden break-wordss">
                                      <label className="flex requestedClass items-center">
                                        {/* <input
                              type="checkbox"
                              defaultChecked="true"
                              value="Photo"
                              /> */}
                                        <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                        <p className="text-[#7A7A7A] text-[15px] pl-4">Photo Requested</p>
                                      </label>
                                    </span>
                                  : ""
                                }

                                {matchViewProfile1?.profiledetails?.profileparentsnumber == "" ?
                                  !matchViewProfile1?.profiledetails?.parentnumberrequestcheck ?
                                    <span className="lg:block text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold md:hidden break-wordss">
                                      <label className="flex items-center">
                                        <input
                                          id="parentsNumber"
                                          type="checkbox"
                                          checked={parentNumberChecked}
                                          value="Parents Number"
                                          onChange={(event) => handleChange(event)}
                                        />
                                        <p className="text-[#7A7A7A] text-[15px] pl-4">Request Parents Number</p>
                                      </label>
                                    </span>
                                    :
                                    <span className="lg:block text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold md:hidden break-wordss">
                                      <label className="flex items-center requestedClass">
                                        <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                        <p className="text-[#7A7A7A] text-[15px] pl-4">Parents Number Requested</p>
                                      </label>
                                    </span>
                                  : ""
                                }


                              </div>
                              <div className="flex items-center pl-[2.5rem] mt-2">
                                {requestsType?.length == 0 ?
                                  ""
                                  :
                                  <button onClick={() => apicallHoroTrustPhotoParentRequest(matchViewProfile1?.profiledetails?.profileid, 0, "tyep")} className="bg-[#D10A11] px-7 py-2 font-bold rounded-[5px] text-white text-[13px]">
                                    Send Request
                                  </button>
                                }

                              </div>


                              {/* <div
                              className="flex flex-col items-center justfy-center cursor-pointer"
                              onClick={() => {
                                if (matchViewProfile1?.horoscopeRequest === "Y") {
                                } else if( requestHoroscope ){

                                }
                                else if (
                                matchViewProfile1?.horoscopeRequest === "N"
                                ) {
                                  if(matchViewProfile1?.profiledetails?.profileblockedByMe == "Y"){
                                    setShowBlockPopup(true)
                                  }else {
                                    setShowRequestpopup(true);
                                    setRequestType("horoscope");
                                  }
                                  // apicallHoroTrustRequest(
                                  //   matchViewProfile1?.profiledetails?.profileid,
                                  //   "horoscope"
                                  // );
                                  // apicallMatches(1);
                                } else {
                                  setShowHoroScopeLoader(true)
                                  setShowImageHoroScopepopup(true);
                                }
                              }}
                            >
                              <img
                                className="w-10 h-10 md:w-8  md:h-8"
                                src={
                                  requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                    ? "/Assets/Images/Group 279 green.svg"
                                    : "/Assets/Images/Group 279.svg"
                                }
                                alt=" "
                              />
                              <span
                                className={`lg:block ${ requestHoroscope  || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                  ? "text-[#039511]"
                                  : "text-[#7A7A7A]"
                                  } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                              >
                                { requestHoroscope   || matchViewProfile1?.horoscopeRequest === "Y" 
                                // || matchViewProfile1?.horoscopeRequest === ""
                                  ? "Horoscope Requested"
                                  : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == "" 
                                    ? "Request Horoscope"
                                    : "View Horoscope "}
                              </span>
                              <div className="text-[#7A7A7A] text-center font-segeo text-[14px] md:text-[10px] lg:text-[16px] font-semibold lg:hidden">
                                <span
                                  className={`lg:block ${requestHoroscope  || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                    ? "text-[#039511]"
                                    : "text-[#7A7A7A]"
                                    } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold break-wordss`}
                                >
                                  { requestHoroscope   || matchViewProfile1?.horoscopeRequest === "Y" 
                                  // || matchViewProfile1?.horoscopeRequest === ""
                                  ? "Horoscope Requested"
                                  : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == "" 
                                    ? "Request Horoscope"
                                    : "View Horoscope "}
                                </span>
                                {/* <p className="flex justify-center"> Request </p>
                                <p className="flex justify-center"> Horoscope </p> */}
                              {/* </div>
                            </div> */}
                              {/* <div
                              onClick={() => {
                                if (matchViewProfile1?.trustRequest === "Y") {
                                }else if(requestPhoto){

                                }
                                else if (
                                  matchViewProfile1?.trustRequest === "N"
                                ) {
                                  if(matchViewProfile1?.profiledetails?.profileblockedByMe == "Y"){
                                    setShowBlockPopup(true)
                                  }else{
                                    setShowRequestpopup(true);
                                    setRequestType("trust");
                                  }
                                  // apicallHoroTrustRequest(
                                  //   matchViewProfile1?.profiledetails?.profileid,
                                  //   "trust"
                                  // );
                                  // apicallMatches(1);
                                } else {
                                  setTrustPopup(true);
                                }
                              }}
                              className="py-3 md:py-0 lg:py-3 flex flex-col md:mt-1 lg:mt-3 items-center justfy-center cursor-pointer"
                            >
                              <img
                                className="w-10 h-10 md:w-8  md:h-8"
                                src={
                                requestPhoto  || matchViewProfile1?.trustRequest === "Y" ||matchViewProfile1?.trustRequest === ""
                                    ? "/Assets/Images/Group 280 green.svg"
                                    : "/Assets/Images/Group 280.svg"
                                }
                              />
                              <span
                                className={`lg:block ${ requestPhoto  || matchViewProfile1?.trustRequest === "Y" ||matchViewProfile1?.trustRequest === ""
                                  ? "text-[#039511]"
                                  : "text-[#7A7A7A]"
                                  } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                              >
                                {matchViewProfile1?.trustRequest === "Y" || requestPhoto 
                                  ? "Trust Document Requested"
                                  : matchViewProfile1?.trustRequest === "N"
                                    ? "Request Trust Document"
                                    : "View Trust Document"}
                              </span>
                              <div className="text-[#7A7A7A] font-segeo text-[14px] md:text-[10px] flex flex-col items-center justfy-center lg:text-[16px] font-semibold lg:hidden">
                                <span
                                  className={`lg:block ${ requestPhoto  || matchViewProfile1?.trustRequest === "Y" ||matchViewProfile1?.trustRequest === ""
                                    ? "text-[#039511]"
                                    : "text-[#7A7A7A]"
                                    } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold break-wordss`}
                                >
                                  {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                    ? "Trust Document Requested"
                                    : matchViewProfile1?.trustRequest === "N"
                                      ? "Request Trust Document"
                                      : "View Trust Document"}
                                </span>
                                {/* <p className="flex justify-center"> Request </p>
                                  <p className="flex justify-center">
                                    {" "}
                                    Trust Document{" "}
                                  </p> */}
                              {/* </div> */}
                              {/* <!-- <span className="text-[#7A7A7A] font-segeo text-[14px] md:text-[10px] lg:text-[16px] font-semibold md:hidden ">Request Trust Document</span> --> */}
                              {/* </div> */}
                            </div>


                          </div>
                          {/* <div className="flex items-center"> */}
                          {/* md:h-[26%] lg:h-[28%] xl:h-[25%]         */}
                          {
                            matchViewProfile1?.profiledetails?.profileparentsnumber ?
                              <div className="grid grid-cols-5 h-[3.6rem] md:px-0 lg:px-0 bg-[#D10A11] md:mt-[0.1rem] lg:-mt-[6px]  items-center ">
                                <div
                                  className="flex flex-col cursor-pointer  items-center text-[12px] text-[#FFFFFF]"
                                  onClick={() => {
                                    setButtonAction({
                                      ...buttonAction,
                                      select: !buttonAction.select,
                                    });
                                    callbackSelectReject(
                                      "SELECT",
                                      matchViewProfile1?.profiledetails?.profileid,
                                      buttonAction.select ? "Selected" : "Select",
                                      matchViewProfile1?.profiledetails
                                    );
                                  }}
                                >
                                  <img
                                    className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                    src={
                                      buttonAction.select
                                        ? "/Assets/Images/Group 169 yellow.svg"
                                        : "/Assets/Images/Group 169 white.svg"
                                    }
                                  />
                                  <span
                                    className={`${buttonAction.select
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } text-[11px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                  >
                                    {buttonAction.select ? "Selected" : "Select"}
                                  </span>
                                </div>
                                <div
                                  className="flex flex-col  cursor-pointer items-center  font-[600] md:font-[0] text-[#FFFFFF]"
                                  onClick={() => {
                                    setButtonAction({
                                      ...buttonAction,
                                      reject: !buttonAction.reject,
                                    });

                                    callbackSelectReject(
                                      "REJECT",
                                      matchViewProfile1?.profiledetails?.profileid,
                                      buttonAction.reject ? "Rejected" : "Reject",
                                      matchViewProfile1?.profiledetails
                                    );
                                  }}
                                >
                                  <img
                                    className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                    src={
                                      buttonAction.reject
                                        ? "/Assets/Images/Group 166 yellow.svg"
                                        : "/Assets/Images/Group 166 white.svg"
                                    }
                                  />
                                  <span
                                    className={`${buttonAction.reject
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } text-[11px] md:text-[9px] lg:text-[12px] `}
                                  >
                                    {buttonAction.reject ? "Rejected" : "Reject"}
                                  </span>
                                </div>
                                <div
                                  className="flex flex-col cursor-pointer items-center  font-[600] md:font-[0] text-[#FFFFFF]web"
                                  onClick={() => {
                                    callbackSelectReject(
                                      "MESSAGE",
                                      matchViewProfile1?.profiledetails?.profileid,
                                      "",
                                      matchViewProfile1?.profiledetails
                                    );
                                    setMessageFor("profile");
                                  }}
                                >
                                  <img
                                    className="w-6 h-6 md:w-5 md:h-5 lg:w-6 lg:h-6 "
                                    src={
                                      buttonAction.message || messaged
                                        ? "/Assets/Images/Group 185 yellow.svg"
                                        : "/Assets/Images/Group 185 white.svg"
                                    }
                                    alt=""
                                  />
                                  <span
                                    className={`${buttonAction.message || messaged
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } text-[11px] md:text-[9px] lg:text-[12px] `}
                                  >
                                    {buttonAction.message || messaged ? "Messaged" : "Message"}
                                  </span>
                                </div>
                                <div className="flex justify-center cursor-pointer">
                                  <button
                                    onClick={() => {
                                      setButtonAction({
                                        ...buttonAction,
                                        phoneNo: true,
                                      });
                                      callBackViewPhone(
                                        matchViewProfile1?.profiledetails?.profileid
                                      );
                                    }}
                                    className={`${buttonAction.phoneNo || phoneNumberViewed
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } flex  flex-col items-center text-[11px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                  >
                                    <img
                                      className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                      src={
                                        buttonAction.phoneNo || phoneNumberViewed ? "/Assets/Images/Group 173 yellow.svg"
                                          : "/Assets/Images/Group 173 white.svg"
                                      }
                                      alt=""
                                    />
                                    <div
                                      className={`${buttonAction.phoneNo || phoneNumberViewed
                                        ? "text-[#FFE500]"
                                        : "text-[#FFFFFF]"
                                        } lg:mt-[5px] lg:leading-[15px] `}
                                    >
                                      <p> Phone </p>
                                      <p> Number </p>
                                    </div>
                                  </button>
                                </div>

                                {matchViewProfile1?.profiledetails?.profileparentsnumber ?
                                  <div className="flex justify-center cursor-pointer">
                                    <button
                                      onClick={() => {
                                        setButtonAction({
                                          ...buttonAction,
                                          parentsPhoneNo: true,
                                        });
                                        callBackViewPhone(
                                          matchViewProfile1?.profiledetails?.profileid
                                        );
                                      }}
                                      className={`${buttonAction.parentsPhoneNo
                                        ? "text-[#FFE500]"
                                        : "text-[#FFFFFF]"
                                        } flex  flex-col  items-center  text-[10px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                    >
                                      <img
                                        className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                        src={
                                          buttonAction.parentsPhoneNo
                                            ? "/Assets/Images/Group 172 yellow.svg"
                                            : "/Assets/Images/Group 172 white.svg"
                                        }
                                      />
                                      <div className="lg:mt-[5px] lg:leading-[15px]">
                                        <p> Parents </p>
                                        <p> Number </p>
                                      </div>
                                    </button>
                                  </div>
                                  : ""

                                }

                              </div>
                              :
                              <div className="grid grid-cols-5 h-[3.6rem] md:px-0 lg:px-0 bg-[#D10A11] md:mt-[0.2rem] lg:mt-0  items-center ">
                                <div
                                  className="flex flex-col cursor-pointer  items-center text-[12px] text-[#FFFFFF]"
                                  onClick={() => {
                                    setButtonAction({
                                      ...buttonAction,
                                      select: !buttonAction.select,
                                    });
                                    callbackSelectReject(
                                      "SELECT",
                                      matchViewProfile1?.profiledetails?.profileid,
                                      buttonAction.select ? "Selected" : "Select",
                                      matchViewProfile1?.profiledetails
                                    );
                                  }}
                                >
                                  <img
                                    className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                    src={
                                      buttonAction.select
                                        ? "/Assets/Images/Group 169 yellow.svg"
                                        : "/Assets/Images/Group 169 white.svg"
                                    }
                                  />
                                  <span
                                    className={`${buttonAction.select
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } text-[11px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                  >
                                    {buttonAction.select ? "Selected" : "Select"}
                                  </span>
                                </div>
                                <div
                                  className="flex flex-col  cursor-pointer items-center  font-[600] md:font-[0] text-[#FFFFFF]"
                                  onClick={() => {
                                    setButtonAction({
                                      ...buttonAction,
                                      reject: !buttonAction.reject,
                                    });

                                    callbackSelectReject(
                                      "REJECT",
                                      matchViewProfile1?.profiledetails?.profileid,
                                      buttonAction.reject ? "Rejected" : "Reject",
                                      matchViewProfile1?.profiledetails
                                    );
                                  }}
                                >
                                  <img
                                    className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                    src={
                                      buttonAction.reject
                                        ? "/Assets/Images/Group 166 yellow.svg"
                                        : "/Assets/Images/Group 166 white.svg"
                                    }
                                  />
                                  <span
                                    className={`${buttonAction.reject
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } text-[11px] md:text-[9px] lg:text-[12px] `}
                                  >
                                    {buttonAction.reject ? "Rejected" : "Reject"}
                                  </span>
                                </div>
                                <div
                                  className="flex flex-col cursor-pointer items-center  font-[600] md:font-[0] text-[#FFFFFF] "
                                  onClick={() => {
                                    callbackSelectReject(
                                      "MESSAGE",
                                      matchViewProfile1?.profiledetails?.profileid,
                                      "",
                                      matchViewProfile1?.profiledetails
                                    );
                                    // setMessageFor("profile");
                                  }}
                                >
                                  <img
                                    className="w-6 h-6 md:w-5 md:h-5 lg:w-6 lg:h-6 "
                                    src={
                                      buttonAction.message || messaged
                                        ? "/Assets/Images/Group 185 yellow.svg"
                                        : "/Assets/Images/Group 185 white.svg"
                                    }
                                    alt=""
                                  />
                                  <span
                                    className={`${buttonAction.message || messaged
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } text-[11px] md:text-[9px] lg:text-[12px] `}
                                  >
                                    {buttonAction.message || messaged ? "Messaged" : "Message"}
                                  </span>
                                </div>
                                <div className="flex justify-center cursor-pointer">
                                  <button
                                    onClick={() => {
                                      setButtonAction({
                                        ...buttonAction,
                                        phoneNo: true,
                                      });
                                      callBackViewPhone(
                                        matchViewProfile1?.profiledetails?.profileid
                                      );
                                    }}
                                    className={`${buttonAction.phoneNo || phoneNumberViewed
                                      ? "text-[#FFE500]"
                                      : "text-[#FFFFFF]"
                                      } flex  flex-col items-center text-[11px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                  >
                                    <img
                                      className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                      src={
                                        buttonAction.phoneNo || phoneNumberViewed
                                          ? "/Assets/Images/Group 173 yellow.svg"
                                          : "/Assets/Images/Group 173 white.svg"
                                      }
                                    />
                                    <div
                                      className={`${buttonAction.phoneNo || phoneNumberViewed
                                        ? "text-[#FFE500]"
                                        : "text-[#FFFFFF]"
                                        } lg:mt-[5px] lg:leading-[15px] `}
                                    >
                                      <p> Phone </p>
                                      <p> Number </p>
                                    </div>
                                  </button>
                                </div>

                                {matchViewProfile1?.profiledetails?.profileparentsnumber ?
                                  <div className="flex justify-center cursor-pointer">
                                    <button
                                      onClick={() => {
                                        setButtonAction({
                                          ...buttonAction,
                                          parentsPhoneNo: true,
                                        });
                                        callBackViewPhone(
                                          matchViewProfile1?.profiledetails?.profileid
                                        );
                                      }}
                                      className={`${buttonAction.parentsPhoneNo
                                        ? "text-[#FFE500]"
                                        : "text-[#FFFFFF]"
                                        } flex  flex-col  items-center  text-[10px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                    >
                                      <img
                                        className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                        src={
                                          buttonAction.parentsPhoneNo
                                            ? "/Assets/Images/Group 172 yellow.svg"
                                            : "/Assets/Images/Group 172 white.svg"
                                        }
                                      />
                                      <div className="lg:mt-[5px] lg:leading-[15px]">
                                        <p> Parents </p>
                                        <p> Number </p>
                                      </div>
                                    </button>
                                  </div>
                                  : matchViewProfile1?.profiledetails?.profileparentsnumber == "" ?
                                    matchViewProfile1?.profiledetails?.parentnumberrequestcheck ?
                                      <div className="flex justify-center cursor-pointer">
                                        <button
                                          className={`text-[#FFE500] flex  flex-col  items-center  text-[10px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                        >
                                          <img
                                            className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                            src={
                                              "/Assets/Images/Group 172 yellow.svg"
                                            }
                                          />
                                          <div className="lg:mt-[5px] lg:leading-[15px]">
                                            <p> Parents </p>
                                            <p> Number Requested </p>
                                          </div>
                                        </button>
                                      </div>
                                      :
                                      <div className="flex justify-center cursor-pointer">
                                        <button
                                          onClick={() => {
                                            handleRequestParent()
                                          }}
                                          className={`${buttonAction.parentsPhoneNo
                                            ? "text-[#FFE500]"
                                            : "text-[#FFFFFF]"
                                            } flex  flex-col  items-center  text-[10px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                        >
                                          <img
                                            className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                            src={
                                              buttonAction.parentsPhoneNo
                                                ? "/Assets/Images/Group 172 yellow.svg"
                                                : "/Assets/Images/Group 172 white.svg"
                                            }
                                          />
                                          <div className="lg:mt-[5px] lg:leading-[15px]">
                                            <p> Request Parents </p>
                                            <p> Number </p>
                                          </div>
                                        </button>
                                      </div>
                                    : ""

                                }

                              </div>
                          }

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:hidden">
                    <div className="relative">
                      {matchViewProfile1?.profileimages && (
                        <Slider {...settings}>
                          {
                            matchViewProfile1?.profileimages?.map((element, i) => (
                              <>
                                <img
                                  key={i}
                                  onClick={() => {
                                    if (
                                      matchViewProfile1?.profiledetails?.isAvatarPic ===
                                      false
                                    ) {
                                      setShowImagePopup(true);
                                    }
                                  }}
                                  className={`${ProfileData?.myphoto == "N" && i != 0
                                    ? "blur-[2px]" :
                                    matchViewProfile1?.blurPhoto === "Y"
                                      ? "blur-[2px]"
                                      :
                                      ""
                                    } w-full h-[25rem] rounded-t-[40px]`}
                                  src={element}
                                  alt="Person"
                                />
                                {ProfileData?.myphoto == "N" && i != 0 ?
                                  <p className="popup-warning-msg-photo w-[96vw] md:w-[63vw] lg:w-[41vw]">
                                    <center> <Link to={RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath)} className=" text-[18px] ">
                                      Upload your photo to view more photos
                                    </Link></center>
                                  </p>
                                  // <p className="absolute z-10 top-[43%] py-5">Upload your photo to view more photos</p>
                                  : ""}
                              </>
                            ))

                          }
                        </Slider>
                      )}
                      {isPasswordProtected && (
                        <div
                          onClick={() => setShowPasswordpopup(true)}
                          className="absolute top-0 flex justify-center items-center h-full w-full cursor-pointer"
                        >
                          {/* profilerequestcheck */}
                          {/* profilefromPasswordProtected */}
                          <img
                            className="h-[5rem] w-[5rem]"
                            src="/Assets/Images/lockicon.svg"
                            alt="Person"
                          />
                        </div>
                      )}
                      {matchViewProfile1?.profiledetails?.isAvatarPic && (
                        <div
                          onClick={() => {
                            if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                              setShowBlockPopup(true)
                            }
                            else {
                              if (!photoRequest &&
                                matchViewProfile1?.profiledetails
                                  ?.profilerequestcheck === false
                              ) {
                                handleRequestPhoto();
                              }
                            }
                          }}
                          className="absolute flex justify-center items-center bottom-2 h-[3rem] w-full hover:backdrop-blur-lg text-[#575556] mb-6 backdrop-blur-sm cursor-pointer "
                        >
                          <span className=" text-center font-bold ">
                            {photoRequest || matchViewProfile1?.profiledetails?.profilerequestcheck
                              ? "PHOTO REQUESTED"
                              : "REQUEST PHOTO"}
                          </span>
                        </div>
                      )}
                      {/* <img
                        className="w-full rounded-t-[40px] "
                        src="/Assets/Images/image.png"
                        alt="Person"
                      /> */}
                    </div>
                    <div className="absolute top-[200px] left-[30px] flex py-1 bg-[#9F9F9F] rounded-[6px] px-2">
                      {matchViewProfile1?.profiledetails?.profilelastOnline ===
                        "Online Now" ? (
                        <div onClick={onlineNowClick} className="flex items-center cursor-pointer">
                          <img src="/Assets/Images/Group 619.svg" alt="" />
                          <p className="text-[#000000] font-bold text-[13px] px-1">
                            online
                          </p>
                        </div>
                      ) : (
                        <p className="text-[#000000] font-bold text-[13px] px-1">
                          {dateFormat(matchViewProfile1?.profiledetails?.profilelastOnline)}
                        </p>
                      )}
                    </div>
                    {matchViewProfile1?.profileimagecount === "0" ? "" :
                      <div className="absolute top-[200px] right-[28px] flex py-1 bg-[#FEBABA] rounded-[6px] px-2">
                        <img src="/Assets/Images/Group 263.svg" alt="" />
                        <p className="text-[#000000] font-bold text-[13px] px-1">
                          {matchViewProfile1?.profileimagecount}
                        </p>
                      </div>
                    }

                    <div className="flex flex-col justify-center border-t border-[#E9E9E9] border-r  sm:col-span-2">
                      <div className="flex justify-around space-x-3 px-4">
                        <div className="py-5 mt-2 space-y-2">
                          <p className="  text-[#575556] font-segeo text-[18px] font-bold">
                            {matchViewProfile1?.profiledetails?.profilename}
                          </p>
                          <p className="text-[14px] text-[#575556] font-normal font-segeo">
                            Profile ID -{" "}
                            {matchViewProfile1?.profiledetails?.smProfileId}
                          </p>
                          <p className="text-[14px] text-[#575556] font-normal font-segeo">
                            {matchViewProfile1?.profiledetails?.profileage},{" "}
                            {matchViewProfile1?.profiledetails?.profileheight},{" "}
                            {
                              matchViewProfile1?.profiledetails
                                ?.profilenativelanguage
                            }
                            ,
                          </p>
                          <p className="text-[14px] text-[#575556] font-normal font-segeo">
                            {matchViewProfile1?.profiledetails?.profilecaste},
                          </p>
                          <p className="text-[14px] text-[#575556] font-normal font-segeo">
                            {matchViewProfile1?.profiledetails?.profilecity},{" "}
                            {matchViewProfile1?.profiledetails?.profilestate}
                          </p>
                        </div>
                        <div className="flex justify-around  flex-col items-center">
                          <div className="flex col-span-2   flex-col col-end-7 col-span-2 cursor-pointer">
                            <div className=" space-y-4 py-4 md:py-0 pt-[1.7rem] md:pt-[1rem] lg:py-0  lg:pt-4 lg:pb-[0.1rem] flex-col col-end-7 col-span-2 cursor-pointer">

                              {
                                matchViewProfile1?.profiledetails?.isAvatarPic === false
                                  && matchViewProfile1?.profiledetails?.profileparentsnumber != "" && matchViewProfile1?.trustRequest === "" &&
                                  matchViewProfile1?.horoscopeRequest === ""
                                  ?

                                  <div
                                    className="flex flex-col justfy-center cursor-pointer"
                                    onClick={() => {
                                      if (matchViewProfile1?.horoscopeRequest === "Y") {
                                      } else if (requestHoroscope) {

                                      }
                                      else if (
                                        matchViewProfile1?.horoscopeRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "horoscope"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setShowHoroScopeLoader(true)
                                        setShowImageHoroScopepopup(true);
                                      }
                                    }}
                                  >
                                    <div className="flex flex-col cursor-pointer  items-center ">
                                      {matchViewProfile1?.horoscopeRequest === "" &&
                                        <img
                                          className="w-10 h-10 md:w-[3rem]  md:h-[3rem] "
                                          src={
                                            requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                              ? "/Assets/Images/Group 279 green.svg"
                                              : "/Assets/Images/Group 279.svg"
                                          }
                                          alt=" "
                                        />
                                      }
                                      <div
                                        className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                                      >
                                        {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                          // || matchViewProfile1?.horoscopeRequest === ""
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <div>
                                              <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Horoscope Requested</p>
                                            </div>
                                          </label>
                                          : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                            // ? "Request Horoscope"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Horoscope"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <div>
                                                <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Horoscope</p>
                                              </div>
                                            </label>
                                            : "View Horoscope "}
                                      </div>

                                    </div>
                                  </div>
                                  :
                                  <div
                                    className="flex flex-col justfy-center cursor-pointer"
                                    onClick={() => {
                                      if (matchViewProfile1?.horoscopeRequest === "Y") {
                                      } else if (requestHoroscope) {

                                      }
                                      else if (
                                        matchViewProfile1?.horoscopeRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "horoscope"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setShowHoroScopeLoader(true)
                                        setShowImageHoroScopepopup(true);
                                      }
                                    }}
                                  >
                                    <div className="flex">
                                      {matchViewProfile1?.horoscopeRequest === "" &&
                                        <img
                                          className="w-[1.8rem] h-[1.5rem] relative right-[7px] "
                                          src={
                                            requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                              ? "/Assets/Images/Group 279 green.svg"
                                              : "/Assets/Images/Group 279.svg"
                                          }
                                          alt=" "
                                        />
                                      }
                                      <div
                                        className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden break-wordss`}
                                      >
                                        {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                          // || matchViewProfile1?.horoscopeRequest === ""
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <div>
                                              <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Horoscope Requested</p>
                                            </div>
                                          </label>
                                          : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                            // ? "Request Horoscope"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Horoscope"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <div>
                                                <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Horoscope</p>
                                              </div>
                                            </label>
                                            : "View Horoscope "}
                                      </div>

                                    </div>
                                  </div>

                              }


                              {matchViewProfile1?.profiledetails?.isAvatarPic === false
                                && matchViewProfile1?.profiledetails?.profileparentsnumber != "" && matchViewProfile1?.trustRequest === "" &&
                                matchViewProfile1?.horoscopeRequest === ""
                                ?

                                <div
                                  onClick={() => {
                                    if (matchViewProfile1?.trustRequest === "Y") {
                                    } else if (requestPhoto) {

                                    }
                                    else if (
                                      matchViewProfile1?.trustRequest === "N"
                                    ) {
                                      if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                        setShowBlockPopup(true)
                                      } else {
                                      }
                                      // apicallHoroTrustRequest(
                                      //   matchViewProfile1?.profiledetails?.profileid,
                                      //   "trust"
                                      // );
                                      // apicallMatches(1);
                                    } else {
                                      setTrustPopup(true);
                                    }
                                  }}
                                  className="flex flex-col justfy-center cursor-pointer"
                                >
                                  <div className="flex flex-col cursor-pointer  items-center">
                                    {matchViewProfile1?.trustRequest == "" &&
                                      <img
                                        className="w-10 h-10 md:w-[3rem]  md:h-[3rem] "
                                        src={
                                          requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                            ? "/Assets/Images/Group 280 green.svg"
                                            : "/Assets/Images/Group 280.svg"
                                        }
                                      />
                                    }
                                    <span
                                      className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                        ? "text-[#039511]"
                                        : "text-[#7A7A7A]"
                                        } font-segeo text-[14px] text-center md:text-[13px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden whitespace-nowrap`}
                                    >
                                      {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                        ? <label className="flex items-center requestedClass">
                                          <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                          <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start ">z</p>
                                        </label>
                                        : matchViewProfile1?.trustRequest === "N"
                                          // ? "Request Trust Document"
                                          ? <label className="flex items-center">
                                            <input
                                              type="checkbox"
                                              defaultChecked="true"
                                              value="Trust"
                                              onChange={(event) => handleChange(event)}
                                            />
                                            <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Trust Document</p>
                                          </label>
                                          : "View Trust Document"}
                                    </span>

                                  </div>
                                </div>
                                :


                                <div
                                  onClick={() => {
                                    if (matchViewProfile1?.trustRequest === "Y") {
                                    } else if (requestPhoto) {

                                    }
                                    else if (
                                      matchViewProfile1?.trustRequest === "N"
                                    ) {
                                      if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                        setShowBlockPopup(true)
                                      } else {
                                      }
                                      // apicallHoroTrustRequest(
                                      //   matchViewProfile1?.profiledetails?.profileid,
                                      //   "trust"
                                      // );
                                      // apicallMatches(1);
                                    } else {
                                      setTrustPopup(true);
                                    }
                                  }}
                                  className="flex flex-col justfy-center cursor-pointer"
                                >
                                  <div className="flex">
                                    {matchViewProfile1?.trustRequest == "" &&
                                      <img
                                        className="w-[1.3rem] h-[1.5rem] relative right-[7px] "
                                        src={
                                          requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                            ? "/Assets/Images/Group 280 green.svg"
                                            : "/Assets/Images/Group 280.svg"
                                        }
                                      />
                                    }
                                    <span
                                      className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                        ? "text-[#039511]"
                                        : "text-[#7A7A7A]"
                                        } font-segeo text-[14px] text-center md:text-[13px] lg:text-[14px] xl:text-[16px] font-semibold md:hidden whitespace-nowrap`}
                                    >
                                      {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                        ? <label className="flex items-center requestedClass">
                                          <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                          <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start ">Trust Document Requested</p>
                                        </label>
                                        : matchViewProfile1?.trustRequest === "N"
                                          // ? "Request Trust Document"
                                          ? <label className="flex items-center">
                                            <input
                                              type="checkbox"
                                              checked={trustChecked}
                                              value="Trust"
                                              onChange={(event) => handleChange(event)}
                                            />
                                            <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Trust Document</p>
                                          </label>
                                          : "View Trust Document"}
                                    </span>

                                  </div>
                                </div>

                              }



                              {matchViewProfile1?.profiledetails?.isAvatarPic ?
                                matchViewProfile1?.profiledetails?.profilerequestcheck == false ?
                                  <div className=" text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold md:hidden break-wordss">
                                    <label className="flex items-center">
                                      <input
                                        id="photo"
                                        type="checkbox"
                                        checked={photoChecked}
                                        value="Photo"
                                        onChange={(event) => handleChange(event)}
                                      />
                                      <p className="text-[#7A7A7A] text-[13px]  text-start pl-[0.4rem]">Request Photo</p>
                                    </label>
                                  </div>
                                  :

                                  <div className="text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold  break-words">
                                    <label className="flex requestedClass items-center">

                                      <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                      <p className="text-[#7A7A7A] text-[13px]  text-start pl-[0.4rem]">Photo Requested</p>
                                    </label>
                                  </div>
                                : ""
                              }



                              {matchViewProfile1?.profiledetails?.profileparentsnumber == "" ?
                                !matchViewProfile1?.profiledetails?.parentnumberrequestcheck ?
                                  <div className=" text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold  break-words">
                                    <label className="flex items-center">
                                      <input
                                        id="parentsNumber"
                                        type="checkbox"
                                        checked={parentNumberChecked}
                                        value="Parents Number"
                                        onChange={(event) => handleChange(event)}
                                      />
                                      <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start whitespace-nowrap">Request Parents Number</p>
                                    </label>
                                  </div>
                                  :
                                  <div className="text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold md:hidden break-wordss">
                                    <label className="flex items-center requestedClass">
                                      <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                      <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start whitespace-nowrap">Parents Number Requested</p>
                                    </label>
                                  </div>
                                : ""
                              }


                            </div>
                            <div className="flex items-center pl-[0.5rem] mt-2 mb-2">
                              {requestsType?.length == 0 ?
                                ""
                                :
                                <button type="button" onClick={() => apicallHoroTrustPhotoParentRequest(matchViewProfile1?.profiledetails?.profileid, 0, "tyep")} className="bg-[#D10A11] px-5 py-2 font-bold rounded-[5px] text-white text-[12px]">
                                  Send Request
                                </button>
                              }

                            </div>

                          </div>

                          {/* <div
                          // onClick={() => {
                          //   if (matchViewProfile1?.horoscopeRequest === "Y" ) {
                          //   }  else if( requestHoroscope ){

                          //       }
                          //   else if (
                          //     matchViewProfile1?.horoscopeRequest === "N"
                          //   ) {
                          //     setShowRequestpopup(true);
                          //     setRequestType("horoscope");
                          //     // apicallHoroTrustRequest(
                          //     //   matchViewProfile1?.profiledetails?.profileid,
                          //     //   "horoscope"
                          //     // );
                          //     // apicallMatches(1);
                          //   } else {
                          //     setShowImageHoroScopepopup(true);
                          //   }
                          // }}
                          onClick={() => {
                            if (matchViewProfile1?.horoscopeRequest === "Y") {
                            } else if( requestHoroscope ){

                            }
                            else if (
                            matchViewProfile1?.horoscopeRequest === "N"
                            ) {
                              if(matchViewProfile1?.profiledetails?.profileblockedByMe == "Y"){
                                setShowBlockPopup(true)
                              }else {
                                setShowRequestpopup(true);
                                setRequestType("horoscope");
                              }
                              // apicallHoroTrustRequest(
                              //   matchViewProfile1?.profiledetails?.profileid,
                              //   "horoscope"
                              // );
                              // apicallMatches(1);
                            } else {
                              setShowHoroScopeLoader(true)
                              setShowImageHoroScopepopup(true);
                            }
                          }}
                          className="flex flex-col items-center pt-[1rem] "
                        >
                          <img
                            className="w-9 h-9"
                            src={
                            requestHoroscope  ||  matchViewProfile1?.horoscopeRequest === "Y" || matchViewProfile1?.horoscopeRequest === ""
                                ? "/Assets/Images/Group 279 green.svg"
                                : "/Assets/Images/Group 279.svg"
                            }
                          />
                          <span
                            className={`${
                              requestHoroscope   || matchViewProfile1?.horoscopeRequest === "Y" || matchViewProfile1?.horoscopeRequest === ""
                              ? "text-[#039511]"
                              : "text-[#7A7A7A]"
                              } font-bold text-[11px]`}
                          >
                            { requestHoroscope   || matchViewProfile1?.horoscopeRequest === "Y" || matchViewProfile1?.horoscopeRequest === ""
                              ? "Horoscope Requested"
                              : matchViewProfile1?.horoscopeRequest === "N"
                                ? "Request Horoscope"
                                : "View Horoscope "}
                          </span>
                        </div>
                        <div
                          // onClick={() => {
                          //   if (matchViewProfile1?.trustRequest === "Y") {
                          //   } else if (matchViewProfile1?.trustRequest === "N") {
                          //     setShowRequestpopup(true);
                          //     setRequestType("trust");
                          //     // apicallHoroTrustRequest(
                          //     //   matchViewProfile1?.profiledetails?.profileid,
                          //     //   "trust"
                          //     // );
                          //     // apicallMatches(1);
                          //   } else {
                          //     setTrustPopup(true);
                          //   }
                          // }}
                          onClick={() => {
                            if (matchViewProfile1?.trustRequest === "Y") {
                            }else if(requestPhoto){

                            }
                            else if (
                              matchViewProfile1?.trustRequest === "N"
                            ) {
                              if(matchViewProfile1?.profiledetails?.profileblockedByMe == "Y"){
                                setShowBlockPopup(true)
                              }else{
                                setShowRequestpopup(true);
                                setRequestType("trust");
                              }
                              // apicallHoroTrustRequest(
                              //   matchViewProfile1?.profiledetails?.profileid,
                              //   "trust"
                              // );
                              // apicallMatches(1);
                            } else {
                              setTrustPopup(true);
                            }
                          }}
                          className=" flex flex-col items-center"
                        >
                          <img
                            className="w-9 h-9"
                            src={
                              matchViewProfile1?.trustRequest === "Y" ||matchViewProfile1?.trustRequest === ""
                                ? "/Assets/Images/Group 280 green.svg"
                                : "/Assets/Images/Group 280.svg"
                            }
                          />
                          <span
                            className={`${matchViewProfile1?.trustRequest === "Y" ||matchViewProfile1?.trustRequest === ""
                              ? "text-[#039511]"
                              : "text-[#7A7A7A]"
                              } font-bold text-[11px]`}
                          >
                            {matchViewProfile1?.trustRequest === "Y"
                              ? "Trust Document Requested"
                              : matchViewProfile1?.trustRequest === "N"
                                ? "Request Trust Document"
                                : "View Trust Document"}
                          </span>
                        </div> */}
                        </div>
                      </div>
                      <div className="  bg-[#D10A11] flex items-center justify-between py-2 px-[0.55rem] ">
                        <div
                          onClick={() => {
                            setButtonAction({
                              ...buttonAction,
                              select: !buttonAction.select,
                            });
                            callbackSelectReject(
                              "SELECT",
                              matchViewProfile1?.profiledetails?.profileid,
                              buttonAction.select ? "Selected" : "Select",
                              matchViewProfile1?.profiledetails
                            );
                          }}
                          className="flex flex-col items-center  text-[#FFFFFF]"
                        >
                          <img
                            className="w-5 h-5"
                            src={
                              buttonAction.select
                                ? "/Assets/Images/Group 169 yellow.svg"
                                : "/Assets/Images/Group 169 white.svg"
                            }
                          />
                          <span
                            className={`${buttonAction.select
                              ? "text-[#FFE500]"
                              : "text-[#FFFFFF]"
                              } text-[11px] font-semibold `}
                          >
                            {buttonAction.select ? "Selected" : "Select"}
                          </span>
                        </div>
                        <div
                          onClick={() => {
                            setButtonAction({
                              ...buttonAction,
                              reject: !buttonAction.reject,
                            });

                            callbackSelectReject(
                              "REJECT",
                              matchViewProfile1?.profiledetails?.profileid,
                              buttonAction.reject ? "Rejected" : "Reject",
                              matchViewProfile1?.profiledetails
                            );
                          }}
                          className="flex flex-col items-center  text-[#FFFFFF]"
                        >
                          <img
                            className="w-5 h-5"
                            src={
                              buttonAction.reject
                                ? "/Assets/Images/Group 166 yellow.svg"
                                : "/Assets/Images/Group 166 white.svg"
                            }
                          />
                          <span
                            className={`${buttonAction.reject
                              ? "text-[#FFE500]"
                              : "text-[#FFFFFF]"
                              } text-[11px] font-semibold`}
                          >
                            {buttonAction.reject ? "Rejected" : "Reject"}
                          </span>
                        </div>
                        <div
                          onClick={() => {

                            callbackSelectReject(
                              "MESSAGE",
                              matchViewProfile1?.profiledetails?.profileid,
                              "",
                              matchViewProfile1?.profiledetails
                            );
                            setMessageFor("profile");
                          }}
                          className="flex flex-col items-center text-[12px] text-[#FFFFFF] mobile"
                        >
                          <img
                            className="w-6 h-6"
                            src={
                              buttonAction.message || messaged
                                ? "/Assets/Images/Group 185 yellow.svg"
                                : "/Assets/Images/Group 185 white.svg"
                            }
                            alt=""
                          />
                          <span
                            className={`${buttonAction.message || messaged
                              ? "text-[#FFE500]"
                              : "text-[#FFFFFF]"
                              } text-[11px] font-semibold `}
                          >
                            {buttonAction.message || messaged ? "Messaged" : "Message"}
                          </span>
                        </div>
                        <div className="mt-1">
                          <button
                            onClick={() => {
                              setButtonAction({
                                ...buttonAction,
                                phoneNo: true,
                              });
                              callBackViewPhone(
                                matchViewProfile1?.profiledetails?.profileid
                              );
                            }}
                            className="flex flex-col items-center text-[11px] "
                          >
                            <img
                              className="h-5 w-5"
                              src={
                                buttonAction.phoneNo || phoneNumberViewed
                                  ? "/Assets/Images/Group 173 yellow.svg"
                                  : "/Assets/Images/Group 173 white.svg"
                              }
                            />
                            <div
                              className={`${buttonAction.phoneNo || phoneNumberViewed
                                ? "text-[#FFE500]"
                                : "text-[#FFFFFF]"
                                } text-[11px] font-semibold `}
                            >
                              <p>Phone </p>
                              <p>Number</p>
                            </div>
                          </button>
                        </div>
                        {matchViewProfile1?.profiledetails?.profileparentsnumber ?
                          <div className="flex justify-center cursor-pointer">
                            <button
                              onClick={() => {
                                setButtonAction({
                                  ...buttonAction,
                                  parentsPhoneNo: true,
                                });
                                callBackViewPhone(
                                  matchViewProfile1?.profiledetails?.profileid
                                );
                              }}
                              className={`${buttonAction.parentsPhoneNo
                                ? "text-[#FFE500]"
                                : "text-[#FFFFFF]"
                                } flex  flex-col  items-center  text-[10px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                            >
                              <img
                                className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                src={
                                  buttonAction.parentsPhoneNo
                                    ? "/Assets/Images/Group 172 yellow.svg"
                                    : "/Assets/Images/Group 172 white.svg"
                                }
                              />
                              <div className="lg:mt-[5px] lg:leading-[15px]">
                                <p> Parents </p>
                                <p> Number </p>
                              </div>
                            </button>
                          </div>
                          : matchViewProfile1?.profiledetails?.profileparentsnumber == "" ?
                            matchViewProfile1?.profiledetails?.parentnumberrequestcheck ?
                              <div className="flex justify-center cursor-pointer">
                                <button
                                  className={`text-[#FFE500] flex  flex-col  items-center  text-[10px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                >
                                  <img
                                    className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                    src={
                                      "/Assets/Images/Group 172 yellow.svg"
                                    }
                                  />
                                  <div className="lg:mt-[5px] lg:leading-[15px]">
                                    <p> Parents </p>
                                    <p> Number Requested </p>
                                  </div>
                                </button>
                              </div>
                              :
                              <div className="flex justify-center cursor-pointer">
                                <button
                                  onClick={() => {
                                    handleRequestParent()
                                  }}
                                  className={`${buttonAction.parentsPhoneNo
                                    ? "text-[#FFE500]"
                                    : "text-[#FFFFFF]"
                                    } flex  flex-col  items-center  text-[10px] md:text-[9px] lg:text-[12px] font-[600] md:font-[0] `}
                                >
                                  <img
                                    className=" h-5 w-5 md:h-4 md:w-4 lg:w-5 lg:h-5 "
                                    src={
                                      buttonAction.parentsPhoneNo
                                        ? "/Assets/Images/Group 172 yellow.svg"
                                        : "/Assets/Images/Group 172 white.svg"
                                    }
                                  />
                                  <div className="lg:mt-[5px] lg:leading-[15px]">
                                    <p> Request Parents </p>
                                    <p> Number </p>
                                  </div>
                                </button>
                              </div>
                            : ""

                        }
                      </div>
                    </div>
                  </div>

                  <div className="md:px-4 lg:px-4 lg:pl-[3rem] ">
                    <div>
                      <div className=" hidden md:block lg:hidden flex col-span-2   flex-col col-end-7 col-span-2 cursor-pointer">
                        <div className="flex col-span-2   flex-col col-end-7 col-span-2 cursor-pointer">
                          <div className=" py-4  md:py-0 pt-[1.7rem] md:pt-[1rem] lg:py-0  lg:pt-4 lg:pb-[0.1rem] flex-col col-end-7 col-span-2 cursor-pointer">
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                {matchViewProfile1?.profiledetails?.isAvatarPic === false
                                  && matchViewProfile1?.profiledetails?.profileparentsnumber != "" && matchViewProfile1?.trustRequest === "" &&
                                  matchViewProfile1?.horoscopeRequest === ""
                                  ?
                                  <div
                                    className="flex flex-col justfy-center cursor-pointer pl-1"
                                    onClick={() => {
                                      if (matchViewProfile1?.horoscopeRequest === "Y") {
                                      } else if (requestHoroscope) {

                                      }
                                      else if (
                                        matchViewProfile1?.horoscopeRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "horoscope"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setShowHoroScopeLoader(true)
                                        setShowImageHoroScopepopup(true);
                                      }
                                    }}
                                  >
                                    <div className="flex flex-col cursor-pointer  items-center">
                                      {matchViewProfile1?.horoscopeRequest === "" &&
                                        <img
                                          className="w-10 h-10 md:w-[2rem]  md:h-[3rem] "
                                          src={
                                            requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                              ? "/Assets/Images/Group 279 green.svg"
                                              : "/Assets/Images/Group 279.svg"
                                          }
                                          alt=" "
                                        />
                                      }
                                      <div
                                        className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] flex items-center text-center md:text-[13px] lg:text-[14px] xl:text-[16px] font-semibold break-words`}
                                      >
                                        {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                          // || matchViewProfile1?.horoscopeRequest === ""
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <div>
                                              <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Horoscope Requested</p>
                                            </div>
                                          </label>
                                          : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                            // ? "Request Horoscope"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Horoscope"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <div>
                                                <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Horoscope</p>
                                              </div>
                                            </label>
                                            : "View Horoscope "}
                                      </div>

                                    </div>
                                  </div>
                                  :
                                  <div
                                    className="flex flex-col justfy-center cursor-pointer pl-1"
                                    onClick={() => {
                                      if (matchViewProfile1?.horoscopeRequest === "Y") {
                                      } else if (requestHoroscope) {

                                      }
                                      else if (
                                        matchViewProfile1?.horoscopeRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "horoscope"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setShowHoroScopeLoader(true)
                                        setShowImageHoroScopepopup(true);
                                      }
                                    }}
                                  >
                                    <div className="flex">
                                      {matchViewProfile1?.horoscopeRequest === "" &&
                                        <img
                                          className="w-[1.8rem] h-[1.5rem] relative right-[7px] "
                                          src={
                                            requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                              ? "/Assets/Images/Group 279 green.svg"
                                              : "/Assets/Images/Group 279.svg"
                                          }
                                          alt=" "
                                        />
                                      }
                                      <div
                                        className={`lg:block ${requestHoroscope || matchViewProfile1?.horoscopeRequest === "" || matchViewProfile1?.horoscopeRequest === "Y"
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo  text-[14px] flex items-center text-center md:text-[10px] lg:text-[14px] xl:text-[16px] font-semibold break-words`}
                                      >
                                        {requestHoroscope || matchViewProfile1?.horoscopeRequest === "Y"
                                          // || matchViewProfile1?.horoscopeRequest === ""
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <div>
                                              <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Horoscope Requested</p>
                                            </div>
                                          </label>
                                          : matchViewProfile1?.horoscopeRequest === "N" && matchViewProfile1?.horoscopeurl == ""
                                            // ? "Request Horoscope"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Horoscope"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <div>
                                                <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Horoscope</p>
                                              </div>
                                            </label>
                                            : "View Horoscope "}
                                      </div>

                                    </div>
                                  </div>

                                }

                                <div>
                                  {matchViewProfile1?.profiledetails?.profileparentsnumber == "" ?
                                    !matchViewProfile1?.profiledetails?.parentnumberrequestcheck ?
                                      <div className="pt-1 pl-1 text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[13px] lg:text-[15px] xl:text-[15px] font-semibold  break-words">
                                        <label className="flex items-center">
                                          <input
                                            id="parentsNumber"
                                            type="checkbox"
                                            checked={parentNumberChecked}
                                            value="Parents Number"
                                            onChange={(event) => handleChange(event)}
                                          />
                                          <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Parents Number</p>
                                        </label>
                                      </div>
                                      :
                                      <div className="text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold break-words">
                                        <label className="flex items-center requestedClass pt-1 pl-1">
                                          <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                          <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Parents Number Requested</p>
                                        </label>
                                      </div>
                                    : ""
                                  }
                                </div>
                              </div>
                              <div>

                                {matchViewProfile1?.profiledetails?.isAvatarPic === false
                                  && matchViewProfile1?.profiledetails?.profileparentsnumber != "" && matchViewProfile1?.trustRequest === "" &&
                                  matchViewProfile1?.horoscopeRequest === ""
                                  ?
                                  <div
                                    onClick={() => {
                                      if (matchViewProfile1?.trustRequest === "Y") {
                                      } else if (requestPhoto) {

                                      }
                                      else if (
                                        matchViewProfile1?.trustRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "trust"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setTrustPopup(true);
                                      }
                                    }}
                                    className="flex flex-col justfy-center cursor-pointer"
                                  >
                                    <div className="flex flex-col cursor-pointer  items-center ">
                                      {matchViewProfile1?.trustRequest == "" &&
                                        <img
                                          className="w-10 h-10 md:w-[2rem]  md:h-[3rem]  "
                                          src={
                                            requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                              ? "/Assets/Images/Group 280 green.svg"
                                              : "/Assets/Images/Group 280.svg"
                                          }
                                        />
                                      }
                                      <span
                                        className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] flex items-center text-center md:text-[13px] lg:text-[14px] xl:text-[16px] font-semibold  whitespace-nowrap`}
                                      >
                                        {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start ">Trust Document Requested</p>
                                          </label>
                                          : matchViewProfile1?.trustRequest === "N"
                                            // ? "Request Trust Document"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Trust"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Trust Document</p>
                                            </label>
                                            : "View Trust Document"}
                                      </span>

                                    </div>
                                  </div>
                                  :
                                  <div
                                    onClick={() => {
                                      if (matchViewProfile1?.trustRequest === "Y") {
                                      } else if (requestPhoto) {

                                      }
                                      else if (
                                        matchViewProfile1?.trustRequest === "N"
                                      ) {
                                        if (matchViewProfile1?.profiledetails?.profileblockedByMe == "Y") {
                                          setShowBlockPopup(true)
                                        } else {
                                        }
                                        // apicallHoroTrustRequest(
                                        //   matchViewProfile1?.profiledetails?.profileid,
                                        //   "trust"
                                        // );
                                        // apicallMatches(1);
                                      } else {
                                        setTrustPopup(true);
                                      }
                                    }}
                                    className="flex flex-col justfy-center cursor-pointer"
                                  >
                                    <div className="flex">
                                      {matchViewProfile1?.trustRequest == "" &&
                                        <img
                                          className="w-[1.3rem] h-[1.5rem] relative right-[7px] "
                                          src={
                                            requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                              ? "/Assets/Images/Group 280 green.svg"
                                              : "/Assets/Images/Group 280.svg"
                                          }
                                        />
                                      }
                                      <span
                                        className={`lg:block ${requestPhoto || matchViewProfile1?.trustRequest === "Y" || matchViewProfile1?.trustRequest === ""
                                          ? "text-[#039511]"
                                          : "text-[#7A7A7A]"
                                          } font-segeo text-[14px] flex items-center text-center md:text-[13px] lg:text-[14px] xl:text-[16px] font-semibold  whitespace-nowrap`}
                                      >
                                        {matchViewProfile1?.trustRequest === "Y" || requestPhoto
                                          ? <label className="flex items-center requestedClass">
                                            <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                            <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start ">Trust Document Requested</p>
                                          </label>
                                          : matchViewProfile1?.trustRequest === "N"
                                            // ? "Request Trust Document"
                                            ? <label className="flex items-center">
                                              <input
                                                type="checkbox"
                                                defaultChecked="true"
                                                value="Trust"
                                                onChange={(event) => handleChange(event)}
                                              />
                                              <p className="text-[#7A7A7A] text-[13px] pl-[0.4rem] text-start">Request Trust Document</p>
                                            </label>
                                            : "View Trust Document"}
                                      </span>

                                    </div>
                                  </div>

                                }



                                <div>
                                  {matchViewProfile1?.profiledetails?.isAvatarPic ?
                                    matchViewProfile1?.profiledetails?.profilerequestcheck == false ?
                                      <div className=" text-[#7A7A7A] font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold  break-words">
                                        <label className="flex items-center pt-1">
                                          <input
                                            id="photo"
                                            type="checkbox"
                                            checked={photoChecked}
                                            value="Photo"
                                            onChange={(event) => handleChange(event)}

                                          />
                                          <p className="text-[#7A7A7A] text-[13px]  text-start pl-[0.4rem]">Request Photo</p>
                                        </label>
                                      </div>
                                      :
                                      <div className="text-[#7A7A7A] pt-1 font-segeo text-[14px] text-center md:text-[10px] lg:text-[15px] xl:text-[15px] font-semibold  break-words">
                                        <label className="flex requestedClass items-center">

                                          <img src="/Assets/Images/Group 851.svg" className="w-[1rem]" />
                                          <p className="text-[#7A7A7A] text-[13px]  text-start pl-[0.4rem]">Photo Requested</p>
                                        </label>
                                      </div>
                                    : ""
                                  }
                                </div>

                              </div>
                            </div>
                            <div className="flex items-center justify-center mt-[0.5rem] mb-2">
                              {requestsType?.length == 0 ?
                                ""
                                :
                                <button type="button" onClick={() => apicallHoroTrustPhotoParentRequest(matchViewProfile1?.profiledetails?.profileid, 0, "tyep")} className="bg-[#D10A11] px-5 py-1 font-bold rounded-[5px] text-white text-[10px]">
                                  Send Request
                                </button>
                              }

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="md:px-0 pt-[0.6rem] md:pt-0 px-5">
                      <p className="text-[#575556] text-[20px] md:text-[22px] font-semibold  py-2">
                        Match Score {matchViewProfile1?.kmatchscore}%
                      </p>
                      <div className="bg-white ">
                        <div className="md:w-[20rem] w-[51%] bg-[#707070] rounded-lg h-1">
                          <div
                            className="bg-[#73FE81] h-1 rounded"
                            //  style="width: 76%"
                            style={{ width: `${matchViewProfile1?.kmatchscore}%` }}
                          ></div>
                        </div>
                        {/* <!-- <div className="flex items-center justify-between">
                                  <span className="text-slate-400 text-sm text-slate-500"></span>
                                  <span className="px-2 py-1 bg-teal-50 rounded-lg text-xs text-teal-400 font-medium min-w-[46px] text-center"></span>
                                  </div> --> */}
                        <div className="py-2">
                          <p className="text-[#7A7A7A] text-[12px] md:text-[14px] whitespace-nowrap rounded-lg font-semibold">
                            Click to see your detail matching score{" "}
                            <span>
                              <a
                                onClick={scrollHandle}
                                className="text-[12px] md:text-[14px] lg:text-[15px] text-[#D10A11] underline font-bold cursor-pointer"
                              >
                                Know More
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="md:grid md:grid-cols-1 lg:grid lg:grid-cols-2 bg-[#E9E9E9]">
                        {matchViewProfile1?.messageSent?.length > 0 && (
                          <div className=" p-2  px-4">
                            <div className=" flex  ">
                              <img
                                src="/Assets/Images/Group 510.svg"
                                className="w-9 md:w-12"
                              />
                              <div className="p-3">
                                <p className="text-[#000000] pt-1 font-semibold font-segeo text-[14px]">
                                  Message Sent|{" "}
                                  {matchViewProfile1?.messageSent[0]?.mailDate}
                                </p>

                                <p className="  text-[#000000] pt-2 text-[12px] md:text-[14px] font-segeo font-semibold">
                                  You have sent a Message{" "}
                                  <span


                                    // onClick={()=>
                                    //   navigate(RouteSegments.INBOX, { state: {  id: props?.cardData?.profileId} 
                                    //   })
                                    // }
                                    onClick={() => {
                                      ProfileData?.status == "open" ?
                                        navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), {
                                          state: {
                                            profileId: matchViewProfile1?.profiledetails?.profileid,
                                            threadId: matchViewProfile1?.messageSent[0].subjectId,
                                            type: "Unread"
                                          }
                                        })
                                        :
                                        setProfileStatusMessage(
                                          ProfileData?.status == "inactive" ?
                                            "Profile has been inactive"
                                            : ProfileData?.status == "hidden" ?
                                              "Profile has been hidden"
                                              : ProfileData?.status == "delete" ?
                                                "Profile has been deleted"
                                                : ProfileData?.status == "suspension" ?
                                                  "Profile has been suspended"
                                                  : ""
                                        );
                                      setShowProfileStatusPopup(true)
                                      setMenuSelect("3")
                                    }
                                    }

                                    className="text-[13px] cursor-pointer  md:text-[14px]  lg:text-[15px] whitespace-nowrap font-segeo text-[#D10A11] underline font-bold "
                                  >
                                    View Message
                                  </span>



                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {matchViewProfile1?.photoRequestDetails?.length > 0 && (
                          <div className="p-2 px-4">
                            <div className=" border-t-[1px] border-[#7A7A7A] lg:border-t-0 lg:border-l-[1px] lg:border-[#7A7A7A] pr-4">
                              <div className="flex lg:pl-[2rem]">
                                <img
                                  src="/Assets/Images/Group 630.svg"
                                  className="w-9 md:w-12"
                                />
                                <div className="p-3">
                                  <p className="text-[#000000] pt-1 font-segeo font-semibold text-[14px] md:text-[14px]">
                                    Photo Request Received|{" "}
                                    {
                                      matchViewProfile1?.photoRequestDetails[0]
                                        ?.requestDate
                                    }
                                  </p>
                                  <p className="text-[#000000] pt-2  md:first-line text-[12px] md:text-[14px] font-semibold">
                                    Requested Your Photo{" "}
                                    <span>
                                      <button
                                        onClick={() => {
                                          ProfileData?.status == "open" ?
                                            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
                                              state: { tab: "3" },
                                            })
                                            :
                                            setProfileStatusMessage(
                                              ProfileData?.status == "inactive" ?
                                                "Profile has been inactive"
                                                : ProfileData?.status == "hidden" ?
                                                  "Profile has been hidden"
                                                  : ProfileData?.status == "delete" ?
                                                    "Profile has been deleted"
                                                    : ProfileData?.status == "suspension" ?
                                                      "Profile has been suspended"
                                                      : ""
                                            )
                                          setShowProfileStatusPopup(true)
                                          setMenuSelect("4")
                                        }}
                                        className="cursor-pointer text-[13px]  md:text-[14px]  lg:text-[15px] text-[#D10A11] underline font-bold"
                                      >
                                        Add Now
                                      </button>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className="hidden lg:hidden md:grid  bg-[#E9E9E9]">
                      <div className="   px-4">
                        <div className=" flex  ">
                          <img
                            src="/Assets/Images/Group 510.svg"
                            className="w-12"
                          />
                          <div className="p-3">
                            <p className="text-[#000000] pt-1 font-semibold font-segeo text-[14px]">
                              Message Sent| {matchViewProfile1?.messageSent[0]?.mailDate}
                            </p>

                            <p className=" whitespace-nowrap  text-[#000000] pt-2 text-[14px] font-segeo font-semibold">
                              You have sent a Message
                              <span className="">
                                <a className="text-[13px] whitespace-nowrap font-segeo text-[#D10A11] underline font-bold ">
                                  {" "}
                                  View Message
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>
                        
                      </div>
                      <hr className=" mx-3 border-[#7A7A7A] dark:bg-gray-700" />

                      <div className=" px-4 ">
                        <div className=" flex ">
                          <img
                            src="/Assets/Images/Group 630.svg"
                            className="w-12"
                          />
                          <div className="p-3">
                            <p className="text-[#000000] pt-1 font-semibold text-[14px]">
                              Photo Request Received| 5 Jul 2022
                            </p>
                            <p className="text-[#000000] pt-2 text-[14px] font-semibold">
                              Requested Your Photo
                              <span>
                                <a className="text-[13px] text-[#D10A11] underline font-bold">
                                  Add Now
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      {/* <div className="md:hidden grid grid-cols-1 bg-[#E9E9E9]">
                      <div className=" p-2  px-4">
                        <div className="flex  ">
                          <img
                            src="/Assets/Images/Group 510.svg"
                            className="w-9"
                          />
                          <div className="p-3">
                            <p className="text-[#000000]  font-semibold font-segeo text-[12px]">
                              Message Sent| {matchViewProfile1?.messageSent[0]?.mailDate}
                            </p>
                            <p className="text-[#000000] text-[12px] font-segeo font-semibold">
                              You have sent a Message
                              <span>
                                <a className="text-[9pt] font-segeo text-[#D10A11] underline font-bold">
                                  View Message
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      {matchViewProfile1?.aboutprofile && <div className="py-4 md:px-0 px-4">
                        <p className="text-[#575556] text-[16px] md:text-[17px] lg:text-[18px] font-bold">
                          About {matchViewProfile1?.profiledetails?.profilename}
                        </p>
                        <p className="text-[#787878] text-[12px] lg:text-[14px] py-2 font-normal break-words">
                          {matchViewProfile1?.aboutprofile ?
                            <ReactReadMoreReadLess
                              charLimit={350}
                              readMoreText={<span className=" text-[#000000] flex justify-center">View more <img className="pl-1" src="/Assets/Images/Group 56.svg" alt="" /></span>}
                              readLessText={<span className=" text-[#000000] flex justify-center">View less <img className="ml-1 rotate-180" src="/Assets/Images/Group 56.svg" alt="" /></span>}
                              readMoreClassName="read-more-less--more text-[12px] md:text-[14px] lg:text-[15px] underline font-bold cursor-pointer"
                              readLessClassName="read-more-less--less text-[12px] md:text-[14px] lg:text-[15px] underline font-bold cursor-pointer"
                            >
                              {matchViewProfile1?.aboutprofile}

                              {/* {"Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum at eum explicabo consequuntur fugiat quibusdam pariatur unde nostrum molestias consectetur. Officiis est dolore facere cumque, beatae delectus dolorum neque modi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum at eum explicabo consequuntur fugiat quibusdam pariatur unde nostrum molestias consectetur. Officiis est dolore facere cumque, beatae delectus dolorum neque modi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum at eum explicabo consequuntur fugiat quibusdam pariatur unde nostrum molestias consectetur. Officiis est dolore facere cumque, beatae delectus dolorum neque modi."} */}

                            </ReactReadMoreReadLess>
                            : "Not Specified"}
                          {/* {matchViewProfile1?.aboutprofile ? matchViewProfile1?.aboutprofile :
                          "Not Specified"} */}
                        </p>
                        {/* <div className="md:hidden flex space-x-2 justify-center">
                        <button className="text-[#000000] text-[15px] font-bold">
                          View more
                        </button>
                        <img className="" src="/Assets/Images/Group 56.svg" />
                      </div> */}
                      </div>}
                      <div className="px-4 md:px-0">
                        <p className="text-[#575556] text-[16px] md:text-[17px] lg:text-[18px] font-bold">
                          Basic Details
                        </p>
                        <div className="grid grid-cols-2 py-2">
                          <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 503.svg"}
                            title={"Age"}
                            value={
                              matchViewProfile1?.basic_detailsprofile
                                ?.basicprofileage
                            }
                          />
                          <DetailsItem
                            className={
                              "flex pl-[2rem] leading-[17px] mt-2 md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={"/Assets/Images/Group 500.svg"}
                            title={"Weight"}
                            value={
                              matchViewProfile1?.basic_detailsprofile
                                ?.basicprofileweight
                            }
                          />
                          <DetailsItem
                            className={
                              "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={"/Assets/Images/Group 272.svg"}
                            title={"Marital Status"}
                            value={
                              matchViewProfile1?.basic_detailsprofile
                                ?.basicprofilemartialstatus
                            }
                          />
                          <DetailsItem
                            className={
                              "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 pl-[2rem] md:p-2"
                            }
                            icon={"/Assets/Images/Group 504.svg"}
                            title={"Citizen"}
                            value={
                              matchViewProfile1?.basic_detailsprofile
                                ?.basicprofilecitzen
                            }
                          />
                          <DetailsItem
                            className={"flex mt-[0.5rem] md:mt-0 md:p-2"}
                            icon={"/Assets/Images/Group 505.svg"}
                            title={"Lives in"}
                            value={
                              matchViewProfile1?.basic_detailsprofile
                                ?.basicprofilelivesin
                            }
                          />
                          <DetailsItem
                            className={
                              "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={"/Assets/Images/Group 272.svg"}
                            title={"Physical Status"}
                            value={
                              matchViewProfile1?.basic_detailsprofile
                                ?.basicphysicalstatus
                            }
                          />
                            <DetailsItem
                            className={
                              "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={complexionicon}
                            title={"Complexion"}
                            value={
                              matchViewProfile1?.basic_detailsprofile
                                ?.basicprofilecomplexion
                            }
                          />
                        </div>
                        {matchViewProfile1?.religious?.mothertougue == 'Not Specified' && matchViewProfile1?.religious?.domain == 'Not Specified'
                         && matchViewProfile1?.religious?.caste == 'Not Specified' 
                         && matchViewProfile1?.religious?.subcaste == 'Not Specified'
                          && matchViewProfile1?.religious?.gothrom == 'Not Specified'
                          ? null
                          : <div>
                          <p className="text-[#575556] text-[16px] md:text-[17px] lg:text-[18px] font-bold">
                            {matchViewProfile1?.profiledetails?.profileGender ===
                              "M"
                              ? "His"
                              : "Her"}{" "}
                            religious background
                          </p>
                          <div className="md:grid grid-cols-2 py-2">
                              {matchViewProfile1?.religious?.mothertougue != "Not Specified" && <DetailsItem
                              className={"flex md:p-2 py-1"}
                              icon={"/Assets/Images/Group 506.svg"}
                              title={"Religion & Mother Tongue"}
                              value={`${matchViewProfile1?.religious?.mothertougue}`}
                              />}
                              {(matchViewProfile1?.religious?.mothertougue?.includes("Christian") && matchViewProfile1?.religious?.domain != "Not Specified") && <DetailsItem
                              className={"flex md:p-2 py-1"}
                              icon={"/Assets/Images/Group 274.svg"}
                              title={"Denomination"}
                              value={matchViewProfile1?.religious?.domain}
                            />}
                              {matchViewProfile1?.religious?.caste != "Not Specified" && <DetailsItem
                              className={"flex md:p-2 py-1"}
                              icon={"/Assets/Images/Group 274.svg"}
                              title={"Caste"}
                              value={matchViewProfile1?.religious?.caste}
                              />}
                              {matchViewProfile1?.religious?.subcaste != "Not Specified" && <DetailsItem
                              className={"flex md:p-2  py-1"}
                              icon={"/Assets/Images/Group 274.svg"}
                              title={"Sub-Caste"}
                              value={matchViewProfile1?.religious?.subcaste}
                              />}
                              {matchViewProfile1?.religious?.gothrom == "Not Specified" ?"" : <DetailsItem
                              className={"flex md:p-2  py-1"}
                              icon={"/Assets/Images/Group 274.svg"}
                              title={"Gothram"}
                              value={matchViewProfile1?.religious?.gothrom}
                              />}
                          </div>
                          </div>}
                        {matchViewProfile1?.horoscope?.star == 'Not Specified' && matchViewProfile1?.horoscope?.rashi == 'Not Specified' && matchViewProfile1?.horoscope?.dosham == 'Not Specified'
                          ? null
                          : <div>
                          <p className="text-[#575556] text-[16px] md:text-[17px] lg:text-[18px] font-bold">
                            Horoscope
                          </p>
                          <div className="grid grid-cols-2 py-2">
                              {matchViewProfile1?.horoscope?.star != "Not Specified" && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={"/Assets/Images/Group 511.svg"}
                              title={"Star"}
                              value={matchViewProfile1?.horoscope?.star}
                              />}
                              {matchViewProfile1?.horoscope?.rashi != "Not Specified" && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={"/Assets/Images/Group 512.svg"}
                              title={"Raasi"}
                              value={matchViewProfile1?.horoscope?.rashi}
                              />}
                              {matchViewProfile1?.horoscope?.dosham != "Not Specified" && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={"/Assets/Images/Group 513.svg"}
                              title={"Dosham"}
                              value={matchViewProfile1?.horoscope?.dosham}
                              />}
                          </div>
                          </div>}
                        {matchViewProfile1?.familydetails?.familystatus == 'Not Specified' && matchViewProfile1?.familydetails?.familytype == 'Not Specified' && matchViewProfile1?.familydetails?.familyvalues == 'Not Specified' && (matchViewProfile1?.familydetails?.parentsmonthlyincome == 0 || matchViewProfile1?.familydetails?.parentsmonthlyincome == 'Not Specified')
                        ? null
                        :<div>
                          <p className="text-[#575556] text-[16px] md:text-[17px] lg:text-[18px] font-bold">
                            Family Details
                          </p>
                          <div className="grid grid-cols-2 py-2">
                              {matchViewProfile1?.familydetails?.familystatus != 'Not Specified' && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={familystatusicon}
                              title={"Family Status"}
                              value={matchViewProfile1?.familydetails?.familystatus}
                              />}
                              {matchViewProfile1?.familydetails?.familytype != 'Not Specified' && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={familyicon}
                              title={"Family Type"}
                              value={matchViewProfile1?.familydetails?.familytype}
                              />}
                              {matchViewProfile1?.familydetails?.familyvalues != 'Not Specified' && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={familyvaluesicon}
                              title={"Family Values"}
                              value={matchViewProfile1?.familydetails?.familyvalues}
                              />}
                              {(matchViewProfile1?.familydetails?.parentsmonthlyincome != 'Not Specified' && matchViewProfile1?.familydetails?.parentsmonthlyincome != 0) && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={incomicon}
                              title={"Parents Income"}
                              value={matchViewProfile1?.familydetails?.parentsmonthlyincome}
                              />}
                          </div>
                        </div>}
                        {matchViewProfile1?.foodandhabits?.dietPreferences == 'Not Specified' && matchViewProfile1?.foodandhabits?.drinking == 'Not Specified' && matchViewProfile1?.foodandhabits?.Smoking == 'Not Specified'
                          ? null
                          : <div className="pt-[1.5rem] md:pt-0">
                          <p className="text-[#575556] text-[16px] md:text-[17px] lg:text-[18px] font-bold">
                            Food and Habits
                          </p>
                          <div className="grid grid-cols-2 md:py-2 pt-[0.7rem] md:pt-0 ">
                              {matchViewProfile1?.foodandhabits?.dietPreferences != 'Not Specified' && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={"/Assets/Images/Group 509.svg"}
                              title={"Food Habits"}
                              value={
                                matchViewProfile1?.foodandhabits?.dietPreferences
                              }
                              />}
                              {matchViewProfile1?.foodandhabits?.drinking != 'Not Specified' && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={"/Assets/Images/Group 507 (1).svg"}
                              title={"Drinking"}
                              value={matchViewProfile1?.foodandhabits?.drinking}
                              />}
                              {matchViewProfile1?.foodandhabits?.smoking != 'Not Specified' && <DetailsItem
                              className={"flex md:p-2 "}
                              icon={"/Assets/Images/Group 508.svg"}
                              title={"Smoking"}
                              value={matchViewProfile1?.foodandhabits?.Smoking}
                              />}
                          </div>
                          </div>}
                        {matchViewProfile1?.professionaldetails?.degree == 'Not Specified' && matchViewProfile1?.professionaldetails?.employementType == 'Not Specified' && (matchViewProfile1?.professionaldetails?.AnnualIncome == 'Not Specified' || matchViewProfile1?.professionaldetails?.AnnualIncome == 0) && matchViewProfile1?.professionaldetails?.designation == 'Not Specified'
                          ? null
                          : <div>
                        <p className="text-[#575556] pt-[1.5rem] md:pt-0 text-[16px] md:text-[17px] lg:text-[18px] font-bold">
                          {matchViewProfile1?.profiledetails?.profileGender === "M"
                            ? "His"
                            : "Her"}{" "}
                          professional details
                        </p>
                        <div className="grid md:grid-cols-2 py-2">
                              {matchViewProfile1?.professionaldetails?.degree != 'Not Specified' && <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 514.svg"}
                            title={"Degree"}
                            value={matchViewProfile1?.professionaldetails?.degree}
                              />}
                              {matchViewProfile1?.professionaldetails?.employementType != 'Not Specified' && <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 514.svg"}
                            title={"Employment Type"}
                            value={
                              matchViewProfile1?.professionaldetails?.employementType
                            }
                              />}
                              {(matchViewProfile1?.professionaldetails?.AnnualIncome != 'Not Specified' && matchViewProfile1?.professionaldetails?.AnnualIncome != 0) && <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 499.svg"}
                            title={"Monthly Income"}
                            value={
                              matchViewProfile1?.professionaldetails?.AnnualIncome
                            }
                              />}
                              {matchViewProfile1?.professionaldetails?.designation != 'Not Specified' && <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 514.svg"}
                            title={"Designation"}
                            value={
                              matchViewProfile1?.professionaldetails?.designation
                            }
                              />}

                        </div>
                          </div>}
                      </div>
                    </div>
                    <div className="md:hidden ">
                      {/* {addBanner && <img className="" src={addBanner} />} */}
                      {addKjBanner === "Y" ?
                        <img
                          className="cursor-pointer"
                          alt=""
                          src={addBanner}
                          closeModel={closeModel}
                          onClick={() => {
                            setShowKjBannerPopup(true);
                          }} />
                        :
                        <img
                          className="cursor-pointer"
                          alt=""
                          src={addBanner}

                          onClick={() => {
                            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                          }} />
                      }
                    </div>
                  </div>
                  <div
                    ref={matchesRef}
                    className="text-center py-2 md:py-3 lg:py-5 px-[0.75rem] md:pl-0 md:pr-0 lg:pl-[2.75rem] lg:pr-[0.75rem]"
                  >
                    <p className="text-[#575556] text-[18px] md:text-[20px] lg:text-[25px]  py-2 md:py-5 lg:py-7 font-bold">
                      Match score against your preferences
                    </p>
                    <div className=" flex justify-around  lg:mx-12 md:mx-5 py-3 md:py-4 lg:py-7">
                      <div className="relative">
                        <img
                          className={`${matchViewProfile1?.blurPhoto === "Y" ? "blur-[2px]" : ""
                            } w-16 h-16 md:w-[4rem] rounded-full md:h-[4rem] lg:w-[7rem]  lg:h-28`}
                          src={matchViewProfile1?.profiledetails?.profileurl}
                          alt=""
                        />
                        {matchViewProfile1?.fromPasswordProtected === "Y" ?
                          <div className="absolute  top-[-0.4rem] right-[0rem] md:right-[0rem] lg:right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer">
                            <img className="h-[2rem] w-[2rem] " src="/Assets/Images/lockicon.svg" alt="Person" />
                          </div>
                          : " "}
                      </div>

                      <div className="px-3 md:px-3">
                        <p className="text-[#575556] text-start text-[18px] md:text-[25px] lg:text-[33px] font-semibold">
                          Match Score {matchViewProfile1?.kmatchscore}%
                        </p>
                        <div className="w-30 md:w-68 bg-[#707070] rounded-lg h-1   lg:mb-6 mt-2">
                          <div
                            className="bg-[#73FE81] h-1 rounded"
                            style={{ width: `${matchViewProfile1?.kmatchscore}%` }}
                          ></div>
                        </div>
                        {/* <!-- <div className="flex items-center justify-between">
                                  <span className="text-slate-400 text-sm text-slate-500"></span>
                                  <span className="px-2 py-1 bg-teal-50 rounded-lg text-xs text-teal-400 font-medium min-w-[46px] text-center"></span>
                                  </div> --> */}
                        <div className="flex mt-3 md:mt-1 lg:mt-0 whitespace-nowrap">
                          <p className="text-[#7A7A7A] text-[12px] md:text-[15px] lg:text-[18px] font-segeo font-semibold">
                            Edit Partner Preference.{" "}
                            <span
                              onClick={() => {
                                navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), {
                                  state: {
                                    tab: "2", isfrom: "profiledetails"

                                  },

                                });
                                setMenuSelect("3")

                              }}
                              className="text-[#D10A11] font-semibold font-segeo underline cursor-pointer"
                            >
                              Edit Now
                            </span>{" "}
                          </p>
                        </div>
                      </div>

                      <div>
                        <img
                          className="w-16 h-16 md:w-[4rem] rounded-full md:h-[4rem] lg:w-[7rem] lg:h-28"
                          src={ProfileData?.profile_imageurl}
                        />
                      </div>

                    </div>
                  </div>
                  {/* <!-- component --> */}
                  <div className="hidden md:block w-full ">
                    <div className="">
                      <div className="w-full bg-white px-12 md:px-3 lg:px-12 table-fixed  ">
                        <div className="grid grid-cols-9">

                          <div className="col-span-3 break-wordss py-3 md:py-1 lg:py-3 px-6 md:px-5 lg:px-6 text-left text-[#F18181] text-[18px] md:text-[15px] lg:text-[18px] font-segeo">
                            <p>Basic Preferences</p>
                          </div>

                          <div

                            className="col-span-3 break-wordss py-3 md:py-1 lg:py-3 pl-[4.5rem] pr-[2.5rem] md:pl-1 md:pr-[3px] lg:pl-[4.5rem] lg:pr-[2.5rem] text-left text-[#F18181]  text-[18px] md:text-[15px] lg:text-[18px] font-segeo"
                          >
                            <p>
                              {matchViewProfile1?.profiledetails
                                ?.profileGender === "M"
                                ? "His"
                                : "Her"}{" "}
                              Preferences
                            </p>
                          </div>

                          <div

                            className="col-span-3 break-wordss py-3 md:py-1 lg:py-3 pl-[4.5rem] pr-[2.5rem] md:pl-1 md:pr-0 lg:pl-[4.5rem] lg:pr-[2.5rem] text-left text-[#F18181]  text-[18px] md:text-[15px] lg:text-[18px] font-segeo"
                          >
                            <p>Your preferences </p>{" "}
                          </div>

                        </div>
                        <div>
                          <MatchingRow
                            title={"Age"}
                            herValue={matchPreference?.groomAge?.herstaus}
                            yourValue={matchPreference?.groomAge?.yourstatus}
                            isHerValue={matchPreference?.groomAge?.herflag}
                            isYourValue={matchPreference?.groomAge?.yourflag}
                          />
                          <MatchingRow
                            title={"Height"}
                            herValue={matchPreference?.height?.herstaus}
                            yourValue={matchPreference?.height?.yourstatus}
                            isHerValue={matchPreference?.height?.herflag}
                            isYourValue={matchPreference?.height?.yourflag}
                          />
                          <MatchingRow
                            title={"Marital Status"}
                            herValue={matchPreference?.maritalStatus?.herstaus}
                            yourValue={matchPreference?.maritalStatus?.yourstatus}
                            isHerValue={matchPreference?.maritalStatus?.herflag}
                            isYourValue={matchPreference?.maritalStatus?.yourflag}
                          />
                          <MatchingRow
                            title={"Mother Tongue"}
                            herValue={matchPreference?.mothertongue?.herstaus}
                            yourValue={matchPreference?.mothertongue?.yourstatus}
                            isHerValue={matchPreference?.mothertongue?.herflag}
                            isYourValue={matchPreference?.mothertongue?.yourflag}
                          />
                          <MatchingRow
                            title={"Physical Status"}
                            herValue={matchPreference?.physicalstatus?.herstaus}
                            yourValue={matchPreference?.physicalstatus?.yourstatus}
                            isHerValue={matchPreference?.physicalstatus?.herflag}
                            isYourValue={matchPreference?.physicalstatus?.yourflag}
                          />
                          <MatchingRow
                            title={"Dhosam"}
                            herValue={matchPreference?.dhosam?.herstaus}
                            yourValue={matchPreference?.dhosam?.yourstatus}
                            isHerValue={matchPreference?.dhosam?.herflag}
                            isYourValue={matchPreference?.dhosam?.yourflag}
                          />
                          <MatchingRow
                            title={"Eating Habits"}
                            herValue={matchPreference?.eatinghabits?.herstaus}
                            yourValue={matchPreference?.eatinghabits?.yourstatus}
                            isHerValue={matchPreference?.eatinghabits?.herflag}
                            isYourValue={matchPreference?.eatinghabits?.yourflag}
                          />
                          <MatchingRow
                            title={"Drinking Habits"}
                            herValue={matchPreference?.drinkinghabits?.herstaus}
                            yourValue={matchPreference?.drinkinghabits?.yourstatus}
                            isHerValue={matchPreference?.drinkinghabits?.herflag}
                            isYourValue={matchPreference?.drinkinghabits?.yourflag}
                          />
                          <MatchingRow
                            title={"Smoking Habits"}
                            herValue={matchPreference?.smokinghabits?.herstaus}
                            yourValue={matchPreference?.smokinghabits?.yourstatus}
                            isHerValue={matchPreference?.smokinghabits?.herflag}
                            isYourValue={matchPreference?.smokinghabits?.yourflag}
                          />

                          <tr>
                            <td className=" whitespace-nowrap py-9 px-6 text-left text-[#9F9F9F] text-[17px] font-normal font-segeo">
                              Religious preferences
                            </td>
                          </tr>
                          <MatchingRow
                            title={"Religion"}
                            herValue={matchPreference?.religion?.herstaus}
                            yourValue={matchPreference?.religion?.yourstatus}
                            isHerValue={matchPreference?.religion?.herflag}
                            isYourValue={matchPreference?.religion?.yourflag}
                          />
                          <MatchingRow
                            title={"Caste"}
                            herValue={matchPreference?.caste?.herstaus}
                            yourValue={matchPreference?.caste?.yourstatus}
                            isHerValue={matchPreference?.caste?.herflag}
                            isYourValue={matchPreference?.caste?.yourflag}
                          />
                          <MatchingRow
                            title={"Sub Caste"}
                            herValue={matchPreference?.SubCaste?.herstaus}
                            yourValue={matchPreference?.SubCaste?.yourstatus}
                            isHerValue={matchPreference?.SubCaste?.herflag}
                            isYourValue={matchPreference?.SubCaste?.yourflag}
                          />
                          <tr>
                            <td className="py-9 px-6 text-left text-[#9F9F9F] text-[17px] font-normal font-segeo">
                              Professional Expectation
                            </td>
                          </tr>
                          <MatchingRow
                            title={"Education"}
                            herValue={matchPreference?.education?.herstaus}
                            yourValue={matchPreference?.education?.yourstatus}
                            isHerValue={matchPreference?.education?.herflag}
                            isYourValue={matchPreference?.education?.yourflag} />
                          <MatchingRow
                            title={"Occupation"}
                            herValue={matchPreference?.occupation?.herstaus}
                            yourValue={matchPreference?.occupation?.yourstatus}
                            isHerValue={matchPreference?.occupation?.herflag}
                            isYourValue={matchPreference?.occupation?.yourflag} />

                          <tr>
                            <td className="py-9 px-6 text-left text-[#9F9F9F] text-[17px] font-normal font-segeo">
                              Location preferences
                            </td>
                          </tr>
                          <MatchingRow
                            title={"Country"}
                            herValue={matchPreference?.country?.herstaus}
                            yourValue={matchPreference?.country?.yourstatus}
                            isHerValue={matchPreference?.country?.herflag}
                            isYourValue={matchPreference?.country?.yourflag}
                          />
                          <MatchingRow
                            title={"Residing City"}
                            herValue={matchPreference?.residingcity?.herstaus}
                            yourValue={matchPreference?.residingcity?.yourstatus}
                            isHerValue={matchPreference?.residingcity?.herflag}
                            isYourValue={matchPreference?.residingcity?.yourflag}
                          />
                          <MatchingRow
                            title={"Residing State"}
                            herValue={matchPreference?.residingstate?.herstaus}
                            yourValue={matchPreference?.residingstate?.yourstatus}
                            isHerValue={matchPreference?.residingstate?.herflag}
                            isYourValue={matchPreference?.residingstate?.yourflag}
                          />
                          <MatchingLastRow
                            herPercentage={
                              matchPreference?.matchingscores?.herstaus
                            }
                            yourPercentage={
                              matchPreference?.matchingscores?.yourstatus
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:hidden  w-full ">
                    <div className="grid grid-cols-9">
                      <div className="col-span-3">
                        <div className="py-3 pl-3  text-left text-[#F18181] text-[12pt] font-bold">
                          <p>Basic</p>
                          <p> Preferences </p>
                        </div>
                      </div>
                      <div className="col-span-3">
                        <div className="py-3 pl-2  text-left text-[#F18181] text-[12pt] font-bold">
                          <p>
                            {matchViewProfile1?.profiledetails?.profileGender ===
                              "M"
                              ? "His"
                              : "Her"}
                          </p>
                          <p> Preferences </p>
                        </div>
                      </div>
                      <div className="col-span-3">
                        <div className="py-3 pl-3  text-left text-[#F18181] text-[12pt] font-bold">
                          <p>Your</p>
                          <p> Preferences </p>
                        </div>
                      </div>
                    </div>

                    <MatchingRow2
                      title={"Age"}
                      herValue={matchPreference?.groomAge?.herstaus}
                      yourValue={matchPreference?.groomAge?.yourstatus}
                      isHerValue={matchPreference?.groomAge?.herflag}
                      isYourValue={matchPreference?.groomAge?.yourflag}
                    />
                    <MatchingRow2
                      title={"Height"}
                      herValue={matchPreference?.height?.herstaus}
                      yourValue={matchPreference?.height?.yourstatus}
                      isHerValue={matchPreference?.height?.herflag}
                      isYourValue={matchPreference?.height?.yourflag}
                    />
                    <MatchingRow2
                      title={"Marital Status"}
                      herValue={matchPreference?.maritalStatus?.herstaus}
                      yourValue={matchPreference?.maritalStatus?.yourstatus}
                      isHerValue={matchPreference?.maritalStatus?.herflag}
                      isYourValue={matchPreference?.maritalStatus?.yourflag}
                    />
                    <MatchingRow2
                      title={"Mother Tongue"}
                      herValue={matchPreference?.mothertongue?.herstaus}
                      yourValue={matchPreference?.mothertongue?.yourstatus}
                      isHerValue={matchPreference?.mothertongue?.herflag}
                      isYourValue={matchPreference?.mothertongue?.yourflag}
                    />
                    <MatchingRow2
                      title={"Physical Status"}
                      herValue={matchPreference?.physicalstatus?.herstaus}
                      yourValue={matchPreference?.physicalstatus?.yourstatus}
                      isHerValue={matchPreference?.physicalstatus?.herflag}
                      isYourValue={matchPreference?.physicalstatus?.yourflag}
                    />
                    <MatchingRow2
                      title={"Dhosam"}
                      herValue={matchPreference?.dhosam?.herstaus}
                      yourValue={matchPreference?.dhosam?.yourstatus}
                      isHerValue={matchPreference?.dhosam?.herflag}
                      isYourValue={matchPreference?.dhosam?.yourflag}
                    />
                    <MatchingRow2
                      title={"Eating Habits"}
                      herValue={matchPreference?.eatinghabits?.herstaus}
                      yourValue={matchPreference?.eatinghabits?.yourstatus}
                      isHerValue={matchPreference?.eatinghabits?.herflag}
                      isYourValue={matchPreference?.eatinghabits?.yourflag}
                    />
                    <MatchingRow2
                      title={"Drinking Habits"}
                      herValue={matchPreference?.drinkinghabits?.herstaus}
                      yourValue={matchPreference?.drinkinghabits?.yourstatus}
                      isHerValue={matchPreference?.drinkinghabits?.herflag}
                      isYourValue={matchPreference?.drinkinghabits?.yourflag}
                    />
                    <MatchingRow2
                      title={"Smoking Habits"}
                      herValue={matchPreference?.smokinghabits?.herstaus}
                      yourValue={matchPreference?.smokinghabits?.yourstatus}
                      isHerValue={matchPreference?.smokinghabits?.herflag}
                      isYourValue={matchPreference?.smokinghabits?.yourflag}
                    />
                    {/* <MatchingRow2
                                    title={"Education"} 
                                    herValue={matchPreference?.height?.herstaus} 
                                    yourValue={matchPreference?.height?.yourstatus} 
                                    isHerValue={matchPreference?.height?.herflag} 
                                    isYourValue={matchPreference?.height?.yourflag}  /> */}
                    <p className="py-3 px-3 text-left text-[#9F9F9F] whitespace-nowrap text-[16px]  ">
                      Religious preferences
                    </p>
                    <MatchingRow2
                      title={"Religion"}
                      herValue={matchPreference?.religion?.herstaus}
                      yourValue={matchPreference?.religion?.yourstatus}
                      isHerValue={matchPreference?.religion?.herflag}
                      isYourValue={matchPreference?.religion?.yourflag}
                    />
                    <MatchingRow2
                      title={"Caste"}
                      herValue={matchPreference?.caste?.herstaus}
                      yourValue={matchPreference?.caste?.yourstatus}
                      isHerValue={matchPreference?.caste?.herflag}
                      isYourValue={matchPreference?.caste?.yourflag}
                    />
                    <MatchingRow2
                      title={"Sub Caste"}
                      herValue={matchPreference?.SubCaste?.herstaus}
                      yourValue={matchPreference?.SubCaste?.yourstatus}
                      isHerValue={matchPreference?.SubCaste?.herflag}
                      isYourValue={matchPreference?.SubCaste?.yourflag}
                    />
                     <p className="py-3 px-3 text-left text-[#9F9F9F] whitespace-nowrap text-[16px]  ">
                     Professional Expectation
                    </p>
                    <MatchingRow2
                      title={"Education"}
                      herValue={matchPreference?.education?.herstaus}
                      yourValue={matchPreference?.education?.yourstatus}
                      isHerValue={matchPreference?.education?.herflag}
                      isYourValue={matchPreference?.education?.yourflag}
                    />
                    <MatchingRow2
                      title={"Occupation"}
                      herValue={matchPreference?.occupation?.herstaus}
                      yourValue={matchPreference?.occupation?.yourstatus}
                      isHerValue={matchPreference?.occupation?.herflag}
                      isYourValue={matchPreference?.occupation?.yourflag}
                    />
                    <p className="py-3 px-3 text-left text-[#9F9F9F] whitespace-nowrap text-[16px] ">
                      Location preferences
                    </p>
                    <MatchingRow2
                      title={"Country"}
                      herValue={matchPreference?.country?.herstaus}
                      yourValue={matchPreference?.country?.yourstatus}
                      isHerValue={matchPreference?.country?.herflag}
                      isYourValue={matchPreference?.country?.yourflag}
                    />
                    <MatchingRow2
                      title={"Residing City"}
                      herValue={matchPreference?.residingcity?.herstaus}
                      yourValue={matchPreference?.residingcity?.yourstatus}
                      isHerValue={matchPreference?.residingcity?.herflag}
                      isYourValue={matchPreference?.residingcity?.yourflag}
                    />
                    <MatchingRow2
                      title={"Residing State"}
                      herValue={matchPreference?.residingstate?.herstaus}
                      yourValue={matchPreference?.residingstate?.yourstatus}
                      isHerValue={matchPreference?.residingstate?.herflag}
                      isYourValue={matchPreference?.residingstate?.yourflag}
                    />
                    <MatchingLastRow2
                      herPercentage={matchPreference?.matchingscores?.herstaus}
                      yourPercentage={matchPreference?.matchingscores?.yourstatus}
                    />
                  </div>
                </div>

              <div className="md:hidden bg-white px-1">
                {similarMatchesData.length > 0 && (
                  <DashboardSection 
                  count={similarMatchesDataCount?.similarMatchesCount}
                    title={"Similar Matches Profiles"}
                    data={similarMatchesData}
                    callLoadMore={callbackLoadMoreSimilarMatches}
                    ViewAllCallBack={() => {
                      setNavList()
                      setNavList(["Matches", "Similar Matches"])
                      props.viewAllCallback()
                      navigate(RouteHelper.getSimilirMatchesUrl, {
                        state: { isfrom: "similarmatches", id:matchViewProfile2?.similarmatches?.map((element) => element.profileid)  },
                      });
                      
                    }}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,
                        "similar"
                      );
                      setMessageFor("similar");
                    }}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                       // handleRequestPhoto("sidepanel");
                       onPhotoRequestClick()
                      setMessageFor("similar");
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                       // handleRequestParent()
                       onParentRequestClick()
                       setMessageFor("recent");
                      setParentNumber("similar");
                    }}
                    callBackViewPhone={callBackViewPhone}
                  />
                )}

                {recentlyViewedData.length > 0 && (
                  <MatchesProfileSection2
                  count={recentlyViewedDataCount?.recentlyViewedCount}
                    title={"Recently Viewed"}
                    data={recentlyViewedData} 
                    callLoadMore={callbackLoadMoreRecentlyView}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      // handleRequestPhoto("sidepanel");
                      onPhotoRequestClick()
                      setMessageFor("recent");
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      // handleRequestParent()
                      onParentRequestClick()
                      setMessageFor("recent");
                      setParentNumber("recent");
                    }}
                    callBackViewPhone={callBackViewPhone}
                    ViewAllCallBack={() => {
                      props.viewAllCallback()
                        navigate(RouteHelper.getRecentlyViewedUrl, {
                          state: { isfrom: "recentlyviewed" },
                        });
                        setNavList([])
                        setNavList(["Matches", "RecentlyViewed"])
                        //console.log("viewmore");
                      }}
                      callbackSelectReject={(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index
                      ) => {
                        callbackSelectReject(
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index,
                          "recent"
                        );
                        setMessageFor("recent");
                      }}
                    />
                  )}
                </div>

                <div className="hidden col-span-3 md:col-span-4 lg:col-span-3 md:block">
                  <ProfileSection data={profileData} profileDataCount={leftPanelData} onSubmitSuccess={(newPhone, newIsd) => {
                    console.log("newPhone, newIsd", newPhone, newIsd);
                    setProfileData(prev => ({ ...prev, parentsMobileNumber: newPhone, parentsIsdCode: newIsd }))
                  }} />

                  {recentlyViewedData.length > 0 && (
                    <DashboardSection4
                      menu="matchview"
                      count={recentlyViewedDataCount?.recentlyViewedCount}
                      title={"Recently Viewed"}
                      data={recentlyViewedData}
                      callLoadMore={callbackLoadMoreRecentlyView}
                      isViewmore={true}
                      callbackSelectReject={(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index
                      ) => {
                        callbackSelectReject(
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index,
                          "recent"
                        );
                        setMessageFor("recent");
                      }}
                      callBackChatnow={() => { }}
                      callbackRequestPhoto={(value) => {
                        //console.log(value,"jkjnki");
                        setMessageProfileDetails(value);
                        // handleRequestPhoto("sidepanel");
                        onPhotoRequestClick()
                        setMessageFor("recent");
                      }} 
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      // handleRequestParent()
                      onParentRequestClick()
                      setMessageFor("recent");
                      setParentNumber("recent");
                    }}
                    callBackViewMore={(value) => {
                      //console.log("viewmore", value); 
                    }}
                    ViewAllCallBack={() => {
                        // viewallselection("recentlyviewed");
                        props.viewAllCallback()
                        navigate(RouteHelper.getRecentlyViewedUrl, {
                          state: { isfrom: "recentlyviewed" },
                        });
                        setNavList([])
                        setNavList(["Matches"])
                        //console.log("viewmore");
                      }}
                    />
                  )}

                  <div className="py-4">
                    {/* {addBanner && <img className="" alt="" src={addBanner} />} */}
                    {addKjBanner === "Y" ?
                      <img
                        className="cursor-pointer"
                        alt=""
                        src={addBanner}
                        closeModel={closeModel}
                        onClick={() => {
                          setShowKjBannerPopup(true);
                        }} />
                      :
                      <img
                        className="cursor-pointer"
                        alt=""
                        src={addBanner}

                                  onClick={() => {
                                    navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                                  }} />
                                }
                </div>
                {similarMatchesData.length > 0 
                &&
              
                  
                  <MatchesProfileSection 
                  menu="similarview"
                    title={"Similar Matching Profiles"}
                    count={similarMatchesDataCount?.similarMatchesCount}
                    data={similarMatchesData}
                    callLoadMore={callbackLoadMoreSimilarMatches}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      // handleRequestPhoto("sidepanel"); 
                      onPhotoRequestClick()
                      setMessageFor("similar");
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      // handleRequestParent()
                      onParentRequestClick()
                      setMessageFor("similar");
                      setParentNumber("similar");
                    }}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,
                        "similar"
                      );
                      setMessageFor("similar");
                    }}
                    callBackViewPhone={callBackViewPhone}

                      ViewAllCallBack={() => {
                        setNavList()
                        setNavList(["Matches", "Similar Matches"])
                        props.viewAllCallback()
                        navigate(RouteHelper.getSimilirMatchesUrl, {
                          state: { isfrom: "similarmatches", id: matchViewProfile1?.profiledetails?.profileid },
                        });

                      }}
                    />




                  }
                </div>
              </div>

            </div>
            {/* // PLACE THE FOOTER COMPONENT HERE Moorthy //   */}
            <Footer />
          </>
          :
          <center><img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt="" /></center>
        }
      </main>
      <UserProfileFooter />
      {isCommunitySite ? null : <DownloadAppBar dashboardData={ProfileData} />}
    </div>
  );
};
const DetailsItem = (props) => {
  return (
    <div
      className="flex item-start justify-start p-2"
    // className={props.className}
    >
      <div className="w-9 md:w-12">
        <div className="flex justify-center item-center relative">
          <div className="w-9 md:w-12">
            <img src="/Assets/Images/Ellipse 76.svg" className="w-9 md:w-12" />
          </div>
          <span className="absolute w-9 md:w-12 mt-[5px] md:mt-[10px] lg:mt-[12px]">
            <div className=" flex justify-center item-center">
              {/* <span className="flex justify-center pl-[0.4rem] md:pl-[0.65rem] lg:pl-[0.65rem]  absolute mt-[8px] md:mt-[10px] lg:mt-[13px]"> */}
              <img className=" w-6 h-6 md:w-7 md:h-7 " src={props.icon} />
              {/* </span> */}
            </div>
          </span>
        </div>
      </div>
      <div className="pl-2 mt-0 md:mt-0 lg:mt-0 leading-[17px] md:leading-[20px] lg:leading-[26px]">
        <p className="text-[#575556] font-normal text-[12px] md:text-[13px] lg:text-[14px] ">
          {props.title}
        </p>
        <span className="text-[#575556] text-[14px] lg:whitespace-nowrap break-wordss font-bold">
          {props.value}
        </span>
      </div>
    </div>
  );
};

const MatchingRow = (props) => {

  useEffect(() => {
    //console.log(props?.herValue?.split(",").join(",\n"),"string");
  }, [props?.herValue]);
  const string = props?.herValue;
  // //console.log(string.length, "slength");


  return (
    <div className="grid grid-cols-9  border-b border-[#E9E9E9] ">
      <div className="col-span-3 py-4 px-6 text-left whitespace-nowrap">
        <div className="flex items-center text-[#575556] text-[14px] font-normal font-segeo">
          <span className="">{props.title}</span>
        </div>
      </div>
      <div className="col-span-3 py-4 pl-[4.5rem] pr-[2.5rem] md:pl-1 md:pr-0 lg:pl-[4.5rem] lg:pr-[2.5rem] text-left text-[13px] text-[#575556] font-bold ">
        {/* <div className="flex items-center space-x-2 lg:space-x-4 "> */}
        <div className="grid grid-cols-5 ">
          <div className="col-span-1">
            <img
              className="w-7 h-7 md:w-5 md:h-5 lg:w-7 lg:h-7 rounded-full"
              src={
                props.isHerValue
                  ? "/Assets/Images/Group 345.svg"
                  : "/Assets/Images/Group 370.svg"
              }
              alt=""
            />
          </div>
          <div className=" col-span-4 md:mt-0 pl-1 lg:mt-1 items-center break-words max-w-xs ">
            <span className=" break-words">{props?.herValue?.split(",").join(",\n")}</span>


            {/* <div className="  ">
             {
              props?.herValue?.split(",").map((item,i) => 
               <div className="">
                  {item},
               </div>
               )
             }
            </div> */}

          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="col-span-3 py-4 pl-[4.5rem] pr-[2.5rem] md:pl-1 md:pr-0 lg:pl-[4.5rem] lg:pr-[2.5rem] text-left text-[13px] text-[#575556] font-bold ">
        {/* <div className="flex items-center space-x-2 lg:space-x-4 "> */}
        <div className="grid grid-cols-5 ">
          <div className="col-span-1">
            <img
              className="w-7 h-7 md:w-5 md:h-5 lg:w-7 lg:h-7 rounded-full"
              src={
                props.isYourValue
                  ? "/Assets/Images/Group 345.svg"
                  : "/Assets/Images/Group 370.svg"
              }
              alt=""
            />
          </div>
          <div className=" col-span-4 pl-1 md:mt-0 lg:mt-1 items-center break-words">
            <span className=" ">
              {props?.yourValue?.replace(/,/g, ", ")}
              {/* {
              props?.yourValue?.split(",").map((item,i) => 
                  
               <div className="">
                  {item},
               </div>
               )
             } */}
            </span>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
const MatchingLastRow = (props) => {
  return (
    <div className="grid grid-cols-9   ">
      <div className="col-span-3 py-4 px-6 text-left text-[#D10A11] text-[18px] font-semibold font-segeo">
        <div className="items-center">
          <p>Matching</p>
          <p>Scores</p>
        </div>
      </div>
      <div className="col-span-3 py-4 md:pl-[0.2rem] lg:pl-[4.7rem] lg:pr-[1.5rem] text-left text-[17px] text-[#D10A11] font-bold font-segeo">
        <div className="flex items-center ">
          <span className="">{props.herPercentage}%</span>
        </div>
      </div>
      <td className="col-span-3 py-4  md:pl-[0.2rem] lg:pl-[4.7rem] lg:pr-[1.5rem] text-center text-[17px] text-[#D10A11] font-bold font-segeo">
        <div className="flex items-center">
          <div className="flex ">
            <span className="">{props.yourPercentage}%</span>
          </div>
        </div>
      </td>
    </div>
  );
};

const MatchingRow2 = (props) => {

  useEffect(() => {

  }, [props?.herValue]);
  const string = props?.herValue;


  return (
    <div className="grid grid-cols-9 border-b border-[#E9E9E9]  ">
      <div className="col-span-3">
        <div className="flex py-3 pl-3 text-left   text-[#575556] text-[12px] ">
          <span className="">{props.title}</span>
        </div>
      </div>
      <div className="col-span-3">
        <div className="flex py-3 pl-2 text-left  text-[#575556] text-[13px] font-bold ">
          <div className="  grid grid-cols-5">
            <div className="col-span-1">
              <img
                alt=""
                className="w-5 h-5 md:w-6 md:h-6 rounded-full"
                src={
                  props.isHerValue
                    ? "/Assets/Images/Group 345.svg"
                    : "/Assets/Images/Group 370.svg"
                }
              />
            </div>
            <div className="col-span-4 pl-1" >
              <span className=" break-words">{props?.herValue?.split(",").join(",\n")}</span>
            </div>
            {/* <div className="col-span-4 pl-1  ">
             {props?.herValue?.split(",").map((item,i) => 
             <div className="">
                 {item.split(",").join(",\n")}
              </div>
             )}
             </div> */}

          </div>
        </div>
      </div>
      <div className="col-span-3">
        <div className="flex py-3 pl-2 text-left  text-[#575556] text-[13px] font-bold ">
          <div className="  grid grid-cols-5">
            <div className="col-span-1">
              <img
                alt=""
                className="w-5 h-5 md:w-6 md:h-6 rounded-full"
                src={
                  props.isYourValue
                    ? "/Assets/Images/Group 345.svg"
                    : "/Assets/Images/Group 370.svg"
                }
              />
            </div>
            <div className="col-span-4 pl-1" >
              <span className=" break-words">{props?.yourValue?.split(",").join(",\n")}</span>
            </div>
            {/* <div className="col-span-4   ">
             {props?.yourValue.split(",").map((item,i) => 
             <div className="">
                 {item.split(",").join(",\n")}
              </div>
             )}
             </div> */}

          </div>
        </div>
      </div>
    </div>
  );
};

const MatchingLastRow2 = (props) => {
  return (
    <div className="grid grid-cols-9   ">
      <div className="col-span-3">
        <div className=" py-3 pl-3 text-left   text-[#D10A11] text-[10pt] font-bold">
          <p className="">Matching </p>
          <p> Scores</p>
        </div>
      </div>
      <div className="col-span-3">
        <div className="flex py-3 pl-3 text-left   text-[#D10A11] text-[17px] font-bold ">
          <div className="flex justify-end ">
            <span className=" pl-[0.4rem] md:pl-[1.2rem]">{props.herPercentage}%</span>
          </div>
        </div>
      </div>
      <div className="col-span-3">
        <div className="flex py-3 pl-3 text-left  text-[#D10A11] text-[17px] font-bold ">
          <div className="flex justify-end ">
            <span className="pl-[0.4rem] md:pl-[1.2rem]">{props.yourPercentage}%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DetailsItem, MatchingRow, MatchingLastRow, MatchingRow2 };
export default MatchesProfile;