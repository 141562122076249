import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
  SelectInput,
  SelectCurrencyInput
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
// import Constants, { loginFrom } from "../../../Constants/constants";
import { EventKeys, blockInvalidCharForNumber, blockInvalidCharForText } from "../../../Constants/constants";
import config from "../../../config";
import { validateNumber } from "../../../Utils";
import Constants, {
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import LoaderGif from '../../../Gif/loader.gif'
import { getDeviceInfo } from '../../Kalyanlite/utils';
import { getGroupAuthId } from "../../../Components/Kalyanlite/utils";
import { useDispatch, useSelector } from "react-redux";
import { getConversationList } from "../../../Store/Chats/Action";
import { ValidationNotificationPopup } from "../../CommonPopup";

const FamilyComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);

  const [isShowEdit, setEditView] = useState(false);
  const [familyStatusDropDown, setfamilyStatusDropDown] = useState([]);
  const [familyTypeDropDown, setfamilyTypeDropDown] = useState([]);
  const [familyValuesDropDown, setfamilyValuesDropDown] = useState([]);
  const [parentsIsdCodeDropDown, setparentsIsdCodeDropDown] = useState([]);
  const [currencyDropDown, setcurrencyDropDown] = useState([]);
  const [relationDropDown, setRelationDropDown] = useState([{id: 5 , name: "Mother"},{id: 6, name: "Father"}]);
  const [showValidationMessage,setShowValidationMessage] = useState({show:false,message:""})

  const [submitStatus, setSubmitStatus] = useState(false)
  const [isParentNumberChanged, setIsParentNumberChanged] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState("")
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
    );
    const dispatch = useDispatch()
    
    const loggedInProfileStatus = userData?.status
    const receiverId = useSelector((state)=> state?.Chats?.currentChat?.receiverId)
 
  const formik = useFormik({
    initialValues: {
      familyStatus: props?.data?.familyStatus
        ? props?.data?.familyStatus[0].id
        : "",
      familyType: props?.data?.familyType ? props?.data?.familyType[0].id : "",
      familyValues: props?.data?.familyValues
        ? props?.data?.familyValues[0].id
        : "",
      parentsIsdCode: props?.data?.parentsIsdCode
        ? props?.data?.parentsIsdCode
        : "",
      parentNumber: props?.data?.parentsMobileNumber
        ? props?.data?.parentsMobileNumber
        : "",
      relationshipId : props?.data?.relationshipId 
        ? props?.data?.relationshipId 
        : "",
      parentsMonthly: props?.data?.parentsMonthly
        ? props?.data?.parentsMonthly
        : "",
      currency: props?.data?.parentsCurrency?.currency ? props?.data?.parentsCurrency?.currency : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      familyStatus: Yup.string(),
      familyType: Yup.string(),
      familyValues: Yup.string(),
      parentsIsdCode: Yup.string(),
      relationshipId: Yup.string().when("familyStatus", (familyStatus,schema) => {

        if (props?.isFrom == "requestFamilyDetails") {
          return schema;
        } else {
          return schema.required("Please Select Relation");
        }
      }),
      // relationshipId: Yup.string().required("Please Select Relation"),
      // parentNumber: Yup.string().matches(/^\+?[1-9][0-9]{9,19}$/, 'Please enter valid phone number'),
      // parentNumber: Yup.string().min(10, "Please enter valid mobile number")
      // .test("india", "Mobile number should have 10 digits", function (value) {
      //   const { parentsIsdCode } = this.parent;
      //   //console.log(parentsIsdCode, 'countryId');
      //   if (parentsIsdCode === '+91') {
      //     return !value || value.length === 10 ? true : this.createError({ message: "Mobile number should have 10 digits", path: "parentNumber" });
      //   }
      //   return true;
      // })
      //   .test("other", "Mobile number should have 20 digits", function (value) {
      //     const { parentsIsdCode } = this.parent;
      //     if (parentsIsdCode !== '+91') {
      //       return !value || value.length < 21 ? true : this.createError({ message: "Please enter valid mobile number", path: "parentNumber" });
      //     }
      //     return true;
      //   }),
      parentNumber: Yup.string()
      .test('valid-number', 'Please enter a valid Mobile Number', function(value) {
        const { parentsIsdCode } = this.parent;
        if (parentsIsdCode === '+91') {
          // Validate for India (+91) specific rules
          if(value){
            if (value?.length !== 10) {
              return this.createError({ message: 'Please enter a valid Mobile Number', path: 'parentNumber' });
            }
          }
        } else {
          // Validate for other countries
          if(value){
            if (value?.length < 7 || value?.length > 15) {
              return this.createError({ message: 'Please enter a valid Mobile Number', path: 'parentNumber' });
            }
          }
        }
        return true;
      }).when("familyStatus", (familyStatus,schema) => {
        if (props?.isFrom == "requestFamilyDetails") {
          return schema;
        } else {
          return schema.required("Please enter Mobile Number");
        }
      }),
      parentsMonthly: Yup.string(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true)
      apiCallFamily(values);
    },
  });

  useEffect(() => {
    setfamilyStatusDropDown(props?.dropDown?.familyStatusData || []);
    setfamilyTypeDropDown(props?.dropDown?.familyTypeData || []);
    setfamilyValuesDropDown(props?.dropDown?.familyValuesData || []);
    setparentsIsdCodeDropDown(props?.dropDown?.isdcode || []);
    setcurrencyDropDown(props?.dropDown?.currencyData || []);
  }, [props?.dropDown]);

  useEffect(() => { }, [
    familyStatusDropDown,
    familyTypeDropDown,
    familyValuesDropDown,
    parentsIsdCodeDropDown,
    currencyDropDown,
    relationDropDown
  ]);

  const requestType = ((props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44)

  const apiCallFamily = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      FamilyStatusId: value.familyStatus,
      familyTypeId: value.familyType,
      familyValuesId: value.familyValues,
      parentsMobileNumber: isParentNumberChanged?value.parentNumber:"",
      // parentsMobileNumber: value.parentNumber,
      parentsIsdcode: value.parentsIsdCode,
      parentsMonthly: value.parentsMonthly,
      parentsCurrency: value.currency == selectedParentsCurrenyCode.name ? selectedParentsCurrenyCode.id : value.currency,
      relationshipId : value.relationshipId,
      ...(value?.familyStatus != "" && {familystatusrequesttypeid:27}),
      ...(value?.familyType != "" && {familytyperequesttypeid:28}),
      ...(value?.familyValues != "" && {familyvaluerequesttypeid:29}),
      ...(value?.parentsMonthly != "" && {parentmonthlyrequesttypeid:30}),
      requestfulfilledfrom:getDeviceInfo() ,
      ...(props?.isFrom == "requestFamilyDetails" ? {requestfulfilledvia:"chat"} : {requestfulfilledvia:"viewprofile"}) ,
      recieverprofileid: getGroupAuthId(),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editfamilyinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false)
        setIsParentNumberChanged(false)
        if (requestType) {
          setShowValidationMessage({show:true, message:"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."})
          props.close()
        }else{
          props.callBackReload();
          setEditView(false);
        }
        if(props?.isFrom == "requestFamilyDetails"){
          onClickTrack(EventName.Chat_Request_fulfillment_popup_Save_Family_details_Button , {[EventKeys.requestFamilyDetails]:value})
        }
        else{
        onClickTrack(EventName.profileFamilySaveButton , {[EventKeys.profileFamily]:value})
        }
      }
      else{
        setSubmitStatus(false)
        setPhoneNumberError(data?.data?.statusMessage)
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const selectedFamilyStatus = useMemo(()=>{
    return familyStatusDropDown?.filter(option=>option.id === formik.values.familyStatus)?.at(0)
  },[familyStatusDropDown, formik.values.familyStatus])

  const selectedFamilyType= useMemo(()=>{
    return familyTypeDropDown?.filter(option=>option.id === formik.values.familyType)?.at(0)
  },[familyTypeDropDown, formik.values.familyType])

const selectedFamilyValues= useMemo(()=>{
  return familyValuesDropDown?.filter(option=>option.id === formik.values.familyValues)?.at(0)
},[familyValuesDropDown, formik.values.familyValues])

  const selectedParentsIsdCode = useMemo(() => {
    return parentsIsdCodeDropDown?.filter(option => option.isdcode === formik.values.parentsIsdCode)?.at(0)
  }, [formik.values.parentsIsdCode, parentsIsdCodeDropDown])

  const selectedParentsCurrenyCode = useMemo(() => {
    return currencyDropDown?.filter(option => option.name === formik.values.currency)?.at(0)
  }, [formik.values.currency, currencyDropDown])

  const selectedRelation = useMemo(()=>{
    return relationDropDown?.filter(option => option.id == formik.values.relationshipId)?.at(0)
  },[relationDropDown, formik.values.relationshipId])

  const { onClickTrack } = useAnalytics()
  const saveCallBack=(e) => {
    e.preventDefault();
  }

  return (
    <div>
      {/* {showValidationMessage?.show && */}
        <ValidationNotificationPopup message={showValidationMessage?.message} onClose={()=>{setShowValidationMessage({ show: false, message: ""} )}}  />
      {/* } */}
      {requestType ? '' : isShowEdit ? (
        <></>
      ) : (
        <div className="md:mb-4 p-5 md:p-0">
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>
          <BasicTextView
            title={"Family Status"}
            value={
              props?.data?.familyStatus
                ? props?.data?.familyStatus[0].name
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Family Type"}
            value={
              props?.data?.familyType
                ? props?.data?.familyType[0].name
                : "Not Specified"
            }
          />

          <BasicTextView
            title={"Family Values"}
            value={
              props?.data?.familyValues
                ? props?.data?.familyValues[0].name
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Parents Isdcode"}
            value={
              props?.data?.parentsIsdCode
                ? props?.data?.parentsIsdCode
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Parents MobileNumber"}
            value={
              props?.data?.parentsMobileNumber
                ? props?.data?.parentsMobileNumber
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Parents Relation"}
            value={
              props?.data?.relationshipName
                ? props?.data?.relationshipName
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"Parents Income"}
            value={
              props?.data?.parentsMonthly && props?.data?.parentsCurrency?.currency
                ? (props?.data?.parentsCurrency?.currency)+" "+(props?.data?.parentsMonthly)
                : "Not Specified"
            }
          />
        </div>
      )}
      {/* <!-- edit part  --> */}
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit || (props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? (
          <>
          <div className={`${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'grid grid-cols-1 md:grid-cols-2 lg:mt-[-2rem]' : 'p-5 md:p-0'}`}>
            <SelectInput
              name="familyStatus"
              title="Family Status"
              value={selectedFamilyStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("familyStatus", selected.id)
              }}
              error={formik.errors.familyStatus}
              defaultValue={formik.values.familyStatus}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...familyStatusDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              fieldType={props.fieldType}
            />
            {/* <BasiSelectView
              title={"Family Status"}
              inputID={"familyStatus"}
              inputName={"familyStatus"}
              value={formik.values.familyStatus}
              onChange={formik.handleChange}
              error={formik.errors.familyStatus}
              options={familyStatusDropDown}
            /> */}
            <SelectInput
              name="familyType"
              title="Family Type"
              value={selectedFamilyType}
              onChange={(selected, ev) => {
                formik.setFieldValue("familyType", selected.id)
              }}
              error={formik.errors.familyType}
              defaultValue={formik.values.familyType}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...familyTypeDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              fieldType={props.fieldType}
            />
            {/* <BasiSelectView
              title={"Family Type"}
              inputID={"familyType"}
              inputName={"familyType"}
              value={formik.values.familyType}
              onChange={formik.handleChange}
              error={formik.errors.familyType}
              options={familyTypeDropDown}
            /> */}
            <SelectInput
              name="familyValues"
              title="Family Values"
              value={selectedFamilyValues}
              onChange={(selected, ev) => {
                formik.setFieldValue("familyValues", selected.id)
              }}
              error={formik.errors.familyValues}
              defaultValue={formik.values.familyValues}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...familyValuesDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              fieldType={props.fieldType}
            />
            {/* <BasiSelectView
              title={"Family Values"}
              inputID={"familyValues"}
              inputName={"familyValues"}
              value={formik.values.familyValues}
              onChange={formik.handleChange}
              error={formik.errors.familyValues}
              options={familyValuesDropDown}
            /> */}
            <SelectInput
              name="parentsIsdCode"
              title="Parent's Isd Code"
              value={selectedParentsIsdCode}
              onChange={(selected, ev) => {
                formik.setFieldValue("parentsIsdCode", selected.isdcode)
              }}
              error={formik.errors.parentsIsdCode}
              defaultValue={formik.values.parentsIsdCode}
              options={[{ id: "", name: "Select", isDisabled: true }, ...parentsIsdCodeDropDown]}
              getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
              getOptionValue={(option) => option.isdcode}
              fieldType={props.fieldType}
            />
            {/* <BasiSelectView 
              title={"Parent's IsdCode"}
              inputID={"parentsIsdCode"}
              inputName={"parentsIsdCode"}
              value={formik.values.parentsIsdCode}
              onChange={formik.handleChange}
              error={formik.errors.parentsIsdCode}
              options={parentsIsdCodeDropDown}
            /> */}
            <BasicEditTextView
              title={"Parent's Mobile Number"}
              inputID={"parentNumber"}
              inputName={"parentNumber"}
              value={formik.values.parentNumber}
              onChange={(e)=>{
                if(validateNumber(e.target.value)){
                  setPhoneNumberError("")
                  setIsParentNumberChanged(true)
                  formik.handleChange(e)
                }
              }}
              phoneNumberError={phoneNumberError}
              error={formik.errors.parentNumber}
              inputType={"tel"}
              onKeyDown={blockInvalidCharForNumber}
              fieldType={props.fieldType}
            />

            <SelectInput
              name="relationshipId"
              title="Parent's Relation"
              value={selectedRelation}
              onChange={(e) => {
                formik.setFieldValue("relationshipId", e.id)
              }}
              error={formik.errors.relationshipId}
              defaultValue={formik.values.relationshipId}
              options={[{ id: "", name: "Select", isDisabled: true }, ...relationDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              fieldType={props.fieldType}
            />
            {(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? '' : 
            <div className="flex justify-center pt-4">
              <p className="text-[12px] text-[red]">* This number will be visible to Matches. Matches can also contact you on this number</p>
            </div>}

            <div className={`${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? '' :  'grid grid-cols-9 '} mt-[2rem] `}>
              <div className={`md:col-span-4 col-span-9 flex items-center ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'md:pl-[1rem] lg:pl-[1rem]' : 'md:pl-[1rem] lg:pl-[2rem]'}`} >
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  ">
                  Parent's Monthly Income
                  {/* <span className="text-[#D10A11]">*</span> */}
                </p>
              </div>
              <div className={`md:col-span-5 col-span-9 md:pr-[1rem] ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'lg:pr-[4rem]  md:pl-[1rem] pt-2' : 'lg:pr-[4rem]  md:pl-[1rem]'}`}>
                <p className="  ">
                  <div className="flex space-x-3 ">
                    {/* <label className=" select font-bold text-[#575556] w-[8rem]"> */}
                      <SelectCurrencyInput
                      menu="familyincom"
                        name="currency"
                        id="currency"
                        title="currency"
                        error={formik.errors.currency}
                        defaultValue={formik.values.currency}
                        value={selectedParentsCurrenyCode}
                        // onChange={formik.handleChange}
                        onChange={(selected, ev) => {
                          formik.setFieldValue("currency", selected.name)
                        }}
                        className={`md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'w-[8rem]' : 'w-full'  }`}
                      
                        // {/* {currencyDropDown?.map((e, i) => (
                        //   <option key={i} value={e.name}>
                        //     {e.name}
                        //   </option>
                        // ))} */}
                        options={[{ id: "", name: "Select", isDisabled: true }, ... currencyDropDown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.currency }`}
                        getOptionValue={(option) => option.id}
                      />
                  
                    <p className=" flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                      inputID={"parentsMonthly"}
                      inputName={"parentsMonthly"}
                        type="tel"
                        onChange={(e)=>{
                          if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                          }
                        }}
                        id="parentsMonthly"
                        value={formik.values.parentsMonthly}
                        className=" RegPhone  w-[100%] border border-[#8F8F8F] rounded-[3px] h-[35px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px]"
                      />
                    </p>
                    {formik.errors.parentsMonthly && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.parentsMonthly}
                      </p>
                    )}
                    {}
                  </div>
                </p>
              </div>
            </div>
            {(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? '' : 
            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                setEditView(false);
                props.refresh()
                onClickTrack(EventName.profileFamilyCancelButton)
              }}
              submitStatus={submitStatus}
            />}
          </div>
          {requestType?
          <div className="flex py-6 justify-center gap-5">
              <button type="submit" 
                className="text-[12px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#FFFFFF] font-bold rounded-[4px] bg-red-600"
              >
                {submitStatus ? <div className='flex items-center justify-center p-1'><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>Saving...</span></div> : <span>Save</span>}
              </button>
          </div>: ''}
        </>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default FamilyComponent;
