import react,{useContext} from 'react';
import { call, put, takeEvery } from "redux-saga/effects";
import constant from '../../Constants/constants'


// Login Redux States
import { CASTE_LIST, SUB_CASTE_LIST, REGISTER_DETAIL, RAASI_LIST, COUNTRY_LIST, PINCODE_LIST, STATE_LIST, REGISTER_SUBMIT, CITY_LIST, STAR_LIST, PROFILE_SUB_CASTE_LIST, VALIDATE_NAME, DENOMINATION_LIST} from "./ActionTypes";
import { apiError, registerDetailSuccess, getCasteListSuccess, getSubCasteListSuccess, getRassiListSuccess, getCountrySuccess, getStateSuccess, getPincodeSuccess, registerApiSubmitSuccess, getCitySuccess, getStarListSuccess, getProfileSubCasteListSuccess, validateName, validateNameSuccess, validateNameErrors, validateNameError, getDenominationList, getDenominationListSuccess, getDenominationListFail} from "./Action";
import { Register_Detail,Caste_List, Sub_Caste_List, Raasi_list, Country_List, State_List, Pincode_List, Register_Submit, City_List, Star_list, Profile_Sub_Caste_List, Validate_Name, Denomination_list} from "../../Helpers/api_helpers";
import RouteSegments from '../../Routes/RouteSegment';
import Constants from '../../Constants/constants';

//context
// import { LoginContext } from "../../Contexts/LoginContext";


function* registerDetail() {
  try { // 
    const response = yield call(Register_Detail);
    ////console.log(response,"register response");
    if (response.data.status === "Success") {
      yield put(registerDetailSuccess(response));
      ////console.log("123");
    } else {
      ////console.log("123");
      yield put(apiError(response));
    }
  } catch (error) {
    ////console.log("123");
    yield put(apiError(error));
  }
}

function* registerCasteList({ payload: data }) {
  ////console.log( data,"123");
  try {// 
    ////console.log( Caste_List+`?motherTongueId=${data.motherTongueId}&religionId=${data.ReligionId}`,"123");
    const response = yield call(Caste_List,data)
    ////console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getCasteListSuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerSubCasteList({ payload: data }) {
  ////console.log( data,"123");
  try {// 
    ////console.log( Sub_Caste_List,"123");
    const response = yield call(Sub_Caste_List,data)
    ////console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getSubCasteListSuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* profileSubCasteList({ payload: data }) {
  try {
    const response = yield call(Profile_Sub_Caste_List,data)
    console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getProfileSubCasteListSuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerStarList({ payload: data }) {
  //console.log( data,"123");
  try {// 
    ////console.log( Raasi_list,"123");
    const response = yield call(Star_list,data)
    ////console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getStarListSuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerRaasiList({ payload: data }) {
  //console.log( data,"123");
  try {// 
    ////console.log( Raasi_list,"123");
    const response = yield call(Raasi_list,data)
    ////console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getRassiListSuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}


function* registerCountryList({ payload: data }) {
  ////console.log( data,"123");
  try {// 
    ////console.log( Country_List,"123");
    const response = yield call(Country_List,data)
    ////console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getCountrySuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerStateList({ payload: data }) {
  ////console.log( data,"123");
  try {// 
    ////console.log( State_List,"123");
    const response = yield call(State_List,data)
    ////console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getStateSuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerCityList({ payload: data }) {
  ////console.log( data,"123");
  try {// 
    ////console.log( City_List,"123");
    const response = yield call(City_List,data)
    ////console.log(response.data.data.status,"login response");
    if (response.data.data.status === "SUCCESS") {
      yield put(getCitySuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerPincodeList({ payload: data }) {
  ////console.log( data,"123");
  try {// 
    ////console.log( Pincode_List,"123");
    const response = yield call(Pincode_List,data)
    ////console.log(response.data,"login response");
    if (response.data.status === "Success") {
      yield put(getPincodeSuccess(response));
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerSubmit({ payload }) {
  try {
    const response = yield call(Register_Submit,{
      "email": payload?.values?.email,
      "userId":localStorage.getItem(constant.loginLocalStorageKeys.loginId),
      "profileForId":payload?.values?.profileId,
      "groomName":payload?.values?.name,
      "gender":payload?.values?.gender,
      "maritialStatusId":payload?.values?.martialStatusID,
      "dateofbirth":payload?.values?.DOBDate,
      "monthofbirth":payload?.values?.DOBMonth,
      "yearofbirth":payload?.values?.DOBYear,
      "countryId":payload?.values?.countryNameId,
      "mobileNumber":payload?.values?.phone,
      "stateId":payload?.values?.stateId,
      "cityId":payload?.values?.cityId,
      "motherTongueId":payload?.values?.motherTongueId,
      "religionId":payload?.values?.religionId,
      "domainId":payload?.values?.denomination==""?null:payload?.values?.denomination, 
      "casteId":payload?.values?.casteId,
      "gothraId":payload?.values?.gothraId==""?null:payload?.values?.gothraId,
      "isdcode":payload?.values?.countryId,
      "pincodeId":payload?.values?.pinCodeID.toString(),
      "heightInInchesId":payload?.values?.heightId,
      "weight":payload?.values?.weight,
      "bodyTypeId":payload?.values?.bodyTypeId,
      "complexionId":payload?.values?.complexionId,
      "physicalstatusId":payload?.values?.physicalStatusId,
      "starId":payload?.values?.starId,
      "raasiId":payload?.values?.rassiId,
      "doshamId":payload?.values?.dhosamId,
      "foodId":payload?.values?.foodHabitsId,
      "smokingId":payload?.values?.smokingId,
      "drinkingId":payload?.values?.drinkingId,
      "familtStatusId":payload?.values?.familyStatusId,
      "familyTypeId":payload?.values?.familyTypeId,
      "familyValuesId":payload?.values?.familyValuesId,
      "moreInfo":payload?.values?.description,
      "parentsCurrency":payload?.values?.pCurrecnyId,
      "parentsMothly":payload?.values?.pIncome,
      "parentsIsdcode":payload?.values?.pMobileNumberCode,
      "educationId":payload?.values?.educationId,
      "occupationId":payload?.values?.occupationId,
      "employedInId":payload?.values?.empTypeId,
      "monthly":payload?.values?.income,
      "subcasteId":payload?.values?.subCasteId==""?null:payload?.values?.subCasteId,
      "otherSubcaste":payload?.values?.otherSubcaste==""?null:payload?.values?.otherSubcaste,
      "parentsMobileNumber":payload?.values?.pMobileNumber,
      "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "registerFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "currencyName":payload?.values?.currencyId,
      "isWhatsapp" :payload?.values?.isWhatsappNumber,
      "relationshipId":payload?.values?.relationshipId,
      "otherCaste" :payload?.values?.otherCaste,
      "descriptionEdit":payload?.values?.isDescription ? "N" : "Y", 

      "othersubCaste":payload?.values?.othersubCaste ==""?null :payload?.values?.othersubCaste, 
    })
    //console.log(response.data,"login response");
    if (response.data.data.status === "Success") {
      yield put(registerApiSubmitSuccess(response));
      localStorage.setItem(Constants.loginLocalStorageKeys.afterRegister,JSON.stringify(response?.data?.data))
      localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage,RouteSegments.PROFILE_OTP)
      payload.history(RouteSegments.PROFILE_OTP)
    } else {
      yield put(registerApiSubmitSuccess(response));
      payload.setSubmitting(false)
      // response.data.data.alreadyRegisted?
      // alert(response.data.data.alreadyRegisted)
      // :
      // alert(response.data.data.statusMessage)
      yield put(apiError(response));
    }
  } catch (error) {
    console.log(error,"error data")
    yield put(apiError(error));
  }
}

function* validateNameDetail({ payload: name }) {
  alert(name)
  ////console.log( data,"123");
  try {// 
    ////console.log( Pincode_List,"123");
    const response = yield call(Validate_Name,{
      "name": name,
    })
    console.log(response.data,"name api response");
    if (response.data.status === "Success") {
      yield put(validateNameSuccess(response));
    } else {
      yield put(validateNameError(response));
    }
  } catch (error) {
    yield put(validateNameError(error));
  }
}

function* getDenominationListApi({ payload }) {
  try {
    const response = yield call(Denomination_list)
    if (response.data.status === "Success") {
      yield put(getDenominationListSuccess(response?.data));
    } else {
      yield put(getDenominationListFail(response));
    }
  } catch (error) {
    yield put(getDenominationListFail(error));
  }
}



function* homeSaga() {
  yield takeEvery(REGISTER_DETAIL, registerDetail);
  yield takeEvery(CASTE_LIST, registerCasteList);
  yield takeEvery(SUB_CASTE_LIST, registerSubCasteList);
  yield takeEvery(PROFILE_SUB_CASTE_LIST, profileSubCasteList);
  yield takeEvery(STAR_LIST, registerStarList);
  yield takeEvery(RAASI_LIST, registerRaasiList);
  yield takeEvery(COUNTRY_LIST, registerCountryList);
  yield takeEvery(STATE_LIST, registerStateList);
  yield takeEvery(CITY_LIST, registerCityList);
  yield takeEvery(PINCODE_LIST, registerPincodeList);
  yield takeEvery(REGISTER_SUBMIT, registerSubmit)
  yield takeEvery(VALIDATE_NAME, validateNameDetail)
  yield takeEvery(DENOMINATION_LIST,getDenominationListApi)
}

export default homeSaga;
