import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import ImageCroperComponent from "./Edit Profile/ImageCroperComponent";
import EndPoints from "../Constants/EndPoints";
import { IP_Address } from "../Constants/IpAddress";
import { GET, POST } from "../Services/api_services";
import Constants, {
  EditProfileMenu,
  loginFrom,
  EventName,
  EventKeys,
} from "../Constants/constants";
import { LoginContext } from "../Contexts/LoginContext";
import axios from "axios";
import { Field, FieldArray, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import RouteSegments, { RouteHelper } from "../Routes/RouteSegment";
import { useNavigate } from "react-router";
import { error } from "jquery";
import { validateImage } from "../Utils";
import ImageCropper from "./Edit Profile/ImageCropper";
import Loader from "./Loader";
import config from "../config";
import LoaderGif from "../Gif/loader.gif";
import CloseIcon from "../Images/Group 761@2x.png";
import MessageSentIcon from "../Images/message-sent.svg";
import SuccessIcon from "../Images/Group 809.svg";
import EyeIcon from "../Images/passwordeye.svg";
import EyeOpenIcon from "../Images/passwordeyeopen.png";
import UploadPhotoIcon from "../Images/Group 865-red1.svg";
import { CommonCheckButton } from "./DashboardComponents/DashboardUtills";
import { call, put, retry, takeEvery } from "redux-saga/effects";
import DangerIcon from "../Components/Kalyanlite/assets/Images/Group 2666.svg";
import { useLocation } from "react-router-dom";
import {
  phonenoicon,
  kalyanremovebg,
  chaticon,
  validityicon,
  gifticon,
  avatarimage,
  DefaultAvatar,
  redtickicon,
  resplusicon,
  profilematch1,
  profileimages,
} from "../Components/Kalyanlite/assets/images";
import Inviteicon from '../Components/Kalyanlite/assets/Images/inviteicon.svg';
import messageicon from '../Components/Kalyanlite/assets/Images/googlemessagesicon.svg'
import emailIcon from '../Components/Kalyanlite/assets/Images/GmailLogo.svg'
import * as Images from "../Components/Kalyanlite/assets/images"
import KalyanLeafLogo from "../Components/Kalyanlite/assets/Images/kalyan-logo.png"

import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
// import SocialMediaUpload from "./BasicInfo/SocialMediaUpload";
// import Img from 'react-optimized-image';
import Compressor from "compressorjs";
import {
  imageSizeReducer,
  imageSizeCompression,
  handleImageSizeCompress,
} from "./ImageSizeReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  getDenominationList,
  registerCasteList,
  registerDetail,
  registerSubCasteList,
} from "../Store/Home/Action";
import {
  BasicEditTextView,
  RawSelectInput,
  SelectCurrencyInput,
  SelectInput,
} from "./Edit Profile/EditProfileUtills";
import SocialMediaUpload from "./BasicInfo/SocialMediaUpload";

import { useMediaQuery } from "react-responsive";

import addphotoIcon from "../Components/Kalyanlite/assets/Images/addphotoIcon2.svg";
import facebookIcon from "../Components/Kalyanlite/assets/Images/facebookIcon.svg";
import instragramIcon from "../Components/Kalyanlite/assets/Images/instragramIcon.svg";
import whatsappIcon from "../Components/Kalyanlite/assets/Images/whatsappIcon.svg";
import googleicon from "../Images/google.png";
import playstoreicon from "../Images/appstore.png";
import AddGroupIcon from "../Components/Kalyanlite/assets/Images/addgroupicon.svg";
import DefaultIcon from "../Components/Kalyanlite/assets/Images/default_avatar.svg";

// import welcomeimage from '../Components/Kalyanlite/assets/Images/11111@3x.png'
import welcomeimage from "../Components/Kalyanlite/assets/Images/video.png";
import DPimage from "../Components/Kalyanlite/assets/Images/Rectangle 10@2x.png";
import HelpPage from "../Pages/Footer/HelpPage";
import QueryForm from "../Components/HelpAndFAQ/QueryForm";
import HelpSection from "../Components/HelpAndFAQ/HelpSection";
import { sendCustomerServiceMessage,setCurrentChat, toggleMuteConversations, RankingPopup, getCustomerServiceConversation} from "../Store/Chats/Action";
import {
  applyFilter,
  closeCurrentMatch,
  toggleProfileBlock,
} from "../Store/KalyanLite/Action";
import ForgetPasswordPopup from "./LoginForm/ForgetPasswordPopup";
import * as KalyanLiteSelector from "../Store/KalyanLite/selectors";
import { CMS_Login, Family_Chats_Detail, familycode_login } from '../Helpers/api_helpers'
import { NavBarContext } from "../Contexts/NavBarContext";
import { useAnalytics } from "../Hooks/usePageChange";

import { validateNumber } from "../Utils";
import { getDeviceInfo, getGroupAuthId } from "./Kalyanlite/utils";
import { getBasicData } from "../Store/Login/Action";
import { Divider, Flex, Progress } from "antd";
import { SettingsRadioButton } from "./Edit Profile/SettingsComponents/SettingsUtills";
import ProfileHoroscopeComponent from "./Edit Profile/ProfileHoroscopeComponent";
import HoroscopeComponent from "./Edit Profile/MyProfileComponents/HoroscopeComponent";
import ProfileBasicComponent from "./Edit Profile/ProfileBasicComponent";
import AstroComponent from "./TrailPack/AstroComponent";
import AstroBasic from "./TrailPack/AstroBasic";
import AstrologicalComponent from "./TrailPack/AstrologicalComponent";
import FamilyBasic from "./TrailPack/FamilyBasic";
import HabitsBasic from "./TrailPack/HabitsBasic";
import HoroBasic from "./TrailPack/HoroBasic";
import FamilyComponent from "./Edit Profile/MyProfileComponents/FamilyComponent";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import PaperBlast from "./PaperBlast";
import { rewardPopupShown } from "../Store/KalyanRewards/Action";
import { toast } from "react-toastify";
import SuccessPopup from "./Kalyanlite/Popup/SuccessPopup";
import ActionRestrict from "./Kalyanlite/Common/ActionRestrict";
import SadImage from "../Components/Kalyanlite/assets/Images/sad-face.svg"
import HappyImage from "../Components/Kalyanlite/assets/Images/smile.svg"
import {FaStar} from "react-icons/fa"

const CommonPopup = (props) => {
  return (
    <div className=" flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className={`${
          props.menu === "policy"
            ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
            : props?.menu === "View Horoscope Match"
            ? "relative w-[32rem] lg:w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
            ? "relative w-[25rem] text-center my-6 mx-6 "
            : props.menu === "report"
            ? "relative  my-6 mx-6  w-[30rem]"
            : props?.menu == "profileRejection"
            ? "relative text-center my-6 mx-6"
            : props?.menu == "liteprofile"
            ? "relative  my-6 mx-6 w-[90%] md:w-[60%]  lg:w-[30%]"
            : "relative  my-6 mx-6 w-[90%] md:w-[50%]  lg:w-[38%]"
        } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div
            className={
              props.menu == "liteprofile"
                ? "flex items-center py-1 justify-center mt-2 mx-2  rounded-t "
                : "flex items-center py-1 justify-center border-b border-dashed mt-2 border-[#D10A11] mx-2  rounded-t "
            }
          >
            <h3
              className={`text-[20px] text-[#D10A11] ml-2 font-semibold align-middle ${
                props?.menu == "profileRejection" ? "lg:whitespace-nowrap" : ""
              }`}
            >
              {props.title}
            </h3>
          </div>
          {props?.isLoading ? (
            <Loader />
          ) : (
            <div
              className={
                props?.menu === "policypopup"
                  ? "relative p-10 pr-[0.1rem]  flex justify-center items-center"
                  : props?.menu == "liteprofile"
                  ? "relative px-10 py-5 flex justify-center items-center"
                  : "relative p-10  flex justify-center items-center"
              }
            >
              {props.children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CommonPopupWithoutHeader = (props) => {
  return (
    <div className=" flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className={`${
          props.menu === "policy"
            ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
            : props?.menu === "View Horoscope Match"
            ? "relative w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
            ? "relative w-[25rem] text-center my-6 mx-6 "
            : props.menu === "report"
            ? "relative  my-6 mx-6  w-[30rem]"
            : "relative  my-6 mx-6 w-[90%] md:w-[80%]  lg:w-[38%]"
        } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="Noscroll max-h-[80vh] overflow-y-auto border-0  md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center mt-2 mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div
            className={
              props?.menu === "policypopup"
                ? "relative px-6 py-6 pr-[0.1rem] w-[100%] flex-auto"
                : "relative p-6  flex-auto w-[100%]"
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const MessageSuccessPopup = (props) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative p-6 flex-auto">
            {props?.dashboard1Data?.myphoto === "Y" ? (
              <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
                <span className="text-[16px] font-semibold">
                  Message sent successfully!
                </span>
                <img className="w-16 h-16 mt-2" alt="" src={MessageSentIcon} />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <span className="text-[16px] font-normal">
                  Message sent successfully
                </span>
                <span className="text-[16px] font-normal">
                  Increase your chance of getting a response by adding your
                  photo
                </span>
                <a
                  onClick={() => {
                    navigate(
                      RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath),
                      { state: { tab: "3" } }
                    );
                  }}
                  className="text-[#D10A11] font-bold underline cursor-pointer"
                >
                  Add Now
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonSuccessPopup = (props) => {
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className='relative p-6 flex-auto'>
            <div className='flex flex-col justify-center items-center w-[60vw] md:w-[30vw] '>
              <span className='text-[13px] md:text-[16px] lg:text-[16px] font-semibold md:whitespace-nowrap lg:whitespace-nowrap text-center'>
                {props.content}
              </span>
              <img className="w-16 h-16 mt-2" alt="" src={SuccessIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const CommonPayNowPopup = (props) => {
//   const { onClickTrack } = useAnalytics()
//   const navigate = useNavigate();

//   const handlePayNowClick = () => {
//     onClickTrack(EventName.View_Profile_All_Unpaid_Popup_Pay_Now_Button)
//     navigate(RouteSegments.MEMBERSHIP_REGISTER());
//     props.close(); // Close the popup after navigating
//   };

//   return (
//     <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//       <div className="relative w-[80%]  md:w-[50%] lg:w-[35%] my-6 mx-6">
//         <div className="">
//           <button
//             className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
//             onClick={() => {
//               props.close();
//             }}
//           >
//             <img className="h-5 w-5 " src={CloseIcon} alt="" />
//           </button>
//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative p-6 flex-auto">
//             <div className="space-y-2">
//               <p className="text-[#1A1A1A] font-bold text-[16px] xl:text-[18px] pb-2">{props?.title}</p>
//               <p className="text-[#7A7A7A] text-[12px] xl:text-[14px] pb-4">
//                 {props?.content}
//               </p>
//             </div>

//             <div className="flex py-3 justify-center gap-5">
//               <button
//                 onClick={handlePayNowClick}
//                 className="text-[13px] py-2 px-[2.5rem] text-white font-bold rounded-md bg-red-600" >Pay Now</button>
//             </div>
//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }
// const CommonPayBackPopup = (props) => {

//   const navigate = useNavigate();

//   const handlePayNowClick = () => {
//     navigate(RouteSegments.MEMBERSHIP_REGISTER());
//     props.close();
//   };

//   return (
//     <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//       <div className="relative w-[42%] lg:w-[35%] my-6 mx-6">
//         <div className="">
//           <button
//             className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
//             onClick={() => {
//               props.close();
//             }}
//           >
//             <img className="h-5 w-5 " src={CloseIcon} alt="" />
//           </button>
//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative p-6 flex-auto">
//             <div className="space-y-2">
//               <p className="text-[#575556] font-bold text-[16px]">Buy a Pack to Contact Profile</p>
//               <p className="text-[#7A7A7A] text-[12px] pb-4">
//                 You have already contacted this profile and awaiting response.Please Upgrade to send personalized messages
//               </p>
//             </div>

//             <div className="flex py-3 justify-center gap-5">
//               <button onClick={() => {
//                 props.close();
//               }} className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]">Cancel</button>
//               <button
//                 onClick={handlePayNowClick}
//                 className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600" >Pay Now</button>
//             </div>

//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }

const CommonConfrimPopup = (props) => {
  const navigate = useNavigate();

  const handlePayNowClick = () => {
    navigate(RouteSegments.MEMBERSHIP_REGISTER());
    props.close();
  };

  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[96%] md:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className="text-[#575556] font-bold text-[16px]">Logout</p>
              <p className="text-[#7A7A7A] text-[12px] pb-4">
                Are You Sure You Want To Logout?
              </p>
            </div>

            <div className="flex py-3 justify-center gap-5">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]"
              >
                Cancel
              </button>
              <button className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const CommonDownloadmediaPopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6">
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2 py-4">
              <p className="text-[#7A7A7A] text-[12px]">
                show newly downloaded media from this chat in your device's
                gallery?{" "}
              </p>
            </div>

            <div className="flex items-center space-x-6 mb-4">
              <input
                type="radio"
                id="option1"
                value="option1"
                checked={selectedOption === "option1"}
                onChange={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option1"
                className="ml-2   text-[13px] text-[#575556]"
              >
                Default (Yes)
              </label>
            </div>
            <div className="flex items-center space-x-6 mb-4">
              <input
                type="radio"
                id="option2"
                value="option2"
                checked={selectedOption === "option2"}
                onChange={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option2"
                className="ml-2   text-[13px] text-[#575556]"
              >
                Yes
              </label>
            </div>
            <div className="flex items-center space-x-6 mb-4">
              <input
                type="radio"
                id="option3"
                value="option3"
                checked={selectedOption === "option3"}
                onChange={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option3"
                className="ml-2   text-[13px] text-[#575556]"
              >
                No
              </label>
            </div>

            <div className="flex py-3 justify-center gap-5">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]"
              >
                Cancel
              </button>
              <button className="text-[13px] py-2 px-[4rem] text-white rounded-md bg-red-600">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonCameraPopup = (props) => {
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[94%] md:w-[91%] lg:w-[60%]  my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" p-6 flex md:h-[27rem] lg:h-[30rem]">
            <div className="flex justify-start items-end">
              <div className="relative">
                <div className="w-[12rem] md:w-[24rem] h-[30rem]">
                  <img
                    className="h-[92%] w-[170px] md:h-full md:w-full object-cover"
                    src={welcomeimage}
                    alt="WelcomeImage"
                  />
                </div>
              </div>
            </div>
            <div className="self-end right-[1rem] md:right-0 relative bottom-[11rem] md:bottom-[9rem]">
              <div className="flex justify-center flex-col">
                <p className="text-[14px] md:text-[22px] text-[#000000DB] whitespace-nowrap">
                  <span className="text-[#D63838] font-bold text-[14px] md:text-[22px]">
                    Download
                  </span>{" "}
                  Our App <br /> to connect{" "}
                </p>

                <p className="text-[#000000] text-[14px] lg:text-[22px] text-start ">
                  using{" "}
                  <span className="text-[#000000] text-[14px] md:text-[22px] text-start font-bold">
                    video call
                  </span>{" "}
                </p>
              </div>
              <div className="flex gap-3 md:gap-10 mt-[1.5rem]">
                <a
                  target="_blank"
                  href={RouteSegments.staticLinkForAppDownload.android}
                >
                  {" "}
                  <img
                    className="w-[8rem] md:h-[2.6rem] h-[2rem]"
                    src={playstoreicon}
                    alt="ApptoreIcon"
                  />
                </a>
                <a
                  href={RouteSegments.staticLinkForAppDownload.ios}
                  target="_blank"
                >
                  <img
                    className="w-[8rem] h-[2rem] md:h-[2.6rem]"
                    src={googleicon}
                    alt="PlayStoreIcon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonMutePopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("all time");
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  const { onClickTrack } = useAnalytics();

  const cancelClick = () => {
    onClickTrack(EventName.chatThreeDotMuteCancelClick);
    props.close();
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const onMute = () => {
    dispatch(
      toggleMuteConversations({
        conversation_id: props.conversationId,
        receiver_id: props.receiverId,
        mute_duration: selectedOption,
        operation: "mute",
      })
    );
    onClickTrack(EventName.chatThreeDotMuteOKClick, {
      [EventKeys.reciverId]: props.receiverId,
      [EventKeys.conversationId]: props.conversationId,
      [EventKeys.muteDuration]: props.selectedOption,
    });
    props.close();
    props.onMute(true);
  };

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6">
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2 space-x-0 md:space-x-4 lg:space-x-8">
              <p className="text-[#1A1A1A] font-bold text-[18px] pl-0 md:pl-[1rem] lg:pl-[2rem]">
                Mute notifications
              </p>
              <p className="text-[#7A7A7A] text-[12px] pb-2">
                The other participant will not see that you <br /> have muted
                this chat.
              </p>
            </div>
            <div className="pl-0 md:pl-[1rem] lg:pl-[2rem]">
              <div className="flex items-center space-x-6 mb-4">
                <input
                  type="radio"
                  id="option1"
                  value="1 day"
                  checked={selectedOption === "1 day"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
                />
                <label
                  htmlFor="option1"
                  className="ml-2  text-[12px] text-[#575556]"
                >
                  1-day
                </label>
              </div>

              <div className="flex items-center  space-x-6 mb-4">
                <input
                  type="radio"
                  id="option2"
                  value="7 days"
                  checked={selectedOption === "7 days"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4  accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
                />
                <label
                  htmlFor="option2"
                  className="ml-2 text-[12px] text-[#575556]"
                >
                  1-week
                </label>
              </div>
              <div className="flex items-center mb-4 space-x-6">
                <input
                  type="radio"
                  id="option3"
                  value="all time"
                  checked={selectedOption === "all time"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4  accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
                />
                <label
                  htmlFor="option2"
                  className="ml-2 text-[12px] text-[#575556]"
                >
                  Always
                </label>
              </div>
            </div>

            <div className="flex py-3 justify-center gap-5">
              <button
                onClick={() => {
                  cancelClick();
                }}
                className="text-[13px] py-1.5 px-[3rem] ext-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
                Cancel
              </button>
              <button
                onClick={onMute}
                className="text-[13px] py-1.5 px-[4rem] text-[#FFFFFF] rounded-[4px] font-bold bg-[#D10A11]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// Block
const CommonBlockPopup = ({ userData, props, receiverId, close, name }) => {
  const [selectedOption, setSelectedOption] = useState(false);
  const { onClickTrack } = useAnalytics();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleOptionChange = (event) => {
    setSelectedOption((prev) => !prev);
    onClickTrack(EventName.chatThreeDotBlockReportContactRadioClick);
  };

  const cancelClick = () => {
    onClickTrack(EventName.chatThreeDotBlockCancelClick);
    close();
  };

  const blockProfile = () => {
    dispatch(
      toggleProfileBlock({
        profileId: receiverId,
        isBlocked: false,
      })
    );
    if (selectedOption) {
      navigate(RouteSegments.HELP_AND_FAQ(), {
        state: { isfrom: "report", senderId: userData?.profileId, receiverId },
      });
      onClickTrack(EventName.chatThreeDotBlockReport_BlockButtonClick, {
        [EventKeys.reciverId]: receiverId,
        [EventKeys.senderId]: userData?.profileId,
      });
    }
    if (!selectedOption) {
      onClickTrack(EventName.chatThreeDotBlockButtonClick, {
        [EventKeys.reciverId]: receiverId,
        [EventKeys.senderId]: userData?.profileId,
      });
    }
    close();
  };

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-bold text-[18px]">{`Block ${name}?`}</p>
              <p className="text-[#7A7A7A] text-[13px] pb-2">
                Blocked contacts cannot call or send you messages.This <br />
                match will not be notified
              </p>
            </div>

            <div className="flex items-center space-x-6 mb-4">
              <input
                type="radio"
                id="option1"
                value="option1"
                checked={selectedOption}
                onClick={handleOptionChange}
                className="form-radio h-4 w-4 mb-[2rem] lg:mb-3 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option1"
                className="ml-2 font-Poppins-Regular  font-bold text-[12px] text-[#575556]"
              >
                Report contact
                <p className="text-[#7A7A7A] font-normal text-[12px]">
                  The last 5 messages will be forwarded to Kalyan Matrimony
                </p>
              </label>
            </div>
            <div className="flex py-4 justify-center gap-5">
              <button
                onClick={() => {
                  cancelClick();
                }}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
                Cancel
              </button>
              <button
                onClick={() => blockProfile(selectedOption)}
                className="text-[13px] font-bold text-[#FFFFFF] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem]  rounded-[4px] border border-[#D10A11] bg-[#D10A11]"
              >
                {selectedOption ? "Report & Block" : "Block"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonUnBlockPopup = (props) => {
  const dispatch = useDispatch();
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const unBlockProfile = () => {
    dispatch(
      toggleProfileBlock({
        profileId: props.receiverId,
        isBlocked: true,
      })
    );
    props.close();
  };

  return (
    <div className="flex justify-center bg-opacityblack75 items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[36%] modal-container md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-bold text-[18px]">
                Profile Status
              </p>
              <p className="text-[#7A7A7A] pl-1 text-[13px] pt-2 pb-2">
                {`${props?.name} was blocked by you. Do you wish to unblock now?`}
              </p>
            </div>
            <div className="flex pt-3 pb-4 justify-center gap-5">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] rounded-[4px] border text-[#7A7A7A] border-[#707070]"
              >
                Cancel
              </button>
              <button
                className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#FFFFFF] font-bold rounded-[4px] bg-red-600"
                onClick={unBlockProfile}
              >
                Unblock
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ApplyEditPreferencePopup = (props) => {
  const { onClickTrack } = useAnalytics();
  return (
    <div className="flex justify-center bg-opacityblack items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[100%] md:w-[55%] lg:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-8 flex-auto">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] text-[15px] pb-2 text-center">
                Set this as your Partner Expectations?
              </p>
            </div>

            <div className="flex pt-4 justify-center gap-5">
              <button
                onClick={() => {
                  props.close();
                  onClickTrack(
                    EventName.filterBasicSetAsPartnerExpectationNoButton
                  );
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070] text-[#575556]"
              >
                No
              </button>
              <button
                className="text-[13px] py-2 px-[3rem] text-white rounded-md bg-red-600"
                onClick={() => {
                  props.onClick();
                  onClickTrack(
                    EventName.filterBasicSetAsPartnerExpectationYesButton
                  );
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonReportPopup = ({ close, title, onReportClick }) => {
  const [selectedOption, setSelectedOption] = useState(false);
  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleOptionChange = (event) => {
    setSelectedOption((prev) => !prev);
    onClickTrack(EventName.chatThreeDotReportBlockContactRadioClick);
  };

  const handleReportClick = (selectedOption) => {
    if (selectedOption) {
      onClickTrack(EventName.chatThreeDotReport_ReportAndBlockButtonClick);
      onReportClick();
    } else {
      onClickTrack(EventName.chatThreeDotReportButtonClick);
      onReportClick();
    }
  };

  const onReportCancel = () => {
    onClickTrack(EventName.chatThreeDotReportCancelClick);
    close();
  };

  console.log({ selectedOption });

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%] md:w-[55%] lg:w-[35%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={close}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className=" font-Poppins-Bold text-[#1A1A1A] text-[18px]">
                {title}
              </p>
              <p className=" font-Poppins-Regular text-[#7A7A7A] text-[12px] pb-4">
                The last 5 messages from this profile will be seen by our
                customer care executive for verification. This profile will not
                be notified
              </p>
            </div>

            <div className="flex items-center space-x-6 mb-4">
              <input
                type="radio"
                id="option1"
                value="option1"
                checked={selectedOption}
                onClick={handleOptionChange}
                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
              />
              <label
                htmlFor="option1"
                className="ml-2 font-Poppins-Regular  text-[12px] text-[#7A7A7A]"
              >
                Block contact and delete chat
              </label>
            </div>

            <div className="flex py-3 justify-center gap-5">
              <button
                onClick={onReportCancel}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
                Cancel
              </button>
              <button
                onClick={() => handleReportClick(selectedOption)}
                className=" whitespace-nowrap text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#FFFFFF] rounded-[4px] font-bold bg-[#D10A11]"
              >
                {selectedOption ? "Report & Block" : "Report"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonMessagePopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[96%] md:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-8 w-8 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full h-[42rem] bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="flex flex-col lg:flex-row lg:space-x-4">
              <div className="flex-shrink-0">
                <img src={DPimage} className="w-16 h-16" alt="" />
              </div>
              <div>
                <p className="text-[#4C5B71] text-[18px]">Jasmine Thompson</p>
                <p className="text-[#8292A8] text-[14px]">
                  25 yrs, 4'7", Pillal, B.Sc IT,
                </p>
                <p className="text-[#8292A8] text-[14px]">
                  Software Developer, Bengaluru
                </p>
              </div>
            </div>
            <div className="mt-[1rem] space-y-4">
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option1"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option1"
                  className="text-[12px] text-[#575556] px-2"
                >
                  We are interested in your profile and would like to proceed
                  further
                </label>
              </div>
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option2"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option2"
                  className="text-[12px] text-[#575556] px-2"
                >
                  We think you will be a good match for our Son/Daughter. Let us
                  know how we can take this ahead
                </label>
              </div>
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option3"
                  value="option3"
                  checked={selectedOption === "option3"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option3"
                  className="text-[12px] text-[#575556] px-2"
                >
                  Very happy to know you. I would like to take this forward and
                  connect with you.
                </label>
              </div>
              <div className="flex items-center w-full h-16 px-6 bg-gray-200 border border-gray-300 rounded-md">
                <input
                  type="radio"
                  id="option4"
                  value="option4"
                  checked={selectedOption === "option4"}
                  onChange={handleOptionChange}
                  className="form-radio h-4 w-4 accent-[#D10A11] mb-0.5 mr-2 mx-4 cursor-pointer"
                />
                <label
                  for="option4"
                  className="text-[12px] text-[#575556] px-2"
                >
                  We seem to have a lot of shared interests. would like to know
                  you better.
                </label>
              </div>
            </div>
            <div className="flex py-5 justify-center">
              <p className="text-[#D10A11] text-[18px] underline">
                Buy a pack to Send Your Own Message
              </p>
            </div>

            <div className="flex-col mt-2">
              <div className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <label className="mx-2 font-semibold text-[20px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                  Request Parent's Number
                </label>
              </div>

              <div className="flex items-center mt-4">
                <input type="checkbox" className="mr-2" />
                <label className="mx-2 font-semibold text-[20px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                  Request Photo
                </label>
              </div>
            </div>

            <div className="flex py-5 justify-left">
              <p className="text-[#D10A11] text-[14px] underline">
                Report an abuse
              </p>
            </div>

            <div className="flex  justify-center ">
              <button className="w-[22rem] h-[3rem] bg-[#D10A11] rounded-lg">
                <p className="text-[#ffffff] text-[20px] font-bold">Send</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonDangerPopup = ({ close, content, icon }) => {
  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[100%] lg:w-[35%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2 text-center py-4">
              <div className="flex flex-col items-center justify-center">
                <img
                  src={icon ? icon : DangerIcon}
                  className="w-12 h-12"
                  alt="Danger Icon"
                />
              </div>
              <div className="py-3">
                <p className="text-[#000000] text-[18px]">{content}</p>
              </div>
            </div>

            <div className="flex justify-center gap-5">
              <button
                onClick={close}
                className="text-[13px] py-2 px-8 text-white font-bold rounded-md bg-red-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FileSizeWarningPopup = (props) => {
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2 text-center py-4">
              <div className="flex flex-col items-center justify-center">
                <img src={DangerIcon} className="w-12 h-12" alt="Danger Icon" />
              </div>
              <div className="py-3 w-[80%] mx-auto xl:px-5">
                <p
                  className="text-[#000000] text-[13px] lg:text-[12px] xl:text-[14px]"
                  dangerouslySetInnerHTML={{ __html: props.content }}
                />
              </div>
            </div>

            <div className="flex justify-center gap-5">
              {/* <button onClick={props.close} className='text-[13px] py-2 px-8 text-white font-bold rounded-md bg-red-600'>
                Close
              </button> */}
              <button
                onClick={props.onAdd}
                className="text-[13px] py-2 px-8 text-white font-bold rounded-md bg-red-600"
              >
                Add Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonParentPopup = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setSearchFilter, setShowClearFilterPopup } =
    useContext(NavBarContext);
  const dispath = useDispatch();

  const onEditClick = () => {
    // setShowClearFilterPopup(false)
    if (location.pathname === RouteSegments.FILTER_LITE) {
      props.close();
      return;
    }
    if (location?.state?.isFrom == "basicAdvanceSearch") {
      navigate(RouteSegments.FILTER_LITE, {
        state: {
          tab: location.state?.isBasicSearch ? 1 : 2,
          isFrom: "editSearch",
          formData: location.state?.formData,
          searchState: location.state,
        },
      });
      setSearchFilter(false);
    } else {
      setSearchFilter(true);
      navigate(RouteSegments.FILTER_LITE, { state: { isFrom: "editfilter" } });
    }
    props.close();
  };

  const onClearClick = () => {
    setShowClearFilterPopup(false);
    if (location?.state?.isFrom == "basicAdvanceSearch") {
    } else {
      dispath(applyFilter([]));
    }
    setSearchFilter(true);
    props.close();
    sessionStorage.removeItem(Constants.loginLocalStorageKeys.filterState);
    dispath(closeCurrentMatch());
    navigate(props.navPath ?? RouteSegments.MATCH_LITE);
  };

  return (
    <div className="flex justify-center bg-opacityblack items-center md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      {/* <div className='font-Poppins-Regular flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000] outline-none focus:outline-none'> */}

      <div className="relative w-[100%] md:w-[55%] lg:w-[35%] my-6 mx-6">
        <div className="lg:block md:block">
          <button
            className="bg-transparent z-40 border-0 text-black  absolute top-[-8px] md:top-[-8px] lg:top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative lg:mt-[0.5rem] py-6 lg:py-0 mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2 text-center lg:py-2">
              <div className="lg:py-3">
                <p className="lg:text-[#1A1A1A] text-[#787878] text-[14px] font-segoe-ui lg:font-poppins lg:text-[16px]">
                  Do You Want To Edit Or Clear All Filters?
                </p>
              </div>
            </div>

            <div className="flex justify-center gap-5 pt-9 lg:py-4">
              <button
                onClick={onEditClick}
                className="text-[13px] py-2 px-[3rem] text-[#575556] rounded-md border lg:border-[#707070] border-[#707070]"
              >
                Edit
              </button>
              <button
                onClick={() => {
                  onClearClick();
                }}
                className="text-[13px] py-2 px-[2rem] text-white font-bold rounded-md bg-red-600"
              >
                Clear All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonValidPopup = (props) => {
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const afterOneSpaceOnDot = (str) => {
    if (str && typeof str === "string") {
      return str
        .split(".")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(". ");
    }
  };

  return (
    <div className="flex justify-center bg-opacityblack75 items-end md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%] md:w-[50%] lg:w-[35%] md:my-6 md:mx-6 modal-container"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-poppins font-bold text-[20px] text-start">
                {props.title}
              </p>
              <p className="text-[#7A7A7A] font-poppins text-[16px] pt-3 pb-1 text-start">
                {afterOneSpaceOnDot(props.content)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonProfilePopup = (props) => {
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const afterOneSpaceOnDot = (str) => {
    if (str && typeof str === "string") {
      return str
        .split(".")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(". ");
    }
  };

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%] md:w-[45%] lg:w-[31%] md:my-6 md:mx-6 modal-container"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className="text-[20px] text-center text-[#D10A11] ml-2 font-semibold align-middle ">
                {props.title}
              </p>
              <div className="relative p-4  flex-auto">
                <p className="text-[#7A7A7A] text-center font-poppins text-[16px] pt-3 pb-1 ">
                  {afterOneSpaceOnDot(props.content)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const YesOrNoComponent = ({ value = true, onChange }) => {
  return (
    <>
      <CommonCheckButton
        id={"bulkRequestYes"}
        name={"bulkRequestYes"}
        selected={value}
        checktext={"Yes"}
        value={"yes"}
        onChange={onChange}
      />
      <CommonCheckButton
        id={"bulkRequestNo"}
        name={"bulkRequestNo"}
        selected={!value}
        checktext={"No"}
        value={"no"}
        onChange={onChange}
      />
    </>
  );
};

export const BulkRequestPopup = ({
  close,
  title,
  content,
  onSend,
  message,
  imageSrc,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 678 });
  const handleOutsideClick = (e) => {
    if (!e.target.closest(".modal-container")) {
      // Close the modal if clicked outside the modal content
      // onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const [choiceStatus, setChoiceStatus] = useState(true);
  return (
    <div
      onClick={(e) => {
        if (e.target.id == "model-wrapper" && isMobile) {
          close();
        }
      }}
      id="model-wrapper"
      className="flex md:justify-center bg-opacityblack   justify-end items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div
        id="model-content"
        className="relative md:w-auto md:my-6 md:mx-6 modal-container"
      >
        <div className="hidden md:block">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={close}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0  rounded-tl-[40px] rounded-tr-[40px] md:rounded-lg shadow-lg relative md:mt-[0.5rem] md:mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div
            className={
              imageSrc
                ? "flex items-center pb-1 pt-5 justify-center  border-[#D10A11] mx-2   "
                : "flex items-center pb-1 pt-[3rem] justify-center  border-[#D10A11] mx-2   "
            }
          >
            <h3
              className={
                imageSrc
                  ? "text-[20px] text-[#D10A11] ml-2 font-semibold align-middle"
                  : "text-[#D10A11] align-middle font-bold font-Poppins text-[18px]"
              }
            >
              {title}
            </h3>
          </div>
          <div
            className={
              imageSrc
                ? "relative p-6 flex-auto"
                : "relative px-6 mb-[3rem] flex-auto"
            }
          >
            <div className="flex flex-col justify-center items-center w-[88vw] md:w-[35vw]">
              <p
                className={
                  imageSrc
                    ? "text-[16px] font-semibold whitespace-nowrap text-[#575556]"
                    : "text-[#777777] font-regular pt-2 font-Poppins text-[14px] whitespace-nowrap"
                }
              >
                {content}
              </p>
              {imageSrc ? (
                <img className="w-16 h-16 mt-2" alt="" src={imageSrc} />
              ) : (
                <img
                  className="w-10 h-10 mt-[1.2rem]"
                  src={SuccessIcon}
                  alt=""
                />
              )}
              {message && (
                <>
                  <p className="text-[14px] text-[#575556] text-center pb-2 pt-4">
                    {message}
                  </p>
                  <div className="flex w-full justify-center space-x-4">
                    <YesOrNoComponent
                      value={choiceStatus}
                      onChange={() => setChoiceStatus((prev) => !prev)}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => onSend(choiceStatus)}
                    className="mt-4 flex items-center justify-center lg:w-[2.5rem] lg:h-[1rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[4rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[14px]"
                  >
                    Send Request
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PaymentBannerPopup = ({ close }) => {
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={close}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              Hi
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadPhotoPopuplite = (props) => {
  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();
  const { setMenuSelect } = useContext(NavBarContext);
  const location = useLocation();
  const { onClickTrack } = useAnalytics()

  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const handleCrop = (file) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Height and Width must be more than 100px",
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Image size should not be greater than 10mb",
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");
      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
  };

  const handleOriginalImageUpload = async (file) => {
    // let imageData = new FormData();
    // imageData.append("image", file);
    // let compressedImageData = await axios.post(
    //   `${config.cms_api}/compressImage`,
    //   imageData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );

    // if (compressedImageData.data.code === 200) {

    // await fetch(compressedImageData?.data?.base64,{
    //     mode: 'no-cors'
    //   })
    // .then((res) => res.blob())
    // .then((myBlob) => {
    //   console.log(myBlob,"myBlob");
    //    const compressedImageUrl = new File([myBlob], compressedImageData?.data?.compressed_image, {type: myBlob.type});
    //    originalImageUpload(compressedImageUrl)
    //   });

    // }
    //  else {
    //   alert("Please upload file less than 10 mb");
    //   props.close();
    // }
    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);
      },
    });
  };

  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        setShowCrop(true);
      } else {
        props.close();
      }
    } else {
      props.close();
    }
  };

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let notificationId = null;
      const loginUrl = sessionStorage.getItem(
        Constants.loginLocalStorageKeys.loginUrl
      );

      if (loginUrl?.includes("photo") || props?.isFromInitialPopup) {
        notificationId = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.notificationId
        );
        sessionStorage.removeItem(
          Constants.loginLocalStorageKeys.notificationId
        );
      }
      console.log(notificationId, "notificationId");
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      if (notificationId) {
        fileData.append("notificationId", notificationId);
      }
      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setshowSuccessPopup(true);
          if (props.isFrom == "contentcard") {
            props?.callBackReload();
          }
          props?.close();
          props?.callBack();
        } else {
          props.close();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.close();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  // const logoutUsignAPI = async () => {
  //   let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });

  //   if (data.status === 200) {
  //     setShowLoader(false);
  //     logout();
  //   }
  // };

  // const signout = () => {
  //   setShowLoader(true)
  //   logoutUsignAPI()
  // };

  useEffect(() => {}, [props?.menu]);

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
    props.close();
  };

  const closePopup = () => {
    // Add any additional logic to close the popup
    props.close();
  };

  console.log(props?.count, "props?.countprops?.count");

  // function openInstagramPopup() {
  //   const screenWidth = window.screen.width;
  //   const screenHeight = window.screen.height;
  //   const popupWidth = 500;
  //   const popupHeight = 800;

  //   const left = (screenWidth - popupWidth) / 2;
  //   const top = (screenHeight - popupHeight) / 2;

  //   window.open(
  //     "https://www.instagram.com/accounts/login/?force_authentication=1&enable_fb_login=1&next=%2Foauth%2Fauthorize%3Fclient_id%3D266374166312609%26response_type%3Dcode%26scope%3Duser_profile%2Cuser_media%26redirect_uri%3Dhttps%3A%2F%2Fv2-uat.kalyanmatrimony.com%2Fdashboard%26logger_id%3D7e472074-54f8-438c-838d-d9ed56a3a5c0",
  //     "InstagramPopup",
  //     `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
  //   );
  // }

  // function openFacebookPopup() {
  //   const screenWidth = window.screen.width;
  //   const screenHeight = window.screen.height;
  //   const popupWidth = 500;
  //   const popupHeight = 800;

  //   const left = (screenWidth - popupWidth) / 2;
  //   const top = (screenHeight - popupHeight) / 2;

  //   window.open(
  //     "https://www.facebook.com/login.php?skip_api_login=1&api_key=606506484678232&kid_direct",
  //     "FacebookPopup",
  //     `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
  //   );
  // }
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const helplineClickTrack = () => {
    onClickTrack(EventName.web_addphotopopup_callexecutive)
  }

  const renderhelpline = () => {
    return <div className="border rounded-lg flex space-x-2 px-2.5 py-2 mx-auto w-fit items-center">
      <img alt="call" src={Images.PhoneIcon} className="w-6 h-6 mx-2" />
      <div className=" space-y-1.5">
        <p className="text-center text-black-500 font-Poppins-SemiBold text-[12px] md:text-[14px]">Call Us!</p>
        <p className="text-center text-[#D10A11] font-Poppins-Bold text-[12px] md:text-[14px]">{Constants.helpLinePhNo}</p>
      </div>
    </div>
  }

  return (
    <>
      <>
        {notification.show ? (
          <NotificationPopup
            close={closeNotification}
            title={notification.title}
            content={notification.message}
          />
        ) : null}
        {showCrop && (
          // <ImageCroperComponent
          //   close={() => setShowCrop(false)}
          //   image={currentimage}
          //   imgType={originalImage.type}
          //   callBackCroper={cropedImage}
          // />
          <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
              if (isCropped) {
                setShowCrop(false);
              } else {
                props.close();
              }

              if (props?.callBackReload) {
                props?.callBackReload();
              }
            }}
          />
        )}

        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowSuccessPopup(false);
            }}
            title={"Success!"}
            content={"Image upload success"}
          />
        )}
        {
          !showCrop && showLoader ? (
            <Loader
              className="loaderTransperancy"
              loaderClassName="loaderTransperancySpin"
            />
          ) : props?.menu === "profileview" ? (
            <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-6">
                <div className="flex items-center py-1 justify-between border-b border-solid bg-[#D10A11] border-gray-300 rounded-t ">
                  <h3 className="text-[16px] text-white ml-4 font-semibold align-middle">
                    {props.title}
                  </h3>
                  <button
                    className=" bg-transparent border-0 text-black float-right pr-4"
                    onClick={() => {
                      props.close();
                    }}
                  >
                    <img className="h-5 w-5 " src={CloseIcon} alt="" />
                  </button>
                </div>
                {compressing ? (
                  <div className="p-6 bg-white flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                      Uploading...
                    </div>
                  </div>
                ) : isUploading ? (
                  <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                      Uploading...
                    </div>
                  </div>
                ) : (
                  <div className="bg-white relative p-6 flex-auto ">
                    <div className="flex flex-col justify-center items-center w-[60vw] md:w-[40vw] ">
                      {props.title === "WHO VIEWED MY PROFILE" ? (
                        <p className="text-[20px] font-semibold mb-4 text-center">
                          {props.count}
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="text-[18px] ">
                        People who viewed you might look for your Photo.{" "}
                      </p>
                      <p className="text-[18px] ">
                        Please add now to increase the response to your Profile
                      </p>

                      <div
                        className="mt-5"
                        onClick={() => {
                          if (props.isFrom == "sign out") {
                            setMenuSelect("2");
                            navigate(
                              RouteHelper.getEditProfileUrl(
                                EditProfileMenu[2].urlPath
                              ),
                              { state: { tab: "3" } }
                            );
                            closePopup();
                          }
                        }}
                      >
                        <label>
                          <a className="bg-[#D10A11] font-semibold text-white py-2 px-3 text-[13px] cursor-pointer">
                            Add Your Photo Now
                          </a>

                          <input
                            style={{ display: "none" }}
                            type="file"
                            onChange={(event) => {
                              handleCrop(event.target.files[0]);
                            }}
                            accept="image/png, image/jpg, image/jpeg"
                            data-max-size="10485760"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="font-Poppins-Regular flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000] outline-none focus:outline-none">
                  <div className="relative w-auto md:my-6  mt-[6rem] mb-[2rem] mx-6">
                <div className="">
                  <button
                        className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-2px]"
                    onClick={() => {
                      props.close();
                    }}
                  >
                    <img
                      className="h-5 w-5 "
                      src={CloseIcon}
                      alt=""
                      loading="lazy"
                    />
                  </button>
                </div>

                    <div className="lg:max-w-[70vw] border-0 rounded-lg shadow-lg relative lg:px-12 mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none xl:w-[50vw]">
                  <div className="flex items-center py-1 mt-5 justify-center mx-2 ">
                    <h3 className="text-[23px] text-[#1A1A1A] ml-2 font-Poppins font-bold align-middle">
                      {props.title == "Add Photo" ? "Add Photo" : "logout"}
                    </h3>
                  </div>
                  {isUploading ? (
                    <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                      <img
                        alt="loader"
                        src={LoaderGif}
                        className={`w-16 h-16`}
                      />
                      <div className="text-[16px] font-semibold my-2">
                        Uploading...
                      </div>
                    </div>
                  ) : (
                          <div className="relative p-4 md:p-6 flex-auto ">
                      <div className="flex flex-col justify-center items-center whitespace-nowrap">
                        {props?.menu === "profileview" ? (
                          ""
                        ) : (
                          <span className="xl:text-[16px] md:text-[16px] text-[11px] text-[#1A1A1A] font-regular font-Poppins font-bold">
                            Profiles with photo, get more responses!
                          </span>
                        )}
                      </div>

                            <div className="grid md:grid-cols-2 md:gap-4">
                              <div>
                                <div className="grid grid-cols-2 gap-4 px-3 py-4 md:py-9">
                                  {/* <lable
                        className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl"
                        style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}
                        onClick={() => {
                          if (props.isFrom == "sign out") {
                            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                            closePopup();
                          }

                        }}
                      >
                          <img src={addphotoIcon} className="w-[3rem] h-[3rem]" />
                          <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#D10A11] shadow-md rounded-xl cursor-pointer">
                            <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                            <a className="cursor-pointer">
                              Add Photo Now!
                            </a>
                            {props.isFrom === "sign out"
                            ? <></> :
                            <input
                              style={{ display: "none" }}
                              type="file"
                              onChange={(event) => {
                                handleCrop(event.target.files[0]);
                              }}
                              accept="image/png, image/jpg, image/jpeg"
                              data-max-size="10485760"
                            />
                          }
                            </p>
                          </div>
                      </lable> */}

                                  <label>
                                    <div
                                      className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer"
                                      style={{
                                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                      }}
                                      onClick={() => {
                                        if (props.isFrom == "sign out") {
                                          setMenuSelect("2");
                                          navigate(
                                            RouteHelper.getEditProfileUrl(
                                              EditProfileMenu[2].urlPath
                                            ),
                                            { state: { tab: "3" } }
                                          );
                                          closePopup();
                                        }
                                      }}
                                    >
                                      <img
                                        src={addphotoIcon}
                                        className="w-[3rem] h-[3rem]"
                                        loading="lazy"
                                        alt=""
                                      />
                                      <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#D10A11] shadow-md rounded-xl cursor-pointer">
                                        <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                                          <a className="text-[#D10A11] font-bold  cursor-pointer">
                                            Add Photo Now
                                          </a>

                                          {props.isFrom === "sign out" ? (
                                            <></>
                                          ) : (
                                            <input
                                              style={{ display: "none" }}
                                              type="file"
                                              onChange={(event) => {
                                                handleCrop(event.target.files[0]);
                                              }}
                                              accept="image/png, image/jpg, image/jpeg"
                                              data-max-size="10485760"
                                            />
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </label>

                                  <div
                                    className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer"
                                    style={{
                                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        Constants.kalyanSupportWhatsappUrl(
                                          userData?.smProfileId
                                        )
                                      )
                                    }
                                  >
                                    <img
                                      src={whatsappIcon}
                                      className="w-[3rem] h-[3rem]"
                                      loading="lazy"
                                      alt=""
                                    />
                                    <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#49C858] shadow-md rounded-xl cursor-pointer">
                                      <p className="text-[#000000] font-medium font-Poppins text-[8px] cursor-pointer">
                                        Add Photo via WhatsApp!
                                      </p>
                                    </div>
                                  </div>

                                  {/* <div className="flex flex-col  space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} onClick={() => openFacebookPopup()}>
                        <img src={facebookIcon} className="w-[2.5rem] h-[2.5rem]" loading="lazy" alt=""/>
                        <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#1976D2] shadow-md rounded-xl cursor-pointer">
                          <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                            <a href="https://www.facebook.com/login.php?skip_api_login=1&api_key=606506484678232&kid_direct"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Upload From facebook</a>
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}  onClick={() => openInstagramPopup()}>
                        <img src={instragramIcon} className="w-[2.5rem] h-[2.5rem]" loading="lazy" alt=""/>
                        <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#7F4BAD] shadow-md rounded-xl cursor-pointer">
                          <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                            <a href="https://www.instagram.com/accounts/login/?force_authentication=1&enable_fb_login=1&next=%2Foauth%2Fauthorize%3Fclient_id%3D266374166312609%26response_type%3Dcode%26scope%3Duser_profile%2Cuser_media%26redirect_uri%3Dhttps%3A%2F%2Fv2-uat.kalyanmatrimony.com%2Fdashboard%26logger_id%3D7e472074-54f8-438c-838d-d9ed56a3a5c0"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                              Upload From Instagram
                            </a>
                          </p>
                        </div>
                      </div> */}
                                </div>
                                <SocialMediaUpload
                                  closeModal={() => props.close()}
                                  callBackImage={(e) => props?.callBackReload(e)}
                                />
                              </div>

                              <div className="md:flex">
                                <div className="hidden md:block relative mr-2 pt-3">
                                  <div className="flex justify-center items-center absolute  h-full right-[-2px]">
                                    <span className="text-[#D10A11] font-bold bg-white py-2 z-[1]">OR</span>
                                  </div>
                                  <Divider type="vertical" style={{ height: "100%" }} plain={false} />
                                </div>
                                <div className="block md:hidden mr-2">
                                  <Divider style={{ width: "100%" }}>
                                    <div className="flex justify-center items-center text-[#D10A11] font-bold">
                                      OR
                                    </div>
                                  </Divider>
                                </div>
                                <div className="flex flex-col justify-center items-center space-y-4 mb-4">
                                  <div className="flex justify-center items-center">
                                    <img alt="call" src={Images.PhoneIcon} className="w-8 h-8 mx-2" />
                                    <p className="text-center font-Poppins font-bold text-[13px] md:text-[16px]">Call our expert to know what is a good photo and help you add it to your profile.</p>
                                  </div>

                                  <div className="hidden lg:block">{renderhelpline()}</div>
                                  <a onClick={helplineClickTrack} className="block lg:hidden" href={`tel:${Constants.helpLinePhNo}`}>
                                    {renderhelpline()}
                                  </a>
                                </div>
                              </div>
                            </div>

                      {props.isFrom == "sign out" && (
                        <div className="flex justify-center mb-2">
                          <button
                            onClick={props?.signout}
                            className=" flex items-center justify-center lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                          >
                            Sign out
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
          //   <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          //   <div className="relative w-auto my-6 mx-6">
          //     <div className="">
          //       <button
          //         className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
          //         onClick={() => {
          //           props.close();
          //         }}
          //       >
          //         <img
          //           className="h-5 w-5 "
          //           src={CloseIcon}
          //         />
          //       </button>
          //     </div>

          //     <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          //       <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
          //         <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
          //           {props.title}
          //         </h3>
          //       </div>
          //       {compressing ?
          //         <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
          //           <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
          //           <div className="text-[16px] font-semibold my-2">
          //             Uploading...
          //           </div>
          //         </div>
          //         :
          //         isUploading
          //           ? <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
          //             <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
          //             <div className="text-[16px] font-semibold my-2">
          //               Uploading...
          //             </div>
          //           </div>
          //           : <div className="relative p-6 flex-auto ">

          //             <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
          //               {props?.menu === "profileview" ? ""
          //                 : <span className="text-[16px] font-semibold">
          //                   Profiles with photo, gets more response
          //                 </span>}
          //               {props?.menu === "profileview" ? "" : <img
          //                 className="w-16 h-16 mt-2"
          //                 alt=""
          //                 src={UploadPhotoIcon}
          //               />}
          //               <label>

          //                 <a className="text-[#D10A11] font-bold underline cursor-pointer"
          //                   onClick={() => {
          //                     if (props.isFrom == "sign out") {
          //                       navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
          //                       closePopup();
          //                     }

          //                   }}
          //                 >
          //                   Add Photo Now
          //                 </a>

          //                 {props.isFrom === "sign out"
          //                   ? <></> :
          //                   <input
          //                     style={{ display: "none" }}
          //                     type="file"
          //                     onChange={(event) => {
          //                       handleCrop(event.target.files[0]);
          //                     }}
          //                     accept="image/png, image/jpg, image/jpeg"
          //                     data-max-size="10485760"
          //                   />
          //                 }
          //               </label>
          //             </div>
          //           </div>}

          //       {/* Social Login */}
          //       {props?.menu === "profileview" ? "" :

          //         <SocialMediaUpload closeModal={() => props.close()} callBackImage={(e) => props?.callBackImage(e)} />

          //       }
          //       {props.isFrom == "sign out" &&
          //         <div className="flex justify-center mb-2">
          //           <button
          //             onClick={signout}
          //             className=" flex items-center justify-center lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
          //           >
          //             Sign out
          //           </button>
          //         </div>
          //       }
          //     </div>
          //   </div>
          // </div>
        }
      </>
    </>
  );
};

const UploadPhotoPopup = (props) => {
  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();

  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const handleCrop = (file) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Height and Width must be more than 100px",
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Image size should not be greater than 10mb",
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");
      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
  };

  const handleOriginalImageUpload = async (file) => {
    // let imageData = new FormData();
    // imageData.append("image", file);
    // let compressedImageData = await axios.post(
    //   `${config.cms_api}/compressImage`,
    //   imageData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );

    // if (compressedImageData.data.code === 200) {

    // await fetch(compressedImageData?.data?.base64,{
    //     mode: 'no-cors'
    //   })
    // .then((res) => res.blob())
    // .then((myBlob) => {
    //   console.log(myBlob,"myBlob");
    //    const compressedImageUrl = new File([myBlob], compressedImageData?.data?.compressed_image, {type: myBlob.type});
    //    originalImageUpload(compressedImageUrl)
    //   });

    // }
    //  else {
    //   alert("Please upload file less than 10 mb");
    //   props.close();
    // }
    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);
      },
    });
  };

  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        setShowCrop(true);
      } else {
        props.close();
      }
    } else {
      props.close();
    }
  };

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setshowSuccessPopup(true);
          if (props.isFrom == "contentcard") {
            props?.callBackReload();
          }
          props?.close();
          props?.callBack();
        } else {
          props.close();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.close();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  const logoutUsignAPI = async () => {
    let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        loginFrom: localStorage.getItem(
          Constants.loginLocalStorageKeys.loginDevice
        ),
        familyloginid:
          localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId) ??
          undefined,
      },
    });

    if (data.status === 200) {
      setShowLoader(false);
      logout();
    }
  };

  const signout = () => {
    setShowLoader(true);
    logoutUsignAPI();
  };

  useEffect(() => {}, [props?.menu]);

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" });
    props.close();
  };

  const closePopup = () => {
    // Add any additional logic to close the popup
    props.close();
  };

  console.log(props?.count, "props?.countprops?.count");

  return (
    <>
      <>
        {notification.show ? (
          <NotificationPopup
            close={closeNotification}
            title={notification.title}
            content={notification.message}
          />
        ) : null}
        {showCrop && (
          // <ImageCroperComponent
          //   close={() => setShowCrop(false)}
          //   image={currentimage}
          //   imgType={originalImage.type}
          //   callBackCroper={cropedImage}
          // />
          <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
              if (isCropped) {
                setShowCrop(false);
              } else {
                props.close();
              }
            }}
          />
        )}

        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowSuccessPopup(false);
            }}
            title={"Success!"}
            content={"Image upload success"}
          />
        )}
        {!showCrop && showLoader ? (
          <Loader
            className="loaderTransperancy"
            loaderClassName="loaderTransperancySpin"
          />
        ) : props?.menu === "profileview" ? (
          <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-6">
              <div className="flex items-center py-1 justify-between border-b border-solid bg-[#D10A11] border-gray-300 rounded-t ">
                <h3 className="text-[16px] text-white ml-4 font-semibold align-middle">
                  {props.title}
                </h3>
                <button
                  className=" bg-transparent border-0 text-black float-right pr-4"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="h-5 w-5 " src={CloseIcon} alt="" />
                </button>
              </div>
              {compressing ? (
                <div className="p-6 bg-white flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                  <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                  <div className="text-[16px] font-semibold my-2">
                    Uploading...
                  </div>
                </div>
              ) : isUploading ? (
                <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                  <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                  <div className="text-[16px] font-semibold my-2">
                    Uploading...
                  </div>
                </div>
              ) : (
                <div className="bg-white relative p-6 flex-auto ">
                  <div className="flex flex-col justify-center items-center w-[60vw] md:w-[40vw] ">
                    {props.title === "WHO VIEWED MY PROFILE" ? (
                      <p className="text-[20px] font-semibold mb-4 text-center">
                        {props.count}
                      </p>
                    ) : (
                      ""
                    )}
                    <p className="text-[18px] ">
                      People who viewed you might look for your Photo.{" "}
                    </p>
                    <p className="text-[18px] ">
                      Please add now to increase the response to your Profile
                    </p>

                    <div className="mt-5">
                      <label>
                        <a
                          className="bg-[#D10A11] font-semibold text-white py-2 px-3 text-[13px] cursor-pointer"
                          onClick={() => {
                            if (props.isFrom == "sign out") {
                              navigate(
                                RouteHelper.getEditProfileUrl(
                                  EditProfileMenu[2].urlPath
                                ),
                                { state: { tab: "3" } }
                              );
                              closePopup();
                            }
                          }}
                        >
                          Add Your Photo Now
                        </a>

                        <input
                          style={{ display: "none" }}
                          type="file"
                          onChange={(event) => {
                            handleCrop(event.target.files[0]);
                          }}
                          accept="image/png, image/jpg, image/jpeg"
                          data-max-size="10485760"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-6">
              <div className="">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="h-5 w-5 " src={CloseIcon} alt="" />
                </button>
              </div>

              <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full md:w-[25rem] lg:w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center py-1 justify-center border-[#D10A11] mx-2  rounded-t ">
                  <h3 className="text-[20px] text-[#D10A11] ml-2 mt-2 font-semibold align-middle">
                    {props.title}
                  </h3>
                </div>
                {compressing ? (
                  <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                      Uploading...
                    </div>
                  </div>
                ) : isUploading ? (
                  <div className="p-6 flex-auto flex items-center flex-col w-[60vw] md:w-[30vw] justify-center">
                    <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                    <div className="text-[16px] font-semibold my-2">
                      Uploading...
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="relative p-6 flex-auto flex justify-center">
                      <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
                        {props?.menu === "profileview" ||
                        props?.menu == "newphoto" ? (
                          ""
                        ) : props?.menu == "changephoto" ? (
                          <span className="text-[16px] font-semibold">
                            Add a new photo to change your profile picture
                          </span>
                        ) : (
                          <span className="text-[16px] font-semibold">
                            Profiles with photo, gets more response
                          </span>
                        )}
                        {props?.menu === "profileview" || props?.isV3User ? (
                          ""
                        ) : (
                          <img
                            className="w-16 h-16 mt-2"
                            alt=""
                            src={UploadPhotoIcon}
                          />
                        )}
                        <label>
                          {/* {props.menu=="mobileverification"?
                  <a className="text-[#D10A11] font-bold underline cursor-pointer" 
                      onClick={() => {
                      if (props.isFrom == "sign out") {
                        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                        }
                      }}
                      >
                    Browse
                  </a>
                  : */}
                          {props?.isV3User ? (
                            <></>
                          ) : (
                            <a
                              className="text-[#D10A11] font-bold underline cursor-pointer"
                              onClick={() => {
                                if (props.isFrom == "sign out") {
                                  navigate(
                                    RouteHelper.getEditProfileUrl(
                                      EditProfileMenu[2].urlPath
                                    ),
                                    { state: { tab: "3" } }
                                  );
                                  closePopup();
                                }
                              }}
                            >
                              Add Photo Now
                            </a>
                          )}
                          {/* // <button type="submit" className="w-full flex items-center justify-center  h-[1rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1rem]  whitespace-nowrap rounded-[5px] font-bold   py-4 text-[12px] ">
                  //   Browse 
                  // </button>
                    } */}
                          {props.isFrom === "sign out" ? (
                            <></>
                          ) : (
                            <input
                              style={{ display: "none" }}
                              type="file"
                              onChange={(event) => {
                                handleCrop(event.target.files[0]);
                              }}
                              accept="image/png, image/jpg, image/jpeg"
                              data-max-size="10485760"
                            />
                          )}
                        </label>
                      </div>
                    </div>
                    {/* Social Login */}
                    {props?.menu === "profileview" ? (
                      ""
                    ) : (
                      <SocialMediaUpload
                        closeModal={() => props.close()}
                        callBackImage={(e) => props?.callBackImage(e)}
                        handleCrop={handleCrop}
                        menu={props?.menu}
                      />
                    )}
                  </>
                )}

                {props.isFrom == "sign out" && (
                  <div className="flex justify-center mb-2">
                    <button
                      onClick={signout}
                      className=" flex items-center justify-center lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                    >
                      Log out
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

const ViewPhotoPasswordPopup = (props) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      errorMsg: "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Please Enter photo password")
        .min(8, "Password must contain at least 8 characters")
        .max(16, "Password must less than 16 characters"),
    }),

    onSubmit: (values) => {
      props.callBackApply(values.password);
    },
  });

  useEffect(() => {
    //console.log(props?.errorMsg,"props?.errorMsg");

    if (props?.errorMsg) {
      // props?.errorMsg
    } else {
      return;
    }
  }, [props?.errorMsg, formik.values]);

  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6 max-w-3xl ">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-1px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="w-[90vw] md:w-[57vw] lg:w-[35vw] border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              View Photo
            </h3>
          </div>
          <p className="mx-5 mt-5 text-[13px] text-center text-[#575556]">
            This photo is password protected, Please send a request to view
            photo or enter password to view photo
          </p>
          <div className="flex items-center justify-center relative p-6 flex-auto ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col items-center justify-center"
            >
              <label className="my-2">
                <input
                  name={"password"}
                  id={"password"}
                  type={showHidePassword ? "text" : "password"}
                  placeholder="Enter photo password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className="w-[72vw] md:w-[41vw] lg:w-[30vw] p-2.5 my-1  text-sm text-gray-900  rounded-[3px] border border-[#F18181]"
                />
                <div className="absolute top-[3rem] md:top-[3rem] lg:top-[2.8rem] right-[3rem] md:right-[4.5rem] lg:right-[3rem] 2xl:right-[3.7rem]">
                  <img
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                    src={showHidePassword ? EyeOpenIcon : EyeIcon}
                    className="w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                    alt=""
                  />
                </div>
                <div className="h-2">
                  {props?.errorMsg && formik.touched.password ? (
                    <p className="text-[red] text-[12px]">{props?.errorMsg}</p>
                  ) : (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.password}
                    </p>
                  )}
                </div>
              </label>

              <button
                type="submit"
                className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotificationPopup = (props) => {
  return (
    <div
      onClick={() => {
        props.close();
      }}
      className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img alt="" className="h-5 w-5 " src={CloseIcon} />
          </button>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative p-6 flex-auto">
            <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
              <span
                className="text-black text-[16px] font-semibold"
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuspendedPopup = (props) => {
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} />
          </button>
        </div>
        <div className="w-[35rem] border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle text-center">
              {props.title}
            </h3>
          </div>
          <div className="relative p-6 flex-auto">
            <div className="flex flex-col justify-center items-center ">
              <span className="text-[16px] font-semibold font-segeo">
                {props.content}
              </span>
              <p className="mt-4 text-center">
                Please contact our{" "}
                <a
                  href={RouteSegments.HELP_AND_FAQ()}
                  className=" text-[#D10A11] font-semibold font-segeo cursor-pointer underline"
                  target="_blank"
                >
                  help desk
                </a>{" "}
                For more details
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginVia = (props) => {
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="w-[35rem] border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle text-center">
              Status
            </h3>
          </div>
          <div className="relative p-6 flex-auto">
            <div className="flex flex-col justify-center items-center ">
              <span className="text-[16px] font-semibold font-segeo">
                {props.content}
              </span>
              <p className="mt-4 text-center">{props?.status}</p>
            </div>
            <button
              onClick={() => {
                props.close();
              }}
              type="button"
              className=" items-center flex float-right my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.3rem] md:px-[1.3rem] lg:px-[2rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterPopup = (props) => {
  return (
    <div className=" flex justify-center bg-opacityblack  items-center overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none lg:hidden">
      <div className="relative  my-6 mx-6  w-[90%] md:w-[65%]">
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full outline-none focus:outline-none">
          <div className="relative flex-auto">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

const RegSuccessPopup = (props) => {
  return (
    <div className=" flex justify-center items-center overflow-x-hidden overflow-y-auto absolute inset-x-0 top-0 z-50 outline-none focus:outline-none">
      <div
        className={`${
          props.menu === "policy"
            ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
            : props?.menu === "View Horoscope Match"
            ? "relative w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
            ? "relative w-[25rem] text-center my-6 mx-6 "
            : props.menu === "report"
            ? "relative  my-6 mx-6  w-[30rem]"
            : "relative  my-6 mx-6 w-[90%] md:w-[80%]  lg:w-[38%]"
        } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            {/* <img className="h-5 w-5 " src={CloseIcon} alt=""/> */}
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-none mt-2 mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div
            className={
              props?.menu === "policypopup"
                ? "relative px-6 py-6 pr-[0.1rem]  flex-auto"
                : "relative p-[4.5rem]  flex-auto"
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageSuccessPopup = (props) => {
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative p-6 flex-auto">
            <div className="flex flex-col justify-center items-center w-[60vw] md:w-[30vw] ">
              <span className="text-[16px] font-semibold whitespace-nowrap">
                {props.content}
              </span>
              <img className="w-16 h-16 mt-2" alt="" src={props?.image} />
            </div>
            <div className="flex justify-end pt-3">
              <button
                onClick={() => props.close()}
                className="mr-4 text-[#7A7A7A] border border-[#9F9F9F] text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px] cursor-pointer font-segeo"
              >
                Close
              </button>
              <button
                onClick={() => props.goto()}
                className="text-[#FFFFFF] bg-[#D10A11] text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px]  md:w-8 lg:w-24 cursor-pointer font-segeo"
              >
                Go to Photos
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReligionMigrationPopup = ({ close, profileDate }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { loginDetail } = useContext(LoginContext);
  console.log(profileDate, "profileDate");

  const dispatch = useDispatch();
  const { casteList, domainList, subcasteList } = useSelector((state) => ({
    casteList: state?.Home?.cList?.data?.data?.castes ?? [],
    subcasteList: state?.Home?.scList?.data?.data?.subcastes ?? [],
    domainList: state?.Home?.denominationList?.data?.domain ?? [],
  }));
  const [showOtherCasteInput, setShowOtherCasteInput] = useState(false);

  useEffect(() => {
    console.log(casteList, "casteList");
  }, [casteList]);
  const [inputCasteValue, setInputCasteValue] = useState("");
  const [matchedCastes, setMatchedCastes] = useState([]);
  const [showOtherSubCasteInput, setShowOtherSubCasteInput] = useState(false);

  const [inputSubCasteValue, setInputSubCasteValue] = useState("");
  const [matchedSubCastes, setMatchedSubCastes] = useState([]);

  console.log(inputSubCasteValue, "inputSubCasteValue");
  const isLoading = casteList.length == 0 && domainList.length == 0;

  useEffect(() => {
    dispatch(getDenominationList());
  }, []);

  const rearrangeOthersToLast = (list) => {
    const othersOption = list.find(
      (option) => option.name.toLowerCase() === "others"
    );
    const filteredList = list.filter(
      (option) => option.name.toLowerCase() !== "others"
    );
    return othersOption ? [...filteredList, othersOption] : filteredList;
  };

  useEffect(() => {
    if (profileDate) {
      dispatch(
        registerCasteList(
          profileDate?.mothertongueid,
          profileDate?.religion ? profileDate?.religion[0]?.newid : ""
        )
      );
      dispatch(
        registerSubCasteList(
          profileDate?.caste ? profileDate?.caste[0]?.newid : ""
        )
      );
    }
  }, [dispatch, profileDate]);

  // getsubcaste
  // const apiCallSubCast = async (castId, initial=false) => {
  //   let { statusCode, data } = await GET(
  //     `${config.api.API_URL}${EndPoints.getSubCastUrl(castId)}`
  //   );
  //   //console.log("subcaste", data);
  //   if (statusCode === 200) {
  //     // setDropDownData(data.data, type);
  //     if (data.status == "Success") {
  //       setSubCastDropDown(data.data.subcastes);

  //       if(initial === false){
  //         const temp = [];
  //         data?.data?.subcastes?.forEach(caste => {
  //           if(formik?.values?.subcaste?.includes(caste.id)){
  //             temp.push(caste.id)
  //           }
  //         });
  //         formik.setFieldValue("subcaste", temp);
  //       }
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };

  const handleCasteSelection = (selectedCaste) => {
    setInputCasteValue(selectedCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("casteId", selectedCaste.id);
    dispatch(registerSubCasteList(selectedCaste.id));
  };

  const handleSubCasteSelection = (selectedSubCaste) => {
    setInputCasteValue(selectedSubCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("subcaste", selectedSubCaste.id); // Update formik values with the selected caste's ID
  };

  const handleSubCasteInputChange = (e) => {
    const SubCasteValue = e.target.value;
    const SubCasteInput = SubCasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
      .trim()
      .replace(/\s+/g, " ");
    setInputSubCasteValue(SubCasteInput);
    formik.setFieldValue("otherSubcaste", SubCasteInput); // Ensure correct field name here

    console.log("SubCasteValue", SubCasteInput);
    if (SubCasteValue.trim() === "") {
      formik.setFieldValue("othersubcaste", "");

      setMatchedSubCastes([]);
    } else {
      const filteredSubCastes = subcasteList?.filter((subcaste) =>
        subcaste.name.toLowerCase().includes(SubCasteValue.toLowerCase())
      );
      setMatchedSubCastes(filteredSubCastes);
    }
  };
  console.log({ profileDate });

  const handleCasteInputChange = (e) => {
    const CasteValue = e.target.value;
    const CasteInput = CasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
      .trim()
      .replace(/\s+/g, " ");
    setInputCasteValue(CasteInput);
    formik.setFieldValue("otherCaste", CasteValue);

    console.log(CasteValue, "CasteValue");

    if (CasteValue.trim() === "") {
      // Clear matched castes when input value is empty
      setMatchedCastes([]);
    } else {
      // Filter caste names starting with the input value
      const filteredCastes = casteList?.filter((caste) =>
        caste.name.toLowerCase().includes(CasteValue.toLowerCase())
      );

      // Check if "Others" is present in the filtered castes
      const othersCaste = filteredCastes.find(
        (caste) => caste.name.toLowerCase() === "others"
      );

      // Remove "Others" from the filtered castes if present
      const filteredCastesWithoutOthers = othersCaste
        ? filteredCastes.filter(
            (caste) => caste.name.toLowerCase() !== "others"
          )
        : filteredCastes;

      // Sort the filtered castes alphabetically
      const sortedFilteredCastes = filteredCastesWithoutOthers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Add "Others" as the last option if present
      const finalFilteredCastes = othersCaste
        ? [...sortedFilteredCastes, othersCaste]
        : sortedFilteredCastes;

      setMatchedCastes(finalFilteredCastes);
    }
  };

  const casteOptions = useMemo(
    () => rearrangeOthersToLast(casteList),
    [casteList]
  );

  const subcasteOptions = useMemo(
    () => rearrangeOthersToLast(subcasteList),
    [subcasteList]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      religionId: profileDate?.religion ? profileDate?.religion[0]?.newid : "",
      denomination: profileDate?.domainId,
      casteId: profileDate?.caste ? profileDate?.caste[0]?.newid : "",
      subcaste: profileDate?.subcaste ? profileDate?.subcaste[0]?.newid : "",
      otherSubcaste: profileDate?.othersubcaste ?? "",
      otherCaste: profileDate?.otherCaste ?? "",
    },
    validationSchema: Yup.object().shape({
      denomination: Yup.string().when("religionId", (religionId, schema) => {
        console.log("value business : ", religionId);
        if (religionId.includes(Constants.christialId)) {
          return schema.required("Please Select Denomination");
        } else {
          return schema;
        }
      }),
      casteId: Yup.string()
        .notOneOf([Constants.casteNotKnowId], "Please select caste")
        .required("Please select caste"),
    }),
    onSubmit: (values) => {
      console.log("ReligionMigrationPopup", values);
      apiCallSubmit(values);
    },
  });

  console.log(profileDate?.subcaste, "profileDate?.subcaste");
  const apiCallSubmit = async (values) => {
    console.log((values, "values1"));
    const request = {
      newsubcasteId: values?.subcaste,
      newcasteId: values?.casteId,
      newdomainId: values?.denomination,
      newreligionId: values?.religionId,
      newothercaste: values.otherCaste,
      newothersubcaste: values.otherSubcaste,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.savecastmigrationUrl()}`,
      request
    );

    if (statusCode == 200) {
      window.location.reload();
      close();
    }
  };

  console.log(profileDate, "profileDate?.otherSubcaste");
  const isChristian = profileDate?.religion
    ? profileDate?.religion[0]?.newid == Constants.christialId
    : "";
  const subcasteAvailable = subcasteList?.length !== 0;

  const subcasteAvailablename = subcasteList[0]?.name === "Others";

  console.log(subcasteAvailablename, "subcasteAvailable");
  const selectedDenomination = useMemo(() => {
    return domainList
      ?.filter((option) => option.id === formik.values.denomination)
      ?.at(0);
  }, [domainList, formik.values.denomination]);

  const selectedCaste = useMemo(() => {
    return casteList
      ?.filter((option) => option.id === formik.values.casteId)
      ?.at(0);
  }, [casteList, formik.values.casteId]);

  const selectedSubCaste = useMemo(() => {
    return subcasteList
      ?.filter((option) => option.id === formik.values.subcaste)
      ?.at(0);
  }, [subcasteList, formik.values.subcaste]);

  const apiCallConfirm = async () => {
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.confirmcastmigrationUrl()}`,
      {
        isconfirmed: "Yes",
      }
    );

    if (statusCode == 200) {
      close();
    }
  };

  const onConfirm = () => {
    if (formik.values.casteId == Constants.casteNotKnowId) {
      setIsEdit(true);
    } else {
      formik.submitForm();
    }
    close();
  };

  const onCancel = () => {
    setIsEdit(false);
  };

  console.log(formik.values.subcaste, "formik.values.subcaste[0]?.name");
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          {/* <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt=""/>
          </button> */}
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              Please Confirm Religious Details
            </h3>
          </div>
          {isLoading ? (
            <div className="w-full h-32 flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div className="relative p-6 flex-auto">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col items-center  ">
                  <div className="flex flex-col w-full  space-y-2">
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        Religious
                      </label>
                      {isEdit ? (
                        <select
                          id="religionId"
                          name="religionId"
                          disabled={true}
                          value={formik.values.religionId}
                          onChange={formik.handleChange}
                          className="  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-2 px-1 md:py-2 md:px-2  w-full block cursor-not-allowed "
                        >
                          <option value={formik.values.religionId}>
                            {profileDate?.religion
                              ? profileDate?.religion[0]?.newName
                              : ""}
                          </option>
                        </select>
                      ) : (
                        <label className="font-segeo text-[14px] font-semibold">
                          {profileDate?.religion
                            ? profileDate?.religion[0]?.newName
                            : "Not Specified"}
                        </label>
                      )}
                    </div>
                    {isChristian && (
                      <div className="flex flex-col">
                        <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                          Denomination
                        </label>
                        {isEdit ? (
                          <RawSelectInput
                            name="denomination"
                            title="Denomination"
                            value={selectedDenomination}
                            onChange={(selected, ev) => {
                              formik.setFieldValue("denomination", selected.id);
                            }}
                            error={formik.errors.denomination}
                            options={[
                              {
                                id: null,
                                name: "Select Denomination",
                                isDisabled: true,
                              },
                              ...domainList,
                            ]}
                            required={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                          />
                        ) : (
                          <label className="font-segeo text-[14px] font-semibold">
                            {profileDate?.domainName}
                          </label>
                        )}
                      </div>
                    )}
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        Caste
                      </label>
                      {isEdit ? (
                        <RawSelectInput
                          name="casteId"
                          title="Caste"
                          value={selectedCaste}
                          onChange={(selected, ev) => {
                            formik.setFieldValue("casteId", selected.id);
                            dispatch(registerSubCasteList(selected.id));
                            setShowOtherCasteInput(
                              selected.id === Constants.casteOtherId
                            );
                          }}
                          error={formik.errors.casteId}
                          options={[
                            {
                              id: null,
                              name: "Select Caste",
                              isDisabled: true,
                            },
                            ...casteOptions,
                          ]}
                          required={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          getOptionClassName={(option) =>
                            option.name.toLowerCase() === "others"
                              ? "dropdown-option-others"
                              : ""
                          }
                        />
                      ) : (
                        <label className="font-segeo text-[14px] font-semibold">
                          {profileDate?.caste
                            ? profileDate?.caste[0]?.newName
                            : "Not Specified"}
                        </label>
                      )}
                    </div>
                    {formik.values.casteId == "433" && isEdit && (
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            Other Caste
                          </p>
                        </div>
                        <div className="col-span-7 mt-2 md:mt-5 pl-2 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="othercaste"
                              autoComplete="off"
                              placeholder="Enter Your Caste"
                              className="form-control shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2 w-full block"
                              value={inputCasteValue}
                              // value={formik.values.otherCaste}
                              // onChange={formik.handleChange}
                              // onChange={handleCasteInputChange}
                              onChange={(e) => {
                                formik.setFieldValue("otherCaste", e.id);
                                handleCasteInputChange(e);
                                // formik.handleChange();
                              }}
                              error={formik.errors.otherCaste}
                            />
                            {inputCasteValue.trim() !== "" &&
                              matchedCastes?.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedCastes.map((caste) => (
                                      <li
                                        key={caste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
                                        onClick={() =>
                                          handleCasteSelection(caste)
                                        }
                                      >
                                        {caste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            {formik.errors?.otherCaste &&
                              formik?.touched?.otherCaste && (
                                <p className="text-[red] text-[12px]">
                                  {formik.errors?.otherCaste}
                                </p>
                              )}{" "}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        Subcaste
                      </label>
                      {isEdit ? (
                        subcasteAvailable ? (
                          <RawSelectInput
                            name="subcaste"
                            title="SubCaste"
                            value={selectedSubCaste}
                            onChange={(selected, ev) => {
                              formik.setFieldValue("subcaste", selected.id);
                              setShowOtherSubCasteInput(
                                selected.name === "Others"
                              );
                            }}
                            error={formik.errors.subcaste}
                            options={[
                              {
                                id: null,
                                name: "Select SubCaste",
                                isDisabled: true,
                              },
                              ...subcasteOptions,
                            ]}
                            required={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            getOptionClassName={(option) =>
                              option.name.toLowerCase() === "others"
                                ? "dropdown-option-others"
                                : ""
                            }
                          />
                        ) : (
                          <>
                            <input
                              type="text"
                              value={formik.values.otherSubcaste}
                              onChange={formik.handleChange}
                              name="otherSubcaste"
                              className="border border-[#8F8F8F] text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold"
                            />
                          </>
                        )
                      ) : (
                        <label className="font-segeo text-[14px] font-semibold">
                          {profileDate?.subcaste
                            ? profileDate?.subcaste[0]?.newName
                            : "Not Specified"}
                        </label>
                      )}
                    </div>
                    {showOtherSubCasteInput && (
                      // ||formik.values?.subcaste?.name == "Others"
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            Other SubCaste
                          </p>
                        </div>
                        <div className="col-span-7 mt-2 md:mt-5 pl-2 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="otherSubcaste"
                              autoComplete="off"
                              placeholder="Enter Your Sub-Caste"
                              className="form-control  shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                              value={inputSubCasteValue}
                              // onChange={handleSubCasteInputChange}
                              onChange={(e) => {
                                formik.setFieldValue("otherSubcaste", e.id);
                                handleSubCasteInputChange(e);
                                // formik.handleChange();
                              }}
                            />

                            {inputSubCasteValue.trim() !== "" &&
                              matchedSubCastes.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 w-[15rem] overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedSubCastes.map((subcaste) => (
                                      <li
                                        key={subcaste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F]cursor-pointer"
                                        onClick={() =>
                                          handleSubCasteSelection(subcaste)
                                        }
                                      >
                                        {subcaste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {isEdit ? (
                    <div className="flex w-full space-x-8 mt-8">
                      <button
                        disabled={formik.isSubmitting}
                        type="submit"
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-full`}
                      >
                        {formik.isSubmitting ? "Saving..." : "Save"}
                      </button>
                    </div>
                  ) : (
                    <div className="flex w-full space-x-8 mt-8 px-4">
                      <button
                        disabled={formik.isSubmitting}
                        onClick={onConfirm}
                        type="button"
                        className={`text-[14px] font-segeo font-semibold text-[#7A7A7A] border border-[#9F9F9F] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        Confirm
                      </button>
                      <button
                        onClick={() => setIsEdit(true)}
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
// const ReligionMigrationLitePopup = ({ close, profileDate }) => {
//   const [isEdit, setIsEdit] = useState(false);
//   const { loginDetail } = useContext(LoginContext);
//   console.log(profileDate,'profileDate');

//   const dispatch = useDispatch();
//   const { casteList, domainList, subcasteList } = useSelector((state) => ({
//     casteList: state.Home?.cList?.data?.data?.castes ?? [] ,
//     subcasteList: state?.Home?.scList?.data?.data?.subcastes ?? [],
//     domainList: state?.Home?.denominationList?.data?.domain ?? [],
//   }));

//   const [showOtherCasteInput, setShowOtherCasteInput] = useState(false);

//   const [inputCasteValue, setInputCasteValue] = useState("");
//   const [matchedCastes, setMatchedCastes] = useState([]);
//   const [showOtherSubCasteInput, setShowOtherSubCasteInput] = useState(false);

//   const [inputSubCasteValue, setInputSubCasteValue] = useState("");
//   const [matchedSubCastes, setMatchedSubCastes] = useState([]);

//   const isLoading = casteList.length == 0 && domainList.length == 0;
//  const [showProfiledata,setShowProfileData] =useState([])

//  console.log(domainList,"showProfiledata");
//   useEffect(() => {
//     dispatch(getDenominationList());
//   }, []);

//   useEffect(() => {
//     const fetchRecommendedMatches = async () => {
//         try {
//             const request = {
//                 "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == "" ? null : localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
//                 "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
//                 "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
//                 "recomendationpageno": "1",
//                 "status": "new",
//             };

//             const response = await axios.post(
//                 `${config.api.API_URL}${EndPoints.matchesliteapp()}`,
//                 request
//             );

//             if (response.data.status === "Success") {

//               setShowProfileData(response.data.data);
//             } else {
//                 // setError(response);
//             }
//         } catch (error) {
//             // setError(error);
//         }
//     };

//     fetchRecommendedMatches();
// }, []); // Add dependencies as needed

//   const rearrangeOthersToLast = (list) => {
//     const othersOption = list.find(
//       (option) => option.name.toLowerCase() === "others"
//     );
//     const filteredList = list.filter(
//       (option) => option.name.toLowerCase() !== "others"
//     );
//     return othersOption ? [...filteredList, othersOption] : filteredList;
//   };

//   useEffect(() => {
//     if (profileDate) {
//       dispatch(
//         registerCasteList(
//           profileDate?.mothertongueid,
//           profileDate?.religionId ? profileDate?.religionId : ""
//         )
//       );
//       dispatch(
//         registerSubCasteList(profileDate?.caste ? profileDate?.caste[0] : "")
//       );
//     }
//   }, [dispatch, profileDate]);

//   // getsubcaste
//   // const apiCallSubCast = async (castId, initial=false) => {
//   //   let { statusCode, data } = await GET(
//   //     `${config.api.API_URL}${EndPoints.getSubCastUrl(castId)}`
//   //   );
//   //   //console.log("subcaste", data);
//   //   if (statusCode === 200) {
//   //     // setDropDownData(data.data, type);
//   //     if (data.status == "Success") {
//   //       setSubCastDropDown(data.data.subcastes);

//   //       if(initial === false){
//   //         const temp = [];
//   //         data?.data?.subcastes?.forEach(caste => {
//   //           if(formik?.values?.subcaste?.includes(caste.id)){
//   //             temp.push(caste.id)
//   //           }
//   //         });
//   //         formik.setFieldValue("subcaste", temp);
//   //       }
//   //     }
//   //   } else if (statusCode === 401) {
//   //     logout();
//   //   }
//   // };

//   const handleCasteSelection = (selectedCaste) => {
//     setInputCasteValue(selectedCaste.name); // Update inputCasteValue with the selected caste's name
//     formik.setFieldValue("casteId", selectedCaste.id); // Update formik values with the selected caste's ID
//   };

//   const handleSubCasteSelection = (selectedSubCaste) => {
//     setInputCasteValue(selectedSubCaste.name); // Update inputCasteValue with the selected caste's name
//     formik.setFieldValue("subcaste", selectedSubCaste.id); // Update formik values with the selected caste's ID
//   };

//   const handleSubCasteInputChange = (e) => {
//     const SubCasteValue = e.target.value;
//     const SubCasteInput = SubCasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
//       .trim()
//       .replace(/\s+/g, " ");
//     setInputSubCasteValue(SubCasteInput);
//     formik.setFieldValue("otherSubcaste", SubCasteInput); // Ensure correct field name here

//     console.log("SubCasteValue", SubCasteInput);
//     if (SubCasteValue.trim() === "") {
//       setMatchedSubCastes([]);
//     } else {
//       const filteredSubCastes = subcasteList?.filter((subcaste) =>
//         subcaste.name.toLowerCase().startsWith(SubCasteValue.toLowerCase())
//       );
//       setMatchedSubCastes(filteredSubCastes);
//     }
//   };
//   console.log({ showProfiledata });

//   const handleCasteInputChange = (e) => {
//     const CasteValue = e.target.value;
//     const CasteInput = CasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
//       .trim()
//       .replace(/\s+/g, " ");
//     setInputCasteValue(CasteInput);
//     formik.setFieldValue("otherCaste", CasteValue);

//     console.log(CasteValue, "CasteValue");

//     if (CasteValue.trim() === "") {
//       // Clear matched castes when input value is empty
//       setMatchedCastes([]);
//     } else {
//       // Filter caste names starting with the input value
//       const filteredCastes = casteList?.filter((caste) =>
//         caste.name.toLowerCase().startsWith(CasteValue.toLowerCase())
//       );

//       // Check if "Others" is present in the filtered castes
//       const othersCaste = filteredCastes.find(
//         (caste) => caste.name.toLowerCase() === "others"
//       );

//       // Remove "Others" from the filtered castes if present
//       const filteredCastesWithoutOthers = othersCaste
//         ? filteredCastes.filter(
//             (caste) => caste.name.toLowerCase() !== "others"
//           )
//         : filteredCastes;

//       // Sort the filtered castes alphabetically
//       const sortedFilteredCastes = filteredCastesWithoutOthers.sort((a, b) =>
//         a.name.localeCompare(b.name)
//       );

//       // Add "Others" as the last option if present
//       const finalFilteredCastes = othersCaste
//         ? [...sortedFilteredCastes, othersCaste]
//         : sortedFilteredCastes;

//       setMatchedCastes(finalFilteredCastes);
//     }
//   };

//   const casteOptions = useMemo(
//     () => rearrangeOthersToLast(casteList),
//     [casteList]
//   );

//   const subcasteOptions = useMemo(
//     () => rearrangeOthersToLast(subcasteList),
//     [subcasteList]
//   );

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       religionId: showProfiledata?.religiondata ? showProfiledata?.religiondata[0]?.newid : "",
//       denomination: showProfiledata?.domainid,
//       casteId: showProfiledata?.castedata ? showProfiledata?.castedata[0]?.newid : "",
//       subcaste: showProfiledata?.subcastedata ? showProfiledata?.subcastedata[0]?.newid : "",
//       otherSubcaste: showProfiledata?.othersubcaste ?? "",
//       otherCaste: showProfiledata?.othercaste ?? "",
//     },
//     validationSchema: Yup.object().shape({
//       denomination: Yup.string().when("religionId", (religionId, schema) => {
//         console.log("value business : ", religionId);
//         if (religionId.includes(Constants.christialId)) {
//           return schema.required("Please Select Denomination");
//         } else {
//           return schema;
//         }
//       }),
//       casteId: Yup.string()
//         .notOneOf([Constants.casteNotKnowId], "Please select caste")
//         .required("Please select caste"),
//     }),
//     onSubmit: (values) => {
//       console.log("ReligionMigrationPopup", values);
//       apiCallSubmit(values);
//     },
//   });

//   const apiCallSubmit = async (values) => {
//     console.log(values,"values11");
//     const request = {
//       newsubcasteId: values?.subcaste ? values?.subcaste : undefined,
//       newcasteId: values?.casteId,
//       newdomainId: values?.denomination,
//       newreligionId: values?.religionId,
//       newothercaste: values.otherCaste,
//       newothersubcaste: values.otherSubcaste ,
//     };

//     let { statusCode, data } = await POST(
//       `${config.api.API_URL}${EndPoints.savecastmigrationUrl()}`,
//       request
//     );

//     if (statusCode == 200) {
//       // window.location.reload();
//       close();
//     }
//   };

//   console.log(showProfiledata,"showProfiledata?.otherSubcaste");
//   const isChristian = showProfiledata?.religiondata
//     ? showProfiledata?.religiondata[0]?.newid == Constants.christialId
//     : "";
//   const subcasteAvailable = subcasteList?.length !== 0;

//   const subcasteAvailablename = subcasteList[0]?.name === "Others"

//   console.log(subcasteAvailablename,"subcasteAvailable");
//   const selectedDenomination = useMemo(() => {
//     return domainList
//       ?.filter((option) => option.id === formik.values.denomination)
//       ?.at(0);
//   }, [domainList, formik.values.denomination]);

//   const selectedCaste = useMemo(() => {
//     return casteList
//       ?.filter((option) => option.id === formik.values.casteId)
//       ?.at(0);
//   }, [casteList, formik.values.casteId]);

//   const selectedSubCaste = useMemo(() => {
//     return subcasteList
//       ?.filter((option) => option.id === formik.values.subcaste)
//       ?.at(0);
//   }, [subcasteList, formik.values.subcaste]);

//   const apiCallConfirm = async () => {
//     let { statusCode, data } = await POST(
//       `${config.api.API_URL}${EndPoints.confirmcastmigrationUrl()}`,
//       {
//         isconfirmed: "Yes",
//       }
//     );

//     if (statusCode == 200) {
//       close();
//     }
//   };

//   const onConfirm = () => {
//     if (formik.values.casteId == Constants.casteNotKnowId) {
//       setIsEdit(true);
//     } else {
//       formik.submitForm();
//     }
//     close();
//   };

//   const onCancel = () => {
//     setIsEdit(false);
//   };

//   console.log(formik.values.subcaste,"formik.values.subcaste[0]?.name");
//   return (
//     <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//       <div className="relative w-auto my-6 mx-6">
//         <div className="">

//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
//           <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
//             <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
//               Please Confirm Religious Details
//             </h3>
//           </div>
//           {isLoading ? (
//             <div className="w-full h-32 flex justify-center items-center">
//               <Loader />
//             </div>
//           ) : (
//             <div className="relative p-6 flex-auto">
//               <form onSubmit={formik.handleSubmit}>
//                 <div className="flex flex-col items-center  ">
//                   <div className="flex flex-col w-full  space-y-2">
//                     <div className="flex flex-col">
//                       <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                         Religious
//                       </label>
//                       {isEdit ? (
//                         <select
//                           id="religionId"
//                           name="religionId"
//                           disabled={true}
//                           value={formik.values.religionId}
//                           onChange={formik.handleChange}
//                           className="  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-2 px-1 md:py-2 md:px-2  w-full block cursor-not-allowed "
//                         >
//                           <option value={formik.values.religionId}>
//                             {showProfiledata?.religiondata
//                               ? showProfiledata?.religiondata[0]?.newName
//                               : ""}
//                           </option>
//                         </select>
//                       ) : (
//                         <label className="font-segeo text-[14px] font-semibold">
//                           {showProfiledata?.religiondata
//                             ? showProfiledata?.religiondata[0]?.newName
//                             : "Not Specified"}
//                         </label>
//                       )}
//                     </div>
//                     {isChristian && (
//                       <div className="flex flex-col">
//                         <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                           Denomination
//                         </label>
//                         {isEdit ? (
//                           <RawSelectInput
//                             name="denomination"
//                             title="Denomination"
//                             value={selectedDenomination}
//                             onChange={(selected, ev) => {
//                               formik.setFieldValue("denomination", selected.id);
//                             }}
//                             error={formik.errors.denomination}
//                             options={[
//                               {
//                                 id: null,
//                                 name: "Select Denomination",
//                                 isDisabled: true,
//                               },
//                               ...domainList,
//                             ]}
//                             required={true}
//                             getOptionLabel={(option) => option.name}
//                             getOptionValue={(option) => option.id}
//                           />
//                         ) : (
//                           <label className="font-segeo text-[14px] font-semibold">
//                             {showProfiledata?.domainname}
//                           </label>
//                         )}
//                       </div>
//                     )}
//                     <div className="flex flex-col">
//                       <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                         Caste
//                       </label>
//                       {isEdit ? (
//                         <RawSelectInput
//                           name="casteId"
//                           title="Caste"
//                           value={selectedCaste}
//                           onChange={(selected, ev) => {
//                             formik.setFieldValue("casteId", selected.id);
//                             dispatch(registerSubCasteList(selected.id));
//                             setShowOtherCasteInput(
//                               selected.id === Constants.casteOtherId
//                             );
//                           }}
//                           error={formik.errors.casteId}
//                           options={[
//                             {
//                               id: null,
//                               name: "Select Caste",
//                               isDisabled: true,
//                             },
//                             ...casteOptions,
//                           ]}
//                           required={true}
//                           getOptionLabel={(option) => option.name}
//                           getOptionValue={(option) => option.id}
//                           getOptionClassName={(option) =>
//                             option.name.toLowerCase() === "others"
//                               ? "dropdown-option-others"
//                               : ""
//                           }
//                         />
//                       ) : (
//                         <label className="font-segeo text-[14px] font-semibold">
//                           {showProfiledata?.castedata
//                             ? showProfiledata?.castedata[0]?.newName
//                             : "Not Specified"}
//                         </label>
//                       )}
//                     </div>
//                     {formik.values.casteId === "433" && showOtherCasteInput && (
//                       <>
//                         <div className="col-span-2 mt-5">
//                           <p className="font-segeo text-[14px] font-semibold text-[#575556]">
//                             Other Caste
//                           </p>
//                         </div>
//                         <div className="col-span-7 mt-2 md:mt-5 pl-2 md:pl-0">
//                           <div className="space-y-1">
//                             <input
//                               type="text"
//                               name="othercaste"
//                               autoComplete="off"
//                               placeholder="Enter Your Caste"
//                               className="form-control shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2 w-full block"
//                               value={inputCasteValue}
//                               // value={formik.values.otherCaste}
//                               // onChange={formik.handleChange}
//                               onChange={handleCasteInputChange}
//                               error={formik.errors.otherCaste}
//                             />
//                             {inputCasteValue.trim() !== "" &&
//                               matchedCastes?.length > 0 && (
//                                 <div
//                                   className="absolute bg-[#ffffff] z-10 overflow-y-auto"
//                                   style={{
//                                     boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//                                     minWidth: "90%",
//                                     maxHeight: "254px",
//                                     height: "auto",
//                                     maxHeight: "254px",
//                                   }}
//                                 >
//                                   <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
//                                     Do you Mean
//                                   </p>
//                                   <ul
//                                     ref={(ulElement) => {
//                                       // Access the ul element and calculate its height
//                                       if (ulElement) {
//                                         const ulHeight =
//                                           ulElement.getBoundingClientRect()
//                                             .height;
//                                         if (ulHeight > 254) {
//                                           ulElement.style.maxHeight = "254px";
//                                         }
//                                       }
//                                     }}
//                                   >
//                                     {matchedCastes.map((caste) => (
//                                       <li
//                                         key={caste.id}
//                                         className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
//                                         onClick={() =>
//                                           handleCasteSelection(caste)
//                                         }
//                                       >
//                                         {caste.name}
//                                       </li>
//                                     ))}
//                                   </ul>
//                                 </div>
//                               )}
//                             {formik.errors?.otherCaste &&
//                               formik?.touched?.otherCaste && (
//                                 <p className="text-[red] text-[12px]">
//                                   {formik.errors?.otherCaste}
//                                 </p>
//                               )}{" "}
//                           </div>
//                         </div>
//                       </>
//                     )}
//                     <div className="flex flex-col">
//                       <label className="font-segeo text-[14px] font-semibold text-[#575556]">
//                         Subcaste
//                       </label>
//                       {isEdit ? (
//                         subcasteAvailable ? (
//                           <RawSelectInput
//                             name="subcaste"
//                             title="SubCaste"
//                             value={selectedSubCaste}
//                             onChange={(selected, ev) => {
//                               formik.setFieldValue("subcaste", selected.id);
//                               setShowOtherSubCasteInput(
//                                 selected.name === "Others"
//                               );
//                             }}
//                             error={formik.errors.subcaste}
//                             options={[
//                               {
//                                 id: null,
//                                 name: "Select SubCaste",
//                                 isDisabled: true,
//                               },
//                               ...subcasteOptions,
//                             ]}
//                             required={true}
//                             getOptionLabel={(option) => option.name}
//                             getOptionValue={(option) => option.id}
//                             getOptionClassName={(option) =>
//                               option.name.toLowerCase() === "others"
//                                 ? "dropdown-option-others"
//                                 : ""
//                             }
//                           />
//                         ) : (
//                           <>
//                             <input
//                               type="text"
//                               value={formik.values.otherSubcaste}
//                               onChange={formik.handleChange}
//                               name="otherSubcaste"
//                               className="border border-[#8F8F8F] text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold"
//                             />

//                           </>
//                         )
//                       ) : (
//                         <label className="font-segeo text-[14px] font-semibold">
//                           {showProfiledata?.subcastedata
//                             ? showProfiledata?.subcastedata[0]?.newName
//                             : "Not Specified"}
//                         </label>
//                       )}
//                     </div>
//                     {formik.values.subcaste==="170" && showOtherSubCasteInput && (
//                       // ||formik.values?.subcaste?.name == "Others"
//                       <>
//                         <div className="col-span-2 mt-5">
//                           <p className="font-segeo text-[14px] font-semibold text-[#575556]">
//                             Other SubCaste
//                           </p>
//                         </div>
//                         <div className="col-span-7 mt-2 md:mt-5 pl-2 md:pl-0">
//                           <div className="space-y-1">
//                             <input
//                               type="text"
//                               name="otherSubcaste"
//                               autoComplete="off"
//                               placeholder="Enter Your Sub-Caste"
//                               className="form-control  shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
//                               value={inputSubCasteValue}
//                               onChange={handleSubCasteInputChange}
//                             />

//                             {inputSubCasteValue.trim() !== "" &&
//                               matchedSubCastes.length > 0 && (
//                                 <div
//                                   className="absolute bg-[#ffffff] z-10 w-[15rem] overflow-y-auto"
//                                   style={{
//                                     boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//                                     minWidth: "90%",
//                                     maxHeight: "254px",
//                                     height: "auto",
//                                     maxHeight: "254px",
//                                   }}
//                                 >
//                                   <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
//                                     Do you Mean
//                                   </p>
//                                   <ul
//                                     ref={(ulElement) => {
//                                       // Access the ul element and calculate its height
//                                       if (ulElement) {
//                                         const ulHeight =
//                                           ulElement.getBoundingClientRect()
//                                             .height;
//                                         if (ulHeight > 254) {
//                                           ulElement.style.maxHeight = "254px";
//                                         }
//                                       }
//                                     }}
//                                   >
//                                     {matchedSubCastes.map((subcaste) => (
//                                       <li
//                                         key={subcaste.id}
//                                         className="px-5 py-2 text-[15px] text-[#8F8F8F]cursor-pointer"
//                                         onClick={() =>
//                                           handleSubCasteSelection(subcaste)
//                                         }
//                                       >
//                                         {subcaste.name}
//                                       </li>
//                                     ))}
//                                   </ul>
//                                 </div>
//                               )}
//                           </div>
//                         </div>
//                       </>
//                     )}
//                   </div>
//                   {isEdit ? (
//                     <div className="flex w-full space-x-8 mt-8">
//                       <button
//                         disabled={formik.isSubmitting}
//                         type="submit"
//                         className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-full`}
//                       >
//                         {formik.isSubmitting ? "Saving..." : "Save"}
//                       </button>
//                     </div>
//                   ) : (
//                     <div className="flex w-full space-x-8 mt-8 px-4">
//                       <button
//                         disabled={formik.isSubmitting}
//                         onClick={onConfirm}
//                         type="button"
//                         className={`text-[14px] font-segeo font-semibold text-[#7A7A7A] border border-[#9F9F9F] p-1  px-3 rounded-[5px] w-[50%]`}
//                       >
//                         Confirm
//                       </button>
//                       <button
//                         onClick={() => setIsEdit(true)}
//                         className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-[50%]`}
//                       >
//                         Edit
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               </form>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
const CommonPayNowPopup = (props) => {
  const navigate = useNavigate();
  const closePopupRef = useRef();
  const { onClickTrack } = useAnalytics();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handlePayNowClick = () => {
    if (props.track) {
      props.track();
      navigate(RouteSegments.MEMBERSHIP_REGISTER());
      props.close(); // Close the popup after navigating
    }
    onClickTrack(EventName.View_Profile_All_Unpaid_Popup_Pay_Now_Button);
    navigate(RouteSegments.MEMBERSHIP_REGISTER());
    props.close(); // Close the popup after navigating
  };

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-full md:w-[50%] lg:w-[35%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className="text-[#1A1A1A] font-bold text-[16px] xl:text-[18px] pb-2">
                {props?.title}
              </p>
              <p className="text-[#7A7A7A] text-[12px] xl:text-[14px] pb-4">
                {props?.content}
              </p>
            </div>

            <div className="flex py-3 justify-center gap-5">
              <button
                onClick={handlePayNowClick}
                className="text-[13px] py-2 px-[2.5rem] text-white font-bold rounded-md bg-red-600"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const CommonPayBackPopup = (props) => {
  const navigate = useNavigate();
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handlePayNowClick = () => {
    navigate(RouteSegments.MEMBERSHIP_REGISTER());
    props.close();
  };

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[42%] lg:w-[35%] md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className="text-[#575556] font-bold text-[16px]">
                Buy a Pack to Contact Profile
              </p>
              <p className="text-[#7A7A7A] text-[12px] pb-4">
                You have already contacted this profile and awaiting
                response.Please Upgrade to send personalized messages
              </p>
            </div>

            <div className="flex py-3 justify-center gap-5">
              <button
                onClick={() => {
                  props.close();
                }}
                className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]"
              >
                Cancel
              </button>
              <button
                onClick={handlePayNowClick}
                className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const CommonConfrimPopup = (props) => {

//   const navigate = useNavigate();

//   const handlePayNowClick = () => {
//     navigate(RouteSegments.MEMBERSHIP_REGISTER());
//     props.close();
//   };

//   return (
//     <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//       <div className="relative w-[96%] md:w-[35%] my-6 mx-6">
//         <div className="">
//           <button
//             className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
//             onClick={() => {
//               props.close();
//             }}
//           >
//             <img className="h-6 w-6 " src={CloseIcon} alt="" />
//           </button>
//         </div>
//         <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative p-6 flex-auto">
//             <div className="space-y-2">
//               <p className="text-[#575556] font-bold text-[16px]">Logout</p>
//               <p className="text-[#7A7A7A] text-[12px] pb-4">
//                 Are You Sure You Want To Logout?
//               </p>
//             </div>

//             <div className="flex py-3 justify-center gap-5">
//               <button onClick={() => {
//                 props.close();
//               }} className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]">Cancel</button>
//               <button
//                 className="text-[13px] py-2 px-[2.5rem] text-white rounded-md bg-red-600" >Confirm</button>
//             </div>

//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }
// const CommonDownloadmediaPopup = (props) => {

//   const [selectedOption, setSelectedOption] = useState('option1');

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//   };
//   return (
//     <div className='flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
//       <div className='relative w-[100%]  md:w-[55%] lg:w-[36%] md:my-6 md:mx-6'>
//         <div className=''>
//           <button
//             className='hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]'
//             onClick={() => {
//               props.close();
//             }}>
//             <img className='h-6 w-6 ' src={CloseIcon} alt='' />
//           </button>
//         </div>
//         <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">

//           <div className="relative p-6 flex-auto">
//             <div className="space-y-2 py-4">
//               <p className="text-[#7A7A7A] text-[12px]">
//                 show newly downloaded media from this chat in your device's gallery?              </p>
//             </div>

//             <div className="flex items-center space-x-6 mb-4">
//               <input
//                 type="radio"
//                 id="option1"
//                 value="option1"
//                 checked={selectedOption === 'option1'}
//                 onChange={handleOptionChange}
//                 className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
//               />
//               <label htmlFor="option1" className="ml-2   text-[13px] text-[#575556]">Default (Yes)</label>

//             </div>
//             <div className="flex items-center space-x-6 mb-4">
//               <input
//                 type="radio"
//                 id="option2"
//                 value="option2"
//                 checked={selectedOption === 'option2'}
//                 onChange={handleOptionChange}
//                 className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
//               />
//               <label htmlFor="option2" className="ml-2   text-[13px] text-[#575556]">Yes</label>

//             </div>
//             <div className="flex items-center space-x-6 mb-4">
//               <input
//                 type="radio"
//                 id="option3"
//                 value="option3"
//                 checked={selectedOption === 'option3'}
//                 onChange={handleOptionChange}
//                 className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
//               />
//               <label htmlFor="option3" className="ml-2   text-[13px] text-[#575556]">No</label>

//             </div>

//             <div className="flex py-3 justify-center gap-5">
//               <button onClick={() => {
//                 props.close();
//               }} className="text-[13px] py-2 px-[3rem] rounded-md border border-[#707070]">Cancel</button>
//               <button className="text-[13px] py-2 px-[4rem] text-white rounded-md bg-red-600" >Ok</button>
//             </div>
//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }

const CommonHelpPopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[75%] h-[80%] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" md:grid grid-cols-9  px-2 mb-[3rem]  md:px-[1rem] lg:px-[3rem] md:space-x-2 lg:space-x-3 ">
            <div className="md:col-span-3 border-b-2 pb-[1rem] pd:mb-0 border border-[#E9E9E9] ">
              <HelpPage />
            </div>
            {/* {props?.menu === "help"?
          <div className="md:col-span-3 mt-[2rem] md:mt-0 border-b-2 pb-[1rem] pd:mb-0 border border-[#E9E9E9] ">
           
            <QueryForm data={helpData} />
          </div>
          : */}
            <div className="md:col-span-3 mt-[2rem] md:mt-0 border-b-2 pb-[1rem] pd:mb-0 border border-[#E9E9E9] ">
              <QueryForm menu="help" />
            </div>
            {/* } */}
            {/* {props?.menu ==="help"?
  <div className=" md:col-span-3 mt-[2rem] md:mt-0 pb-[1rem] pd:mb-0 space-y-3  ">
           
            <HelpSection data={helpData} />
          </div>
        : */}
            <div className=" md:col-span-3 mt-[2rem] md:mt-0 pb-[1rem] pd:mb-0 space-y-3  ">
              <HelpSection menu="help" />
            </div>
            {/* } */}
          </div>
        </div>
      </div>
    </div>
  );
};

const EmailAddPopup = (props) => {
  return (
    <div className=" flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className={`${
          props.menu === "policy"
            ? "relative w-auto my-6 mx-6 md:mt-[50rem] lg:mt-[65rem] "
            : props?.menu === "View Horoscope Match"
            ? "relative w-[54rem] text-center my-6 mx-6 "
            : props.menu === "profileStatus"
            ? "relative w-[25rem] text-center my-6 mx-6 "
            : props.menu === "report"
            ? "relative  my-6 mx-6  w-[30rem]"
            : props?.menu == "profileRejection"
            ? "relative text-center my-6 mx-6"
            : "relative  my-6 mx-6 w-[90%] md:w-[80%]  lg:w-[38%]"
        } `}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed mt-2 border-[#D10A11] mx-2  rounded-t ">
            <h3
              className={`text-[20px] text-[#D10A11] ml-2 font-semibold align-middle ${
                props?.menu == "profileRejection" ? "lg:whitespace-nowrap" : ""
              }`}
            >
              {props.title}
            </h3>
          </div>
          {props?.isLoading ? (
            <Loader />
          ) : (
            <div
              className={
                props?.menu === "policypopup"
                  ? "relative px-6 py-6 pr-[0.1rem]  flex-auto"
                  : "relative px-[3.5rem] py-8  flex-auto"
              }
            >
              {props.children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CommonNoMatchesPopup = (props) => {
  const closePopupRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div>
      <div className="flex items-end md:justify-center lg:justify-center bg-opacityblack md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="lg:mt-[10rem] lg:bottom-[4rem] relative lg:w-auto lg:my-6 lg:mx-auto md:w-[80%] w-[100%] md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg bg-white lg:rounded-[1rem] py-10 flex flex-col items-center justify-center">
            <div className="w-full lg:px-5 md:w-[27rem] lg:w-[100%]  rounded-2xl">
              <div className="hidden md:block lg:block">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-[-0.3rem] right-[-6px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="h-6 w-6 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className="hidden lg:block md:hidden items-start text-center">
                <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                  There are no matches based on the Search Criteria given by
                  you.
                  <br /> We suggest you expand the criteria. Here are a few
                  suggested <br /> Matches based on our AI Matching Algorithm.
                </p>
              </div>
              <div className="hidden md:block lg:hidden items-start text-center">
                <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                  There are no matches based on the Search Criteria given by
                  you.We suggest you expand the criteria. Here are a few
                  suggested Matches based on our AI Matching Algorithm.
                </p>
              </div>
              <div className="lg:hidden md:hidden items-start text-center space-y-2">
                <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                  There are no matches based on the Search Criteria given by
                </p>
                <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                  you. We suggest you expand the criteria. Here are a few
                </p>
                <p className="lg:text-[#1A1A1A] text-[#575556] flex justify-center font-Poppins-Regular text-[12px] md:text-[14px] lg:text-[15px] ">
                  suggested Matches based on our AI Matching Algorithm.
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center pt-[2rem]">
              <button
                className="bg-[#D10A11] text-[#FFFFFF] w-[120px] py-2 rounded"
                onClick={() => {
                  props?.onYesClick();
                  props.close();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonSuccessfullUpload = (props) => {
  return (
    <div>
      <div className="flex items-end md:justify-center lg:justify-center bg-opacityblack md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="mt-[10rem] relative w-auto mx-auto ">
          <div className="bg-white h-[120px] rounded-[1rem] ">
            <div className="w-full py-8 px-10 sm:p-6 sm:pb-4 md:w-[27rem] lg:w-[35rem]  rounded-2xl">
              <div className="">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="h-5 w-5 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className=" items-start ">
                <p className="text-[#1A1A1A] flex justify-center font-bold text-[16px] md:text-[18px] lg:text-[20px]  pr-[1rem] ">
                  Upload Photo
                </p>
              </div>
              <div className="mt-3">
                <p className="text-[#7A7A7A] text-14px text-center">
                  Photo Uploaded Successfully,{" "}
                  <span className="text-[#0095EF] underline">
                    Click here to view
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LogoutPopup = (props) => {
  return (
    <div>
      <div className="flex justify-center bg-opacityblack75 items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none">
        <div className="relative w-[22rem] md:w-auto my-6 mx-auto ">
          <div className="bg-white rounded-[1rem]">
            <div className="w-full py-8 lg:py-12 px-10 sm:p-6 sm:pb-4 md:w-[27rem] md:py-8 lg:w-[30rem]  rounded-2xl">
              <div className="md:block">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-[-6px] right-[-3px] lg:right-[-4px]"
                  onClick={() => {
                    props.close();
                  }}
                >
                  <img className="h-6 w-6 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className=" items-start ">
                <p className="text-[#1A1A1A] flex justify-center font-bold text-[16px] md:text-[18px] lg:text-[20px]  pr-[1rem] ">
                  Logout
                </p>
                <p className="text-[#777777] text-[13px] font-Poppins-Regular py-3 text-center">
                  Are You Sure You Want To Logout?
                </p>
              </div>
              <div className="footer flex justify-center items-center gap-5 pt-2 px-3">
                <button
                  className="px-[3rem] border border-[#707070] text-[#7A7A7A] py-2 text-[13px] rounded font-segeo font-bold"
                  onClick={() => {
                    props.close();
                  }}
                >
                  Cancel
                </button>

                <button
                  className="bg-[#D10A11] text-white py-2 px-[3rem]   text-[13px] rounded font-segeo font-bold"
                  onClick={() => {
                    props.logout();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonFamilyFriendPopup = ({ setShowFamilyPopup, props }) => {
  const [showChoosFriendPopup, setShowChoosFriendPopup] = useState(false);
  console.log(showChoosFriendPopup, "showChoosFriendPopup");

  const formik = useFormik({
    initialValues: {
      number: "",
      isdCode: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      number: Yup.string()
        .min(7, "Please enter a valid Mobile Number")
        .max(15, "Please enter a valid Mobile Number")
        .test("india", "Please enter a valid Mobile Number", function (value) {
          const { isdCode } = this.parent;
          if (isdCode === "+91") {
            return !value || value.length === 10
              ? true
              : this.createError({
                  message: "Please enter a valid Mobile Number",
                  path: "number",
                });
          }
          return true;
        })
        .test("other", "Please enter a valid Mobile Number", function (value) {
          const { isdCode } = this.parent;
          if (isdCode !== "+91") {
            return !value || (value.length >= 7 && value.length <= 15)
              ? true
              : this.createError({
                  message: "Please enter valid mobile number",
                  path: "number",
                });
          }
          return true;
        })
        .required("Please enter a Mobile Number"),
      isdCode: Yup.string().required("Please Select Isd code"),
    }),

    onSubmit: () => {
      setShowChoosFriendPopup(true);
      setShowFamilyPopup(false);
      setTimeout(() => {
        setShowChoosFriendPopup(false);
        setShowFamilyPopup(false);
      }, 2000);
    },
  });
  const OncloseClick = () => {
    setShowChoosFriendPopup(true);
    setShowFamilyPopup(false);
  };
  return (
    <>
      {showChoosFriendPopup && (
        <CommonChooseFamilyPopup close={() => setShowChoosFriendPopup(false)} />
      )}

      <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-[100%] md:w-[60%] lg:w-[35%] md:my-6 md:mx-6">
          <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
              }}
            >
              <img className="h-6 w-6 " src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0  rounded-tr-[40px] rounded-tl-[40px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative p-6 flex-auto justify-center items-center text-center">
              <p className="text-[18px] text-[#D10A11] mt-2 ml-2 font-semibold font-poppins text-center">
                {/* {props.title} */}FAMILY/FRIEND LOGIN
              </p>
            </div>
            <div className="flex items-center justify-center relative px-6 py-3 bottom-6 flex-auto ">
              {/* {onSubmitPopup ?
       <Loader/>
       :showpopup ?
       <div className='border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none'>
       <div className='flex items-center py-1 justify-center mx-2  rounded-t '>
         <h3 className='text-[20px] text-[#D10A11] ml-2 font-semibold align-middle'>
           Success
         </h3>
       </div>
       <div className='relative p-6 flex-auto'>
         <div className='flex flex-col justify-center items-center w-[60vw] md:w-[30vw] '>
           <span className='text-[16px] font-semibold whitespace-nowrap'>
           Request Send Successfully!
           </span>
           <img className='w-16 h-16 mt-2' alt='' src={SuccessIcon} />
         </div>
       </div>
     </div>
         : */}
              <form onSubmit={formik.handleSubmit} className="flex flex-col ">
                <div className="flex space-x-2 my-2 ">
                  <label className="select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[4rem]">
                    <select
                      name="isdCode"
                      id="isdCode"
                      // value={formik.values.isdCode}
                      // onChange={formik.handleChange}
                      className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins md:text-[12px] lg:text-[16px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  "
                    >
                      <option disabled>select</option>
                      <option>+91</option>
                      <option>+93</option>
                      <option>+234</option>
                      {/* {isdCodeList?.map((e, i) => (
                    <option key={i} value={e.isdcode}>
                      {e.isdcode}
                    </option>
                  ))} */}
                    </select>
                    {/* {formik.errors.isdCode && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.isdCode}
                    </p>
                  )} */}
                  </label>

                  <label className="">
                    <input
                      name={"number"}
                      id={"number"}
                      type={"tel"}
                      placeholder="Enter Your Mobile Number"
                      // value={formik.values.number}

                      // onChange={(e)=>{
                      //   if(validateNumber(e.target.value)){
                      //     formik.handleChange(e)
                      //   }
                      // }}
                      className="no-spinner  p-2.5 my-1 w-[12.3rem]  text-[13px] font-Poppins text-[#575556]  rounded-[3px] border border-[#C4C4C4]"
                    />
                    {/* <div className="h-2 mb-2">
                  {formik.errors.number && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.number}
                    </p>
                  )}
                  
                </div> */}
                  </label>
                </div>

                {/* {onSubmitPopup ?
          <button
          type="submit"
         
          className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
        >
          Submitig...
        </button>
        : */}
                <div className="flex  justify-center items-center space-x-8">
                  <button
                    onClick={() => {
                      props.close();
                    }}
                    type="submit"
                    className=" flex items-center justify-center my-1 h-[1.5rem]  text-[#575556] bg-white border border-[#707070] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={OncloseClick}
                    className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                  >
                    Submit
                  </button>
                </div>

                {/* }   */}
              </form>
              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CommonChooseFamilyPopup = ({
  close,
  familynumber,
  parentsMobileNumber,
}) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  // console.log(familynumber, "familydata123");
  const navigate = useNavigate();
  const userProfileData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);

  // console.log(userProfileData, profileData, "userProfileData");
  const { onClickTrack } = useAnalytics();

  const formik = useFormik({
    initialValues: {
      member: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      member: Yup.string().required("Please select member "),
      password: Yup.string().required(
        "Enter the password associated with the profile holder!"
      ),
    }),
    onSubmit: (values) => {
      console.log(values, "values123");
      setShowLoader(true);
      apiLogin(
        values,
        familynumber.find((member) => member.id === values.member)?.id
      );
      onClickTrack(EventName.Home_page_Login_as_FamilyFriend_Login_Button, {
        [EventKeys.FamilyFriendLoginMemberId]: values.member,
      });
    },
  });

  const apiLogin = async (values, selectedMemberId) => {
    try {
      if (!selectedMemberId) {
        console.error("Selected member ID not available");
        return;
      }

      let loginRequest = {
        email: parentsMobileNumber,
        password: values.password,
        loginFrom: localStorage.getItem(
          Constants.loginLocalStorageKeys.loginDevice
        ),
        ioskey: "",
        androidkey: "",
        profileid: selectedMemberId,
      };

      let response = await POST(
        `${config.api.API_URL}${EndPoints.groupLoginUrl()}`,
        loginRequest
      );

      if (response.data.status === "Success") {
        if (response.data.data.status === "SUCCESS") {
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginFrom,
            new URL(window.location.href).pathname +
              new URL(window.location.href).search
          );
          localStorage.setItem(
            Constants.loginLocalStorageKeys.showLoader,
            true
          );

          localStorage.setItem(
            Constants.loginLocalStorageKeys.isLoggedIn,
            true
          ); //is loggedin
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginName,
            response.data.data.userName ?? ""
          ); //username
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginEmail,
            response.data.data.userName ?? ""
          );
          localStorage.setItem(
            Constants.loginLocalStorageKeys.loginId,
            response.data.data.userId
          ); //userid
          localStorage.setItem(
            Constants.loginLocalStorageKeys.familyLoginId,
            response.data.data.familyLoginId
          ); //family login id
          localStorage.setItem(
            Constants.loginLocalStorageKeys.familyLoginMobileNumber,
            parentsMobileNumber
          );

          if (response.data.data?.selectedversion == "V3") {
            localStorage.setItem("isV3User", true)
          // const cms_response = await CMS_Login({
          //   email: response.data?.data?.userName,
          //   password: loginRequest.password,
          // })
          const cms_response = await familycode_login({
            mobile_number : parentsMobileNumber,
            profile_id: loginRequest.profileid,
            password: loginRequest.password,
          })

          setShowLoader(false)

            console.log({ cms_response });
            if (cms_response.data) {
              console.log(cms_response, "cms_response");
              localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLogin,
                String(cms_response?.data?.access_token) ?? "OLD"
              );
              localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLoginId,
                String(cms_response?.data?.id) ?? ""
              );
            }
          }

          if (response.data.data.nextPage == "Redirect") {
            // history(`${response.data.data.redirect}`)
            window.location.href = "https://" + response.data.data.redirect;
          } else if (response.data.data.nextPage === "phoneNumberEdit") {
            localStorage.setItem("phoneNumberEdit", true);
            navigate(
              `${RouteHelper.getEditProfileUrl(EditProfileMenu[5].urlPath)}`
            );
          } else {
            localStorage.setItem(
              Constants.loginLocalStorageKeys.loginCurrentPage,
              `/${response.data.data.nextPage}`
            );
            // history(`/match-lite`)
            navigate(`/${response.data.data.nextPage}`);
          }
        } else {
          // localStorage.setItem(Constant.loginLocalStorageKeys.showLoader,true)
          formik.setFieldError("password", response?.data?.data?.message);
          setShowLoader(false);
        }
      }
    } catch (error) {
      console.error("Error during login API call:", error);
    }
  };

  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      {showLoader && (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      )}
      <div className="relative w-[100%] md:w-[60%] lg:w-[35%] md:my-6 md:mx-6">
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
            onClick={() => {
              close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[40px] rounded-tl-[40px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto justify-center items-center text-center">
            <p className="text-[18px] text-[#D10A11] mt-2 ml-2 font-semibold font-poppins text-center">
              FAMILY/FRIEND LOGIN
            </p>
            <p className="text-[#8292A8] font-bold text-[15px] pt-2">
              Choose Member and Enter Password
            </p>
          </div>
          <div className="flex items-center justify-center relative px-6 bottom-6 flex-auto ">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col space-y-4"
            >
              <div className="flex space-x-2 my-2 ">
                <label className="select text-[#575556] w-[18rem]">
                  <select
                    name="member"
                    id="member"
                    placeholder="Select Member"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.member}
                    className="md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] md:text-[12px] lg:text-[14px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]"
                  >
                    <option>Select Member</option>
                    {familynumber.map((member) => (
                      member?.familynumber === "Y" &&(
                      <option key={member.id} value={member.id}>
                        {member.name} / {member.id}
                      </option>
                      )
                    ))}
                  </select>
                  {formik.touched.member && !formik.errors.member && (
                    <p className="text-[green] text-[12px]"></p>
                  )}
                  {formik.touched.member && formik.errors.member && (
                    <p className="text-[red] pb-1 text-[12px]">
                      {formik.errors.member}
                    </p>
                  )}
                </label>
              </div>
              <div>
                <label className="relative flex justify-end ">
                  <input
                    id="password"
                    name="password"
                    autoComplete="off"
                    placeholder="Enter Password"
                    type={showHidePassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className="w-[14rem] form-input shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2 w-full block"
                  />

                  <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                    <img
                      onClick={() => changeShowHidePassword(!showHidePassword)}
                      src={
                        showHidePassword
                          ? "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161601/passwordeyeopen_jkm7v5.png"
                          : "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161797/passwordeye_cpa0p8.png"
                      }
                      className="w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                      alt=""
                    />
                  </div>
                </label>
                {formik.touched.password && !formik.errors.password && (
                  <p className="text-[green] text-[12px]"></p>
                )}
                {formik.touched.password && formik.errors.password && (
                  <p className="text-[red] text-[12px]">
                    {formik.errors.password}
                  </p>
                )}
              </div>
              <div className="flex justify-center items-center space-x-8">
                <button
                  onClick={() => {
                    close();
                    onClickTrack(
                      EventName.Home_page_Login_as_FamilyFriend_Cancel_Button
                    );
                  }}
                  className="flex items-center justify-center my-1 h-[1.5rem] text-[#575556] bg-white border border-[#707070] px-[2.3rem] md:px-[3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex items-center justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                >
                  Login
                </button>
              </div>
              <div>
                {/* You can include additional components or UI elements here */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonErrorPopup = ({ closePopup, message, buttonClick, buttonName }) => {
  const closePopupRef = useRef();

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!closePopupRef.current.contains(e.target)) {
  //       closePopup();
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });

  useEffect(() => {
    const mobileQuery = window.matchMedia("(max-width: 768px)"); // Adjust the width as needed for mobile views
    const handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        closePopup();
      }
    };
    const addListeners = () => {
      if (mobileQuery.matches) {
        document.addEventListener("mousedown", handler);
      }
    };
    const removeListeners = () => {
      document.removeEventListener("mousedown", handler);
    };

    // Check and add listeners on mount
    addListeners();

    // Remove listeners on unmount
    return () => {
      removeListeners();
    };
  }, []);


  return (
    <div className="flex justify-center bg-opacityblack75 items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%]  md:w-[55%] lg:w-[38%] modal-container md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-[0.1rem] right-[-6px]"
            onClick={() => {
              closePopup();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative px-6 py-8 flex-auto">
            <div className="flex flex-col justify-center items-center mb-5">
              <span className="text-[16px] text-center">
                {message
                  ? message
                  : "Group member limit reached. You can add maximum of 6 members"}
              </span>
            </div>
            {buttonName && (
              <button
                onClick={buttonClick}
                className=" mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
              >
                {buttonName}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonNewPopup = ({
  closePopup,
  message,
  buttonClick,
  buttonName,
  title,
  image,
}) => {
  const closePopupRef = useRef();

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!closePopupRef.current.contains(e.target)) {
  //       closePopup()
  //     }
  //   }
  //   document.addEventListener('mousedown', handler)

  //   return () => {
  //     document.removeEventListener('mousedown', handler)
  //   }
  // })

  return (
    <div className="flex justify-center bg-opacityblack items-center md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative lg:w-[40vw] md:w-[70vw] w-[90vw] modal-container md:my-6 md:mx-6"
        ref={closePopupRef}
      >
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-[0.1rem] left-0"
            onClick={() => {
              closePopup();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-md shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-2xl relative py-8 flex-auto">
            <div className="flex flex-col justify-center items-center mb-5 mx-[1rem]">
              <div className="rounded-full mx-auto bg-[#FEBABA] p-4 flex items-center justify-center">
                <img src={image} className="w-12 h-12" alt="" />
              </div>
              <span className="text-[#D10A11] flex  font-bold text-[11px] md:text-[14px] lg:text-[18px] py-3">
                {title}
              </span>
              {title == "Download our APP to get instant Matches" && (
                <div className="flex md:gap-3 lg:gap-6 xl:gap-10 lg:mt-[2rem] md:mt-[1rem]">
                  <a
                    className="cursor-pointer"
                    target="_blank"
                    href={RouteSegments.staticLinkForAppDownload.ios}
                  >
                    <img
                      className="w-[8rem] md:w-[6rem] md:h-[2rem] lg:h-[2.2rem] xl:w-[7rem] xl:h-[2.4rem] 2xl:w-[8rem] 2xl:h-[2.5rem]"
                      src={playstoreicon}
                      alt="ApptoreIcon"
                    />
                  </a>
                  <a
                    className="cursor-pointer"
                    target="_blank"
                    href={RouteSegments.staticLinkForAppDownload.android}
                  >
                    <img
                      className="w-[8rem] md:w-[6rem] md:h-[2rem] lg:h-[2.2rem] xl:w-[7rem] xl:h-[2.4rem] 2xl:w-[8rem] 2xl:h-[2.5rem]"
                      src={googleicon}
                      alt="PlayStoreIcon"
                    />
                  </a>
                </div>
              )}
              <span className="text-[#575556] text-[14px] lg:text-[16px] font-semibold py-3 text-center">
                {message}
              </span>
            </div>
            {buttonName && (
              <button
                onClick={buttonClick}
                className="mx-auto flex items-center cursor-pointer justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
              >
                {buttonName}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PaymentWhatsAppPopup = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("");
  const [groupNumber, setGroupNumber] = useState([]);
  const [dropdownDisable, setDropdownDisable] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);

  const [numberEnable, setNumberEnable] = useState(true);
  const [typeofNumber, setTypeofNumber] = useState(true);
  const [offlineLoader, setOfflineLoader] = useState(false);

  const submitRef = useRef(null);
  const isV3User = localStorage.getItem("isV3User");

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const { setShowSearch, setShowMedia } = useContext(NavBarContext);

  const familyChatList = useSelector((state) => state?.Chats?.groupChatList);

  const dropdownData = useSelector((state) => state?.Home?.rData?.data);

  const { ProfileData } = useSelector((state) => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data,
  }));

  const formik = useFormik({
    initialValues: {
      whatsappisdcode: "+91",
      whatsappnumber: "",
      relationId: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      relationId: Yup.string().required("Please select relative"),
      whatsappnumber: Yup.string()
        .min(10, "Length should be 10")
        .test(
          "india",
          "whatsapp number should have 10 digits",
          function (value) {
            const { isdCode } = this.parent;
            if (isdCode === "+91") {
              return !value || value.length === 10
                ? true
                : this.createError({
                    message: "Whatsapp number should have 10 digits",
                    path: "whatsappnumber",
                  });
            }
            return true;
          }
        )
        .test("india", "number error", function (value) {
          // if (userData?.parentsMobileNumber == value) {
          // return this.createError({ message: "Number should not be same as parents number", path: "whatsappnumber" });
          // } else if (userData?.userphoneno == value) {
          // return this.createError({ message: "Number should not be same as profile number", path: "whatsappnumber" });
          // }
          return true;
        })
        .required("Please enter whatsapp number"),
    }),
    onSubmit: (values) => {
      setOfflineLoader(true);
      submitWhatsappNumber(values);
    },
  });

  const handleOptionChange = async (event, number, type, relative) => {
    setSelectedOption(event.target.value);
    setTypeofNumber(type);

    if (type == "input") {
      await formik.setFieldValue("whatsappnumber", number);
      const isGroup = groupNumber.filter(
        (e) => e.parentsMobileNumber == number
      );
      if (
        isGroup.length > 0 ||
        userData?.parentsMobileNumber == number ||
        userData?.userphoneno == number
      ) {
        const selectedId =
          userData?.userphoneno == number
            ? "8"
            : userData?.parentsMobileNumber == number
            ? "7"
            : dropdownData?.relationShip
                .filter((e) => e.name == isGroup[0].relationshipName)
                .map((e) => e.id)[0];
        await formik.setFieldValue("relationId", selectedId);
        let optnumber =
          userData?.userphoneno == number
            ? "-1"
            : groupNumber.findIndex(
                (obj) => obj.parentsMobileNumber === number
              );
        setSelectedOption("option" + optnumber);
        setDropdownDisable(true);
      } else {
        // const formik = useFormik();
        const fieldValue = formik.values.relationId;
        if (fieldValue == "8" || fieldValue == "7") {
          await formik.setFieldValue("relationId", null);
        }
        setDropdownDisable(false);
      }
    } else {
      const selectedId =
        relative == "Myself"
          ? "8"
          : relative == "Parent"
          ? "7"
          : dropdownData?.relationShip
              .filter((e) => e.name == relative)
              .map((e) => e.id)[0];
      console.log("whatsappnumber", number);
      await formik.setFieldValue("whatsappnumber", number, false);
      formik.setFieldValue("relationId", selectedId);
      setDropdownDisable(true);
    }
    if (number?.length > 0) {
      setNumberEnable(false);
    } else {
      setNumberEnable(true);
    }
  };

  const createGroupConversation = async () => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          Constants.loginLocalStorageKeys.chatLogin
        )}`,
      },
      data: {
        profile_id: userData?.profileId,
        auth_id: getGroupAuthId(),
        get_starred_message: false,
        page: 1,
        page_size: 1,
      },
    };
    const createConversation = await Family_Chats_Detail(axiosConfig);
  };

  const submitWhatsappNumber = async (values) => {
    let request = {
      profileId: ProfileData?.profileId,
      whatsappnumber: values?.whatsappnumber,
      whatsappisdcode: values?.whatsappisdcode,
      relationId: values?.relationId == "8" ? "8" : values?.relationId,
      isverified:
        typeofNumber == "input"
          ? groupNumber.filter(
              (e) => e.parentsMobileNumber == values?.whatsappnumber
            ).length > 0 || userData?.userphoneno == values?.whatsappnumber
            ? "Y"
            : "N"
          : "Y",
    };

    const { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.saveWhatsappDetails()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        setOfflineLoader(false);
        props.close();
        if (submitRef.current == "addmore") {
          navigate(RouteSegments.MATCH_GROUP, { state: { isFrom: "payment" } });
        } else {
          props?.setwhatsAppSuccessPopup(true);
        }
        setShowSearch(false);
        setShowMedia(false);
        createGroupConversation();
      }
    }
  };

  const fetchGroupData = async () => {
    const profileId = ProfileData?.profileId;
    if (!profileId) {
      console.error("ProfileId not available");
      return;
    }

    const { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        // console.log(data.data.groupMembers, 'groupMembers');
        setGroupNumber(
          data.data.groupMembers?.filter(
            (member) => member?.verifiedstatus == "Y"
          )
        );
      }
    }
  };

  useEffect(() => {
    fetchGroupData();
    dispatch(registerDetail());
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className={`relative ${
            props?.isClose == false
              ? "w-[95vw] md:w-[25rem] lg:w-[25rem]"
              : "w-[95vw] md:w-auto lg:w-auto"
          } my-6 mx-6`}
        >
          {offlineLoader ? (
            <Loader
              className="loaderTransperancy"
              loaderClassName="loaderTransperancySpin"
            />
          ) : (
            <>
              {props?.isClose == false ? (
                <>
                  <div className="">
                    <button
                      className=" bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
                      onClick={() => {
                        props.close();
                      }}
                    >
                      <img className="h-5 w-5 " src={CloseIcon} alt="" />
                    </button>
                  </div>
                  <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center p-3 justify-center border-b border-dashed border-[#1F6700] mx-2 rounded-t ">
                      <h3 className="text-[12px] md:text-[14px] lg:text-[16px] text-[#1F6700] align-middle bg-[#03951130] p-3 rounded-xl">
                        {props.heading}
                      </h3>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {props?.isClose ? (
                <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                  {props?.isFrom == "Match" ? (
                    <></>
                  ) : (
                    <div className="flex items-center p-3 justify-center border-b border-dashed border-[#1F6700] mx-2 rounded-t ">
                      <h3 className="text-[12px] md:text-[14px] lg:text-[16px] text-[#1F6700] align-middle bg-[#03951130] p-3 rounded-xl">
                        {props.heading}
                      </h3>
                    </div>
                  )}

                  <>
                    <div className="flex items-center py-2 justify-center border-b border-dashed border-[#D10A11] mx-2 rounded-t ">
                      <h3 className="text-[14px] md:text-[16px] lg:text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
                        {props.title}
                      </h3>
                    </div>
                    <div className="flex items-center py-1 justify-center mx-2 rounded-t px-4 pt-4 gap-3">
                      <div>
                        <img
                          src={whatsappIcon}
                          className="w-[3rem] h-[3rem]"
                          alt=""
                        />
                      </div>
                      <div className="w-[18rem] md:w-[25rem] lg:w-[25rem]">
                        <p className="text-[#000000] text-[12px] md:text-[12px] lg:text-[12px] font-bold">
                          To get profiles in WhatsApp, add a WhatsApp enabled
                          Number. Profiles will be shared to this WhatsApp
                          Number only!
                        </p>
                      </div>
                    </div>

                    <div className="relative p-6 flex-auto">
                      <form
                        onSubmit={formik?.handleSubmit}
                        className="flex flex-col "
                      >
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex space-x-2 my-2 ">
                            <label className="select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[8rem]">
                              <select
                                name="whatsappisdcode"
                                id="whatsappisdcode"
                                defaultValue={"+91"}
                                value={formik?.values?.whatsappisdcode}
                                onChange={formik.handleChange}
                                className=" md:p-[0.4rem] lg:p-2.5 my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]"
                              >
                                {dropdownData?.isdcode?.map((e, i) => (
                                  <option key={i} value={e.isdcode}>
                                    {e.isdcode} , {e.country}
                                  </option>
                                ))}
                              </select>
                            </label>

                            <label className="">
                              <input
                                name={"whatsappnumber"}
                                id={"whatsappnumber"}
                                type={"tel"}
                                maxLength={10}
                                placeholder="Enter WhatsApp Number"
                                value={formik?.values?.whatsappnumber}
                                onChange={(e) => {
                                  if (validateNumber(e.target.value)) {
                                    handleOptionChange(
                                      e,
                                      e.target.value,
                                      "input",
                                      ""
                                    );
                                    setNumberEnable(false);
                                  }
                                }}
                                onKeyPress={handleKeyPress}
                                className="no-spinner p-2 my-1 w-[11.3rem] md:w-[12.3rem] lg:w-[12.3rem] text-[13px] font-Poppins !text-[#575556] rounded-[3px] border border-[#C4C4C4] placeholder:text-[#575556] font-semibold"
                              />
                              {formik.errors.whatsappnumber && (
                                <p className="text-[#D10A11] text-[12px]">
                                  {formik.errors.whatsappnumber}
                                </p>
                              )}
                            </label>

                            <label className="select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[9rem]">
                              <select
                                name="relationId"
                                id="relationId"
                                value={
                                  formik.values.relationId
                                    ? formik?.values?.relationId
                                    : "Select Relation"
                                }
                                defaultValue={null}
                                disabled={dropdownDisable}
                                onChange={formik?.handleChange}
                                className=" md:p-[0.4rem] lg:p-2.5 my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px] placeholder:text-[#575556]"
                              >
                                <option defaultValue hidden>
                                  Select Relation
                                </option>
                                <option value="7" hidden>
                                  Parent
                                </option>
                                <option value="8" hidden>
                                  Myself
                                </option>
                                {dropdownData?.relationShip?.map((e, i) => (
                                  <option key={i} value={e.id}>
                                    {e.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.relationId &&
                                formik.errors.relationId && (
                                  <p className="text-[#D10A11] text-[12px]">
                                    {formik.errors.relationId}
                                  </p>
                                )}
                            </label>
                          </div>
                          {isV3User && (
                            <div className="flex justify-between gap-2 md:gap-8 lg:gap-8 flex-col md:flex-row">
                              <div className="flex items-center">
                                <p className="text-gray-600 text-[14px] md:text-[12px] lg:text-[12px]">
                                  *This number will be added to your family
                                </p>
                              </div>
                              <div className="flex gap-2 items-center justify-end">
                                <p
                                  className={` text-[14px] md:text-[14px] lg:text-[14px] ${
                                    numberEnable
                                      ? "text-gray-600"
                                      : "text-[#D10A11] cursor-pointer"
                                  } flex items-center gap-2`}
                                  disabled={numberEnable}
                                  onClick={(e) => {
                                    if (numberEnable == false) {
                                      submitRef.current = "addmore";
                                      formik?.submitForm();
                                    }
                                  }}
                                >
                                  <img
                                    src={AddGroupIcon}
                                    alt=""
                                    className="w-3 h-3"
                                  />{" "}
                                  Add more numbers to family
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-center items-center py-[1.5rem]">
                          <hr className="w-[75%] pr-4" />
                          <div className="items-center text-[#D10A11] text-[18px] flex justify-center px-4">
                            OR
                          </div>
                          <hr className="w-[75%] pl-4" />
                        </div>
                        <div className=" ">
                          <p className="text-[12px] md:text-[14px] lg:text-[14px] text-[#717171]">
                            Choose from below number or add new number
                          </p>
                          <div className="h-[15vh] overflow-y-scroll scrollbar-none">
                            <div className="flex items-center space-x-3 mb-4 bg-[#FFE4E4] p-4 w-[17rem] rounded-sm">
                              <img
                                src={DefaultIcon}
                                alt=""
                                className={"w-4 h-4"}
                              />
                              <input
                                type="radio"
                                id={"option-1"}
                                value={"option-1"}
                                checked={selectedOption == "option-1"}
                                onChange={(e) => {
                                  handleOptionChange(
                                    e,
                                    userData?.userphoneno,
                                    "radio",
                                    "Myself"
                                  );
                                  setNumberEnable(false);
                                }}
                                className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 relative cursor-pointer"
                              />
                              <label
                                htmlFor={"option-1"}
                                className="ml-2 text-[13px] text-[#575556]"
                              >
                                {userData?.userphoneno} | {"Myself"}
                              </label>
                            </div>

                            {groupNumber.map((element, i) => (
                              <div
                                className="flex items-center space-x-3 mb-4 bg-[#FFE4E4] p-4 w-[17rem] rounded-sm"
                                key={i}
                              >
                                <img
                                  src={DefaultIcon}
                                  alt=""
                                  className={"w-4 h-4"}
                                />
                                <input
                                  type="radio"
                                  id={"option" + i}
                                  value={"option" + i}
                                  checked={selectedOption == "option" + i}
                                  onChange={(e) => {
                                    handleOptionChange(
                                      e,
                                      element.parentsMobileNumber,
                                      "radio",
                                      element.relationshipName
                                    );
                                    setNumberEnable(false);
                                  }}
                                  className="form-radio h-4 w-4 accent-[#D10A11] border rounded-full border-gray-400 relative cursor-pointer"
                                />
                                <label
                                  htmlFor={"option" + i}
                                  className="ml-2 text-[13px] text-[#575556]"
                                >
                                  {element.parentsMobileNumber} |{" "}
                                  {element.relationshipName}
                                </label>
                              </div>
                            ))}
                          </div>
                          {isV3User && (
                            <div className="flex justify-end pr-4 md:pr-0">
                              <div className="flex gap-2 items-center">
                                <p
                                  className={` text-[14px] ${
                                    numberEnable
                                      ? "text-gray-600"
                                      : "text-[#D10A11] cursor-pointer"
                                  } flex items-center gap-2`}
                                  disabled={numberEnable}
                                  onClick={(e) => {
                                    if (numberEnable == false) {
                                      submitRef.current = "addmore";
                                      formik.submitForm();
                                    }
                                  }}
                                >
                                  <img
                                    src={AddGroupIcon}
                                    alt=""
                                    className="w-3 h-3"
                                  />{" "}
                                  Add more numbers to family
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-center pt-3">
                          <button
                            disabled={numberEnable}
                            type="submit"
                            className={`text-[#FFFFFF] bg-[#D10A11] text-[12px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px] w-[5rem] md:w-[5rem] lg:w-24 cursor-pointer font-segeo font-semibold ${
                              numberEnable
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const CommonCastPopup = ({ close, profileDate }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { loginDetail } = useContext(LoginContext);
  const [showOtherCasteInput, setShowOtherCasteInput] = useState(false);
  const [showOtherSubCasteInput, setShowOtherSubCasteInput] = useState(false);
  const [casteName, setCasteName] = useState([]);
  const dispatch = useDispatch();
  const { casteList, subcasteList, domainList } = useSelector((state) => ({
    casteList: state?.Home?.cList?.data?.data?.castes ?? [],
    subcasteList: state?.Home?.scList?.data?.data?.subcastes ?? [],
  }));

 const isLoading = casteList.length == 0;
  console.log(showOtherCasteInput, "showOtherCasteInput");
  console.log(close, "close");


  const othervalue = casteList[0]?.name === "Others";

  console.log(othervalue, "othervalue");

  const [inputCasteValue, setInputCasteValue] = useState("");
  const [matchedCastes, setMatchedCastes] = useState([]);

  const rearrangeOthersToLast = (list) => {
    const othersOption = list.find(
      (option) => option.name.toLowerCase() === "others"
    );
    const filteredList = list.filter(
      (option) => option.name.toLowerCase() !== "others"
    );

    if (othersOption) {
      othersOption.className = "dropdown-option-others"; // Optionally add a className
      return [...filteredList, othersOption];
    }
    return filteredList;
  };

  const handleCasteSelection = (selectedCaste) => {
    setInputCasteValue(selectedCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("casteId", selectedCaste.id);
    dispatch(registerSubCasteList(selectedCaste.id));
  };

  const handleSubCasteSelection = (selectedSubCaste) => {
    setInputCasteValue(selectedSubCaste.name); // Update inputCasteValue with the selected caste's name
    formik.setFieldValue("subcaste", selectedSubCaste.id); // Update formik values with the selected caste's ID
  };

  useEffect(() => {
    if (profileDate) {
      dispatch(
        registerCasteList(
          profileDate?.mothertongueid,
          profileDate?.religionId ? profileDate?.religionId : ""
        )
      );
      dispatch(
        registerSubCasteList(profileDate?.caste ? profileDate?.caste[0] : "")
      );
    }
  }, [dispatch, profileDate]);

  const casteOptions = useMemo(
    () => rearrangeOthersToLast(casteList),
    [casteList]
  );

  const subcasteOptions = useMemo(
    () => rearrangeOthersToLast(subcasteList),
    [subcasteList]
  );
  console.log(profileDate, "bbnn");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      casteId: profileDate?.cleanclearcaste?.caste
        ? profileDate?.cleanclearcaste?.caste[0]
        : "",
      subcaste: profileDate?.cleanclearcaste?.subcaste
        ? profileDate?.cleanclearcaste?.subcaste[0]
        : "",
      otherSubcaste: profileDate?.otherSubcaste ?? "",
      otherCaste: profileDate?.otherCaste ?? "",
    },
    validationSchema: Yup.object().shape({
      casteId: Yup.string()
        .notOneOf([Constants.casteNotKnowId], "Please select caste")
        .required("Please select caste"),
        otherCaste:Yup.string()
        .required("Please select caste"),
        // otherSubcaste:Yup.string()
        // .required("Please select subcaste"),
    }),
    onSubmit: (values) => {
      console.log("ReligionMigrationPopup", values);
      apiCallSubmit(values);
    },
  });

  useEffect(() => {
    setInputCasteValue(formik.values.otherCaste);
    setInputSubCasteValue(formik.values.otherSubcaste);
  }, [formik.values.otherCaste, formik.values.otherSubcaste]);

  const handleCasteInputChange = (e) => {
    const CasteValue = e.target.value;
    const CasteInput = CasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
      .trim()
      .replace(/\s+/g, " ");
    setInputCasteValue(CasteInput);
    formik.setFieldValue("otherCaste", CasteValue);

    console.log(CasteValue, "CasteValue");

    if (CasteValue.trim() === "") {
      // Clear matched castes when input value is empty
      // setMatchedCastes([]);
    } else {
      // Filter caste names starting with the input value
      const filteredCastes = casteList?.filter((caste) =>
        caste.name.toLowerCase().includes(CasteValue.toLowerCase())
      );

      // Check if "Others" is present in the filtered castes
      const othersCaste = filteredCastes.find(
        (caste) => caste.name.toLowerCase() === "others"
      );

      // Remove "Others" from the filtered castes if present
      const filteredCastesWithoutOthers = othersCaste
        ? filteredCastes.filter(
            (caste) => caste.name.toLowerCase() !== "others"
          )
        : filteredCastes;

      // Sort the filtered castes alphabetically
      const sortedFilteredCastes = filteredCastesWithoutOthers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Add "Others" as the last option if present
      const finalFilteredCastes = othersCaste
        ? [...sortedFilteredCastes, othersCaste]
        : sortedFilteredCastes;

      setMatchedCastes(finalFilteredCastes);
    }
  };

  const [inputSubCasteValue, setInputSubCasteValue] = useState("");
  const [matchedSubCastes, setMatchedSubCastes] = useState([]);

  const handleSubCasteInputChange = (e) => {
    const SubCasteValue = e.target.value;
    const SubCasteInput = SubCasteValue.replace(/[^a-zA-Z0-9\s]/g, "")
      .trim()
      .replace(/\s+/g, " ");
    setInputSubCasteValue(SubCasteInput);
    formik.setFieldValue("othersubcaste", SubCasteInput); // Ensure correct field name here

    console.log("SubCasteValue", SubCasteInput);
    if (SubCasteValue.trim() === "") {
      formik.setFieldValue("othersubcaste", "");
      setMatchedSubCastes([]);
    } else {
      const filteredSubCastes = subcasteList?.filter((subcaste) =>
        subcaste.name.toLowerCase().includes(SubCasteValue.toLowerCase())
      );
      setMatchedSubCastes(filteredSubCastes);
    }
  };

  const apiCallSubmit = async (values) => {
    console.log(values, "valuesvalues");
    const request = {
      email: loginDetail()[0] ?? null,
      userId: loginDetail()[1],
      casteId: values.casteId,
      subcasteId: values?.subcaste ? values?.subcaste : undefined,
      otherCaste: values.otherCaste,
      othersubcaste: values.othersubcaste,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getCasteEdit()}`,
      request
    );

    if (statusCode == 200) {
      window.location.reload()
      close();
    }
  };

  const subcasteAvailable = subcasteList?.length !== 0;

  const selectedCaste = useMemo(() => {
    return casteList
      ?.filter((option) => option.id === formik.values.casteId)
      ?.at(0);
  }, [casteList, formik.values.casteId]);

  const selectedSubCaste = useMemo(() => {
    return subcasteList
      ?.filter((option) => option.id === formik.values.subcaste)
      ?.at(0);
  }, [subcasteList, formik.values.subcaste]);

  console.log(selectedSubCaste?.name, "selectedCaste");

  const apiCallConfirm = async () => {
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.confirmcasteinfosaveUrl()}`,
      {
        isconfirmed: "Yes",
      }
    );

    if (statusCode == 200) {
      close();
    }
  };

  const onConfirm = () => {
    if (formik.values.casteId == Constants.casteNotKnowId) {
      setIsEdit(true);
    } else {
      formik.submitForm();
    }
    close();
    apiCallConfirm()
  };
 
  const isOthersPresent = subcasteList.some(
    (subcaste) => subcaste.name === "Others"
  );

  console.log(isOthersPresent, "isOthersPresent");

  const onCancel = () => {
    setIsEdit(false);
  };

  const [name, setname] = useState(false);

  console.log(name, "namename");
  return (
    <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className=""></div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              Please Confirm Caste Details
            </h3>
          </div>
          {isLoading ? (
            <div className="w-full h-32 flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div className="relative p-6 flex-auto">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col items-center  ">
                  <div className="flex flex-col w-full  space-y-2">
                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        Caste
                      </label>
                      {isEdit ? (
                        <RawSelectInput
                          name="casteId"
                          title="Caste"
                          value={selectedCaste}
                          onChange={(selected, ev) => {
                            formik.setFieldValue("casteId", selected.id);
                            // formik.setFieldValue("subcaste", "");
                            // formik.setFieldValue("otherSubcaste", "");
                            // formik.setFieldValue("otherCaste", );
                            dispatch(registerSubCasteList(selected.id));
                            setShowOtherCasteInput(
                              selected.id === Constants.casteOtherId
                            );
                          }}
                          error={formik.errors.casteId}
                          options={[
                            {
                              id: null,
                              name: "Select Caste",
                              isDisabled: true,
                            },
                            ...casteOptions.map((option) => ({
                              ...option,
                              className: option.name.includes("Others")
                                ? "dropdown-option-others"
                                : "",
                            })),
                          ]}
                          required={true}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        />
                      ) : (
                        // <label className="font-segeo text-[14px] font-semibold">
                        // {(() => {
                        //   const cleanclearcaste = profileDate?.cleanclearcaste;
                        //   if (Array.isArray(cleanclearcaste)) {
                        //     for (const item of cleanclearcaste) {
                        //       if (Array.isArray(item?.caste) && item.caste[0]?.name != "Others") {
                        //         return item.caste[0].name;
                        //       } else if (item.caste[0].name === "Others") {
                        //         return item.othercaste ?? "Not Specified";
                        //       }
                        //     }
                        //   }
                        //   return "Not Specified";
                        // })()}
                        // </label>

                        <label className="font-segeo text-[14px] font-semibold">
                          {(() => {
                            const cleanclearcaste =
                              profileDate?.cleanclearcaste;
                            if (Array.isArray(cleanclearcaste)) {
                              for (const item of cleanclearcaste) {
                                if (
                                  Array.isArray(item?.caste) &&
                                  item.caste[0]?.name === "Others"
                                ) {
                                  // Handle "Others" case here
                                  return item?.othercaste || "Not Specified";
                                } else if (
                                  Array.isArray(item?.caste) &&
                                  item.caste[0]?.name
                                ) {
                                  // Display the caste name if it exists
                                  return item.caste[0].name;
                                }
                              }
                            }
                            return "Not Specified";
                          })()}
                        </label>
                      )}
                    </div>

                    {formik.values.casteId == "433" && (
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            Other Caste
                          </p>
                        </div>
                        <div className="col-span-7 mt-2 md:mt-5 pl-2 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="othercaste"
                              autoComplete="off"
                              placeholder="Enter Your Caste"
                              className="form-control shadow-sm bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2 w-full block"
                              value={inputCasteValue}
                              // value={formik.values.otherCaste}
                              // onChange={formik.handleChange}
                              // onChange={handleCasteInputChange}
                              onChange={(e) => {
                                // formik.setFieldValue("casteId", "");
                                // formik.setFieldValue("subcaste", "");
                                // formik.setFieldValue("otherSubcaste", "");
                                // formik.setFieldValue("otherCaste",e.id)
                                handleCasteInputChange(e);
                                // formik.handleChange();
                              }}
                              error={formik.errors.otherCaste}
                            />
                            {inputCasteValue?.trim() !== "" &&
                              matchedCastes?.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedCastes.map((caste) => (
                                      <li
                                        key={caste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
                                        onClick={() =>
                                          handleCasteSelection(caste)
                                        }
                                      >
                                        {caste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            {formik.errors?.otherCaste &&
                              formik?.touched?.otherCaste && (
                                <p className="text-[red] text-[12px]">
                                  {formik.errors?.otherCaste}
                                </p>
                              )}{" "}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex flex-col">
                      <label className="font-segeo text-[14px] font-semibold text-[#575556]">
                        Subcaste
                      </label>
                      {isEdit ? (
                        subcasteAvailable ? (
                          <RawSelectInput
                            name="subcaste"
                            title="SubCaste"
                            value={selectedSubCaste}
                            onChange={(selected, ev) => {
                              formik.setFieldValue("subcaste", selected.id);
                            
                              setShowOtherSubCasteInput(
                                selected.name === "Others"
                              );
                            }}
                            error={formik.errors.subcaste}
                            options={[
                              {
                                id: null,
                                name: "Select SubCaste",
                                isDisabled: true,
                              },
                              ...subcasteOptions,
                            ]}
                            required={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            getOptionClassName={(option) =>
                              option.name.toLowerCase() === "others"
                                ? "dropdown-option-others"
                                : ""
                            }
                          />
                        ) : (
                          <>
                            <input
                              type="text"
                              value={inputSubCasteValue}
                              onChange={handleSubCasteInputChange}
                              name="subcaste"
                              className="border border-[#8F8F8F] text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold"
                            />
                            <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                              {formik.errors.subcaste &&
                                formik.touched.subcaste &&
                                formik.errors.subcaste}{" "}
                            </div>
                          </>
                        )
                      ) : (
                        //   <label className="font-segeo text-[14px] font-semibold">
                        //   {(() => {
                        //     const cleanclearcaste = profileDate?.cleanclearcaste;
                        //     if (Array.isArray(cleanclearcaste)) {
                        //       for (const item of cleanclearcaste) {
                        //         if (Array.isArray(item?.subcaste) && item.subcaste[0]?.name) {
                        //           return item.subcaste[0].name;
                        //         } else if (item?.othersubcaste) {
                        //           return item.othersubcaste;
                        //         }
                        //       }
                        //     }
                        //     return "Not Specified";
                        //   })()}
                        // </label>

                        <label className="font-segeo text-[14px] font-semibold">
                          {(() => {
                            const cleanclearcaste =
                              profileDate?.cleanclearcaste;
                            if (Array.isArray(cleanclearcaste)) {
                              for (const item of cleanclearcaste) {
                                if (
                                  Array.isArray(item?.subcaste) &&
                                  (item.subcaste.length === 0 ||
                                    item.subcaste[0]?.name === "Others")
                                ) {
                                  // Handle "Others" case or null subcaste here
                                  return item?.othersubcaste || "Not Specified";
                                } else if (
                                  Array.isArray(item?.subcaste) &&
                                  item.subcaste[0]?.name
                                ) {
                                  // Display the subcaste name if it exists
                                  return item.subcaste[0].name;
                                }
                              }
                            }
                            return "Not Specified";
                          })()}
                        </label>
                      )}
                    </div>

                    {selectedSubCaste?.name == "Others" && (
                      <>
                        <div className="col-span-2 mt-5">
                          <p className="font-segeo text-[14px] font-semibold text-[#575556]">
                            Other SubCaste
                          </p>
                        </div>
                        <div className="col-span-7 mt-2 md:mt-5 pl-2 md:pl-0">
                          <div className="space-y-1">
                            <input
                              type="text"
                              name="otherSubcaste"
                              autoComplete="off"
                              placeholder="Enter Your Sub-Caste"
                              className="form-control  shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[3px] focus:ring-[#2D2C6F] border-[1px] border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                              value={inputSubCasteValue}
                              onChange={(e) => {
                                // formik.setFieldValue("casteId", "");
                                // formik.setFieldValue("subcaste", "");
                                // formik.setFieldValue("otherCaste", );
                                // formik.setFieldValue("otherSubcaste",e.id)
                                handleSubCasteInputChange(e);
                                // formik.handleChange();
                              }}
                              // onChange={handleSubCasteInputChange}
                            />

                            {inputSubCasteValue?.trim() !== "" &&
                              matchedSubCastes.length > 0 && (
                                <div
                                  className="absolute bg-[#ffffff] z-10 w-[15rem] overflow-y-auto"
                                  style={{
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    minWidth: "90%",
                                    maxHeight: "254px",
                                    height: "auto",
                                    maxHeight: "254px",
                                  }}
                                >
                                  <p className="text-[#000000] text-[15px] border-b border-[#E9E9E9] px-5 py-3">
                                    Do you Mean
                                  </p>
                                  <ul
                                    ref={(ulElement) => {
                                      // Access the ul element and calculate its height
                                      if (ulElement) {
                                        const ulHeight =
                                          ulElement.getBoundingClientRect()
                                            .height;
                                        if (ulHeight > 254) {
                                          ulElement.style.maxHeight = "254px";
                                        }
                                      }
                                    }}
                                  >
                                    {matchedSubCastes.map((subcaste) => (
                                      <li
                                        key={subcaste.id}
                                        className="px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer"
                                        onClick={() =>
                                          handleSubCasteSelection(subcaste)
                                        }
                                      >
                                        {subcaste.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {isEdit ? (
                    <div className="flex w-full space-x-8 mt-8">
                      <button
                        disabled={formik.isSubmitting}
                        type="submit"
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-full`}
                      >
                        {formik.isSubmitting ? "Saving..." : "Save"}
                      </button>
                    </div>
                  ) : (
                    <div className="flex w-full space-x-8 mt-8 px-4">
                      <button
                        disabled={formik.isSubmitting}
                        onClick={onConfirm}
                        type="button"
                        className={`text-[14px] font-segeo font-semibold text-[#7A7A7A] border border-[#9F9F9F] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        Confirm
                      </button>
                      <button
                        onClick={() => setIsEdit(true)}
                        className={`text-[14px] font-segeo font-semibold text-[#FFFFFF] bg-[#D10A11] p-1  px-3 rounded-[5px] w-[50%]`}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AddFamilyMemberPopup = (props) => {

  const dropdownData = useSelector(
    (state) => state?.Home?.rData?.data,
  );
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  const closePopupRef = useRef();
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const [parentError, setParentError] = useState("");
  const [maxError, setMaxError] = useState("");

  const [familyData, setFamilyData] = useState([]);
  const [addMoreData, setAddMoreData] = useState([])

  const familyDataLength = familyData?.length;
  const isParentNumberAvailable = userData?.parentsNumberAvailable == "No";


  useEffect(() => {
    fetchGroupData()
    dispatch(registerDetail());
  }, [])

  const fetchGroupData = async () => {
    const profileId = userData?.profileId;
    if (!profileId) {
      console.error('ProfileId not available');
      return;
    }

    const { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
    );


    if (statusCode === 200) {
      if (data.status === 'Success') {
        setFamilyData(data.data.groupMembers);
    }
  }
  }

  const addedNumber = [
    ...familyData?.map(data => data?.parentsMobileNumber),
    ...addMoreData
  ]

  

  useEffect(()=>{
    let handler = (e) => {
      if(!closePopupRef.current.contains(e.target)){
        if(window.innerWidth < 768){
          if(isSuccess){
            props.close("success");
          }else{
            props.close()
          }
        }
      }
    }
    document.addEventListener('mousedown',handler)

    return() => {
      document.removeEventListener('mousedown',handler)
    }
  })

  const initialValues = {
    fields: [
      {
        isdcode: "+91",
        relationid: "",
        number : "",
        parentcheck:false
    }
    ],
  };
  
  const validationSchema = Yup.object({
    fields: Yup.array().of(
      Yup.object({
        relationid: Yup.string().required("Please select relation"),
          number: Yup.string()
          .test("india", "number should have 10 digits", function (value, fieldData) {
            const { isdcode } = this.parent;
            if (isdcode === '+91') {
              return !value || value.length === 10 ? true : fieldData.createError({ message: "Number should have 10 digits", path: fieldData?.path });
            }
            return true;
          }).test("other", "Please enter valid Phone Number", function (value,fieldData) {
            const { isdcode } = this.parent;
            if (isdcode !== '+91') {
              return !value || (value.length >= 7 && value.length <= 15) ? true : fieldData.createError({ message: "Please enter valid phone number", path: fieldData?.path});
            }
            return true;
          })
          .notOneOf(addedNumber, "Mobile Number Already Exist")
          .required("Please enter phone number"),
      })
    ).test('unique', 'Duplicate numbers are not allowed', (value,fieldData) => {

      let arrayofvalues = value?.map(data => data?.number);

      const duplicates = arrayofvalues.filter((item, index) => arrayofvalues.indexOf(item) < index);

      if(duplicates.length > 0){
        setParentError("Mobile Number Already Exist")
        return false;
      }else{
        setParentError("")
        return true;
      }

    })
    ,
  });

  const handleSubmit = async (values) => {

    var count = 0;

    setParentError("")
    values?.fields?.forEach((val) => {

      if(val?.parentcheck == true){
        count=count + 1;
      }

    })
    if(count > 1){
      setParentError("Please Check Only One Parent Checkbox");
      return ;
    }

    if(isParentNumberAvailable){
      let containsParent = values?.fields?.filter(val => val.parentcheck == true)
      if(containsParent?.length == 0){
        addfamilymember(values?.fields);
      }else{
        values?.fields?.forEach((val) => {
          if(val?.parentcheck == true){
            duplicateParentsNumber(val?.number,values?.fields)
          }
        })
      }
    }else{
      addfamilymember(values?.fields);
    }

  }

  const duplicateParentsNumber = async (number,fields) => {

    let request = {
      parentsMobileNumber: number
    };
  
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.validateParentsNumberUrl()}`,
      request
    );
      if (data.data.status === "Success") {
        if(data.data.data.Messagge){
          setParentError(data.data.data.Messagge);
          return;
       }else{
          setParentError("");
          setLoading(true);
          setShow(false);
          addfamilymember(fields);
        }
      }
  }

  const addfamilymember = async (values) => {
    
    let famarray =[];
    
    values.map((member) => {
      famarray.push({
        kmcmessageid: props?.messageId,
        fromchat: props?.from === "family-ad" ? "Y" : null,
          parentsMobileNumber: member.number,
          parentsIsdcode: member.isdcode,
          loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
          relationshipId: member.relationid,
          isParentsNumber:member.parentcheck ? member.parentcheck : false,
      })
    })

    const request = {
        familyGroup: famarray,
    };

    try {
        const response = await POST(
            `${config.api.API_URL}${EndPoints.createfamilygroupUrl()}`,
            request
        );

        console.log('API Response:', response);

        if (response.statusCode === 200) {
          setLoading(false);
          setIsSuccess(true);
          setShow(false);

        } else if (response.statusCode === 401) {
         
        } else {
            console.error(`Unexpected status code: ${response.statusCode}`);
           
        }
    } catch (error) {
        console.error('Error during API call:', error);
       
    }
  };

  return (
    <>
    {loading ? 
      <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
        :
        isSuccess ? 
        <div className="flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6" ref={closePopupRef}>
        <div className="hidden md:block lg:block">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close("success");
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
             
            </h3>
          </div>
          <div className='relative p-6 flex-auto'>
            <div className='flex flex-col justify-center items-center w-[60vw] md:w-[30vw] '>
              <span className='text-[13px] md:text-[16px] lg:text-[16px] font-semibold whitespace-nowrap'>
                Family Members Added Successfully
              </span>
              <img className='w-16 h-16 mt-2' alt='' src={SuccessIcon} />
            </div>
          </div>
        </div>
      </div>
        </div>
        : <></>
    }
    {show &&
    <div className="flex justify-center bg-opacityblack  items-end md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative lg:w-[50vw] md:w-[70vw] w-full md:my-6 lg:my-6 mx-6" ref={closePopupRef}>
        <div className="hidden md:block lg:block">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {props.close();}}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="rounded-t-[3rem] md:rounded-lg lg:rounded-lg border-0  shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center px-4 pt-8 justify-start mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className='relative px-6 pb-6 pt-3 flex-auto'>
            <div className='flex-auto items-center'>
              <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              >
                {({ values, errors, touched,handleChange, setFieldValue,validateForm,setFieldError,setFieldTouched }) => (
                  <Form>
                    <FieldArray name="fields">
                      {({ insert, remove, push }) => (
                        <div>
                          <div className={`overflow-y-auto  ${values.fields.length > 3 ? "h-[17rem] md:h-[17rem] lg:h-[17rem] xl:h-[17rem] lite-scrollbar" : "h-auto md:h-auto lg:h-auto xl:h-auto scrollbar-none" } `}>
                            {values?.fields?.length > 0 ?
                              values.fields.map((field, index) => (
                                <div key={index} className="py-2">
                                  <div className="flex-1 justify-center items-center">
                                    <div className="flex space-x-2 my-2 ">
                                    <label className="selectheight font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[9rem] xl:w-[10rem] 2xl:w-[10rem]">
                                        <select
                                          name={`fields.${index}.isdcode`}
                                          defaultValue={"+91"}
                                          onChange={handleChange}
                                          className="!min-h-[2.8rem] my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]"
                                        >
                                          {dropdownData?.isdcode?.map((e, i) => (
                                            <option key={i} value={e.isdcode}>
                                              {e.isdcode} , {e.country}
                                            </option>
                                          ))}
                                        </select>
                                      {errors.fields?.[index]?.isdcode && touched.fields?.[index]?.isdcode ? (
                                            <p className="text-[#D10A11] text-[12px]">{errors.fields[index].isdcode}</p>
                                          ) : null}
                                      </label>

                                      <label className="flex-1 w-full">
                                        <input
                                          name={`fields.${index}.number`}
                                          value={field.number}
                                          type={"tel"}
                                          onChange={(e) => {
                                            if(validateNumber(e.target.value)){
                                              handleChange(e)
                                            }
                                            setParentError("")
                                          }}
                                          placeholder="Enter Phone Number"
                                          className="no-spinner p-3 my-1 w-[11.3rem] md:w-full  text-[13px] font-Poppins !text-[#575556]  rounded-[3px] border border-[#C4C4C4] placeholder:!text-[#575556] font-semibold"
                                        />
                                      {errors.fields?.[index]?.number && touched.fields?.[index]?.number ? (
                                            <p className="text-[#D10A11] text-[12px]">{errors.fields[index].number}</p>
                                          ) : null}
                                      </label>

                                      <label className="selectheight select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[12rem]">
                                    <select
                                      name={`fields.${index}.relationid`}
                                      defaultValue={null}
                                      onChange={(e) => {
                                        if(isParentNumberAvailable){
                                          if(e.target.value == "5" || e.target.value == "6"){

                                            setFieldValue(`fields.${index}.parentcheck`, true);

                                            values?.fields.map((val,indexes) => {
                                              if(indexes != index){
                                                setFieldValue(`fields.${indexes}.parentcheck`, false);
                                              }
                                            })


                                          }else{
                                            setFieldValue(`fields.${index}.parentcheck`, false);
                                          }
                                        }
                                        setParentError("")
                                        handleChange(e)
                                      }}
                                      value={field.relationid ?? "Select Relation"}
                                      className=" !min-h-[2.8rem] my-1 !text-[#575556] font-Poppins text-[12px] md:text-[12px] lg:text-[13px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  placeholder:text-[#575556]"
                                    >
                                      <option defaultValue hidden>Select Relation</option>
                                      {dropdownData?.relationShip?.map((e, i) => (
                                        <option key={i} value={e.id}>
                                          {e.name}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.fields?.[index]?.relationid && touched.fields?.[index]?.relationid ? (
                                            <p className="text-[#D10A11] text-[12px] font-medium">{errors.fields[index].relationid}</p>
                                          ) : null}
                                  </label>
                                    </div>
                                          </div>
                                  {isParentNumberAvailable &&
                                  <div className={`flex justify-between gap-2 md:gap-8 lg:gap-8 flex-col md:flex-row ${field.parentcheck ? "" : field?.relationid == "5" || field?.relationid == "6" ? "" : "hidden"}`}>
                                      <div className='flex items-center gap-2'>
                                        <input
                                              type="checkbox"
                                              name={`fields.${index}.parentcheck`}
                                              checked={field.parentcheck}
                                              onChange={(e) => {
                                                // setFieldValue(`fields.${index}.parentcheck`, false);
                                                values?.fields.map((val,indexes) => {
                                                  if(indexes != index){
                                                    setFieldValue(`fields.${indexes}.parentcheck`, false);
                                                  }else{
                                                    setFieldValue(`fields.${index}.parentcheck`, true);
                                                  }
                                                })
                                                setParentError("")
                                                handleChange(e)
                                              }}
                                              className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative cursor-pointer`}
                                            />
                                        <p className="text-gray-600 text-[14px] md:text-[12px] lg:text-[12px]" >
                                          Add this number to my profile as Parent's Number
                                        </p>
                                      </div>
                                  </div>
                                  }
                                  
                                </div>
                              )) :  <Loader />}
                          </div>
                          <div className="flex justify-end">
                                  <div className="flex gap-2 items-center">
                                    <p className={` text-[13px] text-gray-600" text-[#D10A11] cursor-pointer flex items-center gap-2 font-semibold`} 
                                      onClick={async () => {
                                        const errors = await validateForm();
                                        
                                        if(Object.keys(errors).length == 0 || errors?.fields?.length == 0){
                                          if((familyDataLength + values.fields.length) < 6){
                                            push({isdcode: "+91",number: "",relationid:""})
                                          }else{
                                            setMaxError("You can add upto 6 members only");
                                          }
                                        }else if((!(familyDataLength + values.fields.length < 6))){
                                          setMaxError("You can add upto 6 members only");
                                        }else{
                                          if((familyDataLength + values.fields.length) < 6){
                                            errors?.fields?.forEach((field,index) => {
                                              if(field !== undefined){
                                                setFieldError(`fields.${index}.relationid`, field?.relationid);
                                                setFieldError(`fields.${index}.number`, field?.number);
                                                setFieldTouched(`fields.${index}.relationid`,true);
                                                setFieldTouched(`fields.${index}.number`,true);
                                              }
                                            })
                                          }else{
                                            setMaxError("You can add upto 6 members only");
                                          }
                                        }
                                        }
                                      }
                                      >
                                      <img src={AddGroupIcon} alt="" className="w-4 h-4"/> 
                                      Add more members
                                    </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                    {parentError && <p className="text-[#D10A11] text-[12px]">{parentError}</p>}
                    {maxError && <p className="text-[#D10A11] text-[12px]">{maxError}</p>}
                    <div className="flex justify-center py-4 items-center space-x-8 ">
                              <button
                                type="submit"
                                className="flex items-center cursor-pointer gap-2 justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                              >
                                <span>Send Invite</span> <img alt="" className="w-4 h-4" src={Inviteicon} />
                              </button>
                            </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
}
    </>
  );
};

const ReInviteModal = ({ onClose, handleSmsShare, handleWhatsAppShare ,userData ,parentsNumber}) => {

  console.log(userData?.reinvitecontent,"reinvitecontent");


  const reinvitecontent = userData?.reinvitecontent;

 
 
 
  return (
    <div className="flex justify-center bg-opacityblack75 items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative lg:w-[25vw] md:w-[39vw] mb-[3rem] md:mb-0 w-[90vw] modal-container md:my-6 md:mx-6"
      >
       
        <div className="border-0 rounded-md shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-2xl relative py-8 flex-auto">
            <div className="flex flex-col"> 
              <div className="grid md:grid lg:flex grid-cols-3 gap-5 items-center pt-[0.5rem]">
               <div className=" flex lg:hidden">
                <button
                // onClick={handleSmsShare}
                onClick={() =>
                  window.open(
                    Constants.kalyanSupportMessageLink(
                      parentsNumber,reinvitecontent
                    )
                  )
                }
                
                className="block w-full text-white flex flex-col items-center cursor-pointer"
              >
                <img className="w-[2rem] h-[2rem]" src={messageicon} alt=""/>
                <p className="text-[#000000] font-medium   font-Poppins pt-1 text-[8px]">Messages</p>
              </button>
              </div> 
              <div className="  block w-full text-white flex flex-col items-center cursor-pointer">
              <button
                // onClick={handleWhatsAppShare}
                onClick={() =>
                  window.open(
                    Constants.kalyanSupportWhatsappLink(
                      parentsNumber,reinvitecontent
                    )
                  )
                }

                
                className=" flex flex-col items-center cursor-pointer "
              >
                <img  className="w-[2rem] h-[2rem]" src={whatsappIcon} alt=""/>
                <p className="text-[#000000] font-medium  font-Poppins pt-1 text-[8px]">WhatsApp</p>
              </button>

              </div>
              <div className="lg:hidden block w-full text-white flex flex-col items-center cursor-pointer">
              <button
                  onClick={() =>
                    window.open(
                      `mailto:?subject=Join Kalyan Matrimony&body=${reinvitecontent}`
                    )
                  }
                  className=""
                >
                  <img className="w-[2rem] h-[2rem]" src={emailIcon} alt=""/>
                  <p className="text-[#000000] font-medium  font-Poppins pt-1 text-[8px]">Gmail</p>
                </button>
                </div>
              <LoginSocialFacebook
          isOnlyGetToken
          appId="606506484678232"
          onResolve={({ provider, data }) => {
            // getPhotos(data?.accessToken);
            // setToken(data?.accessToken);
            // setProvider("facebook")
          }}
          scope="user_photos"
          onReject={(err) => {
            console.log(err);
          }}
        >
          <div className="block lg:hidden w-full text-white flex flex-col items-center cursor-pointer" onClick={() => { }}>
            <img src={facebookIcon} className="w-[2rem] h-[2rem]" loading="lazy" alt="" />
            <p className="text-[#000000] font-medium   font-Poppins pt-1 text-[8px]">
            <a href="https://www.facebook.com/login.php?skip_api_login=1&api_key=606506484678232&kid_direct"
                  className="cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >Facebook</a>
                </p>
          </div>
        </LoginSocialFacebook>
      
        <InstagramLogin
          clientId="266374166312609"
          buttonText="Upload from Instagram"
          scope="user_profile,user_media"
          cssClass="lg:hidden h-[35px] text-[10px] md:text-[12px] lg:text-[14px] text-[white] font-bold cursor-pointer rounded-xl"
          onSuccess={(response) => {
            console.log("LoginSocialInstagram", response);
            // getInstaAccessToken(response)
            // setProvider("instagram")
          }}
          onFailure={(data) => {
            console.log("LoginSocialInstagram - fail", data);
          }}
        >
          <div className="block w-full text-white flex flex-col items-center cursor-pointer" onClick={() => { }}>
            <img src={instragramIcon} className="w-[2rem] h-[2rem]" loading="lazy" alt="" />
           
              <p className="text-[#000000] font-medium  font-Poppins pt-1 text-[8px]">
                <a href="https://www.instagram.com/accounts/login/?force_authentication=1&enable_fb_login=1&next=%2Foauth%2Fauthorize%3Fclient_id%3D266374166312609%26response_type%3Dcode%26scope%3Duser_profile%2Cuser_media%26redirect_uri%3Dhttps%3A%2F%2Fv2-uat.kalyanmatrimony.com%2Fdashboard%26logger_id%3D7e472074-54f8-438c-838d-d9ed56a3a5c0"
                  className="cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >Instagram
                </a>
              </p>
         
          </div>
        </InstagramLogin>
              </div>
            

            </div>
                 <div className="border-t mt-[2rem]">
            <button
              onClick={onClose}
              className="mx-auto flex items-center cursor-pointer justify-center  text-[#D10A11]  whitespace-nowrap rounded-[5px] font-segoe font-semibold pt-[1rem] md:text-[12px] lg:text-[12px]"
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
   
  )
}
  

const RemovePopup = ({ title, OnClose, parentsNumber, relationshipid, setIsLoading, apiCallFamilyData }) => {
  const popupRef = useRef(null);

  const handleRemoveFamilyNumber = async () => {
    setIsLoading(true);
    try {
      const request = {
        parentsMobileNumber: parentsNumber,
        relationshipId: relationshipid,
      };

      console.log('Remove Family Request:', request);

      const response = await POST(
        `${config.api.API_URL}${EndPoints.removefamilygroupUrl()}`,
        request
      );

      console.log('API Response:', response);

      if (response.statusCode === 200) {
        // Handle success

        apiCallFamilyData();
      } else if (response.statusCode === 401) {
        // Handle unauthorized error
      } else {
        console.error(`Unexpected status code: ${response.statusCode}`);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    } finally {
      setIsLoading(false); // Ensure loading state is cleared
      OnClose();
    }
  };

 const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      OnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-[100%] md:w-[55%] lg:w-[35%] md:my-6 md:mx-6"
        ref={popupRef}
      >
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={OnClose}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <div className="space-y-2">
              <p className=" font-semibold text-[#1A1A1A] text-[16px]">
                {title}
              </p>

              <p className="text-[12px] text-[#7A7A7A] pt-[0.6rem]">Do You Really Want to Remove this Member?</p>
            </div>

            <div className="flex py-3 justify-center gap-5 pt-[2rem]">
              <button
                onClick={OnClose}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] xl:w-[10rem] text-[#7A7A7A] rounded-[4px] border border-[#707070]"
              >
                Cancel
              </button>

              <button
                onClick={handleRemoveFamilyNumber}
                className="text-[13px] py-1.5 w-[9rem] lg:w-[8rem] bg-[#D10A11] xl:w-[10rem] text-[#ffffff] rounded-[4px] border border-[#D10A11]"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const CommonRewardPopup = ({props ,OnClose ,closePopup ,setopenpopup}) => {
  const [showPopup, setShowPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const popupRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      handelCloseClick();
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleOnclick = () => {
    setShowPopup(true);
  };
  const { completeNow,setCompleteNow ,clickAddHabits, setClickAddHabits ,
    clickAddFamily, setClickAddFamily,clickAddAstro, setClickAddAstro,
    clickAddHoro, setClickAddHoro} = useContext(NavBarContext);

  const [completed, setCompleted] = useState(false);
  const [isEditProfileLoading, setIsEditProfileLoading] = useState(true);
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({
    url: "",
    flag: "",
    show: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
   const [packStatusData,setPackStatusData] = useState(null)
  const handleCompleteNow = () => {
    // Handle completion logic here
    setCompleted(true);
  };



  const handelCloseClick = () =>{
    setCompleteNow(false)
    setopenpopup(false)
  }

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  console.log(userData?.popup[0]?.notificationId,"notificationId");


  const AstroOnClick = () => {
    setClickAddAstro(true);
    setCompleteNow(false)
    
  };


console.log(packStatusData,"packStatusData");
  const handleAddFamilyClick = () => {
    setClickAddFamily(true);
    setCompleteNow(false)
  };


  const handleAddHabitsClick = () => {
    setClickAddHabits(true);
    setCompleteNow(false)
  };

  const handelAddHoroClick = () => {
    setClickAddHoro(true);
    setCompleteNow(false)
  };

  console.log(clickAddAstro, 'clickAddAstro');

  useEffect(() => {
    console.log("clickAddHoro changed:", clickAddHoro); // This should log the updated state
  }, [clickAddHabits]);

  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);

      if (setHoroscope) {
        setgeneratedHoroscopeurl({
          url: data?.data?.horoscope,
          flag: data?.data?.horoflag,
          show: false,
        });
      }
    } else if (statusCode === 401) {
      logout();
    }
    setIsEditProfileLoading(false);
  };

  useEffect(() => {
    apiCallEditProfile();
   
  }, []);

 
    const postData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          EndPoints.trialpackstatus(),
          {}, // Empty body
          {
            headers: {
              'Content-Type': 'application/json', // Adjust headers if necessary
            },
          }
        );
        if (response.status === 200 && response.data.status === "Success") {
          setPackStatusData(response.data.data);
        } else {
          setError('Failed to fetch data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };


    useEffect(() => {

    postData();
    
  }, []);

  const [ userDetails,setUserDetails] =useState(false)
  


  // const notificationId = userData?.popup[0]?.notificationId

  const [showClaimSuccessPopup,setshowClaimSuccessPopup] = useState(false)


  const ClaimNowClick = async () => {
    // Extract the notificationId
    const notificationId = userData?.popup[0]?.notificationId;
  
    // Check if notificationId is available
    if (!notificationId) {
      console.error("Notification ID is missing");
      return;
    }
  
    let request = {
      notificationId: notificationId, // Add notificationId to the request
    };
  
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.claimChottaPack()}`,
      request
    );
  
    if (statusCode === 200) {
      if (data.data.status === "Success") {
        setshowClaimSuccessPopup(true);
        setCompleteNow(false)
      }
      // Handle success
    } else if (statusCode === 401) {
      logout();
    }
  };
  

  const [showCrop, setShowCrop] = useState(false);
  const [currentimage, setCurrentImage] = useState();
  const [originalImage, setOriginalImage] = useState();
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [photoId, setPhotoId] = useState();
  const { setMenuSelect } = useContext(NavBarContext);
  const location = useLocation();

  useEffect(() => {
    console.log(props, "contentResponseData");
  }, [showCrop, currentimage, originalImage]);

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  const handleCrop = (file) => {
    console.log("handle Crop", file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Height and Width must be more than 100px",
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Image size should not be greater than 10mb",
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file, originalImageUpload);
        setCompressing(true);
        // handleImageSizeCompress(file,originalImageUpload,setCompressing)
        setCurrentImage(readerStr || "");
      },
    });
  };
  const cropedImage = (cropedImg) => {
    // handleUpload(originalImage, cropedImg);
    imageSizeReducer(cropedImg, handleUpload);
    setShowCrop(false);
  };

  const handleOriginalImageUpload = async (file) => {
   
    const image = file;
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        console.log(compressedResult, "compressedResult");
        originalImageUpload(compressedResult);
      },
    });
  };

  const originalImageUpload = async (compressedImageUrl) => {
    console.log(compressedImageUrl, "compressedImageUr");
    setOriginalImage(compressedImageUrl);
    let fileData = new FormData();
    fileData.append("email", loginDetail()[0]);
    fileData.append("userId", loginDetail()[1]);
    fileData.append("photoUploadFrom", getDeviceInfo());
    fileData.append("uploadfrom", "Gallery");
    fileData.append("original", compressedImageUrl);
    fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
    fileData.append("contentId", props?.contentId ? props?.contentId : "");
    setIsUploading(true);
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
      fileData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    setIsUploading(false);
    console.log(data, "datadatadatadata");
    if (data.data.statusCode === 200) {
      if (data.data.data.status === "Success") {
        setPhotoId(data.data.data.photoId);
        setShowCrop(true);
      } else {
        props.close();
      }
    } else {
      props.close();
    }
  };

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let notificationId = null;
      const loginUrl = sessionStorage.getItem(
        Constants.loginLocalStorageKeys.loginUrl
      );

      if (loginUrl?.includes("photo") || props?.isFromInitialPopup) {
        notificationId = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.notificationId
        );
        sessionStorage.removeItem(
          Constants.loginLocalStorageKeys.notificationId
        );
      }
      console.log(notificationId, "notificationId");
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      if (notificationId) {
        fileData.append("notificationId", notificationId);
      }
      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsUploading(false);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setshowSuccessPopup(true);
          if (props.isFrom == "contentcard") {
            props?.callBackReload();
          }
          props?.close();
          props?.callBack();
        } else {
          props.close();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.close();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  const logoutUsignAPI = async () => {
    let data = await axios.post(`${config.api.API_URL}${EndPoints.logout()}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status === 200) {
      setShowLoader(false);
      logout();
    }
  };



  useEffect(() => {}, [props?.menu]);


  const [isIdsProofUploading, setIsIdsProofUploading] = useState(false)
  const [isEducationProofUploading, setIsEducationProofUploading] = useState(false)
  const [isIncomeProofUploading, setIsIncomeProofUploading] = useState(false)

  const handleUploadDoc = async (file, type) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("trustDocumentUploadFrom", getDeviceInfo());
      fileData.append("documents", file);
      fileData.append("type", type);

    
      if (type === "idproof") {
        setIsIncomeProofUploading(true)
      } 



      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.trustDocumentUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );


      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          // alert("Image upload succesfully");
          setSuccessPopup({ show: true, content: "Document upload succesfully" })
          props.callBackReload();
          apiCallEditProfile()

        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }
    setIsIdsProofUploading(false)
    setIsEducationProofUploading(false)
    setIsIncomeProofUploading(false)
    apiCallEditProfile()
  };

  const [successPopup, setSuccessPopup] = useState({ show: false, content: "" })


  const onHoroScopeFileChange = (file, type) => {
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({ title: "Invalid image", description: "Height and Width must be more than 100px" })
      },
      onExtensionError: (fileType) => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError: (img) => {
        showNotification({ title: "Invalid image", description: "Image size should not be greater than 10mb" })
      },
      onSuccess: (img, readerStr) => {
        handleUploadDoc(file, type)
      }
    })
  }



  const TrustMarkImageUpload = (props) => {

    return (
      <label >
       {props?.menu=="mobile" ?
       <div >
     
         <p className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
         >
           {props.title}
         </p>
         
        
           </div>
       :
        <div className ="grid grid-cols-7 items-center gap-6 space-x-2 lg:spacex-3 whitespace-nowrap">
        <div className="col-span-4">
          <p className="text-[#000000] text-[13px] xl:text-[13px] 2xl:text-[14px] flex justify-center font-bold">
            {props.title}
          </p>
          </div>
          <div className="col-span-3">
          <img
               className="w-5 h-5"
              src={props.icon} 
              alt=""
            />
            </div>
            </div>
            }

        <input
          style={{ display: "none" }}
          type="file"
          onChange={(event) => {
            // //console.log("file", URL.createObjectURL(event.target.files[0]));
            if (event.target.files[0]) {
              onHoroScopeFileChange(event.target.files[0], props.type);
            }
          }}
          accept="image/jpeg, image/png"
        />
       
      </label>
    );
  };

const [uploadPhotos,setUploadPhotos] = useState(false)

const photoUpload = () =>{
  setUploadPhotos(true)
}

const RupeeSymbol = () => <span className="rupeefont">₹</span>;

  return (
    <>

{showCrop && (
          // <ImageCroperComponent
          //   close={() => setShowCrop(false)}
          //   image={currentimage}
          //   imgType={originalImage.type}
          //   callBackCroper={cropedImage}
          // />
          <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
              if (isCropped) {
                setShowCrop(false);
              } else {
                props?.close();
              }

              if (props?.callBackReload) {
                props?.callBackReload();
              }
            }}
          />
        )}

        {showSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowSuccessPopup(false);
            }}
            title={"Success!"}
            content={"Image upload success"}
          />
        )}

          {showClaimSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setshowClaimSuccessPopup(false);
            }}
            title={"Success!"}
            content={"Chota Pack Successfully Credited"}
          />
        )}
        {clickAddAstro  &&
        <CommonAstroPopup editMyProfileData={editMyProfileData} postData={postData} OnClose={()=>setClickAddAstro(false)}/>}

        {clickAddHabits && 
        <CommonHabitPopup editMyProfileData={editMyProfileData} postData={postData} OnClose={()=>setClickAddHabits(false)}/>}

        {clickAddFamily && 
        <CommonFamilyPopup editMyProfileData={editMyProfileData} postData={postData} OnClose={()=>setClickAddFamily(false)} />}

        {clickAddHoro && 
        <CommonHoroPopup editMyProfileData={editMyProfileData} postData={postData} OnClose={()=>setClickAddHoro(false)}/>}

        {uploadPhotos && <UploadPhotoPopuplite  title={"Add Photo"} close={()=>setUploadPhotos(false)}/>}

        {completeNow &&
      
       
       
      <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      
  
      <div  ref={popupRef} className="relative w-[100%]  md:w-[75%] xl:w-[49%] 2xl:w-[40%] lg:w-[61%] md:my-6 md:mx-6">
       <div className="">
     
         <button
           className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
           onClick={() => handelCloseClick() }
         >
           <img className="h-6 w-6 " src={CloseIcon} alt="" />
         </button>
      
       </div>
       <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
         <div className="relative p-6 flex-auto">
           <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

          
             <div className="items-center  pt-2 justify-center  text-center ">
             
                {packStatusData?.showclaimbutton == "Y"?
                 <div>
                 <p className="text-[#1A1A1A] flex    font-semibold justify-center  text-center  text-[15px] items-center px-5 py-1 ">
                 Congrats! Click Claim Now and <br />
                 </p>
                 <span className="flex font-semibold justify-center text-[15px] text-center gap-2"> Claim Your
                  <p className="text-[#D10A11] items-center font-semibold flex justify-center  text-[15px] text-center ">
                 Reward
                 </p> </span>
               </div>
                :

                 
                 <div>
                   <p className="text-[#1A1A1A] flex    font-semibold justify-center  text-center  text-[16px] items-center px-5 py-1 ">
                     A Complete Profile gets more <br />
                   </p>
                   <p className="text-[#D10A11] items-center font-semibold flex justify-center  text-[16px] text-center ">
                     Responses!
                   </p>
                 </div>}
               
      
             <div>
               <div className="text-center justify-center text-[14px] font-semibold  md:mx-[7rem] pb-[2rem] py-[2rem]">
                 <p className="text-[#6D6D6D]">Your Profile Quality Score</p>
               </div>

               <div className="cursor-pointer ">
                 <StaticCircularProgressBar completed={completed} value={packStatusData?.trailpackscore} packStatusData={packStatusData}/>
               </div>

               <div className="md:grid text-start md:grid-cols-3 gap-6  text-[13px] xl:text-[13px] 2xl:text-[14px] ml-[1rem] font-semibold mt-[2rem] hidden md:block">
                 <div className="flex w-[100%] ">
                   <div className="w-[66%]">
                     <a


                       href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1"
                       target="_blank"
                       className=" text-[#000000]"
                     >
                       Download App
                     </a>
                   </div>
                   <div className="w-[20%]">
                     <a
                      target="_blank"
                       href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1">
                     <img className="w-5 h-5" src={`${packStatusData?.appstatus == "Y" ? redtickicon : resplusicon}`} /></a>
                   </div>
                 </div>
                 <div className="flex w-[100%]">
                   <div className="xl:w-[76%] lg:w-[78%] 2xl:w-[80%]">
                     <p className=" text-[#000000]">
                       Enter Astrological Information
                     </p>
                   </div>
                   <div className="w-[20%]">
                    {packStatusData?.astrostatus == "Y" ?
                     <button >
                     <img
                       className="w-5 h-5"
                       src={`${packStatusData?.astrostatus == "Y" ? redtickicon : resplusicon}`}
                     />
                   </button>:
                   <ActionRestrict remove={false}>
                     <button onClick={AstroOnClick}>
                       <img
                       alt=""
                         className="w-5 h-5"
                         src={`${packStatusData?.astrostatus == "Y" ? redtickicon : resplusicon}`}
                       />
                     </button>
                   </ActionRestrict>
                    }
                   </div>
                 </div>
                 <div className="flex w-[100%]">
                   <div className="xl:w-[71%] lg:w-[71%]">
                     <p className=" text-[#000000]">Add Your Family Details</p>
                   </div>
                   <div className="w-[20%]">
                    {packStatusData?.familystatus == "Y"  ?
                      <button >
                      <img
                        className="w-5 h-5"
                        src={`${packStatusData?.familystatus == "Y"  ? redtickicon : resplusicon}`}
                      />
                    </button>
                     :
                     <ActionRestrict remove={false}>
                     <button onClick={handleAddFamilyClick}>
                       <img
                       alt=""
                         className="w-5 h-5"
                         src={`${packStatusData?.familystatus == "Y"  ? redtickicon : resplusicon}`}
                       />
                     </button>
                     </ActionRestrict>
                           }
                   </div>
                 </div>
                 <div className="flex w-[100%] whitespace-nowrap">
                   <div className="w-[66%] text-initial">
                     <p className=" text-[#000000]">Add Your Habit</p>
                   </div>
                   <div className="w-[20%]">
                    {packStatusData?.habitstatus == "Y" ?
                     <button >
                       <img
                         className="w-5 h-5"
                         src={`${packStatusData?.habitstatus == "Y" ? redtickicon : resplusicon}`}
                       />
                     </button>
                     :
                     <ActionRestrict remove={false}>
                     <button onClick={handleAddHabitsClick}>
                     <img
                     alt=""
                       className="w-5 h-5"
                       src={`${packStatusData?.habitstatus == "Y" ? redtickicon : resplusicon}`}
                     />
                   </button>
                     </ActionRestrict>
                               }
                   </div>
                 </div>
                 <div className="flex w-[100%] items-center  whitespace-nowrap">
                 <div className="flex items-center space-x-4">
                {
                  packStatusData?.photostatus == "Y" ?
                  <label className="cursor-pointer flex gap-6 space-x-2">
                          
                           <p className="text-black">Add Your Photo</p>
                     <img

                         className="w-5 h-5"
                         src={`${packStatusData?.photostatus == "Y" ? redtickicon : resplusicon}`}
                       />
                          
                               
                       </label>
                       :
                 <div className="cursor-pointer flex gap-6 space-x-1">
                         <button className="cursor-pointer flex gap-8 space-x-1"  onClick={photoUpload}>
                           <p className="text-black">Add Your Photo</p>
                     <img

                         className="w-5 h-5"
                         src={`${packStatusData?.photostatus == "Y" ? redtickicon : resplusicon}`}
                       />
                         
                         </button>        
                       </div>}

                  
                 </div>
                 </div>
                 <div className=" ">
                 

                  <TrustMarkImageUpload
                  icon={packStatusData?.idproofstatus == "Y" ? redtickicon : resplusicon}
                  title={"Add an ID Proof"}
                   type={"idproof"}
                   isUploading={isIncomeProofUploading}
                 />
                   
                 </div>
                 <div className="grid md:grid-cols-9 lg:grid-cols-9   text-initial ">
                   <div className="col-span-6 lg:col-span-6 text-initial">
                     <p className=" text-[#000000]">
                       Add Horoscope to your profile
                     </p>
                   </div>
                   <div className="col-span-3">
                    {packStatusData?.horoscopestatus == "Y" ?

                  <button >
                  <img
                    className="w-5 h-5"
                    src={`${packStatusData?.horoscopestatus == "Y" ? redtickicon : resplusicon}`}
                  />
                  </button>
                  :
                     <button onClick={handelAddHoroClick}>
                       <img
                         className="w-5 h-5"
                         src={`${packStatusData?.horoscopestatus == "Y" ? redtickicon : resplusicon}`}
                       />
                     </button>
                       }
                   </div>
                 </div>
               </div>
               <div className="md:grid text-start md:grid-cols-3 space-y-6 gap-8 text-[11px] ml-[1rem] font-semibold mt-[2rem] md:hidden">
                 <div className="flex w-[100%]">
                   <div className="w-[70%]">
                   <a
                       href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1"
                       target="_blank"
                       className=" text-[#000000]"
                     >
                       Download App
                     </a>  </div>
                  
                   <div className="w-[20%] gap-3 flex  ">
                   {packStatusData?.appstatus == "Y" ?
                    <span className="flex gap-3">Done<img className="w-5 h-5" src={`${packStatusData?.appstatus == "Y" ? redtickicon : resplusicon}`} />
                   </span> :
                   <button
                   className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
                 >
                    <a
                       href="https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app&pli=1"
                       target="_blank"
                       className=" text-[#ffffff]"
                     >
                      Add
                     </a>
                 </button>}

                 </div>
                
                   
                 </div>
                 <div className="flex w-[100%]">
                   <div className="w-[70%]">
                     <p className=" text-[#000000]">
                       Enter Astrological Information
                     </p>
                   </div>
                   <div className="w-[20%] gap-3 flex  ">
                   {packStatusData?.astrostatus == "Y" ?  
                   <span  className="flex gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                   :
                   <ActionRestrict remove={false}>
                   <button
                   onClick={AstroOnClick}
                   className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
                 >
                   Add
                 </button>
                   </ActionRestrict>
                     }
                   </div>
                 </div>
                 <div className="flex w-[100%] ">
                   <div className="w-[70%]">
                     <p className=" text-[#000000]">Add Your Family Details</p>
                   </div>
                   <div className="w-[20%] gap-3 flex  ">
                   {packStatusData?.familystatus == "Y" ?  
                   <span  className="flex gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                   :
                   <ActionRestrict remove={false}>

                   <button
                   onClick={handleAddFamilyClick}
                   className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
                 >
                   Add
                 </button>
                   </ActionRestrict>
                     }
                   </div>
                 </div>
                 <div className="flex w-[100%] whitespace-nowrap">
                   <div className="w-[70%]">
                     <p className=" text-[#000000]">Add Your Habit</p>
                   </div>
                   <div className="w-[20%] gap-3 flex  ">
                   {packStatusData?.habitstatus == "Y" ?  
                   <span  className="flex gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                   :
                   <ActionRestrict remove={false}>
                   <button
                   onClick={handleAddHabitsClick}
                   className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
                 >
                   Add
                 </button>
                   </ActionRestrict>
                     }
                   </div>
                 </div>
                 <div className="flex w-[100%] whitespace-nowrap">
                   <div className="w-[70%]">
                     <p className=" text-[#000000]">Add Your Photo</p>
                   </div>
                   <div className="w-[20%] gap-3 flex  ">
                   
                   {packStatusData?.photostatus == "Y" ?  
                   <span  className="flex gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                   :
               
                <div className="cursor-pointer flex gap-8 space-x-1">
                <button onClick={photoUpload} className="cursor-pointer flex gap-8 space-x-1">
                          
                           <p   className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md" >Add </p>
                     
                          
                               
                       </button>
                       </div>
                     }
                   
                   </div>
                 </div>
                 <div className="flex w-[100%] whitespace-nowrap">
                   <div className="w-[70%]">
                     <p className=" text-[#000000]">Add an ID Proof</p>
                   </div>
                   <div className="w-[20%] gap-3 flex  ">
                   {packStatusData?.idproofstatus == "Y" ?  
                   <span  className="flex gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                   :
                   <TrustMarkImageUpload
                  icon={packStatusData?.idproofstatus == "Y" ? redtickicon : resplusicon}
                  title={"Add"}
                   type={"idproof"}
                   menu="mobile"
                   isUploading={isIncomeProofUploading}
                 />
                //    <button
                //    onClick={handelAddHoroClick}
                //    className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
                //  >
                //    Add
                //  </button>
                     }
                   </div>
                 </div>
                 <div className="flex w-[100%]  ">
                   <div className="w-[70%]">
                     <p className=" text-[#000000]">
                       Add Horoscope to your profile
                     </p>
                   </div>
                   <div className="w-[20%] gap-3 flex  ">
                   {packStatusData?.horoscopestatus == "Y" ?  
                   <span  className="flex gap-3">Done <img className="w-5 h-5" src={redtickicon} /></span>
                   :
                   <button
                   onClick={handelAddHoroClick}
                   className="bg-[#D10A11] px-[2rem] py-1 text-white rounded-md"
                 >
                   Add
                 </button>
                     }
                   </div>
                 </div>
               </div>
               {packStatusData?.showclaimbutton == "Y" ? (
                 ""
               ) : (
                 <div className="flex justify-center items-center text-center text-[15px] py-[1.5rem]">
                   <p className="text-[#000000] font-semibold">

                     Complete your profile and get chota pack worth <RupeeSymbol/>5000 for
                     {/* {userData?.popup?.content} */}

                     <span className="text-[#D10A11] font-semibold">
                       {" "}
                       FREE!
                     </span>
                   </p>
                 </div>
               )}
             </div>
          
          
             <div
               className={`flex pt-2 pb-2 ${
                packStatusData?.showclaimbutton == "Y" ? "mt-[2rem]" : ""
               } justify-center gap-5`}
             >
           
           { packStatusData?.showclaimbutton == "Y" ? <button onClick={ClaimNowClick}
                 className={`text-[13px] py-1.5 px-[4rem] text-[#FFFFFF] ${packStatusData?.showclaimbutton == "N"?"cursor-not-allowed":"cursor-pointer"} rounded-[6px] font-bold ${
                   packStatusData?.showclaimbutton == "Y" ? "bg-[#039412]" : "bg-[#D10A11]"
                 } `}
                                 >
                 { "Claim Now"}
               </button>
                :
               <button
               
                 className={`text-[13px] py-1.5 px-[4rem] bg-[#E78387] text-[#FFFFFF] cursor-not-allowed  rounded-[6px] font-bold  `}
                                 >
                 { "Complete Now"}
               </button>
               }
                
             </div>
           
         </div>
       </div>
     </div>
      </div>

      </div>
          
       
}
    
       
     
    </>
  );
};
const ShareItem = ({ onClick, text, logo }) => {
  return <div onClick={onClick} className="flex flex-col gap-4 cursor-pointer justify-center items-center">
    <img src={logo} alt="" className="w-[3rem] h-[3rem] md:w-[4rem] md:h-[4rem]" />
    <p className="text-black text-[12px] md:text-[14px]">{text}</p>
  </div>
}

const SharePopup = ({ close, shareContent, copyContent }) => {

  const closePopupRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        if (window.innerWidth < 768) {
          close()
        }
      }
    }
    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  const onWhatsAppClick = () => {
    window.open(Constants.kalyanSupportWhatsappLink(
      "", shareContent
    ))
  }

  const onFbClick = () => {
    window.open(
      Constants.kalyanSupportFb(
        "", shareContent
      )
    )
  }

  const onSmsClick = () => {
    window.open(
      Constants.kalyanSupportMessageLink(
        "", shareContent
      )
    )
  }

  const onMailClick = () => {
    window.open(Constants.kalyanSupportMail(shareContent))
  }



  const handleCopyLink = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
    navigator.clipboard.writeText(copyContent);
  }

  return (
    <div>
      <div className="flex items-end md:justify-center lg:justify-center bg-opacityblack md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="mt-[3rem] relative w-auto mx-auto " ref={closePopupRef}>
          <div className="bg-white h-auto rounded-t-[2rem] md:rounded-[1rem] lg:md:rounded-[1rem] w-[100vw] md:max-w-[60vw] lg:max-w-[45vw]">
            <div className="py-8 px-6 sm:p-6 sm:pb-4 rounded-2xl">
              <div className="hidden md:block lg:block">
                <button
                  className=" bg-transparent z-40 border-0 text-black  absolute top-[-16px] right-[-14px]"
                  onClick={close}
                >
                  <img className="h-8 w-8 " src={CloseIcon} alt="" />
                </button>
              </div>
              <div className="">
                <p className="text-[#D10A11] flex justify-start font-bold text-[16px] md:text-[18px] lg:text-[20px]  pr-[1rem] ">
                  Share Link to Download App
                </p>
              </div>
              <div>
                <div className="flex gap-2 py-4 items-center">
                  <img src={Images.LiteIcon} alt="" className="w-[5rem] h-[5rem]" />
                  <div className="flex flex-col">
                    <p className="font-bold text-black text-[18px] md:text-[20px] lg:text-[22px]">Kalyan Matrimony &reg; Marriage App</p>
                    <p className="text-[#717171] text-[12px]">Share this app</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 lg:grid-cols-6 gap-4 py-4">
                  <ShareItem logo={Images.FaceBookIcon} onClick={onFbClick} text="Facebook" />
                  <ShareItem logo={Images.WhatsAppIcon} onClick={onWhatsAppClick} text="WhatsApp" />
                  <ShareItem logo={messageicon} onClick={onSmsClick} text="SMS" />
                  <ShareItem logo={emailIcon} onClick={onMailClick} text="Email" />
                </div>
                <div className="border-[2px] border-[#ACACAC] rounded-lg p-2 md:p-4 lg:p-4 flex gap-0 md:gap-5 lg:gap-5 justify-between items-center">
                  <p className="text-[10px] md:text-[14px] lg:text-[16px] whitespace-nowrap w-[50vw] md:w-full overflow-hidden text-ellipsis">{copyContent}</p>
                  {isCopied
                    ? <p className="text-[#01875F] text-[10px] md:text-[14px] lg:text-[16px] whitespace-nowrap">Copied</p>
                    : <div className="flex gap-2 items-center cursor-pointer mr-4" onClick={handleCopyLink}>
                      <p className="text-[#01875F] text-[10px] md:text-[14px] lg:text-[16px] whitespace-nowrap">Copy Link</p>
                      <img src={Images.CopyIcon} alt="" className=" w-4 h-4" />
                    </div>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const CommonAstroPopup = ({ OnClose, setOpenPopup ,postData ,editMyProfileData}) => {
  const [showPopup, setShowPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const { completeNow, setCompleteNow ,submitStatus, setSubmitStatus,clickAddAstro, setClickAddAstro,} = useContext(NavBarContext);
 
  const userData = useSelector((state) => state?.DashBoardAPI?.ProfileData?.data);

  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility


  const handelCloseClick = () => {
    if (submitStatus) {
      setCompleteNow(true);
      OnClose();
      setClickAddAstro(false);
    } else {
      setShowErrorPopup(true); // Show the error popup
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };


 

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && window.innerWidth <= 768) {
      OnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}
     
        <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div ref={popupRef} className="relative w-[100%] md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
            <div>
              <button
                className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
                onClick={handelCloseClick}
              >
                <img className="h-6 w-6" src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative p-6 flex-auto">
                <hr className="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />
                <p className="text-[#000000] items-center text-[18px] font-semibold pt-2 justify-center text-center">
                  Astrological Information
                </p>
                <AstroBasic menu="addastro" postData={postData} data={editMyProfileData}  />
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

const CommonHabitPopup = ({props ,OnClose ,close ,setopenpopup ,postData ,editMyProfileData}) => {
  const [showPopup, setShowPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);

  const {submitStatus, setSubmitStatus, completeNow, setCompleteNow ,openModel, setOpenModel ,clickAddAstro, setClickAddAstro, clickAddHabits, setClickAddHabits} = useContext(NavBarContext);
  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility


  const handelCloseClick = () => {
    if (submitStatus) {
      setCompleteNow(true);
      OnClose();
      setClickAddHabits(false);
    } else {
      setShowErrorPopup(true); // Show the error popup
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };


  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );


 

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      OnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
  
  {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}
     
          <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      
     
          <div  ref={popupRef} className="relative w-[100%]  md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
           <div className="">
         
             <button
               className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
               onClick={() => handelCloseClick() }
             >
               <img className="h-6 w-6 " src={CloseIcon} alt="" />
             </button>
          
           </div>
           <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
           <div className="relative p-6 flex-auto">
            <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

           
              <p className="text-[#000000] text-[18px] items-center font-semibold  pt-2 justify-center  text-center">
              Habits
              </p>
           <HabitsBasic  postData={postData}/> 
           </div>
          
           </div>
         </div>
          </div>

          
       

    
       
     
    </>
  );
};

const CommonHoroPopup = ({props ,OnClose ,close ,setopenpopup ,postData ,editMyProfileData}) => {
  const [showPopup, setShowPopup] = useState(false);
  const { completeNow,setCompleteNow ,isGenerating, setIsGenerating,
    clickAddHoro, setClickAddHoro} = useContext(NavBarContext);

  const { loginDetail, logout } = useContext(LoginContext);

  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility

  // const handelCloseClick = () => {
  //   if (isGenerating) {
  //     setCompleteNow(true);
  //     OnClose();
  //     setClickAddHoro(false);
  //   } else {
  //     setShowErrorPopup(true); // Show the error popup
  //   }
  // };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };


  const handelCloseClick = () =>{
    setCompleteNow(true)
    // setopenpopup(true)
    setClickAddHoro(false)
    OnClose()
  }

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      OnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

    return (
    <>
 
 {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}
     
          <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      
     
          <div  ref={popupRef} className="relative w-[100%]  md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
           <div className="">
         
             <button
               className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
               onClick={() => handelCloseClick() }
             >
               <img className="h-6 w-6 " src={CloseIcon} alt="" />
             </button>
          
           </div>
           <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
           <div className="relative p-6 flex-auto">
            <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

           
             
              <HoroBasic menu="addhoro"  postData={postData} data={editMyProfileData}/>
           </div>
          
           </div>
         </div>
          </div>

             
    </>
  );
};
const CommonFamilyPopup = ({props ,OnClose ,close ,setopenpopup ,editMyProfileData ,postData}) => {
  const [showPopup, setShowPopup] = useState(false);
  
  const { completeNow,setCompleteNow ,
    clickAddFamily, setClickAddFamily,
  submitStatus, setSubmitStatus} = useContext(NavBarContext);
  const { loginDetail, logout } = useContext(LoginContext);

  const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility


  const handelCloseClick = () => {
    if (submitStatus) {
      setCompleteNow(true);
      OnClose();
      setClickAddFamily(false);
    } else {
      setShowErrorPopup(true); // Show the error popup
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false); // Hide the error popup
  };
 

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      OnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (
    <>
  {showErrorPopup && (
        <div className="fixed inset-0 flex justify-center items-center z-[1000]">
          <div className="bg-white p-4 rounded shadow-lg z-[1001]">
            <CommonErrorPopup
              message="Click save to close"
              closePopup={handleCloseErrorPopup}
            />
          </div>
        </div>
      )}
     
       
          <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      
     
          <div   className="relative w-[100%]  md:w-[75%] lg:w-[48%] md:my-6 md:mx-6">
           <div className="">
         
             <button
               className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
               onClick={() => handelCloseClick() }
             >
               <img className="h-6 w-6 " src={CloseIcon} alt="" />
             </button>
          
           </div>
           <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
           <div className="relative p-6 flex-auto">
            <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

           
              <p className="text-[#000000] text-[18px] items-center font-semibold  pt-2 justify-center  text-center">
              Family Details
              </p>
           <FamilyBasic menu="addfamily" postData={postData} data={editMyProfileData} />
           </div>
           </div>
         </div>
          </div>

    </>
  );
};        
const CommonQualityPopup = ({OnClose}) => {
 
  const popupRef = useRef(null);
  const [packdata, setPackData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completeNow,setCompleteNow] = useState(false)
  const [openpopup,setopenpopup] =useState(true)
  const isMobile = useMediaQuery({ maxWidth: 678 });


  // const handleClickOutside = (event) => {
  //   if (
  //     popupRef.current &&
  //     !popupRef.current.contains(event.target) &&
  //     window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
  //   ) {
  //     OnClose();
  //   }
  // };

  useEffect(() => {
    if (isMobile) {
      let handler = (e) => {
        if (!popupRef.current.contains(e.target)) {
          OnClose()
        }
      }

      if(openpopup){
        document.addEventListener('mousedown', handler)
  
        return () => {
          document.removeEventListener('mousedown', handler)
        }

      }
    }
  })

  const handelCompleteClick = () =>{
    setCompleteNow(true)
    setopenpopup(false)
  }

  useEffect(() => {
    // Define the function to fetch data
    const fetchData = async () => {
      try {
        const response = await axios.get(EndPoints.trialpackbenefitDetails());
        if (response.status === 200 && response.data.status === "Success") {
          setPackData(response.data.data);
        } else {
          setError('Failed to fetch data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // Call the function to fetch data
    fetchData();
  
  }, []);
  const RupeeSymbol = () => <span className="rupeefont">₹</span>;


  return (
    <>
    {completeNow && 
    <CommonRewardPopup close={()=>setopenpopup(false)} openpopup={openpopup} setopenpopup={setopenpopup}/>}
{openpopup&&
        <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">

          <div ref={popupRef} className="relative w-[100%]  md:w-[82%] lg:w-[45%] md:my-6 md:mx-6">
      <div className="">
        <button
          className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
          onClick={() => {
            OnClose();
          }}
        >
          <img className="h-6 w-6 " src={CloseIcon} alt="" />
        </button>
      </div>
      <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
        <div className="relative p-6 flex-auto">
          <div className="space-y-2 space-x-0 items-center   justify-center flex text-center md:space-x-4 lg:space-x-8">
            <p className="text-[#1A1A1A] flex bg-[#10B21B30] rounded-[3px] text-[13px] items-center px-5 py-1 ">
              <img className="w-[1.5rem] h-[1.5rem]" src={kalyanremovebg} />{" "}
              <span>Kalyan Rewards</span>
            </p>
          </div>
          <hr class="h-[3px] mt-5 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

          <div className="text-center justify-center font-semibold text-[15px] mx-[3rem] md:mx-[7rem] py-[1rem] md:py-[2rem]">
            <p className="text-[#000000]">
              Complete your profile and get <RupeeSymbol/>5000 worth{" "}
              <span className="text-[#D10A11]">CHOTA</span> pack for{" "}
              <span className="text-[#D10A11]">FREE</span>!
            </p>
          </div>

          <div className="justify-center flex">
            <img className="w-[5rem] h-[6rem]" src={gifticon} />
          </div>

          <div className="md:grid grid-cols-3   py-[2rem] mx-[2rem] font-semibold hidden ">
            <div className="flex gap-2">
              <img className="w-5 h-5" src={validityicon} />
              <span className="text-[#000000] text-[12px]">
                {packdata?.validity}{" "}Days Validity
              </span>
            </div>
            <div className="flex gap-2">
              <img className="w-5 h-5" src={phonenoicon} />
              <span className="text-[#000000] text-[12px]">
              {packdata?.phonecount}{" "}Phone Number
              </span>
            </div>
            <div className="flex gap-2">
              <img className="w-5 h-5" src={chaticon} />
              <span className="text-[#000000] text-[12px]">
              {packdata?.Messagecount}{" "}Chat Messages
              </span>
            </div>
          </div>
          <div className="flex justify-center items-center  mt-[1.5rem] md:mt-3">
            <p className="text-[#000000] font-semibold text-[15px] md:hidden">
              Talk to your matches for{" "}
              <span className="text-[#D10A11]">FREE!!</span> with
            </p>
          </div>
          <div className=" gap-8 space-y-6 pt-[1rem] pb-[2rem] mx-[2rem] font-semibold md:hidden ">
            <div className="flex gap-3 border border-[#FDC300] px-5 py-1.5 items-center justify-center">
              <img className="w-5 h-5" src={validityicon} />
              <span className="text-[#000000] text-[12px]">
                : 7 Days Validity
              </span>
            </div>
            <div className="flex gap-3 border border-[#FDC300] px-5 py-1.5 items-center justify-center">
              <img className="w-5 h-5" src={phonenoicon} />
              <span className="text-[#000000] text-[12px]">
                : 5 Phone Number
              </span>
            </div>
            <div className="flex gap-3 border border-[#FDC300] px-5 py-1.5 items-center justify-center">
              <img className="w-5 h-5" src={chaticon} />
              <span className="text-[#000000] text-[12px]">
                : 10 Chat Messages
              </span>
            </div>
          </div>

          <div className="flex justify-center items-center text-[15px] pb-[1rem] md:pb-[1.5rem] mx-[3rem] md:mx-0 text-center">
            <p className="text-[#000000] font-semibold">
              A Complete Profile gets more
              <span className="text-[#D10A11] font-semibold">
                {" "}
                Responses!
              </span>
            </p>
          </div>

          <div className="flex md:pt-2 pb-1 justify-center gap-5">
            <button
              onClick={handelCompleteClick}
              className="text-[13px] py-1.5 px-[4rem] text-[#FFFFFF] rounded-[6px] font-bold bg-[#D10A11]"
            >
              Complete Now
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
}
    </>
  );
};

const StaticCircularProgressBar = ({ value, page, completed ,packStatusData}) => {
  const radius = 45;
  const strokeWidth = 6;
  const circumference = 2 * Math.PI * radius;
  const progress = (value / 100) * circumference; // Calculate the progress for right-to-left
  const [isShowDropDown, setSetShowDropDown] = useState(false);
  const { onClickTrack } = useAnalytics();

  useEffect(() => {}, [isShowDropDown]);

  const pointerRadius = 4; // Set the radius for the pointer dot
  const outerColorStrokeWidth = 10; // Set the desired width for the outer circle
  const innerColorStrokeWidth = 6; // Set the desired width for the inner progress bar

  return (
    <>
    <div
      className="flex flex-col justify-center items-center gap-5"
      onClick={(e) => {
        e.stopPropagation();
        onClickTrack("View_Profile_Match_Score_Match_Score_Button");
        document
          .getElementById("match-score-section")
          ?.scrollIntoView({ behavior: "smooth" });
      }}
    >
      
      <div
        className={
          "w-[127px] flex justify-center items-center"
        }
      >
        <svg viewBox="0 0 100 100">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: "#ffffff" }} />
              <stop offset="100%" style={{ stopColor: "#ffffff" }} />
            </linearGradient>
          </defs>
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="none"
            stroke="#ddd"
            strokeWidth={outerColorStrokeWidth}
          />
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="none"
            stroke="#ddd" // Outer circle color
            strokeWidth={strokeWidth}
          />
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="url(#gradient)" // Use linear gradient
            stroke="#039511" // Inner progress bar color
            strokeWidth={innerColorStrokeWidth}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={circumference - progress} // Adjust progress for right-to-left
            strokeLinecap={value < 100 ? "round" : "butt"}
            transform="rotate(-90 50 50)" // Rotate the progress bar to start from the top
          />
          {/* Pointer Dot */}
          {value < 100 && (
            <circle
              cx={
                50 +
                radius * Math.cos((value / 100) * 2 * Math.PI - Math.PI / 2)
              }
              cy={
                50 +
                radius * Math.sin((value / 100) * 2 * Math.PI - Math.PI / 2)
              }
              r={pointerRadius}
              fill="#ffffff"
            />
          )}
        </svg>
        <div
          className="flex flex-col justify-center items-center"
          style={{
            position: "absolute",
            // top:"1rem"
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // fontWeight: "bold",
            // width: "80%",
          }}
        >
          <img
            className="w-[6rem] h-[6rem] rounded-full object-cover"
            src={`${packStatusData?.profileurl ? packStatusData?.profileurl : DefaultAvatar}`}
            alt="no image"
          />
        </div>
      </div>
      <div className=" absolute">
        <p className="text-[#D10A11] relative pl-[13rem] text-[23px] md:text-[23px] lg:text-[23px] font-segoe-ui font-bold mt-1">
          {" "}
          {value}{"%"}
        </p>
      </div>
    </div>
   
    </>
  );
};



const ErrorPopup = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/3">
        <p className="text-red-500 font-semibold text-lg">{message}</p>
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 bg-gray-300 text-black rounded-lg hover:bg-gray-400"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};



export const KalyanRewardsPopup = ({ close }) => {

  const [active, setActive] = useState(0);
  const [isSkippedAfter, setIsSkippedAfter] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const RewardPoints = [
    {
      id: 1,
      points: "50",
      imgsrc: Images.TrophyIcon,
      imgtext: "Congrats! You got 50 points as Welcome Bonus",
    },
    {
      id: 2,
      points: "5",
      imgsrc: Images.LoginIcon,
      imgtext: "Log in to app daily and earn 5 points for each day",
    },
    {
      id: 3,
      points: "20",
      imgsrc: Images.EyeIcon,
      imgtext: "View any profile and earn 20 points for each profile viewed.",
    },
    {
      id: 4,
      points: "30",
      imgsrc: Images.SelectIcon,
      imgtext: "Select any profile and earn 30 points for each profile selected.",
    },
    {
      id: 7,
      points: "10",
      imgsrc: Images.DocumentIcon,
      imgtext: "Request photo, parent’s number, horoscope and trust document and earn 10 points for each request.",
    },
    {
      id: 5,
      points: "40",
      imgsrc: Images.MessageIcon,
      imgtext: "Earn 40 points by sending free messages to profiles",
    },
    {
      id: 6,
      points: "50",
      imgsrc: Images.ShareIcon,
      imgtext: "Earn 50 points for replying back to messages.",
    },
    {
      id: 11,
      points: "60",
      imgsrc: Images.MessageIcon,
      imgtext: "Earn 60 points by sending own messages to profiles",
    },
    {
      id: 8,
      points: "70",
      imgsrc: Images.CallIcon,
      imgtext: "Call your matches and e arn 70 points for each profile",
    },
    {
      id: 10,
      points: "200",
      imgsrc: Images.HeartIcon,
      isComingSoon: true,
      imgtext: "Earn points by submitting your marriage or engagement photo.",
    },
    {
      id: 9,
      points: "100",
      imgsrc: Images.RingIcon,
      isComingSoon: true,
      imgtext: "Purchase jewelry or Gold Scheme and earn points for every purchase",
    },
  ];

  const isLast = (RewardPoints.length - 1) == active || isSkippedAfter


  const handleActive = () => {
    if (isLast) {
      close();
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath), {
        replace: true,
      });
    } else {
      setActive(prev => prev + 1);
    }
  }

  const handleSkip = () => {
    if (active == 0) {
      close();
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath));
    } else {
      setIsSkippedAfter(true)
      setActive(0);
    }
  }

  useEffect(() => {
    dispatch(rewardPopupShown())
  }, [])




  return (
    <div className='flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>

      <div className='relative w-[90vw] md:w-auto  my-[0.5rem] md:my-6 lg:my-6 mx-2'>
        <button
          className=' bg-transparent z-40 border-0 text-black  absolute  top-0 -right-1.5'
          onClick={close}>
          <img className='h-6 w-6 ' src={CloseIcon} alt='' />
        </button>
        <div className='border-0 rounded-2xl shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none py-4 md:py-8 lg:py-8'>
          <div className='flex justify-center items-center'>
            <div className='flex items-center justify-center bg-[#D10A11] rounded-lg py-2 px-[2rem]'>
              <img src={KalyanLeafLogo} alt='' className='h-6 w-6 md:h-8 md:w-8 lg:h-8 lg:w-8' />
              <p className=' text-white font-extrabold'>Kalyan Rewards</p>
            </div>
          </div>
          <div className='flex justify-center items-center pt-4'>
            <p className="text-black text-[18px] font-bold">Earn points and claim rewards</p>
          </div>

          {/* <div className="px-[1rem] md:px-[5rem] lg:px-[5rem]">
            {RewardPoints.map((val,index) => {
              return (
              <div className={`grid grid-cols-6 md:grid-cols-12 gap-4 md:gap-0  py-4 md:py-4 lg:py-4`}>
                <div className="col-span-6 flex items-center gap-4">
                    <img src={val.firstpointsrc} alt="" className="w-[3rem] h-[3rem]"/>
                    <p className="text-[#000000] text-[12px]">{val.firstpoint}</p>
                </div>
                {index == RewardPoints.length - 1 ? <></> : 
                  <div className="col-span-6 flex items-center gap-4">
                      <img src={val.secondpointsrc} alt="" className="w-[3rem] h-[3rem]"/>
                      <p className="text-[#000000] text-[12px]">{val.secondpoint}</p>
                  </div>
                }
              </div>
              )
            })}
          </div> */}

          {RewardPoints.map((val, index) => {
            return (
              <>
                <div className={`${index == active ? "" : "hidden"} font-Poppins-Regular`}>
                  <div className="flex justify-center items-center">
                    <div className="relative">
                      <img src={Images.PopperIcon} alt='' className="w-full h-[11rem]" />
                      <div className={`absolute  ${val.id == 4 ? "left-0 -top-[13%]" : val.id == 7 ? "left-[0] top-[2%]" : "left-[29%] top-[2%]"}`}>
                        <img src={val.imgsrc} alt='' className={`${val.id == 4 ? "w-[11rem] h-[13rem]" : val.id == 7 ? "w-[12rem] h-[10rem]" : "w-[5rem] h-[10rem]"} `} />
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-center items-center flex-col gap-2 py-2'>
                    {val?.isComingSoon
                      ? <p className={`text-[#8292A8] font-Poppins-SemiBold text-[18px]`}>Coming soon!</p>
                      : <p className={`${val.id == 1 ? "text-[#D10A11]" : "text-[#8292A8]"} font-Poppins-Bold text-[26px] lg:text-[25px]`}>{val.points} Points<span className="text-[#D10A11] font-bold"> *</span></p>}
                    <p className="text-black font-semibold text-[12] w-[20rem] md:w-[25rem] px-4 text-center">{val.imgtext}</p>
                    <div className={`flex items-center gap-2 cursor-pointer ${(isLast) ? "hidden" : ""}`} onClick={handleSkip}>
                      <p className="text-[#8D9AAD] text-[14px] font-bold underline">Skip</p>
                      <img src={Images.SkipIcon} alt='' className='h-3 w-3' />
                    </div>
                  </div>
                  <div className='flex justify-center items-center py-2'
                    onClick={handleActive}>
                    <div className='flex items-center justify-center bg-[#D10A11] rounded-md py-2 px-[6.5rem] cursor-pointer'>
                      <p className=' text-white font-extrabold text-[18px]'>{isLast ? "Know More" : "Next"}</p>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
      <PaperBlast />
    </div>
  )
}

const PromptPopup = (props) => {
  const { onClickTrack } = useAnalytics();
  const closePopupRef = useRef();
  const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);
  console.log(props.promptFrom,'ppp')
  //for parents number
  const isParentsNumberAvailble = profileData?.profiledetails?.profileparentsnumber != "";
  const isParentsNumberRequested = profileData?.profiledetails?.parentnumberrequestcheck;

  //for photo
  const isProfilePicAvailable = !profileData?.profiledetails?.isAvatarPic;
  const isProfilePicRequested = profileData?.profiledetails?.profilerequestcheck;

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  // Set all checkboxes to be selected by default
  const [selectedRequests, setSelectedRequests] = useState({
    photo: true,
    trust: true,
    parentNumber: true,
    horoscope: true,
  });

  useEffect(() => {
    let handler = (e) => {
      if (!closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [props]);

  const handleCheckboxChange = (requestType) => {
    setSelectedRequests((prevRequests) => ({
      ...prevRequests,
      [requestType]: !prevRequests[requestType],
    }));
  };

  const promptRequest = () => {
    if(profileData?.horoscopeRequest === "N"){
      if (selectedRequests.horoscope) {
        props.sendRequestApi("Horoscope");
      }
    }
    if(profileData?.trustRequest === "N" ){
      if (selectedRequests.trust) {
        props.sendRequestApi("Trust");
      }
    }
    if(isProfilePicAvailable === false && isProfilePicRequested === false) {
      if (selectedRequests.photo) {
        props.sendRequestApi("Photo");
      }
    }
    if(isParentsNumberAvailble === false && isParentsNumberRequested === false){
      if (selectedRequests.parentNumber) {
        props.sendRequestApi("Parents Number");
      }
    }
    if (props.promptFrom === "Share to Family Yes") {
      props.sendRequest();
      props.setPrompt();
    }
    if(props.promptFrom === "Share to Family"){
      props.shareToFamily();
    }
    props.close();
    onClickTrack(EventName.ViewProfilePromptPopupRequestButton);
  };

  return (
    <>
      <div className="flex justify-center bg-opacityblack75 items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative w-[100%] md:w-[55%] lg:w-[36%] modal-container md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
              onClick={() => {
                props.close();
                if (props.promptFrom === "Share to Family Yes") {
                  props.sendRequest();
                  props.setPrompt();
                }
                if(props.promptFrom === "Share to Family"){
                  props.shareToFamily();
                }
              }}
            >
              <img className="h-6 w-6" src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative p-6 flex-auto">
              <div className="space-y-1">
                <p className="text-[#D10A11] font-bold text-[18px]">
                  Know them more!
                </p>
                <p className="text-[#7A7A7A] pl-1 text-[13px] pt-2 pb-2 leading-[24px]">
                  Know more about this profile by sending requests. 
                  <br />
                  Request :
                </p>
                <div className="grid grid-cols-2 gap-2">
                  {(isProfilePicAvailable === false && isProfilePicRequested === false) && (
                    <div className="flex items-center lg:mt-3 mt-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.photo}
                        onChange={() => handleCheckboxChange("photo")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                        Photo
                      </label>
                    </div>
                  )}
                  {profileData?.trustRequest === "N" && (
                    <div className="flex items-center lg:mt-3 mt-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.trust}
                        onChange={() => handleCheckboxChange("trust")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                        Trust Document
                      </label>
                    </div>
                  )}
                  {(isParentsNumberAvailble === false && isParentsNumberRequested === false) && (
                    <div className="flex items-center lg:mt-3 mt-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.parentNumber}
                        onChange={() => handleCheckboxChange("parentNumber")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                        Parent's Number
                      </label>
                    </div>
                  )}
                  {profileData?.horoscopeRequest === "N" && (
                    <div className="flex items-center lg:mt-3 mt-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRequests.horoscope}
                        onChange={() => handleCheckboxChange("horoscope")}
                      />
                      <label className="mx-2 font-semibold text-[13px] md:text-[10px] lg:text-[13px] text-[#575556] cursor-pointer">
                        Horoscope
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex pt-3 pb-4 justify-center gap-5">
                <button
                  onClick={() => {
                    props.close();
                    if (props.promptFrom === "Share to Family Yes") {
                      props.sendRequest();
                      props.setPrompt();
                    }
                    if(props.promptFrom === "Share to Family"){
                      props.shareToFamily();
                    }
                    onClickTrack(EventName.ViewProfilePromptPopupCancelButton);
                  }}
                  className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] rounded-[4px] border text-[#7A7A7A] border-[#707070]"
                >
                  Cancel
                </button>
                <button
                  className="text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#FFFFFF] font-bold rounded-[4px] bg-red-600"
                  onClick={promptRequest}
                >
                  Request
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
}

const CustomerCareTemplateMsgPopup = (props) => {


  console.log(props?.conversations, props?.customerTemplate,"conversationspro");
  const closePopupRef = useRef();
  const dispatch = useDispatch();


  const userData = useSelector((state) => state?.DashBoardAPI?.ProfileData?.data);
  const {currentConversationId } = useSelector((state) => ({currentConversationId: state?.Chats?.currentChat?.conversationId}));
  
  useEffect(()=>{
    let handler = (e) => {
      if(!closePopupRef.current.contains(e.target)){
        if(window.innerWidth < 768){
          props.close();        }
      }
    }
    document.addEventListener('mousedown',handler)

    return() => {
      document.removeEventListener('mousedown',handler)
    }
  })

  // const handleTextClick = (e, text, imageSrc) => {
  //   // Fetch the image as a binary file
  //   fetch(imageSrc)
  //     .then(res => res.blob())
  //     .then(blob => {
  //       const imageFile = new File([blob], "image.png", { type: blob.type });
        
  //       dispatch(
  //         sendCustomerServiceMessage({
  //           conversation_id: currentConversationId,
  //           file: text, // Pass only the text content as 'file'
  //           message_type_id: 7,
  //           template_image: imageFile, // Pass the binary image file as 'template_image[]'
  //         }),
  //       );
  //     });
  
  //   // Update the `is_popup_show` in the Redux state to false
  //   dispatch({
  //     type: 'UPDATE_IS_POPUP_SHOW',
  //     payload: {
  //       conversationId: currentConversationId,
  //       is_popup_show: false,
  //     },
  //   });
  
  //   props.close(); // Close the popup
  // };
  
  
  
  
  const handleTextClick = (e, text, imageSrc, templateId) => {
  
    dispatch(
      sendCustomerServiceMessage({
        conversation_id: currentConversationId,
        file: text,
        message_type_id: 7,
        message_template_id: templateId,
      }),
    );
  
    // Update the `is_popup_show` in the Redux state to false
    dispatch({
      type: 'UPDATE_IS_POPUP_SHOW',
      payload: {
        conversationId: currentConversationId,
        is_popup_show: false,
      },
    });
  
    props.close(); // Close the popup
  };
  
  
  console.log(props.showpopup,'props.showpopup')

  const handleChatClick = () => {
    document.getElementById("customerSupport")?.focus();
    props.close();
  }

  const handleClose = () => {
    props.close();
    dispatch({
      type: 'UPDATE_IS_POPUP_SHOW',
      payload: {
        conversationId: currentConversationId,
        is_popup_show: false,
      },
    });  };

    useEffect(()=>{
      props?.setCustomerTemplate(true)
    },[props?.customerTemplate])

    console.log(props?.isConversationLoading,"props?.isConversationLoading")
  return (
    <>
    {props?.isConversationLoading?
    <Loader/>
    :
      <div className="flex justify-center bg-opacityblack  items-end md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative lg:w-[30vw] md:w-[55vw] w-full md:my-6 lg:my-6" ref={closePopupRef}>
          <div className="hidden md:block lg:block">
            <button
              className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
              onClick={handleClose}
            >
              <img className="h-5 w-5 " src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="rounded-t-[3rem] md:rounded-lg lg:rounded-lg border-0  shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center px-4 pt-8 justify-center mx-2  rounded-t ">
            <h3 className="text-[20px] ml-2 font-semibold align-middle text-center">
                  Welcome To <span className="text-[#D10A11]">Kalyan Matrimony!</span>
                 <p className="flex justify-center">We are here to hear your queries.</p>
                  <p className="flex justify-center">Feel free to ask.</p>
              </h3>
            </div>
            <div className='relative px-6 pb-6 pt-3 flex-auto'>
              <div className='flex-auto items-center '>
              <div className="flex justify-center py-4">
                    <p
                        className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                        onClick={(e) => handleTextClick(e, "How to Search Matches?", Images.RedLensIcon, 1)}
                    >
                        How to <img src={Images.RedLensIcon} className="w-4 h-4 space-x-4" alt="" />Search Matches?
                    </p>
                </div>
                <hr />
                <div className="flex justify-center py-4">
                    <p
                        className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                        onClick={(e) => handleTextClick(e, "How to Search with Family?", Images.FamilyIcon, 2)}
                    >
                        How to <img src={Images.FamilyIcon} className="w-4 h-4 space-x-4" alt=""/>Search with Family?
                    </p>
                </div>
                <hr />
                <div className="flex justify-center py-4">
                    <p
                        className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                        onClick={(e) => handleTextClick(e, "How to Add a Profile Photo?", Images.RedCameraIcon, 3)}
                    >
                        How to <img src={Images.RedCameraIcon} className="w-4 h-4 space-x-4" alt=""/>Add a Profile Photo?
                    </p>
                </div>
                <hr />
                <div className="flex justify-center py-4">
                    <p
                        className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                        onClick={(e) => handleTextClick(e, "How to Complete My Profile?", Images.UserRedIcon, 4)}
                    >
                        How to <img src={Images.UserRedIcon} className="w-4 h-4 space-x-4" alt=""/>Complete My Profile?
                    </p>
                </div>
                <hr />
                <div className="flex justify-center py-4">
                    <p
                        className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                        onClick={(e) => handleTextClick(e, "How to Call Matches?", Images.PhoneIconRed, 5)}
                    >
                        How to <img src={Images.PhoneIconRed} className="w-4 h-4 space-x-4" alt=""/>Call Matches?
                    </p>
                </div>
                <hr />
                <div className="flex justify-center py-4">
                    <p
                        className="text-[#0875AA] flex items-center gap-2 cursor-pointer"
                        onClick={() => handleChatClick()}
                    >
                        Chat<img src={Images.ChatMsgIconRed} className="w-4 h-4 space-x-4" alt=""/>with us!
                    </p>
                </div>

              </div>
            </div>
           </div>
           </div>
           </div>
         }
            </>
  )}
        


const RankingStartPopup = (props) => {
  const closePopupRef = useRef();
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate for potential future routing needs
  localStorage.setItem('ratingClicked', JSON.stringify(
    'Rating link clicked'
  ));

  useEffect(() => {
    const mobileQuery = window.matchMedia("(max-width: 768px)");
    
    const handler = (e) => {
      if (closePopupRef.current && !closePopupRef.current.contains(e.target)) {
        props.close();
      }
    };
  
    const addListeners = () => {
      if (mobileQuery.matches) {
        document.addEventListener("mousedown", handler);
      }
    };
  
    const removeListeners = () => {
      document.removeEventListener("mousedown", handler);
    };
  
    addListeners();
    return () => {
      removeListeners();
    };
  }, [props]);

  const handleFeedbackChange = (e) => {
    const value = e.target.value;
    if (value.length <= 250) {
      setFeedback(value);
      setError('');
    } else {
      setError('Feedback cannot exceed 250 characters');
    }
  };

  const handleSendClick = () => {
    if (rating > 0) { // Ensure rating is provided
      dispatch(
        RankingPopup({
          profile_id: localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
          agent_id: localStorage.getItem(Constants.loginLocalStorageKeys.admUserId),
          message_id: localStorage.getItem(Constants.loginLocalStorageKeys.messageId),
          ratings: rating,
          comments: feedback,
        })
      );
      if (rating === 4 || rating === 5) {
        window.open(RouteSegments.staticLinkForAppDownload.android, '_blank'); 
        props.close(); 
        localStorage.setItem(Constants.loginLocalStorageKeys.RankingClick,'No') 
      } else {
        props.close(); // Optionally close the popup after sending feedback
        localStorage.setItem(Constants.loginLocalStorageKeys.RankingClick,'No')
      }
    } else {
      setError('Rating is required');
    }
  };

  const RatingStars = () => {
    const colors = {
      red: "#D71920",
      grey: "#C4C4C4"
    };

    const [hover, setHover] = useState(undefined);

    const handleClick = (value) => {
      setRating(value);
    };

    const handleMouseOver = (value) => {
      setHover(value);
    };

    const handleMouseLeave = () => {
      setHover(undefined);
    };

    const stars = Array(5).fill(0);

    return (
      <div className="flex space-x-4">
        {stars.map((_, index) => {
          const starColor = (hover || rating) > index ? colors.red : colors.grey;

          return (
            <FaStar 
              key={index} 
              size={24}
              style={{ marginRight: 10, cursor: "pointer", color: starColor }}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-center bg-opacityblack75 items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative w-[100%] md:w-[55%] lg:w-[42%] xl:w-[36%] modal-container md:my-6 md:mx-6"
          ref={closePopupRef}
        >
          <div className="">
            <button
              className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-0 right-[-6px]"
              onClick={props.close}
            >
              <img className="h-6 w-6" src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none px-9">
            <div className="flex items-center py-6 lg:justify-center justify-start border-b border-[#DBDBDB]">
              <h3 className="text-[19px] text-[#D10A11] ml-2 font-Poppins-Bold align-middle">
                Rate Us
              </h3>
            </div>
            <div className="flex flex-col items-center py-5 justify-center border-b border-[#DBDBDB] space-y-2">
              <h3 className="text-[14px] text-[#1A1A1A] font-Poppins-SemiBold text-center">
                Rate Your Experience
              </h3>
              <div className="flex items-center space-x-4 pt-4">
                <img src={SadImage} className="w-[2rem] h-[2rem]" alt="Sad" />
                <RatingStars />
                <img src={HappyImage} className="w-[2rem] h-[2rem]" alt="Happy" />
              </div>
            </div>

            <div className="flex-col items-center justify-center text-center px-5 pt-4">
              <h3 className="text-[11px] text-[#1A1A1A] font-Poppins-Reqular lg:mx-[3.5rem] mx-2">
                We would love to hear from you. Feel free to share your valuable feedback/suggestions here
              </h3>
            </div>

            <div className="flex-col items-center justify-center text-center lg:px-8 pt-2">
              <div className="pt-4">
                <input
                  type="text"
                  className="border border-[#C4C4C4] w-full rounded-md font-Poppins font-medium text-[#000000] py-2 px-2 text-[12px] lg:w-[20rem]"
                  id="feedback"
                  placeholder="Feedback/suggestions"
                  value={feedback}
                  onChange={handleFeedbackChange}
                />
                {error && <p className="text-[#D10A11] text-xs">{error}</p>}
              </div>
            </div>

            <div className="flex items-center py-4 justify-center">
              <button
                onClick={handleSendClick}
                className={`text-[13px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#000000] font-Poppins-Bold rounded-[4px] ${
                  rating > 0 ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                disabled={rating === 0} // Disable the button if rating is not given
                style={{
                  background: 'linear-gradient(90deg, #FDC300 0%, #E59903 100%)'
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


// 
const ValidationNotificationPopup = (props) => {
  const message = props?.message
  
  useEffect(() => {
      if (message) {
          toast(
          <div className='flex items-center'>
              <img src={Images.NotificatationTick} className='w-10 h-10 mr-4' alt='' />
              {message}
          </div>,
              {
                  hideProgressBar: true,
                  onClose: () => {
                      props?.onClose()
                  },
                  // style: { backgroundColor: '#2f9e44',color:"white" }
              }
          )
      }
  }, [message])


  return <></>
};


export {
  ErrorPopup,
  CommonAstroPopup,
  RemovePopup,
  ReInviteModal,
  CommonCastPopup,
  LoginVia,
  ViewPhotoPasswordPopup,
  CommonPopupWithoutHeader,
  CommonSuccessPopup,
  CommonPopup,
  MessageSuccessPopup,
  CommonErrorPopup,
  UploadPhotoPopup,
  NotificationPopup,
  SuspendedPopup,
  FilterPopup,
  RegSuccessPopup,
  ImageSuccessPopup,
  ReligionMigrationPopup,
  EmailAddPopup,
  CommonCameraPopup,
  CommonMutePopup,
  CommonBlockPopup,
  CommonReportPopup,
  CommonPayNowPopup,
  CommonPayBackPopup,
  CommonDownloadmediaPopup,
  CommonConfrimPopup,
  CommonHelpPopup,
  CommonUnBlockPopup,
  ApplyEditPreferencePopup,
  CommonDangerPopup,
  CommonMessagePopup,
  CommonParentPopup,
  CommonValidPopup,
  CommonNoMatchesPopup,
  CommonSuccessfullUpload,
  LogoutPopup,
  CommonFamilyFriendPopup,
  CommonChooseFamilyPopup,
  CommonNewPopup,
  UploadPhotoPopuplite,
  CommonProfilePopup,
  PaymentWhatsAppPopup,
  AddFamilyMemberPopup,
  SharePopup,
  PromptPopup,
  CommonRewardPopup,
  StaticCircularProgressBar,
  CommonQualityPopup,
  CommonHabitPopup,
  CommonFamilyPopup,
  CommonHoroPopup,
  CustomerCareTemplateMsgPopup,
  RankingStartPopup,
  ValidationNotificationPopup,
  
  
};

