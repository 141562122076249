import { post_method, get_method, cms_post_method } from './axios_helpers'
import {
    LOGIN, BASIC_DATA, RESET_PASSWORD,
    REGISTER, REGISTER_DETAIL, REGISTER_EMAIL_CHECK, KJBANNER_DETAIL,
    REGISTER_PHONE_CHECK, CASTE_LIST, SUB_CASTE_LIST,
    RAASI_LIST, COUNTRY_LIST, STATE_LIST, PINCODE_LIST, REGISTER_SUBMIT, CITY_LIST, SEND_OTP,
    OTP_VALIDATION, MOBILE_VERIFICATION, REQUEST_AGAIN, MOBILE_VERIFICATION_FRESH,
    CHANGE_MOBILE_NO, MORE_LINK_CONTENT, HELP_SAVE, MEMBERSHIP_DETAILS, CALLBACK_DETAIL,
    APPLY_COUPEN, HELP_DATA_DETAIL_BEFORE_LOGIN, HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT,
    PAY_NOW, MESSAGE_THREAD, SENT_MESSAGE, FILTER_REQUEST, STORE_LIST,
    STATE_LIST_APPOINTMENT, STORE_LIST_APPOINTMENT, BOOK_APPOINTMENT,
    BLOCK_PROFILE, UNBLOCK_PROFILE, HOROSCOPE_MATCH, ASTRO_BALANCE,
    STAR_LIST, SEARCH_BY_ID, SEARCH_BY_CITY, SEARCH_BY_EDUCATION, SEARCH_BY_OCCUPATION,
     SET_CHAT_MUTE, SEARCH_BY_NAME,
    FAMILIY_CHAT_LIST, FAMILIY_CHAT_DEATAIL, GROUP_CONVERSATION, CHAT_SEND_GROUP_MESSAGE,
    FAMILY_CHAT_REACTIONS,
    GROUP_CHAT_LIST, FAMILY_STAR_MESSAGE, DELETE_GROUP_MESSAGE, READ_GROUP_MESSAGE_API,
    MOBILE_VRIFICATION_FRESH,
    SEARCH_BY_FILTER, ADMIN_LOGIN, SMS_LOGIN, EMAIL_LOGIN,
    COMMUNITY_DETAIL, AKHAYATRITIYA, PARTIAL_ADMIN_LOGIN,
    DYNAMIC_PIXEL_DATA, SEARCH_PAGE_CHECK_REQUEST,
    SUB_CASTE_PROFILE_LIST, VALIDATE_NAME_API, STORE_REGISTER_DETAIL, STORE_REGISTER_FORM_SUBMIT, DASHBOARD_MATCHES_LIST_API,
     DASHBOARD_LEFT_PANEL_API, DASHBOARD_PROFILE_DATA_API, CONTENT_CARD_CLICK_REQUEST, DENOMINATION_LIST, UPDATE_NOTIFICATION_STATUS,
    CONVERSATION_STATUS, MULTI_REQUEST_TYPE, PROFILE, INITIAL_MATCHES, SELECTED_MATCHES, CONVERSATION, CHAT_LIST, SEARCH_CHAT_LIST,
     DELETE_MESSAGE, CHAT_SEND_MESSAGE,
    GET_MEDIA_FILES, VIEW_DOCUMENT, STAR_MESSAGE, DASHBOARD, SEARCH_LIST, LOGIN_VIA, GET_BULK_REQUEST, OFFLINE_PROFILE,
    OFFLINE_LOGIN,
    VIEW_PROFILE_LOGIN,
    MATCH_SCORE,
    CUSTOMER_SERVICE_CONVERSATION,
    CHAT_SEND_CUSTOMER_SERVICE_MESSAGE,
    CHAT_UNREAD_MSG_COUNT,
    FAMILY_SET_FAMILY_CODE,
    FAMAILYCODELOGIN,
    ACTIVITY_SCORE_LIST,
    STRORE_ACTIVITY_SCORE_LIST,
    GET_TOTAL_POINTS,
    ACTIVITY_POPUP_COUNT,
    KMC_CREATE_CONVERSATION,
    RANKING_POPUP,
    UNREAD_MSG_COUNT,
    PROFILE_CHAT_SEND_MESSAGE,
    NOTIFICATION_CLICKED_TRACKING
} from './EndPoints'
import { api, base } from "../config";
import axios from "axios";
import { DYNAMIC_PIXEL } from '../Store/Payment/ActionTypes';


// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";


export const Login = data => post_method(api.API_URL + base.URL + LOGIN, data);
export const AdminLogin = data => get_method(api.API_URL + base.URL + ADMIN_LOGIN + `?jwt=${data.jwt}${data.afterregister ? `&afterregister=${data.afterregister}` : ""}&admuserid=${data.admuserid}`);
export const PartialAdminLogin = data => get_method(api.API_URL + base.URL + PARTIAL_ADMIN_LOGIN + `?jwt=${data.jwt}&admuserid=${data.admuserid}`);
export const SMSLogin = data => post_method(api.API_URL + base.URL + SMS_LOGIN, data);
export const EmailLogin = data => post_method(api.API_URL + base.URL + EMAIL_LOGIN, data);
export const AkhayatritiyaLogin = data => post_method(api.API_URL + base.URL + AKHAYATRITIYA, data);
export const offlineProfileLogin = data => post_method(api.API_URL + base.URL + OFFLINE_LOGIN, data);
export const viewProfileLogin = data => post_method(api.API_URL + base.URL + VIEW_PROFILE_LOGIN, data);
export const CommunitySiteData = data => post_method(api.API_URL + base.URL + COMMUNITY_DETAIL, data);
export const BannerRedirectionData = (pathname, param) => get_method(api.API_URL + base.URL + pathname + param);
export const SiteMapData = data => get_method(api.API_URL + base.URL + data);
export const ChangeMobileNo = data => post_method(api.API_URL + base.URL + CHANGE_MOBILE_NO, data);
export const SendOTP = data => post_method(api.API_URL + base.URL + SEND_OTP, data);
export const OTPValidation = data => post_method(api.API_URL + base.URL + OTP_VALIDATION, data);
export const MobileVerification = data => post_method(api.API_URL + base.URL + MOBILE_VERIFICATION, data);
export const MobileVerificationFresh = data => post_method(api.API_URL + base.URL + MOBILE_VERIFICATION_FRESH, data);
export const RequestAgain = data => post_method(api.API_URL + base.URL + REQUEST_AGAIN, data);
export const ResetPassword = data => post_method(api.API_URL + base.URL + RESET_PASSWORD, data)
export const Register = data => post_method(api.API_URL + base.URL + REGISTER, data);
export const Register_email_check = data => post_method(api.API_URL + base.URL + REGISTER_EMAIL_CHECK, data);
export const Register_phone_check = data => post_method(api.API_URL + base.URL + REGISTER_PHONE_CHECK, data);
export const Register_Detail = () => get_method(api.API_URL + base.URL + REGISTER_DETAIL);
export const MoreLinkContent = () => get_method(api.API_URL + base.URL + MORE_LINK_CONTENT + (window.location.pathname));
export const kjbanner_Detail = () => post_method(api.API_URL + base.URL + KJBANNER_DETAIL)

export const Update_Notification_Status = data => post_method(api.API_URL + base.URL + UPDATE_NOTIFICATION_STATUS, data)

export const BasicData = data => post_method(api.API_URL + base.URL + BASIC_DATA, data);
export const Caste_List = data => get_method(api.API_URL + base.URL + CASTE_LIST + `?motherTongueId=${data.motherTongueId}&religionId=${data.ReligionId}`);
export const Sub_Caste_List = data => get_method(api.API_URL + base.URL + SUB_CASTE_LIST + `?casteId=${data.casteId}`);
export const Profile_Sub_Caste_List = data => get_method(api.API_URL + base.URL + SUB_CASTE_PROFILE_LIST + `?casteId=${data.casteId}`);
export const Star_list = data => get_method(api.API_URL + base.URL + STAR_LIST + `?motherToungeId=${data.motherTongueId}`);
export const Raasi_list = data => get_method(api.API_URL + base.URL + RAASI_LIST + `?starId=${data.starId}&motherToungeId=${data.motherTounge}`);
export const Country_List = data => get_method(api.API_URL + base.URL + COUNTRY_LIST + `?countryId=${data.countryId}`);
export const State_List = data => get_method(api.API_URL + base.URL + STATE_LIST + `?countryId=${data.countryId}`);
export const City_List = data => get_method(api.API_URL + base.URL + CITY_LIST + `?stateId=${data.stateId}`);
export const Pincode_List = data => get_method(api.API_URL + base.URL + PINCODE_LIST + `?q=${data.pincode}`);
export const Register_Submit = data => post_method(api.API_URL + base.URL + REGISTER_SUBMIT, data);
export const Validate_Name = data => post_method(api.API_URL + base.URL + VALIDATE_NAME_API, data);
export const Denomination_list = data => get_method(api.API_URL + base.URL + DENOMINATION_LIST);

//payment page
export const Membership_details = data => post_method(api.API_URL + base.URL + MEMBERSHIP_DETAILS, data)
export const Callback_api = data => post_method(api.API_URL + base.URL + CALLBACK_DETAIL, data)
export const Coupen_Code = data => post_method(api.API_URL + base.URL + APPLY_COUPEN, data)
export const Pay_Now = data => post_method(api.API_URL + base.URL + PAY_NOW, data)


//FAQ
export const helpDataBeforeLogin = () => get_method(api.API_URL + base.URL + HELP_DATA_DETAIL_BEFORE_LOGIN)
export const helpDataBeforeLoginSubmit = data => post_method(api.API_URL + base.URL + HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT, data)

//Conversation
export const Message_Thread = data => post_method(api.API_URL + base.URL + MESSAGE_THREAD, data)
export const Sent_Message = data => post_method(api.API_URL + base.URL + SENT_MESSAGE, data)
export const Block_profile = data => post_method(api.API_URL + base.URL + BLOCK_PROFILE, data)
export const Unblock_profile = data => post_method(api.API_URL + base.URL + UNBLOCK_PROFILE, data)

//Filter Section
export const Send_Filter_Request = data => post_method(api.API_URL + base.URL + FILTER_REQUEST, data)

//help section
export const Help_Save = data => post_method(api.API_URL + base.URL + Help_Save, data)

//book appointment
export const Get_State_List = data => post_method(api.API_URL + base.URL + STATE_LIST_APPOINTMENT, data)
export const Get_Store_List = data => post_method(api.API_URL + base.URL + STORE_LIST_APPOINTMENT, data)
export const Book_Appointment = data => post_method(api.API_URL + base.URL + BOOK_APPOINTMENT, data)

//matching horoscope
export const Get_Astro_Balance = () => post_method(api.API_URL + base.URL + ASTRO_BALANCE)
export const Get_Horoscope_Match = data => post_method(api.API_URL + base.URL + HOROSCOPE_MATCH + `?oppositeProfile=${data}`)

//search by id
export const Search_By_Id = data => post_method(api.API_URL + base.URL + SEARCH_BY_ID, data)

//explore
export const Search_By_City = data => post_method(api.API_URL + base.URL + SEARCH_BY_CITY, data)
export const Search_By_Education = data => post_method(api.API_URL + base.URL + SEARCH_BY_EDUCATION, data)
export const Search_By_Occupation = data => post_method(api.API_URL + base.URL + SEARCH_BY_OCCUPATION, data)
export const Search_By_Filter = data => post_method(api.API_URL + base.URL + SEARCH_BY_FILTER, data)

//pixel
export const Dynamic_Pixel = data => post_method(api.API_URL + base.URL + DYNAMIC_PIXEL_DATA, data)

//search page check Section
export const Send_Search_Page_Check_Request = data => post_method(api.API_URL + base.URL + SEARCH_PAGE_CHECK_REQUEST, data)

//content card onclick track
export const Content_Card_Onclick = data => post_method(api.API_URL + base.URL + CONTENT_CARD_CLICK_REQUEST, data)
//store registration
export const Store_Register_Detail = () => get_method(api.API_URL + base.URL + STORE_REGISTER_DETAIL);
export const Store_Register_Form_Submit = data => post_method(api.API_URL + base.URL + STORE_REGISTER_FORM_SUBMIT, data);

//dashboard optimization
export const Dashboard_Matches_List = data => post_method(api.API_URL + base.URL + DASHBOARD_MATCHES_LIST_API, data)
export const Dashboard_Left_Panel = data => post_method(api.API_URL + base.URL + DASHBOARD_LEFT_PANEL_API, data)
export const Dashboard_Profile_Data = data => post_method(api.API_URL + base.URL + DASHBOARD_PROFILE_DATA_API, data)






//match request
export const Matches_Request = data => post_method(api.API_URL + base.URL + MULTI_REQUEST_TYPE, data)

//get match profile by id
export const Matches_Score = data => post_method(api.API_URL + base.URL + MATCH_SCORE, data)

//get match expectations profile by id
export const Matches_Profile = data => post_method(api.API_URL + base.URL + PROFILE, data)

//get match profile by id
export const Initial_Matches = data => post_method(api.API_URL + base.URL + INITIAL_MATCHES, data)

//get selected matches
export const Selected_Matches = data => post_method(api.API_URL + base.URL + SELECTED_MATCHES, data)

//cms API's for chat
export const CMS_Login = data => post_method(api.chat_api + base.URL + LOGIN, data);
export const CMS_Login_via = data => post_method(api.chat_api + base.URL + LOGIN_VIA, data);
export const Conversation_Data = data => cms_post_method(api.chat_api + base.URL + CONVERSATION, data)
export const Chats_List = data => cms_post_method(api.chat_api + base.URL + CHAT_LIST, data)
export const Group_Chats_List = data => cms_post_method(api.chat_api + base.URL + GROUP_CHAT_LIST, data)
export const Get_Bulk_Request = data => cms_post_method(api.chat_api + base.URL + GET_BULK_REQUEST, data)
export const Search_Chats_List = data => cms_post_method(api.chat_api + base.URL + SEARCH_CHAT_LIST, data)
export const Delete_Message = data => cms_post_method(api.chat_api + base.URL + DELETE_MESSAGE, data)
// FAMILY CODE LOGIN FAMAILYCODELOGIN
export const familycode_login = data => post_method(api.chat_api + base.URL + FAMAILYCODELOGIN, data);


//group chat list
export const Group_Conversation_Data = data => cms_post_method(api.chat_api + base.URL + GROUP_CONVERSATION, data)

//send message
export const Send_Message = data => cms_post_method(api.chat_api + base.URL + CHAT_SEND_MESSAGE, data)
//send group message
export const Send_Group_Message = data => cms_post_method(api.chat_api + base.URL + CHAT_SEND_GROUP_MESSAGE, data)
// send customer service message
export const Send_Customer_Service_Message = data => cms_post_method(api.chat_api + base.URL + CHAT_SEND_CUSTOMER_SERVICE_MESSAGE, data)

//conversation status
export const Conversation_Status = data => cms_post_method(api.chat_api + base.URL + CONVERSATION_STATUS, data)


export const Get_Media_Files = data => cms_post_method(api.chat_api + base.URL + GET_MEDIA_FILES, data)

export const Set_Chat_Mute = data => cms_post_method(api.chat_api + base.URL + SET_CHAT_MUTE, data)


export const sagaPost = data => axios.post(data.url, data.request)


//search by name
export const Search_By_Name = data => post_method(api.API_URL + base.URL + SEARCH_BY_NAME, data)

export const dynamicFilterMatches = data => post_method(api.API_URL + data.url, data.request)

export const View_Document = data => post_method(api.API_URL + base.URL + VIEW_DOCUMENT, data)

export const Star_Message = data => cms_post_method(api.chat_api + base.URL + STAR_MESSAGE, data)
export const Offline_Profile = data => cms_post_method(api.chat_api + base.URL + OFFLINE_PROFILE, data)

//Get match data
export const Matches_List = data => post_method(api.API_URL + base.URL + DASHBOARD, data)

export const Search_list = data => post_method(api.API_URL + base.URL + SEARCH_LIST, data);

//Get family chat list
export const Family_Chats_List = data => cms_post_method(api.chat_api + base.URL + FAMILIY_CHAT_LIST, data)

export const Family_Chats_Detail = data => cms_post_method(api.chat_api + base.URL + FAMILIY_CHAT_DEATAIL, data)
//add reactions
export const Family_Chats_Reactions_Add = data => cms_post_method(api.chat_api + base.URL + FAMILY_CHAT_REACTIONS, data)

// delete group message
export const Delete_Group_Message = data => cms_post_method(api.chat_api + base.URL + DELETE_GROUP_MESSAGE, data);
//group star message
export const Family_Chats_STAR_MESSAGE = data => cms_post_method(api.chat_api + base.URL + FAMILY_STAR_MESSAGE, data)


export const Read_Group_Message = data => cms_post_method(api.chat_api + base.URL + READ_GROUP_MESSAGE_API, data)

// Customer Service Conversation
export const CustomerService_Conversation_Data = data => cms_post_method(api.chat_api + base.URL + CUSTOMER_SERVICE_CONVERSATION, data)

export const Kmcare_Create_Conversation = data => cms_post_method(api.chat_api + base.URL + KMC_CREATE_CONVERSATION, data)

// UnreadMsgCount
export const Unread_Msg_Count = data => cms_post_method(api.chat_api + base.URL +CHAT_UNREAD_MSG_COUNT, data)
export const UnreadMsg_Count = data => cms_post_method(api.chat_api + base.URL +UNREAD_MSG_COUNT, data)

// RankingPopup
export const Ranking_Popup = data => cms_post_method(api.chat_api + base.URL + RANKING_POPUP, data)

// SET_Family_code
export const SET_Family_code = data => post_method(api.API_URL + base.URL + FAMILY_SET_FAMILY_CODE, data)

export const Activity_Store_List = data => axios.get(api.chat_api + base.URL + ACTIVITY_SCORE_LIST, data)

export const Store_Activity_Store_List = data => cms_post_method(api.chat_api + base.URL + STRORE_ACTIVITY_SCORE_LIST, data)

export const Get_Total_Points = data => cms_post_method(api.chat_api + base.URL + GET_TOTAL_POINTS, data)

export const Activity_Popup_Count = data => post_method(api.API_URL + base.URL + ACTIVITY_POPUP_COUNT, data)

// 
export const Profile_Send_Message = data => cms_post_method(api.chat_api + base.URL + PROFILE_CHAT_SEND_MESSAGE, data)

export const Notification_Clicked_Tracking = data => post_method(api.API_URL + base.URL + NOTIFICATION_CLICKED_TRACKING, data)
